import * as echarts from 'echarts/core';

import { BarChart, LineChart, PieChart, FunnelChart } from 'echarts/charts';

import {
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
  AriaComponent,
  ParallelComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';

import { SVGRenderer } from 'echarts/renderers';

echarts.use([
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  GridComponent,
  AriaComponent,
  ParallelComponent,
  BarChart,
  LineChart,
  PieChart,
  FunnelChart,
  SVGRenderer,
  DataZoomComponent,
]);

export default echarts;
