import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import { getAttribution, getProductClass, get_time_zone } from '@/api/commonList';
import store from '@/store';
import { ICountry, IEmployee, SelectOption } from 'types/store';
import { get_ml_account_apply_const } from '@/api/common-cms';
import { get_company_list } from '@/api/meta';
export interface ICommonListsState {
  pm_am_bd: SelectOption[]; // PMAMBD来自chiefs
  opUserList: SelectOption[];
  productStatus: SelectOption[];
  productSubClass: {
    id: number;
    name: string;
    product_class_1_id: number;
    product_class_3_list: any;
  }[];
  platform: SelectOption[]; // 适用平台
  currency: SelectOption[]; // 币种
  attribution: SelectOption[]; // 归因平台-接口
  productClass: {
    id: number;
    name: string;
    product_class_2_list: {
      id: number;
      name: string;
      product_class_1_id: number;
      product_class_3_list: any;
    }[];
  }[];
  industryList: Array<
    {
      id: number;
      name: string;
      class_2_list: { id: number; industry_1_id: number; name: string }[];
    } & SelectOption
  >;
  industrySubList: SelectOption[];
  teamList: Recordable[];
  company_list: Recordable[]; // 和developer有什么区别
  participant: SelectOption[]; //当前登陆人权限
  ml_platform: SelectOption[];
  ml_business: SelectOption[];
  bx_platform: SelectOption[];
  mcc_account_list: Recordable[];
  time_zone: SelectOption[]; //时区
  opp_priority: SelectOption[];
}
export interface IUnselectableCommonListsState {
  countries: Array<ICountry>; //国家
  chiefs: Array<IEmployee>; //公司所有人员
}

export interface ISecurityCommonListsState {
  account_status_list: SelectOption[];
  account_type_list: SelectOption[];
  app_class_list: SelectOption[];
  detect_state_list: SelectOption[];
  test_type_list: SelectOption[];
}

@Module({ dynamic: true, store, name: 'commonLists' })
class CommonLists
  extends VuexModule
  implements ICommonListsState, IUnselectableCommonListsState, ISecurityCommonListsState
{
  public pm_am_bd: SelectOption[] = [];
  public countries: ICountry[] = [];
  public opUserList: SelectOption[] = [];
  public productStatus: SelectOption[] = [];
  public platform: SelectOption[] = [];
  public participant: SelectOption[] = [];
  public productClass: {
    id: number;
    name: string;
    product_class_2_list: {
      id: number;
      name: string;
      product_class_1_id: number;
      product_class_3_list: any;
    }[];
  }[] = [];
  public productSubClass: {
    id: number;
    name: string;
    product_class_1_id: number;
    product_class_3_list: any;
  }[] = [];
  public currency: SelectOption[] = [];
  public attribution: SelectOption[] = [];
  public chiefs: IEmployee[] = [];
  public industryList: Array<
    {
      id: number;
      name: string;
      class_2_list: { id: number; industry_1_id: number; name: string }[];
    } & SelectOption
  > = [];
  public industrySubList: SelectOption[] = [];
  public teamList: Recordable[] = [];
  public company_list: Recordable[] = [];
  public account_status_list: SelectOption[] = []; //账号状态
  public account_type_list: SelectOption[] = []; //账号类型
  public app_class_list: SelectOption[] = []; //应用类型
  public detect_state_list: SelectOption[] = []; //检测状态
  public test_type_list: SelectOption[] = []; //测试类型 初测 复测
  public ml_platform: SelectOption[] = []; //买量平台
  public ml_business: SelectOption[] = []; //买量业务
  public bx_platform: SelectOption[] = [];
  public mcc_account_list: Recordable[] = [];
  public time_zone: SelectOption[] = [];
  public opp_priority: SelectOption[] = [];

  @Mutation
  SET_PMAMBD(pm_am_bd: Array<SelectOption>) {
    this.pm_am_bd = pm_am_bd;
  }
  @Mutation
  SET_COMPANY_LIST(company_list: Recordable[]) {
    this.company_list = company_list;
  }
  @Mutation
  SET_COUNTRIES(countries: Array<ICountry>) {
    this.countries = countries;
  }
  @Mutation
  SET_OP_USER_LIST(opUserList: Array<SelectOption>) {
    this.opUserList = opUserList;
  }
  @Mutation
  SET_PARTICIPANT(participant: Array<SelectOption>) {
    this.participant = participant;
  }
  @Mutation
  SET_PRODUCT_STATUS(productStatus: Array<SelectOption>) {
    this.productStatus = productStatus;
  }
  @Mutation
  SET_CHIEFS(chiefs: Array<IEmployee>) {
    this.chiefs = chiefs;
  }
  @Mutation
  SET_PRODUCT_CLASS(
    productClass: {
      id: number;
      name: string;
      product_class_2_list: {
        id: number;
        name: string;
        product_class_1_id: number;
        product_class_3_list: any;
      }[];
    }[]
  ) {
    this.productClass = productClass;
  }
  @Mutation
  SET_PRODUCT_SUB_CLASS(
    productSubClass: {
      id: number;
      name: string;
      product_class_1_id: number;
      product_class_3_list: any;
    }[]
  ) {
    this.productSubClass = productSubClass;
  }
  @Mutation
  SET_PLATFORM(platform: SelectOption[]) {
    this.platform = platform;
  }
  @Mutation
  SET_CURRENCY(currency: Array<SelectOption>) {
    this.currency = currency;
  }
  @Mutation
  SET_ATTRIBUTION(attribution: Array<SelectOption>) {
    this.attribution = attribution;
  }
  @Mutation
  SET_INDUSTRY_LIST(
    industryList: Array<
      {
        id: number;
        name: string;
        class_2_list: { id: number; industry_1_id: number; name: string }[];
      } & SelectOption
    >
  ) {
    this.industryList = industryList;
  }
  @Mutation
  SET_INDUSTRY_SUB_LIST(industrySubList: SelectOption[]) {
    this.industrySubList = industrySubList;
  }
  @Mutation
  SET_TEAM_LIST(teamList: Recordable[]) {
    this.teamList = teamList;
  }
  @Mutation
  SET_OPP_PRIORITY(opp_priority: Array<SelectOption>) {
    this.opp_priority = opp_priority;
  }
  @Mutation
  SET_ACCOUNT_STATUS_LIST(account_status_list: SelectOption[]) {
    this.account_status_list = account_status_list;
  }
  @Mutation
  SET_ACCOUNT_TYPE_LIST(account_type_list: SelectOption[]) {
    this.account_type_list = account_type_list;
  }
  @Mutation
  SET_APP_CLASS_LIST(app_class_list: SelectOption[]) {
    this.app_class_list = app_class_list;
  }
  @Mutation
  SET_DETECT_STATE_LIST(detect_state_list: SelectOption[]) {
    this.detect_state_list = detect_state_list;
  }
  @Mutation
  SER_TEST_TYPE_LIST(test_type_list: SelectOption[]) {
    this.test_type_list = test_type_list;
  }
  @Mutation
  SET_ML_PLATFORM(ml_platform: SelectOption[]) {
    this.ml_platform = ml_platform;
  }
  @Mutation
  SET_ML_BUSINESS(ml_business: SelectOption[]) {
    this.ml_business = ml_business;
  }
  @Mutation
  SET_BX_PLATFORM(bx_platform: SelectOption[]) {
    this.bx_platform = bx_platform;
  }
  @Mutation
  SET_MCC_ACCOUNT_LIST(mcc_account_list: Recordable[]) {
    this.mcc_account_list = mcc_account_list;
  }
  @Mutation
  SET_TIME_ZONE(time_zone: SelectOption[]) {
    this.time_zone = time_zone;
  }
  @Action
  public getProductStatusById(id: any) {
    try {
      let status;
      this.productStatus.forEach((item) => {
        if (item.id == id) {
          status = item.name;
        }
      });
      return status;
    } catch (err) {
      console.error(err);
    }
  }
  // 产品类型
  @Action({ commit: 'SET_PRODUCT_CLASS' })
  public async getProductClass() {
    try {
      const { product_class_1_list } = await getProductClass();
      return product_class_1_list;
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public getProductClassChoosed(index: number) {
    let temp = { id: -1, name: '' };
    this.productClass.forEach((item) => {
      if (item.id == index) {
        temp = item;
      }
    });
    return temp;
  }
  // 产品子类型
  @Action({ commit: 'SET_PRODUCT_SUB_CLASS' })
  public async getProductSubClass(index: number) {
    try {
      let product_class_2_list: {
        id: number;
        name: string;
        product_class_1_id: number;
        product_class_3_list: any;
      }[] = [];
      this.productClass.forEach((item) => {
        if (item.id == index) {
          product_class_2_list = item.product_class_2_list;
        }
      });
      return product_class_2_list;
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public getProductSubClassChoosed(index: number): SelectOption {
    let temp: SelectOption = { id: -1, name: '' };
    this.productSubClass.forEach((item) => {
      if (item.id == index) {
        temp = item;
      }
    });
    return temp;
  }
  @Action({ commit: 'SET_ATTRIBUTION' })
  public async getAttribution() {
    try {
      const { items } = await getAttribution();
      const changed_items = items.map((m) => ({ id: m.name, name: m.name }));
      return changed_items;
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public getIndustryListChoosed(index: number) {
    let temp = { id: -1, name: '' };
    this.industryList.forEach((item) => {
      if (item.id == index) {
        temp = item;
      }
    });
    return temp;
  }
  @Action({ commit: 'SET_INDUSTRY_SUB_LIST' })
  public async getIndustrySubList(index: number) {
    try {
      let class_2_list: { id: number; industry_1_id: number; name: string }[] = [];
      this.industryList.forEach((item) => {
        if (item.id === index) {
          class_2_list = item.class_2_list;
        }
      });
      return class_2_list;
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public getIndustrySubListChoosed(index: number): SelectOption {
    let temp: SelectOption = { id: -1, name: '' };
    this.industrySubList.forEach((item) => {
      if (item.id == index) {
        temp = item;
      }
    });
    return temp;
  }
  @Action({ commit: 'SET_COMPANY_LIST' })
  public async GET_COMPANY_LIST_ACTION() {
    try {
      const { company_list } = await get_company_list({
        get_all: true,
        pick_fields: ['id', 'name', 'short_name', 'company_type'],
      });
      return company_list;
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public async getMLModel() {
    try {
      const { ml_platform, ml_business, bx_platform, mcc_account_list } = await get_ml_account_apply_const();
      ml_platform.forEach((item) => {
        item.name = item.key;
        item.id = item.key;
      });
      bx_platform.forEach((item) => {
        item.name = item.key;
        item.id = item.key;
      });
      this.SET_ML_PLATFORM(ml_platform);
      this.SET_ML_BUSINESS(ml_business);
      this.SET_BX_PLATFORM(bx_platform);
      this.SET_MCC_ACCOUNT_LIST(mcc_account_list);
    } catch (err) {
      console.error(err);
    }
  }
  @Action
  public async get_time_zone() {
    try {
      const { items } = await get_time_zone();
      const arr = items.map((item) => ({ id: item, name: item }));
      this.SET_TIME_ZONE(arr);
    } catch (err) {
      console.error(err);
    }
  }
}

export const commonStore = getModule(CommonLists);
export function translateCommonList(
  target: keyof ICommonListsState | keyof ISecurityCommonListsState,
  value: string | number
) {
  const list = commonStore[target];
  if (!list) {
    return '-';
  }
  const obj = list.find((item) => item.id === value);
  if (!obj) {
    return '-';
  }
  return obj.name;
}
