import { Module, VuexModule, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
import { DrawerConfig } from '@/components/WeDrawer/types';
import { AlertConfig } from '@/components/WeAlert/types';

@Module({
  dynamic: true,
  store,
  name: 'app',
})
export default class App extends VuexModule {
  public printInfo: Recordable = {};
  public slideWidth: Number = 0;
  public weDialog: DialogConfig | null = null;
  public weOperLog: Recordable = { operation_group: '' };
  public drawerConfig: DrawerConfig | null = null;
  public alertConfig: AlertConfig | null = null;
  public changeConfig: { entity: Recordable; entityType: string } | null = null;
  @Mutation
  SET_PRINT_INFO(printInfo: Recordable) {
    this.printInfo = printInfo;
  }
  @Mutation
  SET_SLIDE_WIDTH(slideWidth: Number) {
    this.slideWidth = slideWidth;
  }
  @Mutation
  CLEAR_INFO() {
    this.printInfo = {};
  }
  @Mutation
  SET_DRAWER_CONFIG(drawerConfig: DrawerConfig | null = null): void {
    if (!drawerConfig) {
      this.drawerConfig = {
        componentName: '',
        keyName: '',
        href: '',
      };
    } else {
      this.drawerConfig = drawerConfig;
    }
  }
  @Mutation
  UPDATE_DIALOG_BLOCKS(blocks: Partial<DialogFormItem>[], type = 'input'): void {
    const inputIndex = this.weDialog?.blocks?.findIndex((inputItem) => inputItem.type === 'input');
    blocks.forEach((item: DialogFormItem) => {
      if (type === 'input') {
        if (inputIndex !== -1 && typeof inputIndex !== 'undefined') {
          const findIndex = this.weDialog?.blocks?.[inputIndex].value.findIndex((jtem) => {
            return item.result === jtem.result;
          });
          if (this.weDialog?.blocks?.[inputIndex].value[findIndex]) {
            this.weDialog.blocks[inputIndex].value[findIndex] = {
              ...this.weDialog?.blocks?.[inputIndex].value[findIndex],
              ...item,
            };
          }
        }
      }
    });
  }
  @Mutation
  SET_WE_OPERLOG(weOperLog: Recordable) {
    this.weOperLog = weOperLog;
  }
  @Mutation
  SET_WE_DIALOG(weDialog: DialogConfig | null) {
    if (weDialog) {
      const defaultConfig = {
        title: 'Dialog',
        width: '800px',
        persistent: true,
        blocks: [],
        btns: [],
        callback: (result) => {
          console.log(result);
        },
      };
      this.weDialog = { ...defaultConfig, ...weDialog };
    } else {
      this.weDialog = null;
    }
  }
  @Mutation
  SET_ALERT_CONFIG(alertConfig: Recordable | null) {
    const defaultConfig = {
      show: true,
      title: 'Warning',
      alertTextStyle: '',
      maxWidth: '600px',
    };
    if (alertConfig) {
      const thisConfig: Recordable = {
        title: alertConfig.title,
        callback: alertConfig.callback,
        cancel: alertConfig.cancel,
      };
      if (alertConfig.alertContent && typeof alertConfig.alertContent === 'string') {
        thisConfig.alertText = alertConfig.alertContent;
      } else {
        thisConfig.alertTable = alertConfig.alertContent;
        defaultConfig.maxWidth = '1000px';
      }
      this.alertConfig = { ...defaultConfig, ...thisConfig, ...alertConfig.moreConfig };
    } else {
      this.alertConfig = null;
    }
  }
  @Mutation
  SET_WE_CHANGE(changeConfig: { entity: Recordable; entityType: string } | null) {
    if (!changeConfig) {
      this.changeConfig = null;
    } else {
      this.changeConfig = { ...changeConfig };
    }
  }
}
export const appStore = getModule<App>(App);
