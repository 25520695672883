import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const workbench: RouteRecordRaw = {
  path: '/admin',
  name: 'Admin',
  meta: { title: 'Admin', ignoreKeepAlive: true },
  redirect: { name: 'AdminQuickLogin' },
  component: AppLayout,
  children: [
    {
      path: 'super',
      name: 'AdminSuper',
      meta: { title: '超管', ignoreKeepAlive: true },
      redirect: { name: 'AdminSuperQuickLogin' },
    },
    {
      path: 'super/quick_login',
      name: 'AdminSuperQuickLogin',
      meta: { title: '快捷登录', ignoreKeepAlive: true, auth: ['RoleOne'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/more/QuickLogin.vue'),
    },

    {
      path: 'system/create-report',
      name: 'MetaCreateReport',
      meta: { title: '报表生成器', ignoreKeepAlive: true, auth: ['RoleGod'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/meta/serveconfig/CreateReport.vue'),
    },
    {
      path: 'system/create-todo',
      name: 'MetaToDoReport',
      meta: { title: '待办生成器', ignoreKeepAlive: true, auth: ['RoleGod'] },
      component: () => import('@/views/meta/serveconfig/CreateTodoConfig.vue'),
    },

    {
      path: 'org/sub_system_menu',
      name: 'OrgSubSystemMenu',
      meta: { title: '嵌入菜单', ignoreKeepAlive: true, auth: ['RoleGod'] },
      component: () => import('@/views/meta/serveconfig/SubSystemMenu.vue'),
    },
    {
      path: 'org/route_permission',
      name: 'OrgRoutePermission',
      meta: { title: '路由权限', ignoreKeepAlive: true, auth: ['RoleGod'] },
      component: () => import('@/views/meta/serveconfig/RoutePermission.vue'),
    },
  ],
};
export default workbench;
