import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_we_select = _resolveComponent("we-select")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_sider_menu = _resolveComponent("sider-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!

  return (_openBlock(), _createBlock(_component_a_layout_sider, {
    class: "app_sider",
    theme: "light",
    width: "210",
    collapsible: "",
    collapsedWidth: 50,
    collapsed: _ctx.collapsed,
    "onUpdate:collapsed": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.collapsed) = $event))
  }, {
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_a_row, { class: "filter_menu_select" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, { span: 24 }, {
            default: _withCtx(() => [
              _createVNode(_component_we_select, {
                options: _ctx.menuOptions,
                value: _ctx.selected_menu_item,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected_menu_item) = $event)),
                placeholder: "请选择",
                onChange: _ctx.changeMenus,
                allowClear: false
              }, null, 8, ["options", "value", "onChange"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.menuOptions.length !== 0 && !_ctx.collapsed]
      ]),
      _createVNode(_component_sider_menu, {
        menus: _ctx.menus,
        theme: "light",
        collapsed: _ctx.collapsed
      }, null, 8, ["menus", "collapsed"])
    ]),
    _: 1
  }, 8, ["collapsed"]))
}