import { get_company_list } from '@/api/crm-customer';
import {
  get_company_prepay_info,
  edit_credit,
  upload_credit,
  edit_recharge,
  create_credit_apply,
  edit_final_approval_limit,
  get_can_do_recharge_company_list,
  get_company_credit_limit,
} from '@/api/ugs-reseller';
import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
import { useJump } from '@/hooks/useJump';
import { useUser } from '@/hooks/useUser';
import { appStore } from '@/store/modules/app';
import { isEmptyData } from '@/utils/common';
import { isFloat, required } from '@/utils/validate';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAppInfo } from '@/hooks/useAppInfo';
import { formatNumberByNumeral } from '@/utils/common';
export function useReseller() {
  // const is_overdue = ref<boolean>(false);
  // const is_over_limit = ref<boolean>(false);
  const customer_items = ref<Recordable[]>([]);
  const credit_customer_items = ref<Recordable[]>([]);
  const { user } = useUser();
  const { model, getModelItems } = useAppInfo();
  const isUGSBMO = computed(() => {
    const user_team_list = user.value.user_team_list;
    return user_team_list.some((item) => item.team.id === 129);
  });
  get_company_list({ pick_fields: ['id', 'name', 'short_name'], list_type: 'demand' }).then((res) => {
    customer_items.value = res.company_list.map((item) => ({ id: item.id, name: item.name }));
  });
  get_can_do_recharge_company_list().then((res) => {
    credit_customer_items.value = res.map((item: Recordable) => ({
      name: item.rd_company_name,
      id: item.company_id,
      c_opp_num: item.c_opp_num,
    }));
  });
  const router = useRouter();
  const { jumpToUGSResellerCreditDetail, jumpToCompany } = useJump();
  async function editCredit(type = 'edit', record, vue) {
    const isAdd = type === 'add';
    const isFinal = type === 'final'; //填写最终审批金额
    const title = isAdd ? '新增授信' : isFinal ? '填写最终审批金额' : '编辑授信';
    // const percent = [
    //   { text: `5%`, value: 0.05 },
    //   { text: `10%`, value: 0.1 },
    //   { text: `15%`, value: 0.15 },
    //   { text: `20%`, value: 0.2 },
    // ];

    // const userItems = vue.validUserList.map((x) => ({ text: x.name, value: x.id }));
    const inputs: DialogFormItem[] = [
      {
        type: 'auto-select',
        label: '客户',
        result: 'company_id',
        width: 24,
        items: customer_items,
        rules: [required()],
        disabled: isFinal,
        change: (result, _input, _config) => {
          get_company_prepay_info({ company_id: result.company_id }).then((res) => {
            result.prepay_amount = res.prepay_amount;
          });
        },
      },
      {
        type: 'text',
        label: 'Prepay金额',
        result: 'prepay_amount',
        width: 12,
        rules: [required(), isFloat],
        disabled: true,
      },
      {
        type: 'text',
        label: 'Normal授信',
        result: 'normal_limit_amount',
        width: 12,
        rules: [required(), isFloat],
      },
      { type: 'date', label: '初始日期', result: 'init_time', width: 12, rules: [required()] },
      {
        type: 'number',
        label: '期初余额',
        result: 'init_account_balance',
        width: 12,
        rules: [required(), isFloat],
      },
      // {
      //   type: 'auto-select',
      //   label: 'Head审核门槛',
      //   result: 'head_review_exceed_percent',
      //   width: 12,
      //   items: percent,
      //   init: 0.05,
      //   rules: [isFloat],
      //   show: () => record.property !== 'Normal',
      // },
      // {
      //   type: 'number',
      //   label: 'GM审核门槛',
      //   result: 'gm_review_exceed_amount',
      //   width: 12,
      //   rules: [isInt],
      //   show: () => record.property !== 'Normal',
      //   componentProps: {
      //     formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      //     parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
      //   },
      // },
    ];
    const dialogConfig: DialogConfig = {
      title,
      width: '800px',
      labelCol: '140px',
      init: record,
      blocks: [
        { type: 'input', value: inputs },
        { type: 'note', label: '授信说明', result: 'note', width: 24 },
        {
          type: 'note',
          label: '其他审核意见',
          result: 'review_note',
          width: 24,
          placeholder: '如有其他意见,请在这里留言',
        },
      ],
      callback: (result) => {
        if (isFinal) {
          edit_final_approval_limit({ ...result, id: record.id }).then(() => {
            vue.$message.success('保存成功');
            appStore.SET_WE_DIALOG(null);
            appStore.SET_ALERT_CONFIG(null);
            vue.initData();
          });
        } else {
          edit_credit({ ...result }).then(() => {
            vue.$message.success('保存成功');
            appStore.SET_WE_DIALOG(null);
            appStore.SET_ALERT_CONFIG(null);
            vue.reload();
          });
        }
      },
    };

    vue.$showDialog(dialogConfig);
  }
  function uploadCreditExcel(vue) {
    const data = {
      客户名称: 'company_name',
      授信总额: 'normal_limit_amount',
      初始账户余额: 'init_account_balance',
    };

    vue.$showDialog({
      title: 'Upload Credit Excel',
      width: '600px',
      blocks: [
        {
          type: 'excel',
          result: 'excel_data',
          config: { headers: Object.keys(data), timeHeaders: ['Init Time'] },
        },
      ],
      callback: (result) => {
        const to_excel_data: Recordable[] = [];
        result.excel_data.forEach((item) => {
          const item_data: Recordable = {};
          for (const key in item) {
            if (Object.keys(data).includes(key)) {
              item_data[data[key]] = item[key];
            }
          }
          to_excel_data.push(item_data);
        });
        result.excel_data = to_excel_data;
        upload_credit({ ...result }).then((res) => {
          vue.$showOperLog({
            operation_group: res.operation_group,
            start_content: '开始',
            end_content: '结束',
            afterClose: () => {
              appStore.SET_WE_DIALOG(null);
              vue.reload();
            },
            footerButtons:
              res.length > 0
                ? [
                    {
                      name: '下载压缩包',
                      click: () => {
                        vue.$showAlert(
                          'Failed Items',
                          {
                            config: {
                              immediate: false,
                              useSearchForm: false,
                              useFilterFromColumns: false,
                              showTableHeader: false,
                              columns: [
                                { title: '客户名称', dataIndex: 'company_name' },
                                { title: '授信总额', dataIndex: 'normal_limit_amount' },
                                { title: '错误原因', dataIndex: 'reason' },
                              ],
                            },
                            items: res,
                          },
                          () => {
                            vue.reload();
                          }
                        );
                      },
                    },
                  ]
                : [],
          });
        });
      },
    });
  }
  async function editRecharge(mode, recharge, vue) {
    const isAdd = mode === 'add';
    const title = isAdd ? '新建充值申请' : '编辑充值申请';
    const id = recharge ? recharge.id : '';
    const ad_platform_items = getModelItems(model.value.Recharge.AD_PLATFORM);
    let inputs: DialogFormItem[] = [];
    //去掉新建充值申请时如果授信余额小于等于0就不让填写大于0数字/不让填写比授信余额多的金额的限制
    // const set_result = (result) => {
    //   if (!isEmptyData(result.company_id)) {
    //     if (result.recharge_property === model.value.Recharge.RECHARGE_PROPERTY.PREPAY) {
    //       if (result.left_limit < 0) {
    //         result.recharge_amount = 0;
    //       } else if (!isEmptyData(result.recharge_amount) && result.recharge_amount > result.left_limit) {
    //         result.recharge_amount = result.left_limit;
    //       }
    //     }
    //   }
    // };
    if (isAdd) {
      inputs = [
        {
          type: 'auto-select',
          label: '客户',
          result: 'company_id',
          width: 24,
          items: credit_customer_items,
          rules: [
            required(),
            {
              validator: async (_rule, val: any) => {
                const find_item = credit_customer_items.value.find((item) => item.id === val);
                if (isEmptyData(val) || find_item!.c_opp_num === 1) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    '此客户有{}个UGS Pipeline，请联系（陶胜旭）！'.replace('{}', find_item!.c_opp_num)
                  );
                }
              },
              trigger: 'change',
            },
          ],
          change: (result) => {
            if (!isEmptyData(result.company_id)) {
              get_company_credit_limit({ company_id: result.company_id, mode: result.recharge_property }).then(
                (res) => {
                  result.left_limit = formatNumberByNumeral(res.left_credit_limit, '0.00');
                  // set_result(result);
                }
              );
            }
          },
        },
        {
          type: 'auto-select',
          label: '充值类型',
          result: 'recharge_property',
          width: 24,
          items: getModelItems(model.value.Recharge.RECHARGE_PROPERTY),
          disabled: true,
          rules: [required()],
          // change: (result) => {
          //   set_result(result);
          // },
        },
        {
          type: 'auto-select',
          label: '媒体平台',
          result: 'ad_platform',
          width: 24,
          items: ad_platform_items,
          rules: [required()],
        },
        {
          type: 'number',
          label: '授信余额',
          result: 'left_limit',
          width: 24,
          disabled: true,
        },
        {
          type: 'number',
          label: '充值金额',
          result: 'recharge_amount',
          width: 24,
          rules: [required(), isFloat],
          change: (result) => {
            if (isNaN(Number(result['recharge_amount']))) {
              result['recharge_amount'] = Number(
                result['recharge_amount'].substr(0, result['recharge_amount'].length - 1)
              );
            }
            // else {
            //   set_result(result);
            // }
          },
        },
        {
          type: 'date',
          label: '充值时间',
          result: 'recharge_time',
          width: 24,
          rules: [required()],
        },
      ];
    } else {
      inputs = [
        {
          type: 'auto-select',
          label: '媒体平台',
          result: 'ad_platform',
          width: 24,
          items: ad_platform_items,
          rules: [required()],
        },
        {
          type: 'text',
          label: '充值金额',
          result: 'recharge_amount',
          width: 24,
          rules: [required(), isFloat],
        },
        {
          type: 'date',
          label: '充值时间',
          result: 'recharge_time',
          width: 24,
          rules: [required()],
        },
      ];
    }
    const dialogConfig: DialogConfig = {
      title,
      width: '600px',
      labelCol: '120px',
      init: recharge,
      blocks: [{ type: 'input', value: inputs }],
      callback: (result) => {
        // if (result.recharge_property === model.value.Recharge.RECHARGE_PROPERTY.PREPAY && result.left_limit <= 0) {
        //   appStore.SET_WE_DIALOG(null);
        //   vue.$showAlert('警告', 'prepay充值剩余额度小于等于0，无法充值！', () => {
        //     appStore.SET_ALERT_CONFIG(null);
        //   });
        //   return;
        // } else if (isAdd) {
        //   delete result.left_limit;
        // }
        if (isAdd) {
          delete result.left_limit;
        }
        edit_recharge({ data: { id: id, ...result } }).then((res) => {
          appStore.SET_WE_DIALOG(null);

          if (isAdd) {
            vue.reload();
            jumpToReseller({ id: res.recharge_id });
          } else {
            vue.afterEditRecharge();
          }
        });
        // get_company_item({ company_id: result.company_id }).then((res) => {
        //   const pipline_list = res.company.pipline_list;
        //   if (
        //     isEmptyData(pipline_list) ||
        //     pipline_list.filter((item) => item.logic_type === model.value.Opp.LOGIC_TYPE.MEDIA).length <= 2
        //   ) {
        //     doEdit();
        //   } else {
        //     vue.$message.error('该客户有多个UGS Pipeline，请联系HW管理员');
        //   }
        // });
      },
    };
    if (!isEmptyData(credit_customer_items.value)) {
      vue.$showDialog(dialogConfig);
    } else {
      vue.$message.warning('正在请求数据中，请稍后');
    }
  }
  function jumpToReseller({ id }: { id: number }) {
    window.open(router.resolve({ name: 'UGSResellerDetail', query: { resellerId: `${id}` } }).href);
  }
  /**
   * 创建申请，修改信息
   */
  function createOrEditApply(vue, type = 'add', record) {
    const isAdd = type === 'add';
    const inputs: DialogFormItem[] = [
      {
        type: 'auto-select',
        label: '全部客户',
        result: 'company_id',
        rules: [required()],
        items: customer_items.value,
        width: 24,
        disabled: !isAdd,
      },
      {
        type: 'number',
        label: '申请Normal额度',
        result: 'apply_limit_amount',
        rules: [required()],
        componentProps: {
          formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
          parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
        },
        width: 24,
      },
    ];
    const dialog_config: DialogConfig = {
      title: isAdd ? '申请授信' : '修改信息',
      blocks: [{ type: 'input', value: inputs }],
      width: '600px',
      labelCol: '140px',
      init: isAdd ? {} : record,
      callback: (result) => {
        if (!isAdd) {
          edit_credit({ id: record.id, apply_limit_amount: result.apply_limit_amount })
            .then(() => {
              vue.$message.success('修改成功');
              appStore.SET_WE_DIALOG(null);
              vue.initData();
            })
            .catch(() => {
              vue.$message.error('修改失败');
              appStore.SET_WE_DIALOG(null);
            });
        } else {
          create_credit_apply(result)
            .then((res) => {
              appStore.SET_WE_DIALOG(null);
              if (res.op_status === 1) {
                vue.$showAlert(
                  '注意',
                  '该客户已申请过授信，如需修改授信额度请前往该客户授信申请详情页Reopen流程',
                  () => {
                    appStore.SET_ALERT_CONFIG(null);
                    jumpToUGSResellerCreditDetail({ id: res.credit_id });
                  },
                  undefined,
                  { okText: '去操作' }
                );
              } else if (res.op_status === 2) {
                vue.$showAlert(
                  '注意',
                  '当前客户未创建MSPipeline，请前往客户详情新建MS跟进',
                  () => {
                    appStore.SET_ALERT_CONFIG(null);
                    jumpToCompany(res.company_id);
                  },
                  undefined,
                  { okText: '去创建' }
                );
              } else if (res.op_status === 3) {
                vue.$showAlert(
                  '注意',
                  '当前客户MSPipeline信息不完善，请前往客户详情完善Pipeline中的跟进信息',
                  () => {
                    appStore.SET_ALERT_CONFIG(null);
                    jumpToCompany(res.company_id);
                  },
                  undefined,
                  { okText: '去完善' }
                );
              } else if (res.op_status === 4) {
                vue.$showAlert(
                  '注意',
                  '当前客户未关联企查查且未上传客户股权（股东信息）截图和实控人信息截图，请前往客户详情在Pipeline跟进信息中完善',
                  () => {
                    appStore.SET_ALERT_CONFIG(null);
                    jumpToCompany(res.company_id);
                  },
                  undefined,
                  { okText: '去关联' }
                );
              } else {
                vue.$message.success('新建成功');
                vue.reload();
                jumpToUGSResellerCreditDetail({ id: res.credit_id });
              }
            })
            .catch(() => {
              vue.$message.error('新建失败');
              appStore.SET_WE_DIALOG(null);
            });
        }
      },
    };
    vue.$showDialog(dialog_config);
  }
  return {
    customer_items,
    editCredit,
    uploadCreditExcel,
    editRecharge,
    jumpToReseller,
    createOrEditApply,
    isUGSBMO,
  };
}
