import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const mmm: RouteRecordRaw = {
  path: '/mmm',
  name: 'Three_CRM',
  meta: { title: '3M', ignoreKeepAlive: true },
  redirect: { name: 'Market' },
  component: AppLayout,
  children: [
    // 销售日常
    {
      path: 'market',
      name: 'Market',
      meta: { title: '销售日常', ignoreKeepAlive: true },
      redirect: { name: 'pipelineList' },
    },
    {
      path: 'market/pipeline_list',
      name: 'pipelineList',
      meta: { title: '我的客户', ignoreKeepAlive: true, auth: ['Role3MUser', 'Role3MUserSimple', 'RoleGod'] },
      props: ({ params, query }) => ({ oppStatus: 'all', ...params, ...query }),
      component: () => import('@/views/crm/market/OppList.vue'),
    },
    {
      path: 'market/daily_report',
      name: 'MarketDailyReport',
      meta: { title: '销售日报', ignoreKeepAlive: true, auth: ['HasMenu_3MSalesDailyReport'] },
      props: ({ params, query }) => ({ oppStatus: 'all', ...params, ...query }),
      component: () => import('@/views/crm/market/DailyReportList.vue'),
    },

    // 客户列表
    {
      path: 'customer',
      name: 'Customer',
      meta: { title: '客户列表', ignoreKeepAlive: true },
      redirect: { name: 'Leads' },
    },
    {
      path: 'customer/assign',
      name: 'Assign',
      meta: { title: '线索分配', ignoreKeepAlive: true, auth: ['HasMenu_CrmAssign'] },
      props: ({ params, query }) => ({ oppStatus: 'assign', ...params, ...query }),
      component: () => import('@/views/crm/market/OppList.vue'),
    },
    {
      path: 'customer/open_sea',
      name: 'OpenSea',
      meta: { title: '公海池', ignoreKeepAlive: true, auth: ['HasMenu_CrmOpenSea'] },
      props: ({ params, query }) => ({ oppStatus: 'open_sea', ...params, ...query }),
      component: () => import('@/views/crm/market/OppList.vue'),
    },
    {
      path: 'customer/list',
      name: 'customer-list',
      meta: { title: '主体列表', ignoreKeepAlive: true, auth: ['HasMenu_CrmCompanyList'] },
      props: ({ params, query }) => ({ params, query }),
      component: () => import('@/views/crm/market/CustomerList.vue'),
    },
    {
      path: 'customer/supplier',
      name: 'customer-supplier',
      meta: { title: '供应商列表', ignoreKeepAlive: true, auth: ['HasMenu_Supplier'] },
      props: ({ params, query }) => ({ supplier: true, params, query }),
      component: () => import('@/views/crm/market/CustomerList.vue'),
    },
    {
      path: 'customer/cfi',
      name: 'customer-cfi',
      meta: { title: '客商信息申请', ignoreKeepAlive: true, auth: ['HasMenu_Cfi'] },
      props: ({ params, query }) => ({ params, query }),
      component: () => import('@/views/crm/market/CfiList.vue'),
    },
    {
      path: 'customer/black',
      name: 'customer-black',
      meta: { title: '黑名单主体', ignoreKeepAlive: true, auth: ['HasMenu_3MBlackCustomer'] },
      props: ({ params, query }) => ({ params, query }),
      component: () => import('@/views/crm/customerTrack/BlackCompanyList.vue'),
    },
    {
      path: 'customer/no-track',
      name: 'customer-no-track',
      meta: { title: '长期未跟进客户', ignoreKeepAlive: true, auth: ['HasMenu_3MLongTimeNoneFlowedCustomer'] },
      props: ({ params, query }) => ({ params, query }),
      component: () => import('@/views/crm/market/NoTrackOppList.vue'),
    },
    {
      path: 'customer/pipeline/detail',
      name: 'PipelineDetail',
      meta: { title: '跟进详情', ignoreKeepAlive: true, auth: ['Role3MUser', 'Role3MUserSimple'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/crm/customerTrack/PipelineDetail.vue'),
    },
    {
      path: 'customer/detail',
      name: 'CustomerDetail',
      meta: { title: '客户主体详情', ignoreKeepAlive: true, auth: [] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/crm/market/CustomerDetail.vue'),
    },
    {
      path: 'customer/cfi/detail',
      name: 'CustomerCfiDetail',
      meta: { title: '客商信息申请详情', ignoreKeepAlive: true, auth: [] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/crm/market/CfiDetail.vue'),
    },
    {
      path: 'customer/order/detail',
      name: 'OrderDetail',
      meta: { title: '商机详情', ignoreKeepAlive: true, auth: [] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/crm/market/OrderDetail.vue'),
    },

    {
      path: 'marketing/clue',
      name: 'CRMCustomerClue',
      meta: { title: '市场线索', ignoreKeepAlive: true, auth: ['HasMenu_3mMarketLeads'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/crm/customerTrack/Clue.vue'),
    },

    {
      path: 'other',
      name: 'CFI',
      meta: { title: '其它', ignoreKeepAlive: true },
      redirect: { name: 'cfi' },
    },
    {
      path: 'other/cfi',
      name: 'cfi',
      meta: { title: '客商信息申请', ignoreKeepAlive: true, auth: ['HasMenu_Cfi'] },
      props: ({ params, query }) => ({ params, query }),
      component: () => import('@/views/crm/market/CfiList.vue'),
    },
  ],
};
export default mmm;
