import { DialogConfig } from '@/components/WeDialog/types';
import { appStore } from '@/store/modules/app';
import { App } from 'vue';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import { DrawerConfig } from '@/components/WeDrawer/types';
import tipConfig from '@/utils/tipConfig';
import dataFinder from '@/utils/dateFinder';
export function registerGlobalProperty(app: App) {
  app.config.globalProperties.$showDialog = (weDialog: DialogConfig) => {
    appStore.SET_WE_DIALOG(weDialog);
  };
  app.config.globalProperties.$message = {
    error: message.error.bind(null),
    success: message.success.bind(null),
    warning: message.warning.bind(null),
    info: message.info.bind(null),
  };
  app.config.globalProperties.$confirm = (
    message = '',
    title = '',
    config: {
      confirmButtonText: string;
      cancelButtonText?: string;
      type: 'confirm';
      width?: number;
    } = {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'confirm',
    }
  ) => {
    return new Promise((resolve, reject) => {
      Modal[config.type]({
        title: title,
        width: config.width || 520,
        content: message,
        okText: config.confirmButtonText,
        cancelText: config.cancelButtonText,
        centered: true,
        onOk() {
          return resolve('');
        },
        onCancel() {
          return reject('error');
        },
      });
    });
  };
  app.config.globalProperties.$showDrawer = (drawerConfig: DrawerConfig) => {
    appStore.SET_DRAWER_CONFIG(drawerConfig);
  };
  app.config.globalProperties.$showOperLog = (weOperLog: Recordable) => {
    appStore.SET_WE_OPERLOG(weOperLog);
  };
  app.config.globalProperties.$showAlert = (
    title: string,
    alertContent: string | Recordable,
    callback?: Fn,
    cancel?: Fn,
    moreConfig?: Recordable
  ) => {
    appStore.SET_ALERT_CONFIG({
      title,
      alertContent,
      callback,
      cancel,
      moreConfig,
    });
  };
  app.config.globalProperties.$showChange = (entity: Recordable, entityType: string) => {
    appStore.SET_WE_CHANGE({
      entity,
      entityType,
    });
  };
  app.config.globalProperties.$tipConfig = tipConfig;
  app.config.globalProperties.$dataFinder = dataFinder;
}
