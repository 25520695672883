import { MenuItem } from 'types/config';

const report: MenuItem = {
  path: 'report',
  title: '报表',
  is_root: true,
  hasTag: true,
  children: [
    {
      path: 'erp',
      title: '3M & ERP',
      icon: 'WE_DocStampOutlined',
      children: [
        {
          path: 'health',
          title: '现金流 & 健康度',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'contract',
          title: '合同报表',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'bill',
          title: '账单报表',
          icon: 'CarryOutOutlined',
          children: [
            { path: 'monthly-summary', title: '月度暂估 & 对账' },
            { path: 'plan-to-pay', title: '付款计划' },
            { path: 'plan-to-collect', title: '收款计划' },
            { path: 'overdue-billing', title: '逾期对账' },
            { path: 'overdue-collection', title: '逾期收款' },
          ],
        },
        {
          path: 'pay',
          title: '收付款报表',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'other',
          title: '其它报表',
          icon: 'CarryOutOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'business',
      title: '业务报表',
      icon: 'WE_CloudUserOutlined',
      children: [
        {
          path: 'harbor',
          title: 'Harbor',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'taurus',
          title: 'Taurus',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'ms',
          title: 'MS',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'cloud',
          title: 'Cloud',
          icon: 'CarryOutOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'cost',
      title: '成本管理',
      icon: 'WE_BillsOutlined',
      children: [
        {
          path: 'cloud_service',
          title: '云 & 服务',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'purchase',
          title: '采购',
          icon: 'CarryOutOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'admin',
      title: '其它报表',
      icon: 'WE_ThreeCirclesOutlined',
      children: [
        {
          path: 'global',
          title: '全局报表',
          icon: 'CarryOutOutlined',
          children: [],
        },
        {
          path: 'other',
          title: '其它报表',
          icon: 'CarryOutOutlined',
          children: [],
        },
      ],
    },
  ],
};
export default report;
