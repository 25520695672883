import { Module, VuexModule, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/store';
import { addRoutesByCustomReportList } from '@/router';
import { getAppInfo } from '@/api/app';
import { commonStore } from './commonLists';
import { IAppInfo } from 'types/store';
import { initAppInfo, getModelItems } from '@/utils/common';
import { addMenusByCustomReportList } from '@/router/menu';
@Module({
  dynamic: true,
  store,
  name: 'cache',
  preserveState: Boolean(localStorage.getItem('hw_app')),
})
export default class Cache extends VuexModule {
  public sidebarCollapsed = false;
  public appInfo: IAppInfo | null = null;
  // 便捷导航已选菜单
  public selectedMenus: { title: string; key: string }[] = [];
  public global_search: { emp_id: String; items: String[] }[] = [];
  @Mutation
  SET_SELECTED_MENUS(selectedMenus: { title: string; key: string }[]): void {
    this.selectedMenus = selectedMenus;
  }
  @Mutation
  SET_GLOBAL_SEARCH(global_search: { emp_id: String; items: String[] }[]): void {
    this.global_search = global_search;
  }
  @Mutation
  TOGGLE_SIDEBAR_COLLAPSED(sidebarCollapsed: boolean): void {
    this.sidebarCollapsed = sidebarCollapsed;
  }
  @Mutation
  SET_APPINFO(appInfo: IAppInfo | null): void {
    this.appInfo = appInfo;
  }
  @Action({ rawError: true })
  async GetAppInfoAction() {
    localStorage.removeItem('appInfo');
    const appInfo = await getAppInfo();
    addMenusByCustomReportList(appInfo.custom_report_list);
    addRoutesByCustomReportList(appInfo.custom_report_list);
    initAppInfo(appInfo);
    this.SET_APPINFO(appInfo);
    if (!appInfo.user_list) {
      return;
    }
    const user_list = appInfo.user_list.filter((item) => item.lark_employee_id !== null);
    const chief_list = user_list.map((item) => ({
      id: item.id,
      lark_employee_id: item.lark_employee_id,
      email: item.email,
      user_name: item.name,
      name: item.name + ' ' + item.email,
    }));
    const pmambd_list = user_list.map((item) => {
      return {
        id: item.id,
        name: `${item.name}-${item.email}`,
      };
    });
    const industry_list = appInfo.industry_1_list.map((item) => ({
      id: item.id,
      name: item.name,
      class_2_list: appInfo.industry_2_list.filter((ftem) => ftem.industry_1_id === item.id),
    })) as any;
    const team_list = appInfo.team_list.filter((item) => item.is_deleted === false && item.is_history === false);
    const role_list = appInfo.user.user_permission_list
      .filter((f) => f.system === 1)
      .map((m) => ({ id: m.permission_id, name: m.name }));
    commonStore.SET_CURRENCY(appInfo.currency_list.map((mtem) => ({ id: mtem.id, name: mtem.name })));
    commonStore.SET_COUNTRIES(appInfo.country_list);
    commonStore.SET_PARTICIPANT(role_list);
    commonStore.SET_CHIEFS(chief_list);
    commonStore.SET_PMAMBD(pmambd_list);
    commonStore.SET_PRODUCT_STATUS(getModelItems(appInfo.model.Product.STATUS));
    commonStore.SET_PLATFORM(getModelItems(appInfo.model.Product.PLATFORM));
    commonStore.SET_INDUSTRY_LIST(industry_list);
    commonStore.SET_TEAM_LIST(team_list);
    commonStore.SET_OPP_PRIORITY(getModelItems(appInfo.model.Opp.PRIORITY));
  }
}
export const cacheStore = getModule<Cache>(Cache);
