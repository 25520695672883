import html2canvas from 'html2canvas';
import JSPDF from 'jspdf';
import { isProd } from './const';

const A4_WIDTH = 592.28;
const A4_HEIGHT = 841.89;

export function outPutPdf(filename, domId) {
  const target = document.getElementById(domId)!;
  if (!target) {
    return;
  }
  const pageHeight = (target.scrollWidth / A4_WIDTH) * A4_HEIGHT;
  const childrenList = target?.childNodes as any;
  childrenList.forEach((child) => {
    if (child && child.classList) {
      child.classList.add(`${domId}-item`);
    }
  });
  const newNodeList: any = [];
  const all_items = target.querySelectorAll(`.${domId}-item`) as any;
  for (let i = 0; i < all_items.length; i++) {
    const child = all_items[i];
    const multiple = Math.ceil((child.offsetTop + child.offsetHeight) / pageHeight);
    if (isSplit(all_items, i, multiple * pageHeight)) {
      const divParent = child.parentNode; // 获取该div的父节点
      const newNode = document.createElement('div');
      newNode.className = 'emptyDiv';
      newNode.style.background = '#ffffff';
      const _H = multiple * pageHeight - (child.offsetTop + child.offsetHeight);
      newNode.style.height = _H + 30 + 'px';
      newNode.style.width = '100%';
      const next = child.nextSibling; // 获取div的下一个兄弟节点
      // 判断兄弟节点是否存在
      if (next) {
        // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
        divParent.insertBefore(newNode, next);
        newNodeList.push(newNode);
      } else {
        // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
        divParent.appendChild(newNode);
        newNodeList.push(newNode);
      }
    }
  }
  function isSplit(nodes, index, currentPageHeight) {
    // 计算当前这块dom是否跨越了a4大小，以此分割
    if (
      nodes[index].offsetTop + nodes[index].offsetHeight < currentPageHeight &&
      nodes[index].offsetHeight < pageHeight &&
      nodes[index + 1] &&
      nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > currentPageHeight &&
      nodes[index + 1].offsetHeight < pageHeight
    ) {
      return true;
    }
    return false;
  }
  return new Promise((resolve, reject) => {
    const element = document.getElementById(domId)!;
    const html2canvas_opt = {
      useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域问题！！重要
      allowTaint: true, // 允许跨域
    };
    html2canvas(element, html2canvas_opt)
      .then((canvas) => {
        const contentWidth = canvas.width;
        const contentHeight = canvas.height;
        console.log(contentWidth);
        console.log(contentHeight);
        // 根据A4纸的大小，计算出dom相应比例的尺寸
        const pageHeight = (contentWidth / A4_WIDTH) * A4_HEIGHT;
        let leftHeight = contentHeight;
        let position = 0;
        const imgWidth = 595.28;
        const imgHeight = (592.28 / contentWidth) * contentHeight;
        // canvas绘图生成image数据，1.0是质量参数
        const pageData = canvas.toDataURL('image/jpeg', 1.0);
        // a4大小
        const PDF = new JSPDF('p', 'pt', 'a4');

        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        !isProd && PDF.save(filename + '.pdf');
        newNodeList.forEach((node) => element.removeChild(node));
        resolve(dataURLtoFile(PDF.output('datauristring'), filename) as any);
      })
      .catch(() => {
        reject(false);
      });
  });
}

export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  let mime = '';
  mime = arr![0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
