import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const business: RouteRecordRaw[] = [
  {
    path: '/business',
    name: 'Business',
    meta: { title: 'Business', ignoreKeepAlive: true },
    redirect: { name: 'CloudBusinessBill' },
    component: AppLayout,
    children: [
      {
        path: 'common',
        name: 'Common',
        meta: { title: '通用', ignoreKeepAlive: true },
        redirect: { name: 'BusinessCommonCMS' },
      },

      {
        path: 'common/cms',
        name: 'BusinessCommonCMS',
        meta: { title: '买量账号管理', ignoreKeepAlive: true },
        redirect: { name: 'BusinessCommonCMSMlApplyList' },
      },
      {
        path: 'common/cms/ml-apply-list',
        name: 'BusinessCommonCMSMlApplyList',
        meta: { title: '买量账号申请', ignoreKeepAlive: true, auth: ['HasMenu_BuMlAccountApply'] },
        component: () => import('@/views/common/cms/CMSApplyList.vue'),
      },
      {
        path: 'common/cms/ml-account-list',
        name: 'BusinessCommonCMSMlAccountList',
        meta: { title: '买量账号列表', ignoreKeepAlive: true, auth: ['HasMenu_BuMlAccount'] },
        component: () => import('@/views/common/cms/CMSAccountList.vue'),
      },
      {
        path: 'common/cms/detail/:id',
        name: 'BusinessCommonCMSDetail',
        meta: { title: '开户申请详情', ignoreKeepAlive: true, auth: ['HasMenu_BuMlAccountApply'] },
        props: true,
        component: () => import('@/views/common/cms/CMSDetail.vue'),
      },
      {
        path: 'common/cms/realize-list',
        name: 'BusinessCMSCommonRealizeList',
        meta: { title: '变现账号列表', ignoreKeepAlive: true, auth: ['HasMenu_BuBxAccountApply'] },
        component: () => import('@/views/common/realize/RealizeList.vue'),
      },
      {
        path: 'common/cms/realize-config',
        name: 'BusinessCommonCMSRealizeConfig',
        meta: { title: 'Admob账号列表', ignoreKeepAlive: true, auth: ['HasMenu_BuAdmobAccount'] },
        component: () => import('@/views/common/realize/RealizeConfig.vue'),
      },
      {
        path: 'common/cms/big-account-config',
        name: 'BusinessCommonCMSBigAccountConfig',
        meta: { title: '大账号列表', ignoreKeepAlive: true, auth: ['HasMenu_BuBigAccount'] },
        component: () => import('@/views/common/realize/BigAccountConfig.vue'),
      },

      {
        path: 'common/opa',
        name: 'BusinessCommonOpa',
        meta: { title: '开户平台管理', ignoreKeepAlive: true },
        redirect: { name: 'UGSOpenAccountAuditList' },
      },
      {
        path: 'common/opa/audit-list',
        name: 'BusinessCommonOpaAuditList',
        meta: { title: '开户申请审核列表', ignoreKeepAlive: true, auth: ['HasMenu_ExternalAccountApplyReview'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/openAccount/AuditList.vue'),
      },
      {
        path: 'common/opa/audit-detail',
        name: 'BusinessCommonOpaAuditDetail',
        meta: { title: '开户申请审核详情', ignoreKeepAlive: true, auth: [] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/openAccount/AuditDetail.vue'),
      },
      {
        path: 'common/opa/recharge-list',
        name: 'BusinessCommonOpaRechargeList',
        meta: { title: '充值申请审核列表', ignoreKeepAlive: true, auth: ['HasMenu_ExternalRechargeApplyReview'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/openAccount/RechargeList.vue'),
      },
      {
        path: 'common/opa/recharge-detail',
        name: 'BusinessCommonOpaRechargeDetail',
        meta: { title: '充值申请审核详情', ignoreKeepAlive: true, auth: [] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/openAccount/RechargeDetail.vue'),
      },
      {
        path: 'common/opa/platform-account-list',
        name: 'BusinessCommonOpaPlatformAccountList',
        meta: { title: '平台账号管理', ignoreKeepAlive: true, auth: ['HasMenu_PlatformAccountManagement'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/openAccount/PlatformAccountList.vue'),
      },

      {
        path: 'pub',
        name: 'PUB',
        meta: { title: '发行', ignoreKeepAlive: true },
        redirect: { name: 'PUBProduct' },
      },
      {
        path: 'pub/product',
        name: 'PUBProduct',
        meta: { title: '产品管理', ignoreKeepAlive: true },
        redirect: { name: 'PUBProductAll' },
      },
      {
        path: 'pub/product/list',
        name: 'PUBProductList',
        meta: {
          title: '产品列表',
          ignoreKeepAlive: true,
          auth: ['HasMenu_BuProductAll', 'HasMenu_BuProductEvaluate', 'HasMenu_BuProductFlowing'],
        },
        component: () => import('@/views/harbor/ProductList.vue'),
      },
      {
        path: 'pub/product/permission-audit-list',
        name: 'PUBProductPermissionAuditList',
        meta: { title: '产品权限审核列表', ignoreKeepAlive: true, auth: ['HasMenu_HarborProductPermissionReview'] },
        component: () => import('@/views/harbor/ProductPermissionAuditList.vue'),
      },
      {
        path: 'pub/product/publish-rate',
        name: 'PUBProductPublishRate',
        meta: { title: '发行分成比例列表', ignoreKeepAlive: true, auth: ['HasMenu_HarborPublishRate'] },
        component: () => import('@/views/harbor/ProductPublishRate.vue'),
      },
      {
        path: 'pub/product/developer-account',
        name: 'PUBProductDeveloperAccount',
        meta: { title: '开发者平台账号列表', ignoreKeepAlive: true, auth: ['HasMenu_HarborDeveloperAccount'] },
        component: () => import('@/views/harbor/HarborDeveloperAccount.vue'),
      },
      {
        path: 'pub/product/detail',
        name: 'PUBProductDetail',
        meta: {
          title: '产品详情',
          ignoreKeepAlive: true,
          auth: ['HasMenu_BuProductAll', 'HasMenu_BuProductEvaluate', 'HasMenu_BuProductFlowing'],
        },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/harbor/ProductDetail.vue'),
      },

      {
        path: 'taurus/scaler/blank',
        name: 'TaurusScalerBlank',
        meta: { title: 'Blank', ignoreKeepAlive: true, auth: ['RoleGod'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/common/Blank.vue'),
      },
      {
        path: 'taurus/scaler/week-report',
        name: 'TaurusScalerWeekReport',
        meta: { title: 'Scaler周报', ignoreKeepAlive: true, auth: ['HasMenu_ScalerWeekReport'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/report/common/scaler/WeekReport.vue'),
      },

      {
        path: 'ugs',
        name: 'MS',
        meta: { title: 'MS业务', ignoreKeepAlive: true },
        redirect: { name: 'UGSReseller' },
      },

      {
        path: 'ugs/reseller',
        name: 'UGSReseller',
        meta: { title: '营销服务', ignoreKeepAlive: true },
        redirect: { name: 'UGSResellerCredits' },
      },
      {
        path: 'ugs/reseller/credits',
        name: 'UGSResellerCredits',
        meta: { title: '授信列表', ignoreKeepAlive: true, auth: ['HasMenu_BuResellerCredit'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/reseller/ResellerCredits.vue'),
      },
      {
        path: 'ugs/reseller/credits/detail',
        name: 'UGSResellerCreditDetail',
        meta: { title: 'Reseller授信详情', ignoreKeepAlive: true },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/reseller/ResellerCreditDetail.vue'),
      },
      {
        path: 'ugs/reseller/recharge',
        name: 'UGSResellerRecharge',
        meta: { title: '充值列表', ignoreKeepAlive: true, auth: ['HasMenu_BuResellerRecharge'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/reseller/ResellerRecharge.vue'),
      },
      {
        path: 'ugs/reseller/recharge/detail',
        name: 'UGSResellerDetail',
        meta: { title: 'Reseller详情', ignoreKeepAlive: true, auth: ['HasMenu_BuResellerRecharge'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/reseller/ResellerDetail.vue'),
      },
      {
        path: 'ugs/reseller/pay-alert-mail',
        name: 'OtherAlertPayMail',
        meta: { title: 'MS预警邮件', ignoreKeepAlive: true, auth: ['HasMenu_UgsAlertEmail'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/ugs/reseller/PayAlertMail.vue'),
      },

      {
        path: 'cloud',
        name: 'Cloud',
        meta: { title: 'Cloud', ignoreKeepAlive: true },
        redirect: { name: 'CloudBusinessBill' },
      },

      {
        path: 'cloud/business-bill',
        name: 'CloudBusinessBill',
        meta: { title: '业务对账', ignoreKeepAlive: true },
        redirect: { name: 'CloudBusinessBillGcp' },
      },
      {
        path: 'cloud/business-bill/gcp',
        name: 'CloudBusinessBillGcp',
        meta: {
          title: 'GCP对账',
          ignoreKeepAlive: true,
          auth: ['HasMenu_BuGcpBillingConfig', 'HasMenu_BuGcpBillingResult', 'HasMenu_BuGcpBillingDetail'],
        },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/gcp/GcpBills.vue'),
      },
      {
        path: 'cloud/business-bill/gsuite',
        name: 'CloudBusinessBillGsuite',
        meta: {
          title: 'GSuite对账',
          ignoreKeepAlive: true,
          auth: ['HasMenu_BuGsuitBillingConfig', 'HasMenu_BuGsuitBillingDetail'],
        },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/gsuite/GsuiteBill.vue'),
      },
      {
        path: 'cloud/business-bill/aws',
        name: 'CloudBusinessBillAws',
        meta: {
          title: 'AWS对账',
          ignoreKeepAlive: true,
          auth: ['HasMenu_BuAwsBillingConfig', 'HasMenu_BuAwsBillingDetail'],
        },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/aws/AwsBill.vue'),
      },
      {
        path: 'cloud/business-bill/msg',
        name: 'CloudBusinessBillMsg',
        meta: { title: 'MSG对账', ignoreKeepAlive: true, auth: ['HasMenu_BuMsgBillingDetail'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/msg/MsgBillDetail.vue'),
      },

      {
        path: 'cloud/self-bill',
        name: 'CloudSelfBill',
        meta: { title: '自用对账', ignoreKeepAlive: true },
        redirect: { name: 'CloudSelfBillConfig' },
      },
      {
        path: 'cloud/self-bill/gcp',
        name: 'CloudSelfBillGcp',
        meta: {
          title: 'GCP自用',
          ignoreKeepAlive: true,
          auth: ['HasMenu_WebeyeBillingConfig', 'HasMenu_WebeyeBillingDetail'],
        },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },
      {
        path: 'cloud/self-bill/aws',
        name: 'CloudSelfBillAws',
        meta: { title: 'AWS自用', ignoreKeepAlive: true, auth: ['HasMenu_AWSWebilling'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },
      {
        path: 'cloud/self-bill/aliyun',
        name: 'CloudSelfBillAliYun',
        meta: { title: '阿里云自用', ignoreKeepAlive: true, auth: ['HasMenu_ALIYUNWebilling'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },
      {
        path: 'cloud/self-bill/ucloud',
        name: 'CloudSelfBillUCloud',
        meta: { title: 'UCloud自用', ignoreKeepAlive: true, auth: ['HasMenu_UcloudWebilling'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },
      {
        path: 'cloud/self-bill/linode',
        name: 'CloudSelfBillLinode',
        meta: { title: 'Linode自用', ignoreKeepAlive: true, auth: ['HasMenu_LinodeWebilling'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },
      {
        path: 'cloud/self-bill/vultr',
        name: 'CloudSelfBillVultr',
        meta: { title: 'Vultr自用', ignoreKeepAlive: true, auth: ['HasMenu_VultrWebilling'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },
      {
        path: 'cloud/self-bill/huawei',
        name: 'CloudSelfBillHuawei',
        meta: { title: '华为云自用', ignoreKeepAlive: true, auth: ['HasMenu_HuaweiWebilling'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/self/SelfBill.vue'),
      },

      {
        path: 'cloud/other',
        name: 'CloudOther',
        meta: { title: '其它功能', ignoreKeepAlive: true },
        redirect: { name: 'CloudOtherTestAccountList' },
      },
      {
        path: 'cloud/other/test-account-list',
        name: 'CloudOtherTestAccountList',
        meta: { title: '测试账号', ignoreKeepAlive: true, auth: ['HasMenu_GCPBillingIDTestData'] },
        props: ({ params, query }) => ({ ...params, ...query }),
        component: () => import('@/views/cloud/other/TestAccount.vue'),
      },
      {
        path: 'cloud/other/currency-rate',
        name: 'CloudOtherCurrencyRate',
        meta: { title: '币种与汇率-Cloud', ignoreKeepAlive: true, auth: ['HasMenu_CloudCurrencyandRate'] },
        component: () => import('@/views/more/CloudCurrencyRate.vue'),
      },
      {
        path: 'cloud/other/commit-contract',
        name: 'CloudOther',
        meta: { title: 'Commit合同统计', ignoreKeepAlive: true, auth: ['HasMenu_ReportCloudCommit'] },
        component: () => import('@/views/more/CommitContract.vue'),
      },
    ],
  },
  {
    path: '/business/hw3/:chapters*',
    name: 'BusinessHW3',
    meta: { title: 'HW3', ignoreKeepAlive: true },
    component: AppLayout,
  },
  {
    path: '/business/opa/:chapters*',
    name: 'BusinessOpa',
    meta: { title: '开户充值', ignoreKeepAlive: true },
    component: AppLayout,
  },
];
export default business;
