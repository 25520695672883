
  import { defineComponent, ref, reactive, computed, onMounted, toRaw, unref, PropType, isRef } from 'vue';
  import { FormActionType, FormItem } from '../types/form';
  import { debouncedWatch } from '@vueuse/core';
  import upperFirst from 'lodash-es/upperFirst';
  import { commonStore } from '@/store/modules/commonLists';
  import { copyObjectToReactive, isEmptyData } from '@/utils/common';
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { required } from '@/utils/validate';
  import { appStore } from '@/store/modules/app';
  import dayjs from 'dayjs';

  export default defineComponent({
    name: 'TableSearchForm',
    emits: ['register', 'table_search_form_change'],
    props: {
      moreFunc: {
        type: Array,
        default: () => [],
      },
      formItems: {
        type: Array as PropType<FormItem[]>,
        default: () => [],
      },
      reload: {
        type: Function,
        default: null,
      },
      getOptions: {
        type: Function,
        default: null,
      },
      need_view_filter: {
        type: Boolean,
        default: false,
      },
      table_key: {
        type: String,
        default: '',
      },
      useWatchInitDefault: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const TableSearchFormElRef = ref<Nullable<FormActionType>>(null);
      const formState = reactive<Recordable>({}); // useForm需要使用
      const form_list = ref(props.formItems);
      const expand = ref<Boolean>(false);
      const need_reload = ref(true);
      const count = computed(() => {
        return expand.value ? form_list.value.length : 4;
      });
      const options = computed(() => {
        const obj: Recordable = {};
        Reflect.ownKeys(props.getOptions()).forEach((item: string) => {
          obj[item] =
            Array.isArray(props.getOptions()[item]) &&
            props
              .getOptions()
              [item].filter((ftem) => {
                return !isEmptyData(ftem);
              })
              .map((jtem) => {
                return {
                  label: jtem,
                  value: jtem,
                  key: jtem,
                };
              });
        });
        return obj;
      });
      const getComponentAttr = function (item: FormItem) {
        const { field, changeEvent = 'change', componentProps, changeFn } = item;
        const eventKey = `on${upperFirst(changeEvent)}`;
        const bindValue: Recordable = {
          ['value']: formState[field],
        };
        const on: Recordable = {
          [eventKey]: (e: Nullable<Recordable>) => {
            const target = e ? e.target : null;
            const value = target ? target.value : e;
            if (changeFn) {
              formState[field] = value;
              changeFn(value, formState, form_list);
            } else {
              formState[field] = value;
            }
          },
        };

        if (componentProps && componentProps!.mode) {
          if (JSON.stringify(componentProps.mode) === `["month","month"]`) {
            on['onPanelChange'] = (val) => {
              const value = [dayjs(val[0]).format('YYYY-MM'), dayjs(val[1]).format('YYYY-MM')];
              if (changeFn) {
                formState[field] = value;
                changeFn(val, formState, form_list);
              } else {
                formState[field] = value;
              }
            };
          }
        }
        const compAttr: Recordable = {
          ...on,
          ...bindValue,
          allowClear: true,
          showSearch: true,
          optionFilterProp: 'label',
          ...componentProps,
        };
        if (item.componentType === 'a-select') {
          if (typeof item.componentProps?.options === 'string') {
            compAttr.options = unref(
              computed(() => {
                if (commonStore[item.componentProps!.options as string].length !== 0) {
                  return commonStore[item.componentProps!.options as string].map((jtem) => {
                    return {
                      label: jtem.name,
                      value: jtem.id,
                      key: jtem.id,
                    };
                  });
                } else {
                  return [];
                }
              })
            );
          } else if (Array.isArray(item.componentProps?.options)) {
            compAttr.options = item.componentProps?.options.map((i) => {
              return { label: i.name, value: i.id, key: i.id };
            });
          } else if (isRef(item.componentProps?.options)) {
            compAttr.options = unref(item.componentProps?.options);
          } else {
            compAttr.options = unref(options)[item.field];
          }
        }
        if (item.componentType === 'a-range-picker' && !item.componentProps?.valueFormat) {
          compAttr.valueFormat = 'YYYY-MM-DD';
        }
        return compAttr;
      };
      function getFieldsValue(): Recordable {
        const state = toRaw(unref(formState));
        Reflect.ownKeys(state).forEach((item: string) => {
          if (!isEmptyData(state[item])) {
            if (typeof state[item] === 'string') {
              state[item] = state[item].trim();
            }
          }
        });
        return state;
      }
      function set_search_item_value(prop, val) {
        formState[prop] = val;
      }
      initDefaultData();
      function initDefaultData() {
        form_list.value.forEach((item) => {
          if (item.init) {
            formState[item.field] = item.init;
          } else {
            formState[item.field] = undefined;
          }
        });
      }
      function initShowElement() {
        form_list.value.forEach((item) => {
          if (item.show) {
            item.showElement = item.show(formState);
          } else {
            item.showElement = true;
          }
        });
      }
      const formAction: Partial<FormActionType> = {
        getFieldsValue,
        set_search_item_value,
      };
      onMounted(() => {
        emit('register', formAction);
      });
      debouncedWatch(
        () => unref(formState),
        (val) => {
          emit('table_search_form_change', val);
          if (need_reload.value) {
            props.reload();
          }
        },
        {
          debounce: 500,
          deep: true,
        }
      );
      return {
        formState,
        TableSearchFormElRef,
        getComponentAttr,
        count,
        expand,
        options,
        form_list,
        initShowElement,
        initDefaultData,
        need_reload,
      };
    },
    methods: {
      clear_form(need_reload = true) {
        this.need_reload = need_reload;
        Reflect.ownKeys(this.formState).forEach((key) => (this.formState[key as string] = undefined));
      },
      isEmptyData,
      reload_data_by_view_item(data) {
        const form_data = JSON.parse(data);
        copyObjectToReactive(this.formState, form_data);
        this.reload();
      },
      toggleExpand() {
        this.expand = !this.expand;
      },
      /**
       * 保存当前视图
       */
      save_current_view() {
        const inputs: DialogFormItem[] = [{ type: 'text', label: '视图名', rules: [required()], result: 'view_name' }];
        const dialog_config: DialogConfig = {
          title: '保存当前筛选视图',
          width: '600px',
          labelCol: '80px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            const param = { name: result.view_name, value: JSON.stringify(this.formState) };
            console.log(param);
            appStore.SET_WE_DIALOG(null);
            this.$message.success('保存成功');
          },
        };
        this.$showDialog(dialog_config);
      },
      /**
       * 删除视图
       */
      delete_view_item() {
        this.$showAlert('注意', '请确定操作', () => {});
      },
      /**
       * 设置默认视图
       */
      set_current_viwe_default() {},
    },
    watch: {
      formItems: {
        handler(val) {
          this.form_list = val;
          if (this.useWatchInitDefault) {
            this.initDefaultData();
          }
          this.initShowElement();
        },
        immediate: true,
      },
    },
  });
