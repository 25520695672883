import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hello-header-menu"
}
const _hoisted_2 = { class: "hello-header-dropdown_item" }
const _hoisted_3 = {
  key: 0,
  class: "block-left"
}
const _hoisted_4 = ["onMouseenter"]
const _hoisted_5 = { class: "svg_span_middle_center" }
const _hoisted_6 = { class: "block-right" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  key: 0,
  class: "third-sub-menu-wrapper"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_menu_item = _resolveComponent("header-menu-item")!
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: "hello-header-menu-wrap",
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleMenuLeave && _ctx.handleMenuLeave(...args)))
  }, [
    (_ctx.getFilteredHWRoutesAndMenus.menus.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFilteredHWRoutesAndMenus.menus, (item) => {
            return (_openBlock(), _createBlock(_component_header_menu_item, {
              key: item.path,
              menu: item,
              onItemMouseEnter: _ctx.handleItemMouseEnter
            }, null, 8, ["menu", "onItemMouseEnter"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "hello-header-dropdown",
      style: _normalizeStyle(_ctx.helloHeaderDropdownStyle)
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.selectedMenuItem.hasTag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedMenuItem.children, (child) => {
                  return (_openBlock(), _createElementBlock("li", {
                    class: _normalizeClass({ 'block-left-item-selected': _ctx.choosedMenuItem.title === child.title }),
                    key: child.path,
                    onMouseenter: ($event: any) => (_ctx.switchRightMenu(child)),
                    onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
                  }, [
                    _createElementVNode("span", _hoisted_5, [
                      _createVNode(_component_svg_icon, {
                        "icon-name": child.icon
                      }, null, 8, ["icon-name"]),
                      _createElementVNode("span", null, _toDisplayString(child.title), 1)
                    ])
                  ], 42, _hoisted_4))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.choosedMenuItem.children, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "third-menu-wrap",
              key: item.path
            }, [
              _createElementVNode("div", {
                class: "third-sub-menu-title",
                onClick: ($event: any) => (_ctx.handleMenuClick(item.children ? item.children[0].path : item.path))
              }, [
                _createVNode(_component_svg_icon, {
                  "icon-name": item.icon,
                  class: "third-sub-menu-title-icon"
                }, null, 8, ["icon-name"]),
                _createElementVNode("span", null, _toDisplayString(item.title), 1)
              ], 8, _hoisted_7),
              (!_ctx.isEmptyData(item.children))
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (child) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(_ctx.getThirdSubMenuItemClass(child.path)),
                        key: child.path,
                        onClick: ($event: any) => (_ctx.handleMenuClick(child.path))
                      }, _toDisplayString(child.title), 11, _hoisted_9))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ], 4)
  ], 32))
}