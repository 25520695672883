
  import { edit_recharge, get_recharge_item } from '@/api/ugs-reseller';
  import { BasicColumn, BasicTableProps } from '@/components/WeTable';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useUser } from '@/hooks/useUser';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  import { defineComponent, ref } from 'vue';
  import { useJump } from '@/hooks/useJump';
  import { useReseller } from './hooks/useReseller';
  import { formatMoneyByRound } from '@/utils/number';
  import { required } from '@/utils/validate';
  import { RuleObject } from 'ant-design-vue/es/form/interface';
  import { formatTime } from '@/utils/time';

  const validateInput = (rule: RuleObject, value: any) => {
    if (rule.required && (!value || value === '')) {
      return Promise.reject('请填写');
    } else {
      return Promise.resolve();
    }
  };
  const validateSelect = (rule: RuleObject, value: any) => {
    if (rule.required && ((!value && value !== 0) || value === '')) {
      return Promise.reject('请选择');
    } else {
      return Promise.resolve();
    }
  };

  export default defineComponent({
    name: 'UGSResellerDetail',
    props: {
      resellerId: {
        type: [String, Number],
        default: '',
      },
    },
    setup() {
      const { editRecharge } = useReseller();
      const { jumpToContract, jumpToCompany } = useJump();
      const recharge = ref<Recordable>({});
      const rechargeForm = ref<Nullable<FormActionType>>(null);
      const rechargeEdit = ref<Recordable>({});
      const { model, userMap, getModelItems } = useAppInfo();
      const { user, isFinance, isAdmin } = useUser();
      return {
        recharge,
        rechargeEdit,
        rechargeForm,
        model,
        user,
        isFinance,
        isAdmin,
        userMap,
        editRecharge,
        getModelItems,
        jumpToContract,
        jumpToCompany,
      };
    },
    data() {
      return {
        editMode: false,
        readOnly: false,
        tracePage: 'Recharge详情',
      };
    },
    created() {
      if (!this.resellerId) {
        this.$router.go(-1);
        return;
      }
      this.getRechargeDetail(Number(this.resellerId));
    },
    computed: {
      contract_table_config(): BasicTableProps {
        const columns: BasicColumn[] = [
          {
            title: '合同',
            dataIndex: 'name',
            slots: {
              customRender: 'name',
            },
          },
          {
            title: '合同主体',
            dataIndex: 'rd_company_name',
          },
          {
            title: 'Sales',
            dataIndex: 'rd_owner_name',
          },
          {
            title: 'AM',
            dataIndex: 'rd_am_name',
          },
        ];
        return {
          title: '客户合同信息',
          useFilterFromColumns: false,
          // showTableHeader: false,
          useSearchForm: false,
          columns,
          immediate: false,
        };
      },
      showRecharge(): boolean {
        return !isEmptyData(this.recharge) && !this.recharge.is_deleted;
      },
      is_me(): boolean {
        return this.recharge.creator_id === this.user.id;
      },
      get_drop_down_menus(): Recordable[] {
        const ret: Recordable[] = [
          {
            show: this.isAdmin || this.is_me,
            label: '删除',
            onClick: () => this.deleteRecharge(this.recharge),
          },
        ];
        return ret.filter((x) => x.show);
      },
      formRules(): object {
        return {
          ad_platform: [{ required: true, trigger: 'change', validator: validateSelect, type: 'number' }],
          recharge_amount: [{ required: true, trigger: 'change', validator: validateInput, type: 'number' }],
          recharge_time: [{ required: true, trigger: 'change', validator: validateInput, type: 'string' }],
        };
      },
    },
    methods: {
      isEmptyData,
      formatMoneyByRound,
      required,
      formatTime,
      editOrAddRecharge(type) {
        this.editRecharge(type, this.recharge, this);
      },
      deleteRecharge(recharge) {
        this.$showAlert('Delete Recharge', 'Are you sure to delete recharge?', () => {
          edit_recharge({ data: { id: recharge.id, is_deleted: true } }).then(() => {
            appStore.SET_ALERT_CONFIG(null);
            this.afterEditRecharge('delete');
          });
        });
      },

      afterEditRecharge(res) {
        this.doAfterEditRecharge(res);
      },
      getRechargeDetail(id: number) {
        get_recharge_item({ recharge_id: id }).then((res) => {
          this.recharge = res;
          this.rechargeEdit = res;
          document.title = this.tracePage;
        });
      },
      doAfterEditRecharge(res) {
        if (res === 'delete') {
          this.recharge = {};
          appStore.SET_DRAWER_CONFIG(null);
        } else if (this.recharge) {
          this.getRechargeDetail(Number(this.resellerId));
        }
      },
      catchFlowFlag(flag, _) {
        if (flag === 'FLAG_EDIT_RECHARGE') {
          this.editMode = true;
          // this.editRecharge('edit', this.recharge, this);
        }
      },
      handleCancel() {
        this.rechargeForm!.clearValidate();
        this.getRechargeDetail(Number(this.resellerId));
        this.editMode = false;
      },
      async handleSave() {
        try {
          await this.rechargeForm!.validate();
          this.$message.success('保存成功');
          edit_recharge({
            data: {
              id: this.recharge.id,
              recharge_amount: this.rechargeEdit.recharge_amount,
              recharge_time: this.rechargeEdit.recharge_time,
              ad_platform: this.rechargeEdit.ad_platform,
            },
          }).then((res) => {
            this.doAfterEditRecharge(res);
            this.editMode = false;
          });
        } catch (err) {
          this.$message.error('保存失败');
          console.log(err);
        }
      },
    },
  });
