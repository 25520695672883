/* eslint-disable camelcase */
import request from '@/utils/request';
import { SelectOption } from 'types/store';
export function get_bank_list() {
  return request.post<null, any>('api/v1/get_bank_list');
}
// 获取产品分类
export function getProductClass() {
  return request.get<
    null,
    {
      product_class_1_list: {
        id: number;
        name: string;
        product_class_2_list: {
          id: number;
          name: string;
          product_class_1_id: number;
          product_class_3_list: any;
        }[];
      }[];
    }
  >('api/v1/get_product_class_list');
}
// 获取归因平台
export function getAttribution() {
  return request.get<null, { items: SelectOption[] }>('/api/v1/pub_product_statis_list');
}
export function loginByDev(params?: { email: string; password: string }) {
  return request.post<null>('/api/v1/login', params);
}
export function switchUser(params: { user_id: number }) {
  request.post<null>('/api/v1/switch_user', params);
}
export function get_similarity_companys(params: { name: string }) {
  return request.post<null, { similarity_companys: Recordable[] }>('/api/v1/get_similarity_companys', params);
}
export function get_similarity_opps(params: Recordable) {
  return request.post<null, { similarity_opps: Recordable[] }>('/api/v1/get_similarity_opps', params);
}
export function get_time_zone() {
  return request.post<null, Recordable>('/api/v1/get_time_zone');
}
