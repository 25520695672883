
  import { defineComponent, ref } from 'vue';
  import { appStore } from '@/store/modules/app';
  import { doPostAsync } from '@/api/crm-customerTrack';
  import { handle_flow_action } from '@/api/crm-customerTrack';
  import { isEmptyData } from '@/utils/common';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { formatTime } from '@/utils/time';
  import { useUser } from '@/hooks/useUser';
  import { useOptionList } from '@/hooks/useOptionList';
  import { required } from '@/utils/validate';
  import { sortBy, indexOf } from 'lodash-es';

  export default defineComponent({
    name: 'FlowTimeLine',
    props: {
      flow: {
        type: Object as PropType<Recordable>,
      },
      onlyShowTitle: {
        type: Boolean,
        default: false,
      },
      onlyShowButton: {
        type: Boolean,
        default: false,
      },
      hiddenButtons: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { model, userMap } = useAppInfo();
      const { user, isGod, isAdmin } = useUser();
      const { get_pmambd } = useOptionList();
      const workFlowVisible = ref(false);
      const showOldStates = ref(false);
      return {
        model,
        userMap,
        user,
        isGod,
        isAdmin,
        get_pmambd,
        workFlowVisible,
        showOldStates,
      };
    },
    watch: {
      flow: {
        handler(val) {
          this.makeStateList(val);
        },
        immediate: true,
      },
    },
    computed: {
      canAssign(): Boolean {
        return this.isAdmin && this.flow!.current_state.status === this.model.FlowState.STATUS.OPEN;
      },
    },
    methods: {
      isEmptyData,
      onUpdate(res) {
        this.$emit('onUpdate', res);
        appStore.SET_WE_DIALOG(null);
        appStore.SET_ALERT_CONFIG(null);
      },
      async changeState(state, action) {
        const front_ext = action.front_ext;
        const params = {
          flow_id: this.flow!.id,
          state_id: state.id,
          action_id: action.id,
          caller: 'front',
        };

        if (front_ext.type === 'front_free') {
          this.$emit('onCatchFlag', front_ext.flag, params);
          return;
        } else {
          const tipContent = isEmptyData(front_ext.content)
            ? !isEmptyData(front_ext.input_fields)
              ? null
              : '确定进行下一步?'
            : front_ext.content;
          const blocks: any[] = [];
          if (!isEmptyData(tipContent)) {
            blocks.push({ type: 'tip', value: tipContent });
          }
          let promiseArr: any = [];
          let title = '提示';
          if (!isEmptyData(front_ext.title)) {
            title = front_ext.title;
          }

          if (!isEmptyData(front_ext.input_fields)) {
            const inputs: any[] = [];
            let tip: any = null;
            front_ext.input_fields.forEach((field) => {
              let input = Object();
              input.result = field.result;
              input.label = field.name;
              input.rules = field.required ? [required()] : [];
              input.type = field.type;
              input.autoSize = field.autosize;
              input.labelAlign = field.labelAlign ? field.labelAlign : 'right';
              input.labelCol = field.labelCol ? field.labelCol : '120px';
              input.width = field.width ? field.width : 24;
              input.value = field.value ? field.value : '';
              if (!isEmptyData(field.init)) {
                input.init = field.init;
              }
              if (input.type.includes('select') || input.type.includes('radio')) {
                if (typeof field.options === 'object') {
                  input.items = field.options;
                } else if (typeof field.options === 'string') {
                  promiseArr.push(
                    doPostAsync('api/v1/' + field.options, {}).then((res) => {
                      input.items = res;
                    })
                  );
                }
              }
              if (['note'].includes(input.type)) {
                blocks.push(input);
              } else if (['tip'].includes(input.type)) {
                tip = { type: 'tip', value: input.value, width: 24 };
              } else {
                inputs.push(input);
              }
            });
            blocks.push({ type: 'input', value: inputs });
            if (!isEmptyData(tip)) {
              blocks.push({ type: 'divider' });
              blocks.push(tip);
            }
          }

          const showTimelineDialog = () => {
            this.$showDialog({
              title: title,
              width: '600px',
              blocks: blocks,
              layout: 'vertical',
              callback: (result) => {
                handle_flow_action({ ...params, data: result }).then((res) => {
                  if (res.error_message) {
                    this.$showAlert('提示', res.error_message);
                  } else {
                    this.onUpdate(res);
                  }
                });
              },
            });
          };
          if (promiseArr.length > 0) {
            Promise.all(promiseArr).then(() => {
              showTimelineDialog();
            });
          } else {
            showTimelineDialog();
          }
        }
      },
      makeStateList(flow) {
        if (isEmptyData(flow)) {
          return;
        }
        const statusItems = this.model.FlowState.STATUS;
        const roleItems = this.model.FlowStateConfig.ROLE;
        const statusMap = {
          Open: '进行中',
          Rejected: '已拒绝',
          Canceled: '已取消',
          Pending: '待定',
        };

        const hasPermission = (state) => {
          return (
            (!isEmptyData(state.deal_owners) &&
              state.deal_owners.includes(this.user.id) &&
              [this.model.FlowState.STATE_TYPE.ALL_PASS, this.model.FlowState.STATE_TYPE.ONE_PASS].includes(
                state.state_type
              )) ||
            (state.owner_id === this.user.id &&
              [this.model.FlowState.STATE_TYPE.SINGLE, this.model.FlowState.STATE_TYPE.ONE_BY_ONE].includes(
                state.state_type
              )) ||
            this.isGod
          );
        };

        flow.state_list.forEach((state) => {
          if (state.id === flow.current_state_id) {
            flow.current_state = state;
            if (hasPermission(flow.current_state)) {
              state.span_show = true;
            }
          }
          let dec = '';
          if (statusItems[state.status]) {
            dec = statusMap[statusItems[state.status]];
            if (dec) {
              dec = state.name + '：' + dec;
            } else {
              dec = state.name;
            }
          }
          if (state.state_desc_config) {
            dec = state.state_desc_config[statusItems[state.status].toLowerCase()] || dec;
          }
          state.state_desc = '，' + dec;
          if (
            [
              this.model.FlowState.STATE_TYPE.SINGLE,
              this.model.FlowState.STATE_TYPE.ONE_PASS,
              this.model.FlowState.STATE_TYPE.ONE_BY_ONE,
            ].includes(state.state_type)
          ) {
            state.state_desc = state.rd_owner.replace(/\t/g, '/') + state.state_desc;
          } else {
            const allOwnerName = state.deal_owners.map((x) => this.userMap[x].name).join('/');
            const doneOwnerName = state.done_owners.map((x) => this.userMap[x.user_id || x].name).join('/');
            if (!isEmptyData(allOwnerName) && !isEmptyData(doneOwnerName)) {
              state.state_desc =
                allOwnerName +
                state.state_desc +
                '；<span style="font-weight: normal; color: #aaa;">' +
                doneOwnerName +
                ', 已处理</span>';
            } else if (!isEmptyData(doneOwnerName)) {
              state.state_desc = doneOwnerName + state.state_desc;
            } else {
              state.state_desc = allOwnerName + state.state_desc;
            }
          }

          state.show_time = formatTime(state.update_time, 'MM-DD HH:mm');
          state.show_long_time =
            formatTime(state.create_time, 'YYYY-MM-DD HH:mm:ss') +
            ' ~ ' +
            formatTime(state.update_time, 'YYYY-MM-DD HH:mm:ss');
          state.state_group_name = roleItems[state.role].slice(0, 1);
          state.show_time_style = 'color: #aaa; width: 90px; display: inline-block;';
          if (state.status == statusItems.OPEN) {
            state.color = '#F57C00';
            state.desc_style = 'font-weight: bold;';
            state.show_time_style = 'font-weight: bold; color: #aaa; width: 90px; display: inline-block;';
          } else if (state.status === statusItems.FINISHED) {
            state.color = '#4CAF50';
            state.desc_style = 'color: #333;';
          } else if (state.status === statusItems.REJECTED) {
            state.color = 'red';
            state.desc_style = 'color: red;';
          } else {
            state.color = 'grey';
            state.desc_style = 'color: #333;';
          }
          state.showAddIcon = false;
          state.can_add = hasPermission(state) && state.status !== statusItems.OPEN;
          state.can_edit = hasPermission(state) && state.status === statusItems.OPEN;
          state.can_alert = state.status === statusItems.OPEN;
          state.can_comment = this.isAdmin;
        });
        if (hasPermission(flow.current_state)) {
          flow.current_state_actions = flow.current_state_actions.filter((action) => action.condition.show);
          flow.current_state_actions = sortBy(flow.current_state_actions, (action) => {
            return indexOf(flow.current_state_order_actions, action.id);
          });
        } else {
          flow.current_state_actions = [];
        }
      },

      initAction(item, state, action) {
        action.is_global = !state.id;

        const isBlankObj = (x) => x === '{}' || JSON.stringify(x) === '{}' || x === '[]' || JSON.stringify(x) === '[]';
        action.message = isBlankObj(action.message) ? '' : action.message;
        action.front_ext = isBlankObj(action.front_ext) ? '' : action.front_ext;
        action.next_state_dynamic_config = isBlankObj(action.next_state_dynamic_config)
          ? ''
          : action.next_state_dynamic_config;
        const next_state = item.state_list.find((x) => x.id === action.next_state_id);
        action.next_state_key = next_state ? next_state.state_key : '';

        const show_next_state_status = action.next_state_status
          ? this.model.FlowAction.STATUS[action.next_state_status]
          : '';
        const show_this_state_status = action.this_state_status
          ? this.model.FlowAction.STATUS[action.this_state_status]
          : '';

        if (action.in_state_ids) {
          action.in_states = item.state_list.filter((x) => action.in_state_ids.includes(x.id));
          action.in_states_text = action.in_states.map((x) => x.state_key).join(' | ');
        }
        if (show_next_state_status || show_this_state_status) {
          action.this_and_next_state_status = `(Next → ${show_next_state_status}, Prev → ${show_this_state_status})`;
        }
      },

      titleClick() {
        if (this.onlyShowTitle) {
          this.showOldStates = true;
        }
      },
    },
  });
