<template>
  <a-input-group>
    <a-row class="we_number_range_group">
      <a-col class="we_number_item left_item">
        <a-input allowClear v-model:value="min" :placeholder="placeholder[0]" />
      </a-col>
      <a-col class="we_number_icon">
        <div>~</div>
      </a-col>
      <a-col class="we_number_item right_item">
        <a-input allowClear v-model:value="max" :placeholder="placeholder[1]" />
      </a-col>
    </a-row>
  </a-input-group>
</template>

<script>
  import { defineComponent, computed } from 'vue';
  export default defineComponent({
    name: 'WeNumberRange',
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            min: '',
            max: '',
          };
        },
      },
      placeholder: {
        type: Array,
        default: () => {
          return [];
        },
      },
    },
    data() {
      return {
        icon: '',
      };
    },
    emits: ['change'],
    setup(props, { emit }) {
      const max = computed({
        get: () => {
          if (props.value) {
            return props.value.max;
          } else {
            return '';
          }
        },
        set: (val) => {
          if (min.value) {
            emit('change', { min: min.value, max: val });
          } else {
            emit('change', { min: '', max: val });
          }
        },
      });
      const min = computed({
        get: () => {
          if (props.value) {
            return props.value.min;
          } else {
            return '';
          }
        },
        set: (val) => {
          if (max.value) {
            emit('change', { min: val, max: max.value });
          } else {
            emit('change', { min: val, max: '' });
          }
        },
      });
      return {
        min,
        max,
      };
    },
  });
</script>

<style lang="less">
  .we_number_range_group {
    width: 100%;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    .we_number_icon {
      width: 10px;
      text-align: center;
      border: none;
      line-height: 30px;
      color: rgba(0, 0, 0, 0.3);
    }
    .we_number_item {
      flex: 1;
      .ant-input-affix-wrapper {
        width: 100%;
        border: none;
        text-align: center;
        .ant-input-suffix {
          display: none;
        }
      }
    }
    .left_item {
      .ant-input-affix-wrapper {
        padding-right: 0;
      }
    }
    .right_item {
      padding-right: 40px;
      .ant-input-affix-wrapper {
        padding-left: 0;
      }
    }
  }
</style>
