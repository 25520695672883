import dayjs from 'dayjs';

export function getTimespan(time1, time2) {
  const t1 = new Date(time1);
  const t2 = new Date(time2);
  let timeDiff = t2.getTime() - t1.getTime();
  if (timeDiff < 0) {
    timeDiff = -timeDiff;
  }
  timeDiff /= 1000;

  if (timeDiff < 60) {
    return { timeDiff: timeDiff, content: Math.round(timeDiff) + ' secs' };
  } else if (timeDiff < 60 * 60) {
    return { timeDiff: timeDiff, content: Math.round(timeDiff / 60) + ' mins' };
  } else if (timeDiff < 60 * 60 * 60) {
    return { timeDiff: timeDiff, content: Math.round(timeDiff / 60 / 60) + ' hours' };
  } else {
    return { timeDiff: timeDiff, content: Math.round(timeDiff / 60 / 60 / 24) + 'days' };
  }
}

export function formatTime(timeStr, format?) {
  if (!timeStr) {
    return '';
  }
  if (timeStr === 'now') {
    return dayjs().format(format);
  }
  if (!format) {
    format = 'YYYY-MM-DD HH:mm';
  }
  return dayjs(timeStr).format(format);
}

export function getLeftDay(deadline, finishTime?) {
  if (!deadline) {
    return {
      content: '',
      color: '',
      leftDay: 0,
    };
  }

  const dTime = new Date(deadline);
  const fTime = finishTime ? new Date(finishTime) : new Date();
  const timeDiff = dTime.getTime() - fTime.getTime();
  const leftDay = Math.ceil(timeDiff / (1000 * 3600 * 24));

  let timeContent = '';
  let timeColor = '';
  if (finishTime) {
    if (leftDay >= 0) {
      timeContent = `Done`;
      timeColor = '#4CAF50'; // green
    } else {
      if (-leftDay > 1) {
        timeContent = `Done, ${-leftDay} Over`;
      } else {
        timeContent = `Done, ${-leftDay} Over`;
      }
      timeColor = '';
    }
  } else {
    if (leftDay > 7) {
      timeContent = `${leftDay} Left`;
      timeColor = '';
    } else if (leftDay > 1) {
      timeContent = `${leftDay} Left`;
      timeColor = '#F57C00';
    } else if (leftDay > 0) {
      timeContent = `${leftDay} Left`;
      timeColor = '#F57C00';
    } else {
      if (-leftDay > 1) {
        timeContent = `${-leftDay} Over`;
      } else {
        timeContent = `${-leftDay} Over`;
      }
      timeColor = 'red';
    }
  }

  return {
    content: timeContent,
    color: timeColor,
    leftDay: leftDay,
  };
}
export function getTimeRangeParam(type: string) {
  let params = {
    start: '',
    end: '',
  };
  switch (type) {
    case 'week':
      params = {
        start: dayjs().day(1).format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      };
      break;
    case 'month':
      params = {
        start: dayjs().startOf('month').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      };
      break;
    case 'lastMonth':
      params = {
        start: dayjs()
          .month(dayjs().month() - 1)
          .startOf('month')
          .format('YYYY-MM-DD'),
        end: dayjs()
          .month(dayjs().month() - 1)
          .endOf('month')
          .format('YYYY-MM-DD'),
      };
      break;
    case 'year':
      params = {
        start: dayjs().startOf('year').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD'),
      };
      break;
  }
  return params;
}
