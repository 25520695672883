import { useUser } from '@/hooks/useUser';
import { appStore } from '@/store/modules/app';
import { cacheStore } from '@/store/modules/cache';
import { findDialogInput, isEmptyData, mapItems } from '@/utils/common';
import { orderBy } from 'lodash-es';
import keyBy from 'lodash-es/keyBy';
import values from 'lodash-es/values';
import { IAppInfo, ITeam } from 'types/store';
import { computed, unref } from 'vue';

export function useAppInfo() {
  const appInfo = computed(() => {
    const temp = cacheStore.appInfo;
    return temp as IAppInfo;
  });
  const sub_app_suffix_list = computed(() => {
    if (!isEmptyData(unref(appInfo)) && !isEmptyData(unref(appInfo).custom_menu_list)) {
      return unref(appInfo).custom_menu_list.map((item) => JSON.parse(item.value).app_root);
    } else {
      return [];
    }
    return [];
  });
  const teamMap = computed(() => {
    const tempTeamMap = {};
    appInfo.value?.team_group_list.forEach((tg) => {
      tg.team_list.forEach((t) => {
        t.team_group = { id: tg.id, name: tg.name, head_id: tg.head_id };
        tempTeamMap[t.id] = t;
      });
    });
    return tempTeamMap;
  });
  const teamList = computed<ITeam[]>(() => {
    return values(unref(teamMap)) as ITeam[];
  });
  const bulineList = computed(() => {
    return unref(appInfo)?.buline_list as Recordable[];
  });
  const bulineMap = computed(() => {
    return keyBy(unref(appInfo)?.buline_list, 'id');
  });
  const userMap = computed(() => {
    return keyBy(unref(appInfo)?.user_list, 'id');
  });
  const currencyMap = computed(() => {
    return unref(appInfo)!.currency_map;
  });
  const model = computed(() => {
    return unref(appInfo)?.model as Recordable;
  });
  const countryList = computed(() => {
    return unref(appInfo)?.country_list;
  });
  const currencyList = computed(() => {
    return unref(appInfo)?.currency_list as Recordable[];
  });
  const quarterlyList = computed(() => {
    return orderBy(
      appInfo.value.time_span_list.filter((x) => x.range_type === model.value.TimeSpan.RANGE_TYPE.BY_Q),
      ['to_time'],
      ['desc']
    );
  });
  const weCompanyList = computed(() => {
    return unref(appInfo)?.we_company_list as Recordable;
  });
  const weCompanyMap = computed(() => {
    return unref(appInfo)?.we_company_map as Recordable[];
  });
  const roleNameList = computed(() => {
    return unref(appInfo)
      ?.permission_list.filter((ftem) => ftem.name.startsWith('Role'))
      .map((mtem) => mtem.name);
  });
  const slideWidth = computed(() => {
    return appStore.slideWidth;
  });
  const apiRoot = computed(() => {
    return process.env.VUE_APP_AXIOS_API + 'api/v1';
  });
  const getTfItems = computed(() => {
    const tfItems = [
      { text: '是', value: true, label: '是' },
      { text: '否', value: false, label: '否' },
    ];
    return tfItems;
  });

  function getTeamUsers(teamId) {
    let searchIds: any[] = [];
    if (teamId instanceof Array) {
      searchIds = teamId;
    } else {
      searchIds.push(teamId);
    }
    const users: any[] = Object.keys(unref(teamMap))
      .filter((t) => searchIds.includes(Number(t)))
      .map((t) =>
        unref(teamMap)
          [t].user_team_list.map((ut) => ut.user_id)
          .map((uid) => unref(userMap)[uid])
      );
    return Array.from(new Set([].concat(...users)));
  }
  function getTeamGroup(teamId) {
    let foundTeamGroup = {} as any;
    appInfo.value.team_group_list.forEach((tg) => {
      tg.team_list.forEach((t) => {
        if (t.id === teamId) {
          foundTeamGroup = tg;
        }
      });
    });
    return foundTeamGroup;
  }
  function getGroupUsers(teamId) {
    const tg = getTeamGroup(teamId);
    if (!isEmptyData(tg)) {
      const uids = new Set();
      tg.team_list.forEach((t) =>
        t.user_team_list.forEach((ut: any) => {
          uids.add(ut.user_id);
        })
      );
      return [...uids].map((uid: any) => unref(userMap)[uid]);
    } else {
      return [];
    }
  }
  function getAllTeams(teamTypes?) {
    const ret: any = [];
    unref(appInfo)?.team_group_list.forEach((tg) => {
      if (teamTypes) {
        tg.team_list.forEach((t) => {
          if (teamTypes.includes(t.team_type)) {
            ret.push(t);
          }
        });
      } else {
        tg.team_list.forEach((t) => ret.push(t));
      }
    });
    return ret;
  }
  function getModelItems(model, keepZero = false) {
    const modelList: any = [];
    Reflect.ownKeys(model).forEach((key: string) => {
      const id = parseInt(key, 10);
      if (Number.isInteger(Number(key)) && (id || (id === 0 && keepZero))) {
        modelList.push({
          name: model[key],
          text: model[key],
          label: model[key],
          id: id,
          value: id,
        });
      }
    });
    return modelList;
  }
  function getDollarRate(currencyId, month) {
    const currencyRateList = unref(currencyMap)[currencyId].currency_rate_list;
    let currencyRate = currencyRateList.find((c) => c.month === month);
    currencyRate = currencyRate ? currencyRate.dollar_rate : 1;
    return currencyRate;
  }
  function getMyTeamList(withGroupTeams = false) {
    const { isAdmin, isGod, isAnalysis, user } = useUser();
    if (unref(isGod)) {
      return unref(teamList);
    } else if (unref(isAdmin) || unref(isAnalysis)) {
      return unref(teamList).filter((x) => !x.is_history);
    } else {
      let teams: Recordable[] = [];
      if (withGroupTeams) {
        const userTeams = unref(user).user_team_list.map((ut) => ut.team);
        const groupIds = userTeams.map((x) => x.team_group_id);
        teams = unref(teamList).filter((x) => groupIds.includes(x.team_group_id));
      } else {
        teams = unref(user).user_team_list.map((ut) => ut.team);
      }
      return teams.filter((x) => !x.is_history);
    }
  }
  function getMyCrmTeams(permissions: any = null) {
    // CrmTeam配置的是一个人是XX Team的XX角色
    // 返回“我”身为“这些角色”涉及的Team
    const { user, hasPermission } = useUser();
    const allTeams = JSON.parse(JSON.stringify(teamList.value.filter((x) => !x.is_history)));
    allTeams.forEach((t) => (t.logic_types = JSON.parse(t.logic_types || '[]')));
    const allCrmTeams = allTeams.filter((x) => x.logic_types.length);

    if (hasPermission('RoleCrmAdmin') || hasPermission('RoleGod')) {
      return allCrmTeams;
    } else {
      permissions = permissions || ['TeamManager', 'CrmTeamManager', 'CrmTeamViewer', 'CrmTeamBD', 'CrmTeamMarket'];
      const userPermissionList = user.value.user_permission_list.filter((p) => permissions.includes(p.name));
      const teamIds = userPermissionList.map((p) => p.target_id);
      return allCrmTeams.filter((x) => teamIds.includes(x.id));
    }
  }
  function getTeamUserDialogChange(team_key, user_key) {
    return (result, _input, config) => {
      const entityInput = findDialogInput(config, user_key);
      entityInput.items = mapItems(getTeamUsers(result[team_key]));
      result[user_key] = '';
    };
  }
  function get_model_list(...fields) {
    let current_model: Recordable = model.value;
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (Reflect.has(current_model, field)) {
        current_model = current_model[field];
      } else {
        current_model = {};
        break;
      }
    }
    return getModelItems(current_model, true);
  }
  return {
    sub_app_suffix_list,
    apiRoot,
    countryList,
    weCompanyList,
    currencyList,
    appInfo,
    bulineMap,
    bulineList,
    teamMap,
    teamList,
    userMap,
    currencyMap,
    model,
    slideWidth,
    weCompanyMap,
    roleNameList,
    getTfItems,
    getTeamGroup,
    getAllTeams,
    getModelItems,
    getDollarRate,
    getMyTeamList,
    getMyCrmTeams,
    getTeamUserDialogChange,
    getTeamUsers,
    getGroupUsers,
    get_model_list,
    quarterlyList,
  };
}
