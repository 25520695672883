import { formatMoneyByRound, digitUppercase } from '@/utils/number';
import { cloneDeep, groupBy, sumBy } from 'lodash-es';
import { isEmptyData, formatNumberByNumeral } from './common';

function getInvoiceInfo(bill) {
  const business_config = JSON.parse(bill.contract.business_config);
  if (!isEmptyData(business_config)) {
    return business_config.confirmation.confirmation_info.cfm_invoice_info;
  }

  return `【南京魔度互娱信息技术有限公司】
名 称： 南京魔度互娱信息技术有限公司
纳税人识别号：91320114MA1YA0RP08
地 址： 南京市雨花台区软件大道21号H栋三层309室
电 话：025-86980686
开户行： 中国银行南京城南支行营业部
账 号：493673322991
发票类型： 增值税专用发票`;
}

function getMailInfo(bill) {
  const business_config = JSON.parse(bill.contract.business_config);
  if (!isEmptyData(business_config)) {
    return business_config.confirmation.confirmation_info.cfm_mail_info;
  }

  return `发票收件地址：南京市雨花台区舜天集团H栋Webeye二楼
收件人：孙玉婷
电话：13855568807`;
}

function getNoteInfo(bill) {
  const business_config = JSON.parse(bill.contract.business_config);
  if (!isEmptyData(business_config)) {
    return business_config.confirmation.confirmation_info.cfm_note_info;
  }

  return '如果结算确认函出现分页，请加盖骑缝章。谢谢！';
}

function getDivideRows(bill) {
  const product_list = cloneDeep(bill.billing.billing_list || []);
  let pub_billing_data_source: Recordable[] = [];
  const has_classified = product_list.some((item) => !isEmptyData(item.name_group));
  if (has_classified) {
    const grouped_product_list = groupBy(product_list, 'name_group');
    Reflect.ownKeys(grouped_product_list).forEach((item: string) => {
      const first_grouped_product_item = grouped_product_list[item][0];
      if (isEmptyData(item)) {
        grouped_product_list[item].forEach((f) => {
          f.name_group = '其它';
        });
      }
      grouped_product_list[item].unshift({
        product_code: '',
        product_name: first_grouped_product_item.name_group,
        cost: sumBy(grouped_product_list[item], 'cost'),
        revenue: sumBy(grouped_product_list[item], 'revenue'),
        profit: sumBy(grouped_product_list[item], 'profit'),
        divide_amount: sumBy(grouped_product_list[item], 'divide_amount'),
        divide_count_in: true,
        is_sum_by_group: true,
      });
      pub_billing_data_source.push(...grouped_product_list[item]);
    });
  } else {
    pub_billing_data_source = product_list;
  }

  let ret = '';
  let sumCost = 0;
  let sumRevenue = 0;
  let sumProfit = 0;
  let sumDivideAmount = 0;
  let sumGrantAmount = 0;

  pub_billing_data_source.forEach((b) => {
    if (b.divide_count_in) {
      ret += `
        <tr>
            <td class="cfm_table_cell">${b.product_code}</td>
            <td class="cfm_table_cell" style=${b.is_sum_by_group ? 'font-weight:700' : ''}>${b.product_name}</td>
            <td class="cfm_table_cell" style=${b.is_sum_by_group ? 'font-weight:700' : ''}>${formatMoneyByRound(
        b.cost
      )}</td>
            <td class="cfm_table_cell" style=${b.is_sum_by_group ? 'font-weight:700' : ''}>${formatMoneyByRound(
        b.revenue
      )}</td>
            <td class="cfm_table_cell" style=${b.is_sum_by_group ? 'font-weight:700' : ''}>${formatMoneyByRound(
        b.profit
      )}</td>
            <td class="cfm_table_cell">${formatMoneyByRound(b.divide_count_in ? b.divide_ratio * 100 : 0)}%</td>
            <td class="cfm_table_cell" style=${b.is_sum_by_group ? 'font-weight:700' : ''}>${formatMoneyByRound(
        b.divide_count_in ? b.divide_amount : 0
      )}</td>
            <td class="cfm_table_cell">${formatMoneyByRound(b.grant_count_in ? b.grant_amount : 0)}</td>
        </tr>
        `;
      if (isEmptyData(b.is_sum_by_group)) {
        sumCost += Number(b.cost);
        sumRevenue += Number(b.revenue);
        sumProfit += Number(b.profit);
        sumDivideAmount += Number(b.divide_amount);
        sumGrantAmount += Number(b.grant_amount);
      }
    }
  });
  if (ret !== '') {
    ret += `
        <tr>
            <td class="cfm_table_cell">总计</td>
            <td class="cfm_table_cell">-</td>
            <td class="cfm_table_cell">${formatMoneyByRound(sumCost)}</td>
            <td class="cfm_table_cell">${formatMoneyByRound(sumRevenue)}</td>
            <td class="cfm_table_cell">${formatMoneyByRound(sumProfit)}</td>
            <td class="cfm_table_cell">-</td>
            <td class="cfm_table_cell">${formatMoneyByRound(sumDivideAmount)}</td>
            <td class="cfm_table_cell">${formatMoneyByRound(sumGrantAmount)}</td>
        </tr>
        `;
  }
  return ret;
}

function getGrantRows(bill) {
  let ret = '';

  bill.billing.adjust_list.forEach((a) => {
    ret += `
        <tr>
            <td class="cfm_table_cell">${a.note}</td>
            <td class="cfm_table_cell">${formatMoneyByRound(a.adjust_money)}</td>
            <td class="cfm_table_cell">${a.count_in ? '计入' : '不计入'}</td>
            <td class="cfm_table_cell">${a.count_in ? a.ratio : 0}%</td>
            <td class="cfm_table_cell">${a.count_in ? a.real_money : 0}</td>
        </tr>
        `;
  });

  return ret;
}

export function genPubCfmHtmlText(bill, isManual) {
  return `
<style>
    .cfm_table_cell { text-align: left; padding-left: 10px; }
    .cfm_field_holder { display: flex; margin: 15px 0; }
    .cfm_field_left { display: inline-flex; width: 180px; font-weight: bold; justify-content: flex-end; padding-right: 20px; }
    .cfm_field_right { display: inline-flex; width: calc(100% - 180px); white-space: pre-wrap; }
    .all_holder tbody tr { height: 26px; }
</style>

<div style="padding: 40px 30px 0px 30px;" class="all_holder">
    <div style="
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 30px;
        ">${bill.rd_company_name} ${bill.month} ${isManual ? '(手工)' : ''}确认函</div>

    <div style="display: flex; margin-bottom: 10px;">
        <span style="
            font-weight: bold;
            ">收款方：${bill.rd_company_name}</span>
    </div>

    <div style="display: flex; margin-bottom: 15px;">
        <span style="
            font-weight: bold;
            ">付款方：${bill.rd_we_company_name}</span>
        <span style="flex-grow: 1;"></span>
        <span style="
            font-weight: bold;
            width: 120px;
            ">结算方式：${bill.rd_currency_name}</span>
    </div>

    <div style="padding-bottom: 20px; margin-bottom: 20px; border-bottom: solid 1px #ccc;">
        <table border="0" cellspacing="0" cellpadding="0" style="width:100%;">
            <tr style="background: #eee; height: 35px;">
                <th width="10%" class="cfm_table_cell">Code</th>
                <th width="18%" class="cfm_table_cell" style="font-weight:700">产品名称/分类</th>
                <th width="12%" class="cfm_table_cell">支出</th>
                <th width="12%" class="cfm_table_cell">收入</th>
                <th width="12%" class="cfm_table_cell">盈利</th>
                <th width="12%" class="cfm_table_cell">分成比例</th>
                <th width="12%" class="cfm_table_cell">分成</th>
                <th width="12%" class="cfm_table_cell">补贴</th>
            </tr>
            ${getDivideRows(bill)}
        </table>

        <table border="0" cellspacing="0" cellpadding="0" style="width:100%; margin-top: 10px; ${
          bill.billing.adjust_list.length === 0 ? 'display: none;' : ''
        }">
            <tr style="background: #eee; height: 35px;">
                <th width="20%" class="cfm_table_cell">其它项</th>
                <th width="20%" class="cfm_table_cell">金额</th>
                <th width="20%" class="cfm_table_cell">是否计入</th>
                <th width="20%" class="cfm_table_cell">计入比例</th>
                <th width="20%" class="cfm_table_cell">计入金额</th>
            </tr>
            ${getGrantRows(bill)}
        </table>
    </div>

    <div>
        <div class="cfm_field_holder">
            <span class="cfm_field_left">金额合计：</span>
            <span class="cfm_field_right">${formatMoneyByRound(bill.billing.result)}</span>
        </div>

        <div class="cfm_field_holder">
            <span class="cfm_field_left">金额合计（大写）：</span>
            <span class="cfm_field_right">${digitUppercase(
              Number(formatNumberByNumeral(bill.billing.result, '0.00'))
            )}</span>
        </div>

        <div class="cfm_field_holder">
            <span class="cfm_field_left">开票信息：</span>
            <span class="cfm_field_right" contenteditable="true" id="cfm_invoice_info">${getInvoiceInfo(bill)}</span>
        </div>

        <div class="cfm_field_holder">
            <span class="cfm_field_left">邮寄地址：</span>
            <span class="cfm_field_right" contenteditable="true" id="cfm_mail_info">${getMailInfo(bill)}</span>
        </div>

        <div class="cfm_field_holder">
            <span class="cfm_field_left">备注：</span>
            <span class="cfm_field_right" contenteditable="true" id="cfm_note_info">${getNoteInfo(bill)}</span>
        </div>

        <div class="cfm_field_holder">
            <span class="cfm_field_left">盖章：</span>
            <span class="cfm_field_right"></span>
        </div>
        <div class="cfm_field_holder" style="margin: 20px 0;">
            <span class="cfm_field_left"></span>
            <span class="cfm_field_right">
                <span>${bill.rd_company_name}</span>
                <span style="flex-grow: 1;"></span>
                <span style="margin-right: 40px;">${bill.rd_we_company_name}</span>
            </span>
        </div>
    </div>
</div>
`;
}
