
  import { defineComponent, watch, ref, computed, unref } from 'vue';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { get_entity_change } from '@/api/erp-contracts';
  import { appStore } from '@/store/modules/app';
  import { BasicColumn } from '../WeTable';
  import dayjs from 'dayjs';
  export default defineComponent({
    name: 'WeChange',
    props: {
      entity: {
        type: Object,
        default: () => {
          return {};
        },
      },
      entityType: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const show = ref(false);
      const loadingChangeList = ref(true);
      const changeList = ref<Recordable[]>([]);
      const columns = computed<BasicColumn[]>(() => {
        return [
          {
            title: 'Change Time',
            dataIndex: 'create_time',
            slots: { customRender: 'create_time' },
          },
          { title: 'Changer', dataIndex: 'owner_id', slots: { customRender: 'changer' } },
          { title: 'Field', dataIndex: 'field' },
          {
            title: 'Change From',
            dataIndex: 'change_from',
            format: (text) => {
              if (isNaN(text) && !isNaN(Date.parse(text))) {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
              }
              return text;
            },
          },
          {
            title: 'Change To',
            dataIndex: 'change_to',
            format: (text) => {
              if (isNaN(text) && !isNaN(Date.parse(text))) {
                return dayjs(text).format('YYYY-MM-DD HH:mm:ss');
              }
              return text;
            },
          },
        ];
      });
      const { userMap, model } = useAppInfo();
      const relatedType = computed(() => {
        if (props.entityType) {
          return unref(model)?.MongoEntityChange.TYPE[props.entityType.toUpperCase()];
        }
        return null;
      });
      const initFav = () => {
        if (props.entityType) {
          loadingChangeList.value = true;
          changeList.value = [];
          get_entity_change({ entity_id: props.entity.id, entity_type: unref(relatedType) }).then((res) => {
            changeList.value = res.change_list;
            loadingChangeList.value = false;
          });
        }
      };
      watch(
        () => props.entityType,
        (newVal) => {
          if (newVal) {
            initFav();
            show.value = true;
          } else {
            show.value = false;
          }
        },
        {
          deep: true,
        }
      );
      return {
        show,
        columns,
        model,
        loadingChangeList,
        changeList,
        userMap,
      };
    },
    methods: {
      closeDialog() {
        appStore.SET_WE_CHANGE(null);
      },
    },
  });
