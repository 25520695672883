import request from '@/utils/request';
import { HWTableListRequestParam, HWTableListResponseType } from 'types/config';

export function get_company_page(params: HWTableListRequestParam) {
  return request.post<null, HWTableListResponseType>('/api/v1/get_company_page', params);
}
export function edit_3m_activity(params: any) {
  return request.post<null, any>('/api/v1/edit_3m_activity', params);
}
export function add_3m_activity_comment(params: any) {
  return request.post<null, any>('/api/v1/add_3m_activity_comment', params);
}
export function batch_assign_lead(params: any) {
  return request.post<null>('/api/v1/batch_assign_lead', params);
}
// 获取客户列表
export function get_company_list(params?: {
  get_all?: boolean;
  pick_fields?: string[];
  company_group_id?: number;
  list_type?: string;
  region_type?: string;
  erp_type?: string;
}) {
  return request.post<null, { company_list: Recordable[] }>('/api/v1/get_company_list', params);
}
export function get_company_item(params: { company_id: number }) {
  return request.post<null, { status: number; company: any }>('/api/v1/get_company_item', params);
}
export function get_company_related_items(params: { company_id: number }) {
  return request.post<
    null,
    {
      status: number;
      company: any;
      person_list: any;
      bank_list: any;
      opp_list: any;
      case_list: any;
      contract_list: any;
      bill_list: any;
      account_list: Recordable[];
      can_edit_name: boolean;
      group_company_list: any;
    }
  >('/api/v1/get_company_related_items', params);
}
export function edit_company(params: any) {
  return request.post<null, Recordable>('/api/v1/edit_company', params);
}
export function set_company_group(params: any) {
  return request.post<null>('/api/v1/set_company_group', params);
}
export function delete_clue(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_clue', params);
}
export function get_clue_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_clue_page', params);
}
export function edit_clue(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_clue', params);
}
export function upload_clue(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_clue', params);
}
export function assign_clue(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_clue', params);
}
export function get_opp_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_opp_page', params);
}
export function get_leads_track_no_track_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_leads_track_no_track_page', params);
}
export function get_opp_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_opp_item', params);
}
export function edit_opp(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_opp', params);
}
export function edit_opp_raw(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_opp_raw', params);
}
export function batch_delete_opp(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_delete_opp', params);
}
export function batch_assign_opp(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_assign_opp', params);
}
export function edit_3m_person(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_3m_person', params);
}
export function get_user_have_opp_count(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_user_have_opp_count', params);
}
export function hw3_get_company_item(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/hw3_get_company_item', params);
}
export function get_3m_pub_product_page(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_3m_pub_product_page', params);
}
export function close_to_win(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/close_to_win', params);
}
export function delete_opp(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_opp', params);
}
export function merge_opp(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/merge_opp', params);
}
export function get_3m_activity_report_daily_page(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_3m_activity_report_daily_page', params);
}
export function edit_3m_activity_report_daily(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_3m_activity_report_daily', params);
}
export function get_user_today_activity_list(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_user_today_activity_list', params);
}
export function get_today_all_activity_report(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_today_all_activity_report', params);
}
export function edit_company_finance_info_apply(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_company_finance_info_apply', params);
}
export function get_company_finance_info_apply_page(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_company_finance_info_apply_page', params);
}
export function get_company_finance_info_item(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_company_finance_info_item', params);
}
export function assign_cfi(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/assign_cfi', params);
}
export function get_black_company_page(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_black_company_page', params);
}
export function batch_add_black_company(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/batch_add_black_company', params);
}
export function edit_black_company(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/edit_black_company', params);
}
