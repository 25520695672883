import { getOSSInfo } from '@/api/oss';
import dayjs from 'dayjs';
/**
 * 文件上传基于el-upload
 */
export async function UploadOSS(option: any, parentPath = 'images', need_suffix = false) {
  const ossConfig: Recordable = await getOSSInfo();
  const client = new (window as any).OSS({
    region: 'oss-cn-beijing',
    accessKeyId: ossConfig.Credentials.AccessKeyId,
    accessKeySecret: ossConfig.Credentials.AccessKeySecret,
    bucket: 'harborpub-bucket',
    stsToken: ossConfig.Credentials.SecurityToken,
  });
  const file = option.file;
  let fileNames = `${parentPath}/${file.name}`;
  if (need_suffix) {
    const point = file.name.lastIndexOf('.');
    const suffix = file.name.substr(point);
    const fileName = file.name.substr(0, point);
    const date = dayjs(new Date()).format('YYYYMMDDHHmm');
    fileNames = `${parentPath}/${fileName}_${date}${suffix}`;
  }
  const ret = await client.multipartUpload(fileNames, file, {
    progress: async function (p: number) {
      const e = {
        percent: 0,
      };
      e.percent = p * 100;
      option.onProgress(e);
    },
  });
  if (ret.res.statusCode === 200) {
    option.onSuccess(ret);
  } else {
    getOSSInfo().then((_res) => {
      UploadOSS(option, parentPath);
    });
  }
}
/**
 *
 * @param option
 * @param ossConfig
 * @param parentPath
 */
export async function UploadOSSByFile(file: any, ossConfig: any, parentPath = 'images', timeValue = true) {
  const client = new (window as any).OSS({
    region: 'oss-cn-beijing',
    accessKeyId: ossConfig.Credentials.AccessKeyId,
    accessKeySecret: ossConfig.Credentials.AccessKeySecret,
    bucket: 'harborpub-bucket',
    stsToken: ossConfig.Credentials.SecurityToken,
  });
  const point = file.name.lastIndexOf('.');
  const suffix = file.name.substr(point);
  const fileName = file.name.substr(0, point);
  const date = dayjs(new Date()).format('YYYYMMDDHHmm');
  let fileNames = `${parentPath}/${fileName}_${date}${suffix}`;
  if (!timeValue) {
    fileNames = `${parentPath}/${fileName}${suffix}`;
  }
  const ret = await client.multipartUpload(fileNames, file, {
    progress: async function (p: number) {
      const e = {
        percent: 0,
      };
      e.percent = p * 100;
    },
  });
  if (ret.res.statusCode === 200) {
    return ret;
  } else {
    getOSSInfo().then((_res) => {
      UploadOSSByFile(file, ossConfig, parentPath);
    });
  }
}
/**
 * @param filename为带目录路径的文件名称，如abc/efg/123.jpg
 * 预览某个文件
 */
export async function PreviewOSS(filename: string, ossConfig: any) {
  const client = new (window as any).OSS({
    region: 'oss-cn-beijing',
    accessKeyId: ossConfig.Credentials.AccessKeyId,
    accessKeySecret: ossConfig.Credentials.AccessKeySecret,
    bucket: 'harborpub-bucket',
    stsToken: ossConfig.Credentials.SecurityToken,
    cname: true,
    endpoint: 'cache.modooplay.com',
    secure: true,
  });
  const url = client.signatureUrl(filename, { expires: 3600 });
  return url;
}

/**
 * @param filename为带目录路径的文件名称，如abc/efg/123.jpg
 * 下载某个文件
 */
export async function downloadOSS(filename: string, ossConfig: any) {
  const client = new (window as any).OSS({
    region: 'oss-cn-beijing',
    accessKeyId: ossConfig.Credentials.AccessKeyId,
    accessKeySecret: ossConfig.Credentials.AccessKeySecret,
    bucket: 'harborpub-bucket',
    stsToken: ossConfig.Credentials.SecurityToken,
    cname: true,
    endpoint: 'cache.modooplay.com',
    secure: true,
  });
  const response = {
    'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`,
  };
  const url = client.signatureUrl(filename, { response });
  return url;
}
