import request from '@/utils/request';
export function manage_entity(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/manage_entity', params);
}
export function get_product_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_product_list', params);
}
export function edit_product_target(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_product_target', params);
}
export function delete_product_target(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_product_target', params);
}
export function get_pd_target_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_pd_target_list', params);
}
// 客户公海列表
export function leads_open_sea(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/leads_open_sea', params);
}
// 客户公海我认领的
export function get_my_leads_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_my_leads_list', params);
}
// 客户公海认领
export function leads_claim(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/leads_claim', params);
}
export function edit_official(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_official', params);
}
export function upload_official(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_official', params);
}
/**
 * Cloud汇率列表
 * @param params
 */
export function get_cloud_rate_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_cloud_rate_page', params);
}
/**
 * 编辑 / 新增Cloud汇率
 * @param params
 */
export function edit_cloud_rate(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_cloud_rate', params);
}
/**
 * 删除Cloud汇率
 * @param params
 */
export function delete_cloud_rate(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_cloud_rate', params);
}
