import { RouteRecordRaw } from 'vue-router';

const commonRouteConfig: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: { name: 'WB' },
    name: 'home',
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录', ignoreKeepAlive: true },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/no-auth',
    name: 'NoAuth',
    meta: { title: '暂无权限', ignoreKeepAlive: true },
    component: () => import('@/views/error/NoAuth.vue'),
  },
  {
    path: '/erp/pays/print',
    name: 'ERPPayPrint',
    meta: { title: '打印', ignoreKeepAlive: true },
    props: ({ params, query }) => ({ ...params, ...query }),
    component: () => import('@/views/erp/pays/Print.vue'),
  },
];

export default commonRouteConfig;
