import type { Directive, App } from 'vue';

const preventDoubleClickDirective: Directive = {
  mounted(el, binding) {
    el.addEventListener('click', () => {
      if (binding.value.loading) {
        el.disabled = true;
      }
      if (!el.disabled && !binding.value.loading) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value.time || 1000);
      }
    });
  },
  updated(el, binding) {
    el.disabled = binding.value.loading;
  },
  unmounted(el) {
    el.disabled = false;
  },
};

export function setupPreventDoubleClickDirective(app: App) {
  app.directive('preventDoubleClick', preventDoubleClickDirective);
}

export default preventDoubleClickDirective;
