import request from '@/utils/request';
// 获取合同列表
export function get_contract_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_contract_page', params);
}
export function getContractItemById(params: { contract_id: number }) {
  return request.post<null, Recordable>('/api/v1/get_contract_item', params);
}
export function get_contract_list(params: Recordable) {
  return request.post<null, { contract_list: Recordable[] }>('/api/v1/get_contract_list', params);
}
export function edit_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_contract', params);
}

export function assignContractToMeById(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_contract_to_me', params);
}
export function assign_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_contract', params);
}
export function delete_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_contract', params);
}
export function generate_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/generate_bill', params);
}
export function ask_reopen_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/ask_reopen_contract', params);
}
export function ask_close_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/ask_close_contract', params);
}
export function reset_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/reset_contract', params);
}
export function make_contract_in_force(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_contract_in_force', params);
}
export function make_contract_close(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_contract_close', params);
}
export function change_contract_buline(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/change_contract_buline', params);
}
export function manage_entity(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/manage_entity', params);
}
export function add_download_contract_template_log(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_download_contract_template_log', params);
}
export function get_entity_change(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_entity_change', params);
}
export function get_gcp_config_options(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_gcp_config_options', params);
}
export function edit_gcp_billing_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_gcp_billing_config', params);
}
export function upload_gcp_billing_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_gcp_billing_config', params);
}
export function set_ms_config_platform(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/set_ms_config_platform', params);
}
export function delete_gcp_billing_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_gcp_billing_config', params);
}
export function edit_media_account_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_media_account_config', params);
}
export function delete_entity_file(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_entity_file', params);
}
export function upload_entity_file_link(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_entity_file_link', params);
}
export function upload_entity_file(params: Recordable, config: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_entity_file', params, config);
}
export function edit_entity_note(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_entity_note', params);
}
export function edit_entity_state_added_note(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_entity_state_added_note', params);
}
export function edit_entity_state_note(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_entity_state_note', params);
}
export function edit_contract_template(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_contract_template', params);
}
export function dup_contract_template(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/dup_contract_template', params);
}
export function get_archive_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_archive_list', params);
}
export function get_contract_template_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_contract_template_list', params);
}
export function get_lark_contract_temp_category_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_lark_contract_temp_category_page', params);
}
export function edit_lark_contract_temp_category(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_lark_contract_temp_category', params);
}
export function get_lark_contract_temps(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_lark_contract_temps', params);
}
export function new_lark_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/new_lark_contract', params);
}
export function delete_lark_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_lark_contract', params);
}
export function sync_lark_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/sync_lark_contract', params);
}
export function sync_lark_contract_temps() {
  return request.post<null, Recordable>(
    '/api/v1/sync_lark_contract_temps',
    {},
    {
      timeout: 180000,
    }
  );
}

export function get_media_email_config_relation_items(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_media_email_config_relation_items', params);
}
export function get_media_email_config_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_media_email_config_page', params);
}
export function edit_media_email_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_media_email_config', params);
}
export function save_no_business_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/save_no_business_contract', params);
}
export function get_contract_email_items(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_contract_email_items', params);
}
export function batch_edit_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_edit_contract', params);
}
export function zip_archive_attach_files(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/zip_archive_attach_files', params);
}
export function get_gcp_billing_config_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_gcp_billing_config_page', params);
}
