
  interface IFileItem {
    filename: string;
    url: string;
    icon_name?: string;
    percent?: number;
    showProgress?: boolean;
  }
  import { defineComponent, ref } from 'vue';
  import { UploadOSS } from '@/utils/oss';
  import { isEmptyData, getFileIconName, formatNumberByNumeral } from '@/utils/common';
  import { DOWNLOAD_URL } from '@/utils/const';
  import { cloneDeep, pick } from 'lodash-es';
  import { setOSSFileToRead } from '@/api/oss';
  export default defineComponent({
    name: 'WeUpload',
    props: {
      editMode: {
        type: Boolean,
        default: true,
      },
      value: {
        type: [Object, Array] as PropType<IFileItem | IFileItem[]>,
        default: () => {
          return [];
        },
      },
      accept: {
        type: String,
        default: '',
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      need_suffix: {
        type: Boolean,
        default: false,
      },
      uploadText: {
        type: String,
        default: '文件上传',
      },
      uploadingText: {
        type: String,
        default: '上传中',
      },
      uploadDirectory: {
        type: String,
        default: 'images',
      },
      size: {
        type: Number,
        default: 3,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:value', 'done'],
    setup() {
      const isCompleted = ref<Boolean>(true);
      const fileList = ref<IFileItem[]>([]);
      fileList.value.forEach((item) => {
        item.icon_name = getFileIconName(item.filename);
        item.showProgress = false;
      });
      return {
        isCompleted,
        fileList,
      };
    },
    watch: {
      value: {
        handler(val) {
          if (isEmptyData(val)) {
            this.fileList = [];
          } else if (!Array.isArray(val)) {
            this.fileList = [val];
          } else {
            this.fileList = val;
          }
        },
        immediate: true,
      },
    },
    methods: {
      emitChange() {
        const files = cloneDeep(this.fileList);
        let val: { filename: string; url: string }[] | { filename: string; url: string } = [];
        if (this.multiple) {
          val = files.map((item) => ({ filename: item.filename, url: item.url }));
        } else {
          val = pick(files[0], ['filename', 'url']);
        }
        this.$emit('update:value', val);
      },
      customUpload(options) {
        if (!this.multiple) {
          this.fileList.splice(0, 1, {
            filename: options.file.name,
            url: '',
            percent: 0,
            icon_name: getFileIconName(options.file.name),
            showProgress: true,
          });
        } else {
          this.fileList.push({
            filename: options.file.name,
            url: '',
            percent: 0,
            icon_name: getFileIconName(options.file.name),
            showProgress: true,
          });
        }
        this.$nextTick(() => {
          const that = this;
          const len = this.fileList.length;
          options.onProgress = function (e) {
            that.fileList[len - 1].percent = formatNumberByNumeral(e.percent, '0');
          };
          UploadOSS(options, this.uploadDirectory, this.need_suffix);
        });
      },
      deleteFile(index: number) {
        this.fileList.splice(index, 1);
        this.emitChange();
      },
      async handleChange(options: any) {
        this.isCompleted = false;
        const file = options.file;
        if (file.status === 'done') {
          await setOSSFileToRead({ uri: file.response.name });
          const index = this.fileList.findIndex((item) => item.filename === file.name);
          this.fileList[index].showProgress = false;
          this.fileList[index].url = DOWNLOAD_URL + file.response.name;
          this.emitChange();
        }
        if (options.fileList.every((item) => item.status === 'done')) {
          let size = 0;
          options.fileList.forEach((item) => {
            size += item.size;
          });
          this.isCompleted = true;
          this.$emit('done', size / 1000 / 1000);
        }
      },
      beforeUpload(_file, fileList) {
        if (this.multiple) {
          if (this.fileList.length + fileList.length > this.size) {
            return false;
          }
        }
        return true;
      },
    },
  });
