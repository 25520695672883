import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const workbench: RouteRecordRaw = {
  path: '/workbench',
  name: 'WB',
  meta: { title: '工作台', ignoreKeepAlive: true },
  redirect: { name: 'WorkbenchMy' },
  component: AppLayout,
  children: [
    {
      path: 'my',
      name: 'WorkbenchMy',
      meta: { title: '我的工作台', ignoreKeepAlive: true },
      redirect: { name: 'WorkbenchMyHome' },
    },
    {
      path: 'my/home',
      name: 'WorkbenchMyHome',
      meta: { title: '首页', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/workbench/My.vue'),
    },
    {
      path: 'my/todo',
      name: 'WorkbenchMyToDo',
      meta: { title: '待办事项', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/workbench/ToDoList.vue'),
    },
    {
      path: 'my/done',
      name: 'WorkbenchMyDone',
      meta: { title: '我的已办', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/workbench/DoneList.vue'),
    },
  ],
};
export default workbench;
