import request from '@/utils/request';
// 获取config列表接口
export function get_gsuit_config_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_gsuit_config_page', params);
}
//  账单列表
export function get_gsuit_billing_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_gsuit_billing_page', params);
}
//  编辑，导入，新增config
export function edit_gsuit_configs(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_gsuit_configs', params);
}
//  导入，编辑账单
export function edit_gsuit_billings(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_gsuit_billings', params);
}
//  删除config
export function delete_gsuit_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_gsuit_config', params);
}
export function edit_cloud_usd_to_cny(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_cloud_usd_to_cny', params);
}
// 获取短信接口列表
export function get_msg_billing_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_msg_billing_page', params);
}
