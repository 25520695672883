export default {
  NEW_CONTRACT: {
    content: '创建合同',
    position: 'top',
  },
  NEW_BACKUP: {
    content: '不会产生任何费用与账单的：协议、证明、框架合同等，仅用于在线存档',
    position: 'right',
  },
  NEW_PURCHASE: {
    content: '采购合同，非业务相关，仅有付款功能，无账单',
    position: 'right',
  },
  NEW_BUSINESS_CONTRACT: {
    content: '业务合同，Supply为我方付款，Demand为我方收款',
    position: 'right',
  },
  NEW_CARD_REPORT_TO: {
    content: '该联系人的上级/领导',
    position: 'right',
  },
  LEAD_FIELD_STATUS_TIP: {
    content: `展示当前Lead的跟进状态：</br>
    Pending——未开始跟进或没有获得有效信息 </br>
    Open——已获取公司有效信息和有效联系人 </br>
    Inactive——不再跟进 </br>
    Converted——有合作机会（此时会转化为Opportunity）`,
    position: 'right',
  },
  LEAD_STATUS_PENDING: {
    content: '“新建跟进状态”，在此状态请添加“客户名片”',
    position: 'top',
  },
  LEAD_STATUS_OPEN: {
    content: '“跟进线索状态”，在此状态请完善“跟进信息”，例如“需求描述”',
    position: 'top',
  },
  LEAD_STATUS_ENDING: {
    content: `有合作机会时Convert转化为Opportunity；</br>
    确认不再跟进时转化为Inactive`,
    position: 'top',
  },
  LEAD_FIELD_PRIORITY: {
    content: 'P1优先级最高',
    position: 'top',
  },
  CASE_FIELD_NAME_TIP: {
    content: '主要用于与客户合作后遇到的相关事务记录或问题处理',
    position: 'right',
  },
  AMOUNT_GOT: {
    content: '已收到款的金额',
    position: 'top',
  },
  AMOUNT_GETTING: {
    content: '财务已收到该客户的款项金额，正在确认中',
    position: 'top',
  },
  TO_GET: {
    content: '待收款的金额',
    position: 'top',
  },
  AMOUNT_PAID: {
    content: '已付款的金额',
    position: 'top',
  },
  AMOUNT_PAYING: {
    content: '财务已安排付款计划，正在走付款流程的金额',
    position: 'top',
  },
  TO_PAY: {
    content: '待付款且还没有进入付款流程的金额',
    position: 'top',
  },
  CONTRACT_AUTO_EMIAL_TIP: {
    content: '请谨慎使用此功能！打开后，在对方付款账期到期前会自动发送提醒邮件，可通过右上角“Edit”打开/关闭',
    position: 'top',
  },
  CONTRACT_CUSTOMER_REJECTED: {
    content: '合同内容变化，需要修改条款，可回到“AM Making Draft”状态',
    position: 'top',
  },
  ASK_FOR_STAMP: {
    content: '业务打印合同，交给法务盖章',
    position: 'top',
  },
  ASK_FOR_PAPER_AND_STAMP: {
    content: '法务打印合同并盖章',
    position: 'top',
  },
  CONTRACT_ALL_DONE: {
    content: '将双方完成签字盖章后的纸质版扫描上传，原件交给法务，即完成全部流程',
    position: 'top',
  },
  CONTRACT_TERMINATE: {
    content: '申请直接关闭/终止合同',
    position: 'bottom',
  },
  LEGAL_REVIEWING_PAPER: {
    content: '此阶段为法务审核纸质合同，确认无误后盖章',
    position: 'right',
  },
  OPP_AMOUNT_TIP: {
    content: '预估月度消耗/成交金额',
    position: 'right',
  },
  ADD_ACTIVITY_TIP: {
    content: '添加关键事项，主要为与客户的沟通，互动',
    position: 'right',
  },
  ADD_TASK_TIP: {
    content: '添加简单任务，主要为内部推动或准备工作等',
    position: 'right',
  },
  ADD_COMMENT_TIP: {
    content: '添加评论',
    position: 'right',
  },
};
