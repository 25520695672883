import { MenuItem } from 'types/config';

const more: MenuItem = {
  path: 'more',
  title: '更多',
  icon: '',
  expandAll: true,
  is_root: true,
  hasTag: false,
  children: [
    {
      path: 'target',
      title: '目标管理',
      icon: 'WE_BarChartEditOutlined',
      children: [
        {
          path: 'scaler',
          title: '目标配置-Scaler',
        },
      ],
    },
  ],
};
export default more;
