import { MenuItem } from 'types/config';

const admin: MenuItem = {
  path: 'admin',
  title: 'Admin',
  icon: '',
  is_root: true,
  hasTag: false,
  children: [
    {
      path: 'super',
      title: '超管',
      icon: 'WE_UserAdminOutlined',
      children: [{ path: 'quick_login', title: '快捷登录' }],
    },
    {
      path: 'system',
      title: '系统设置',
      icon: 'SettingOutlined',
      children: [
        { path: 'serve-config', title: '业务设置' },
        { path: 'create-report', title: '报表生成器' },
        { path: 'create-todo', title: '待办生成器' },
      ],
    },
    {
      path: 'org',
      title: '组织与权限',
      icon: 'WE_ChartGraphOutlined',
      children: [
        { path: 'sub_system_menu', title: '嵌入菜单' },
        { path: 'route_permission', title: '路由权限' },
      ],
    },
  ],
};
export default admin;
