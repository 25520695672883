import request from '@/utils/request';

// permission
export function get_permission_list(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_permission_list', params);
}
export function get_user_permission_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_user_permission_list', params);
}
export function get_user_product_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_user_product_list', params);
}
export function get_role_permission_list(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_role_permission_list', params);
}
export function edit_role_permission(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_role_permission', params);
}
export function add_user_permission(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_user_permission', params);
}
export function delete_user_permission(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_user_permission', params);
}

// organization
export function make_group_history(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_group_history', params);
}
export function edit_group(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_group', params);
}
export function make_team_history(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_team_history', params);
}
export function edit_team(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_team', params);
}
export function set_buline_default_am(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/set_buline_default_am', params);
}
export function make_buline_history(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_buline_history', params);
}
export function edit_buline(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_buline', params);
}
export function sync_lark_info(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/sync_lark_info', params);
}
export function edit_user(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_user', params);
}
export function delete_user(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_user', params);
}
export function reset_password(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/reset_password', params);
}
export function get_buline_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_buline_list', params);
}
export function get_user_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_user_list', params);
}
export function get_team_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_team_list', params);
}
export function get_team_lark_group_members(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_team_lark_group_members', params);
}
export function delete_team_lark_group_member(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_team_lark_group_member', params);
}
export function add_team_lark_group_member(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_team_lark_group_member', params);
}
export function get_company_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_company_list', params);
}
export function get_opp_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_opp_list', params);
}
export function delete_company(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_company', params);
}
export function batch_add_black_company(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_add_black_company', params);
}
export function upload_customer_business_license(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_customer_business_license', params);
}
export function delete_customer_business_license(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_customer_business_license', params);
}
export function edit_bank(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_bank', params);
}
export function get_company_person_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_company_person_list', params);
}
export function edit_person(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_person', params);
}
export function remove_person(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/remove_person', params);
}
export function get_product_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_product_page', params);
}
export function get_product_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_product_item', params);
}
export function get_key_val(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_key_val', params);
}
export function set_key_val(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/set_key_val', params);
}
export function edit_product(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_product', params);
}
export function edit_product_fee_rate(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_product_fee_rate', params);
}
export function delete_product_fee_rate(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_product_fee_rate', params);
}
export function get_account_config_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_account_config_page', params);
}
export function attach_product(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/attach_product', params);
}
export function remove_product(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/remove_product', params);
}
export function get_agency_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_agency_list', params);
}

// 编辑产品分类item
export function edit_product_category_item(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_product_category_item', params);
}

// 获取产品分类list
export function get_product_category_list(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_product_category_list', params);
}

// 编辑产品分类
export function edit_products_group(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_products_group', params);
}
export function get_data_report_config_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_data_report_config_list', params);
}
export function edit_data_report_config(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_data_report_config', params);
}
export function delete_data_report_config(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_data_report_config', params);
}

export function get_model_fields(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_model_fields', params);
}
