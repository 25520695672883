import { DialogFormItem } from './types';

export const basicProps = {
  title: {
    type: String,
    default: '',
  },
  needCache: {
    type: Boolean,
    default: false,
  },
  key: {
    type: String,
    default: 'id',
  },
  labelCol: {
    type: [String, Object],
    default: () => {
      return { style: 'width: 160px' };
    },
  },
  layout: { type: String, default: 'horizontal' },
  labelAlign: { type: String, default: 'right' },
  wrapperCol: {
    type: Object,
    default: () => {
      return { style: 'max-width: calc(100% - 160px)' };
    },
  },
  width: {
    type: String,
    default: '800px',
  },
  blocks: {
    type: Array as PropType<DialogFormItem[]>,
    default: () => [],
  },
  btns: {
    type: Array as PropType<{ text: string; callback: (...arg: any[]) => any }[]>,
    default: () => [],
  },
  callback: {
    type: Function as PropType<(...arg: any[]) => any>,
    default: (result) => {
      console.log(result);
    },
  },
  change: {
    type: Function as PropType<(...arg: any[]) => any>,
    default: undefined,
  },
  cancelStop: {
    type: Function as PropType<(...arg: any[]) => any>,
    default: undefined,
  },
  cancel: {
    type: Function as PropType<(...arg: any[]) => any>,
    default: undefined,
  },
  noCancel: {
    type: Boolean,
    default: false,
  },
  noOk: {
    type: Boolean,
    default: false,
  },
  okText: {
    type: String,
    default: undefined,
  },
  cancelText: {
    type: String,
    default: undefined,
  },
  init: {
    type: Object,
    default: () => {
      return {};
    },
  },
};
