<template>
  <a-range-picker v-model:value="computedValue" v-bind="$attrs" valueFormat="YYYY-MM-DD" />
</template>

<script>
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'WeDateRangePicker',
    props: {
      value: {
        type: Object,
        default: () => {
          return {
            min: '',
            max: '',
          };
        },
      },
    },

    emits: ['change'],
    data() {
      return {
        icon: '',
        numberRange: {
          min: '',
          max: '',
        },
      };
    },
    computed: {
      computedValue: {
        get() {
          return [this.value['mix'], this.value['max']];
        },
        set(val) {
          this.$emit('change', { min: val[0], max: val[1] });
        },
      },
    },
    methods: {
      changeRangeValue(val) {
        this.$emit('change', val);
      },
    },
    watch: {
      numberRange: {
        handler(newVal) {
          this.changeRangeValue(newVal);
        },
        deep: true,
      },
    },
  });
</script>

<style lang="less"></style>
