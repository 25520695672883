import { Ref, unref, onUnmounted, onMounted } from 'vue';
import { useEventListener, useDebounceFn, useTimeoutFn } from '@vueuse/core';
import echarts from '@/expand/echarts';
import { EChartsOption } from 'echarts/types/dist/echarts';
export function useECharts(elRef: Ref<HTMLDivElement>, theme: 'light' | 'dark' | 'default' = 'light') {
  let chart: any = null;
  // 初始化echarts实例
  function initChart() {
    const el = unref(elRef);
    if (!el || !unref(el)) {
      return;
    }
    chart = echarts.init(unref(elRef), theme);
    useEventListener(window, 'resize', resize);
  }
  // echarts适应宽度
  const resize = useDebounceFn(() => {
    if (chart) {
      chart.resize();
    }
  }, 100);
  // 配置echarts
  function setOption(options: EChartsOption, clear = true) {
    if (unref(elRef)?.offsetHeight === 0) {
      useTimeoutFn(() => {
        setOption(options);
      }, 30);
      return;
    }
    clear && chart?.clear();
    chart?.setOption(options);
  }
  function getChart() {
    return chart;
  }
  onUnmounted(() => {
    if (chart) {
      chart.dispose();
      chart = null;
    }
  });
  onMounted(() => {
    initChart();
  });
  return {
    getChart,
    chart,
    resize,
    setOption,
  };
}
