import { MenuItem } from 'types/config';
const workbench: MenuItem = {
  path: 'workbench',
  title: '工作台',
  icon: '',
  is_root: true,
  hasTag: false,
  children: [
    {
      path: 'my',
      title: '我的工作台',
      icon: 'WE_WorkSurfaceOutlined',
      children: [
        { path: 'home', title: '首页' },
        { path: 'todo', title: '待办事项' },
        { path: 'done', title: '我的已办' },
      ],
    },
  ],
};
export default workbench;
