import request from '@/utils/request';
export function edit_credit(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_credit', params);
}
export function get_credit_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_credit_page', params);
}
export function can_change_credit_in_use(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/can_change_credit_in_use', params);
}
export function get_company_prepay_info(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_company_prepay_info', params);
}
export function upload_credit(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_credit', params);
}
export function edit_recharge(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_recharge', params);
}
export function get_recharge_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_recharge_page', params);
}
export function get_recharge_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_recharge_item', params);
}
/**
 * 新建申请
 * @param params
 */
export function create_credit_apply(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/create_credit_apply', params);
}
/**
 * 授信申请详情页
 * @param params
 */
export function get_credit_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_credit_item', params);
}
/**
 * 终止工作流
 * @param params
 */
export function cancel_credit_apply(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/cancel_credit_apply', params);
}
/**
 * 填写最终审批额度
 * @param params
 */
export function edit_final_approval_limit(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_final_approval_limit', params);
}
/**
 * ReOpen 工作流 (全局操作, For 存量记录转工作流)
 * @param params
 */
export function reopen_credit_flow(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/reopen_credit_flow', params);
}
/**
 * 能否 ReOpen 工作流
 * @param params
 */
export function can_open_credit_flow(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/can_open_credit_flow', params);
}
/**
 * 提交申请手动操作
 * @param params
 */
export function credit_apply_to_fbp_review_done(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/credit_apply_to_fbp_review_done', params);
}
/**
 * Prepay 刷新(自动生成Credit记录)
 * @param params
 */
export function refresh_prepay_credit() {
  return request.post<null, Recordable>('/api/v1/refresh_prepay_credit');
}
/**
 * 通知BD
 * @param params
 */
export function notify_bd_to_follow_contract(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/notify_bd_to_follow_contract', params);
}

export function get_can_do_recharge_company_list() {
  return request.post<null, Recordable>('/api/v1/get_can_do_recharge_company_list');
}

export function get_company_credit_limit(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_company_credit_limit', params);
}
