
  import { defineComponent } from 'vue';
  import type { PropType } from 'vue';
  import SiderMenuItem from './SiderMenuItem.vue';
  import { MenuItem } from 'types/config';
  import { useUser } from '@/hooks/useUser';
  import { getFlatTitlePathMenus } from '@/router/menu';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'SiderMenu',
    components: {
      SiderMenuItem,
    },
    props: {
      menus: Array as PropType<MenuItem[]>,
      collapsed: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      const { getFilteredHWRoutesAndMenus, flatMenuList, getMatchedMenuKeyByRoute, getAllParentMenuKeyByRoute } =
        useUser();
      const { sub_app_suffix_list } = useAppInfo();
      return {
        getFilteredHWRoutesAndMenus,
        sub_app_suffix_list,
        flatMenuList,
        getMatchedMenuKeyByRoute,
        getAllParentMenuKeyByRoute,
      };
    },
    data() {
      return {
        selectedKeys: [''],
        openKeys: [''],
      };
    },
    created() {
      this.initMenuKeys();
    },
    methods: {
      async handleMenuClick({ key }: { key: string }) {
        const route_path =
          this.getFilteredHWRoutesAndMenus.routes.find((item) => item.union_menu_key === key)?.route_path || '';
        const findTemp = getFlatTitlePathMenus(this.getFilteredHWRoutesAndMenus.menus).find(
          (item) => item.path === key
        ) as MenuItem;
        this.$dataFinder.pushEvent(findTemp.title, { url: route_path });
        const findItem = this.sub_app_suffix_list.find((item) => route_path.includes(item));
        if (!isEmptyData(findItem)) {
          history.pushState(
            {
              ...history.state,
              ...{
                back: this.$route.fullPath,
                current: route_path.replaceAll(findItem as string, ''),
              },
            },
            '',
            route_path
          );
          if (!(this.$route.fullPath.includes(findItem as string) && key.includes(findItem as string))) {
            location.reload();
          }
        } else {
          this.$router.push({ path: route_path });
        }
      },
      initMenuKeys() {
        const matched_menu_key = this.getMatchedMenuKeyByRoute();
        this.selectedKeys = [`/${matched_menu_key.join('/')}`];
        if (!this.collapsed) {
          this.openKeys = this.getAllParentMenuKeyByRoute();
          // const cloneMenu = cloneDeep(matched_menu_key)!;
          // const temp = cloneMenu.pop();
          // if (temp?.includes(':')) {
          //   //用于动态匹配
          //   cloneMenu.pop();
          // }
          // if (this.openKeys.some((item) => item.split('/')[1] === matched_menu_key[0])) {
          //   this.openKeys.push(`/${cloneMenu.join('/')}`);
          // } else {
          //   this.openKeys = [`/${cloneMenu.join('/')}`];
          // }
        }
      },
    },
    watch: {
      '$route.path': {
        handler() {
          this.initMenuKeys();
        },
      },
      collapsed: {
        handler() {
          this.initMenuKeys();
        },
      },
    },
  });
