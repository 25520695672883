import { get_agency_list, get_apply_demand_am_list, get_ml_account_apply_contract_list } from '@/api/common-cms';
import { get_company_list, get_key_val } from '@/api/meta';
import { get_product_list } from '@/api/more';
import { DialogFormItem } from '@/components/WeDialog/types';
import { useAppInfo } from '@/hooks/useAppInfo';
import { useOptionList } from '@/hooks/useOptionList';
import { commonStore } from '@/store/modules/commonLists';
import { isEmptyData } from '@/utils/common';
import { required } from '@/utils/validate';
import { sortBy } from 'lodash-es';
import { computed, ref } from 'vue';

export function useCMS() {
  const { appInfo } = useAppInfo();
  const product_items = ref<Recordable[]>([]);
  const agency_list = ref<Recordable[]>([]);
  const company_list = ref<Recordable[]>([]);
  const demand_am_list = ref<Recordable[]>([]);
  const demand_contract_items = ref<Recordable[]>([]);
  const supply_contract_items = ref<Recordable[]>([]);
  const account_company_name_items = ref<Recordable[]>([]);
  const status_list = ref([
    { id: 'active', name: 'Active' },
    { id: 'inactive', name: 'Inactive' },
  ]);
  const ad_type_list = ref<Recordable[]>([
    { id: 'ac', name: 'AC' },
    { id: 'nonac', name: 'NonAC' },
  ]);
  const { get_mcc_account_list } = useOptionList();
  const isRS = ref(1);
  const isMB = ref(2);
  const isPD = ref(3);
  const isUG = ref(4);
  const isPK = ref(5);
  const is_china = ref(2);
  const is_foreign = ref(1);
  const is_google = ref(2027);
  const gdt2023_industry_items = computed<Recordable[]>(() => {
    return appInfo.value.key_vals.gdt_industry2023.split('\t').map((m) => ({ id: m, name: m }));
  });
  const jl2023_industry_items = computed<Recordable[]>(() => {
    return appInfo.value.key_vals.oceanengine_industry2023.split('\t').map((m) => ({ id: m, name: m }));
  });
  const initConfig = () => {
    commonStore.getMLModel();
    get_product_list({ get_all: true, pick_fields: ['id', 'name', 'code'] }).then((res) => {
      product_items.value = res.product_list
        .map((item) => ({
          id: item.code!,
          name: `${item.code}-${item.name}`,
          product_id: item.id,
        }))
        .filter((item) => !isEmptyData(item.id));
    });
    get_agency_list().then((res) => {
      agency_list.value = res;
    });
    get_company_list({ get_all: true, pick_fields: ['id', 'name', 'short_name'] }).then((res) => {
      company_list.value = res.company_list;
    });
    get_apply_demand_am_list().then((res) => {
      demand_am_list.value = res.map((item) => ({ id: item.id, name: item.name }));
    });
    get_ml_account_apply_contract_list({ contract_type: 'demand' }).then((res) => {
      demand_contract_items.value = res;
    });
    get_ml_account_apply_contract_list({ contract_type: 'supply' }).then((res) => {
      supply_contract_items.value = res;
    });
    get_key_val({ key: 'ml_account_account_companys' }).then((res) => {
      account_company_name_items.value = res.val.split('\t').map((item) => ({ name: item, id: item }));
    });
  };
  initConfig();
  const getInputs = (type = 'table', vm) => {
    const map_inputs = {
      //国内
      [is_china.value]: {
        [isUG.value]: ['*账号ID', '*产品Code', '*Demand合同', '*优化师邮箱'], //UG
        [isPD.value]: ['*账号ID', '*开户代理', '*开户主体', '*登录账号', '*邮箱密码', '*密码'], //PD
        [isMB.value]: ['*客户名称', '*账号ID', '*开户代理', '*Supply合同'], //MB
        [isRS.value]: ['*账号ID', '*开户代理', '*Supply合同', '*Demand合同'], //PD
        [isPK.value]: ['*账号ID', '*开户代理', '*Supply合同', '*Demand合同'], //PD
      },
      //海外
      [is_foreign.value]: {
        [isRS.value]: [
          '*账号ID',
          vm.platform === 'FB' ? '*Token' : vm.platform === 'Adwords' ? '*MCC账号' : '',
          '*开户代理',
          '*Supply合同',
          '*Demand合同',
        ], //RS
        [isPD.value]: [
          '*账号ID',
          vm.platform === 'FB' ? '*Token' : vm.platform === 'Adwords' ? '*MCC账号' : '',
          '*开户代理',
          '*产品Code',
        ], //PD
        [isMB.value]: [
          '*账号ID',
          vm.platform === 'FB' ? '*Token' : vm.platform === 'Adwords' ? '*MCC账号' : '',
          '*开户代理',
          '*产品Code',
        ], //MB
      },
    };

    const inputs: DialogFormItem[] = [
      {
        type: 'text',
        result: 'account_id',
        label: '账号ID',
        width: 24,
        rules: [required('string', '请输入账号ID')],
      },
      {
        type: 'text',
        result: 'account_name',
        label: '账号名称',
        width: 24,
        rules: vm.platform === 'Kwai' ? [required('string', '请输入账号ID')] : [],
      },
      {
        type: 'select',
        result: 'account_company_name',
        label: '开户主体',
        width: 24,
        items: account_company_name_items,
        rules: [required('string', '请选择开户主体')],
      },
      {
        type: 'text',
        result: 'username',
        label: '登录账号',
        width: 24,
        rules: [required('string', '请输入登录账号')],
      },
      { type: 'text', result: 'token', label: 'Token', width: 24, rules: [required('string', '请输入Token')] },
      {
        type: 'select',
        result: ['import', 'table'].includes(type) ? 'mcc_account_name' : 'mcc_account_id',
        label: 'MCC账号',
        width: 24,
        rules: [required('string', '请选择MCC账号')],
        items: get_mcc_account_list.value,
      },
      {
        type: 'select',
        result: 'company_name',
        resultValueFiled: 'rd_company_name',
        label: '客户名称',
        width: 24,
        items: company_list.value,
        rules: [required('string', '请选择客户')],
        reverse_name: true,
      },
      {
        type: 'select',
        result: 'supply_contract_id',
        label: 'Supply合同',
        width: 24,
        items: supply_contract_items.value,
        rules: [required('string', '请选择Supply合同')],
      },
      {
        type: 'text',
        result: 'password',
        label: '密码',
        width: 24,
      },
      {
        type: 'text',
        result: 'email_password',
        label: '邮箱密码',
        width: 24,
      },
      {
        type: 'text',
        result: 'ua_email',
        resultValueFiled: 'ua__user_info__email',
        label: '优化师邮箱',
        width: 24,
      },
      {
        type: 'select',
        result: 'account_agency_name',
        resultValueFiled: 'rd_account_agency_name',
        label: '开户代理',
        width: 24,
        items: [isMB.value, isPD.value].includes(vm.detail_data.business_type)
          ? is_china.value === vm.detail_data.region_type
            ? agency_list.value.filter((item) => {
                const agency_contract_value = vm.detail_data.agency_contract_value;
                if (!isEmptyData(agency_contract_value)) {
                  const agency_list = JSON.parse(agency_contract_value).map((item) => item.agency_id);
                  return agency_list.includes(item.id);
                }
                return false;
              })
            : agency_list.value
          : agency_list.value,
        rules: [required('string', '请选择开户代理')],
        reverse_name: true,
      },
      {
        type: 'select',
        result: 'product_code',
        resultValueFiled: 'product__code',
        label: '产品Code',
        width: 24,
        items: product_items.value,
        rules: [required('string', '请选择产品Code')],
      },
      {
        type: 'select',
        result: 'demand_contract_id',
        label: 'Demand合同',
        width: 24,
        items: demand_contract_items.value,
        rules: [required('string', '请选择Demand合同')],
      },
    ];
    const region_type = vm.detail_data.region_type;
    const business_type = vm.detail_data.business_type;
    let temp_inputs: Recordable[] = [];
    if (region_type === is_foreign.value && business_type === isPD.value) {
      if (vm.platform === 'Kwai') {
        map_inputs[is_foreign.value][isPD.value].splice(1, 0, '*账号名称');
      } else {
        map_inputs[is_foreign.value][isPD.value].splice(1, 0, '账号名称');
      }
    }
    if (region_type !== 0) {
      temp_inputs = inputs.filter((item) => {
        if (Reflect.has(map_inputs[region_type], business_type)) {
          return map_inputs[region_type][business_type].find((ftem) => ftem.includes(item.label!));
        }
      });
      temp_inputs = sortBy(temp_inputs, (value) => {
        return map_inputs[region_type][business_type].findIndex((item) => item.includes(value.label));
      });
      if (type === 'import') {
        temp_inputs.forEach((item) => {
          item.label = `*${item.label}`;
        });
      }
    }
    if (['import', 'table'].includes(type) && region_type === is_china.value && vm.platform === 'OceanEngine') {
      temp_inputs.splice(2, 0, {
        type: 'text',
        result: 'version_name',
        label: type === 'import' ? '*投放版本（V1/V2）' : '投放版本（V1/V2）',
        width: 24,
      });
    }
    if (['import', 'table', 'edit'].includes(type) && ['OceanEngine'].includes(vm.platform)) {
      temp_inputs.splice(3, 0, {
        type: 'select',
        result: 'industry',
        label: type === 'import' ? '*行业' : '行业',
        width: 24,
        rules: [required('string', '请选择行业')],
        items: jl2023_industry_items,
      });
    }
    if (['import', 'table', 'edit'].includes(type) && ['GDT'].includes(vm.platform)) {
      temp_inputs.splice(2, 0, {
        type: 'select',
        result: 'industry',
        label: type === 'import' ? '*行业' : '行业',
        width: 24,
        rules: [required('string', '请选择行业')],
        items: gdt2023_industry_items,
      });
    }

    if (['import', 'table', 'edit'].includes(type) && vm.detail_data.is_foreign_account) {
      if (['Adwords', 'FB'].includes(vm.platform)) {
        temp_inputs.splice(2, 0, {
          type: 'select',
          result: 'account_region',
          label: type === 'import' ? '*账户性质（尼日利亚/阿根廷/美国/印度）' : '账户性质',
          items: [
            { id: '阿根廷', name: '阿根廷' },
            { id: '尼日利亚', name: '尼日利亚' },
            { id: '美国', name: '美国' },
            { id: '印度', name: '印度' },
          ],
          rules: [required()],
          width: 24,
        });
      } else {
        temp_inputs.splice(2, 0, {
          type: 'select',
          result: 'account_region',
          label: type === 'import' ? '*账户性质（尼日利亚/阿根廷/美国/印度）' : '账户性质',
          items: [
            { id: '阿根廷', name: '阿根廷' },
            { id: '尼日利亚', name: '尼日利亚' },
            { id: '美国', name: '美国' },
            { id: '印度', name: '印度' },
          ],
          rules: [required()],
          width: 24,
        });
      }
    }

    if (vm.platform === 'Wechat') {
      temp_inputs.push({
        type: 'text',
        result: 'service_account_id',
        label: type === 'import' ? '*服务商账号' : '服务商账号',
        width: 24,
      });
    }
    if (vm.platform === 'Adwords') {
      temp_inputs.push({
        type: 'select',
        result: 'ad_type',
        label: type === 'import' ? '*广告类型' : '广告类型',
        width: 24,
        items: ad_type_list.value,
        rules: [required('string', '请选择广告类型')],
      });
    }
    if (type === 'table') {
      if (vm.detail_data.region_type === is_foreign.value && [isMB.value].includes(vm.detail_data.business_type)) {
        temp_inputs.push({
          type: 'select',
          result: 'supply_contract_id',
          label: 'Supply合同',
          width: 24,
          items: supply_contract_items.value,
          rules: [required('string', '请选择Supply合同')],
        });
        temp_inputs.push({
          type: 'select',
          result: 'demand_contract_id',
          label: 'Demand合同',
          width: 24,
          items: demand_contract_items.value,
          rules: [required('string', '请选择Demand合同')],
        });
      }
      if (vm.detail_data.region_type === is_foreign.value && [isPD.value].includes(vm.detail_data.business_type)) {
        temp_inputs.push({
          type: 'select',
          result: 'supply_contract_id',
          label: 'Supply合同',
          width: 24,
          items: supply_contract_items.value,
          rules: [required('string', '请选择Supply合同')],
        });
      }
      if (vm.detail_data.region_type === is_foreign.value && vm.detail_data.platform === 'FB') {
        temp_inputs.push({
          type: 'select',
          result: 'status',
          label: '状态',
          width: 24,
          items: status_list.value,
          init: vm.detail_data.business_type === isRS.value ? 'active' : 'active',
        });
      }
      temp_inputs.push({ type: 'text', result: 'rd_creator_name', label: '创建人', width: 24, disabled: true });
      temp_inputs.push({ type: 'text', result: 'create_time', label: '创建时间', width: 24, disabled: true });
    } else {
      if (
        vm.detail_data.region_type === is_foreign.value &&
        vm.detail_data.platform === 'FB' &&
        ['add', 'edit'].includes(type)
      ) {
        temp_inputs.push({
          type: 'select',
          result: 'status',
          label: '*状态',
          width: 24,
          items: status_list.value,
          init: vm.detail_data.business_type === isRS.value ? 'active' : 'active',
        });
      }
    }
    return temp_inputs;
  };
  return {
    product_items,
    company_list,
    agency_list,
    demand_am_list,
    getInputs,
    isRS,
    isMB,
    isPD,
    isUG,
    isPK,
    is_china,
    is_foreign,
    ad_type_list,
    status_list,
    is_google,
    account_company_name_items,
    supply_contract_items,
    demand_contract_items,
    gdt2023_industry_items,
    jl2023_industry_items,
  };
}
