import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_layout_header = _resolveComponent("app-layout-header")!
  const _component_WaterMark = _resolveComponent("WaterMark")!
  const _component_app_layout_sider = _resolveComponent("app-layout-sider")!
  const _component_app_layout_content = _resolveComponent("app-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!

  return (_openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.zhCN,
    autoInsertSpaceInButton: false
  }, {
    default: _withCtx(() => [
      (_ctx.showApp)
        ? (_openBlock(), _createBlock(_component_a_layout, {
            key: 0,
            class: "app_wrapper"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_layout_header),
              _createVNode(_component_a_layout, null, {
                default: _withCtx(() => [
                  _createVNode(_component_WaterMark),
                  _createVNode(_component_app_layout_sider),
                  _createVNode(_component_app_layout_content)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["locale"]))
}