
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { BasicColumn, BasicTableProps, useTable } from '@/components/WeTable';
  import { computed, defineComponent, ref } from 'vue';
  import { edit_cloud_usd_to_cny } from '@/api/cloud-gsuite';
  import { getValueById, isEmptyData } from '@/utils/common';
  import { appStore } from '@/store/modules/app';
  import dayjs from 'dayjs';
  import { useUser } from '@/hooks/useUser';
  import { required } from '@/utils/validate';
  import { write_cloud_billing_result } from '@/api/cloud-gcp';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import {
    edit_aws_configs,
    get_aws_billing_page,
    get_aws_config_page,
    get_aws_billing_aggregation,
    get_aws_billing_spp_aggregation,
    delete_we_cloud_config,
    get_aws_payer_account_config_page,
    edit_aws_payer_account_config,
  } from '@/api/cloud-aws';
  import { useAwsBill } from './useAwsBIll';
  import { formatNumber } from '@/utils/number';
  import { useApp } from '@/hooks/useApp';

  export default defineComponent({
    name: 'CloudAwsConfig',
    props: {
      isInserted: {
        type: Boolean,
        default: false,
      },
      contract_id: {
        type: Number,
        default: -1,
      },
    },
    setup(props) {
      const { user } = useUser();
      const { getModelItems, model } = useAppInfo();
      const type = ref('date');
      const month = ref('');
      month.value = dayjs().subtract(1, 'months').format('YYYY-MM');
      const { getTfItems } = useAppInfo();
      const { $showDialog, $message, $showOperLog } = useApp();
      const tab = ref('AWS对账配置');
      const account_dialog_title = ref('');
      const spp_dialog_title = ref('');
      const account_dialog_visible = ref(false);
      const spp_dialog_visible = ref(false);
      const spp_table_data = ref<Recordable[]>([]);
      const is_aws_config = computed(() => {
        return tab.value === 'AWS对账配置';
      });
      const is_aws_detail = computed(() => {
        return tab.value === 'AWS对账明细';
      });
      const is_payer_config = computed(() => {
        return tab.value === 'payer账号配置';
      });
      const region_type_items = computed(() => {
        return getModelItems(model.value.Product.REGION_TYPE);
      });
      const aggregation = ref<Recordable[]>([]);
      const company__name = ref('');
      const account_id = ref('');
      const columns = computed<BasicColumn[]>(() => {
        if (is_aws_config.value) {
          const list: BasicColumn[] = [
            {
              title: '合同ID',
              dataIndex: 'contract_id',
              showTop: true,
            },
            {
              title: '客户名称',
              dataIndex: 'contract__company__name',
              showTop: true,
            },
            {
              title: '账单账户ID',
              dataIndex: 'billing_account_id',
              showTop: true,
            },
            {
              title: '折扣',
              dataIndex: 'discount',
            },
            {
              title: '备注',
              dataIndex: 'comment',
            },
            {
              title: '月份',
              dataIndex: 'month',
              componentType: 'a-month-picker',
              componentProps: {
                valueFormat: 'YYYY-MM',
              },
              init: props.isInserted ? dayjs().subtract(1, 'months').format('YYYY-MM') : undefined,
              showTop: true,
              format: (text) => {
                return dayjs(text).format('YYYY-MM');
              },
            },
          ];
          if (props.isInserted) {
            list.push({ title: '操作', dataIndex: '', flag: 'ACTION', slots: { customRender: 'g-action' } });
            return list.filter((item) => item.dataIndex !== 'contract__company__name');
          }
          return list;
        } else if (is_payer_config.value) {
          return [
            {
              title: '地区',
              dataIndex: 'region_type',
              showTop: true,
              format: (text) => getValueById(region_type_items.value, text),
              componentType: 'we-select',
              componentProps: {
                options: region_type_items.value,
              },
            },
            {
              title: '秘钥ID',
              dataIndex: 'access_key_id',
              showTop: true,
            },
            {
              title: 'secret',
              dataIndex: 'access_key',
            },
            {
              title: 's3存储桶',
              dataIndex: 'bucket',
            },
            {
              title: 'billing前缀',
              dataIndex: 'report_path_prefix',
            },
            {
              title: 'payer账号',
              dataIndex: 'payer_account_id',
            },
            {
              title: '操作',
              dataIndex: 'action',
              flag: 'ACTION',
              slots: { customRender: 'payer_action' },
            },
          ];
        } else {
          return [
            {
              title: '合同ID',
              dataIndex: 'contract_id',
              showTop: true,
            },
            {
              title: '客户名称',
              dataIndex: 'company__name',
              showTop: true,
            },
            {
              title: 'Payer ID',
              dataIndex: 'payer_account_id',
              componentType: 'a-select',
              showTop: true,
            },
            {
              title: '账单账户ID',
              dataIndex: 'account_id',
              slots: { customRender: 'aws_bill_account_id' },
              showTop: true,
            },
            {
              title: '折扣',
              dataIndex: 'billing_config__discount',
            },
            {
              title: 'SPP折扣',
              dataIndex: 'spp_discount',
              ignoreField: true,
              hiddenFilter: true,
              componentType: 'we-number-range',
              slots: { customRender: 'spp_discount' },
            },
            {
              title: '成本',
              dataIndex: 'cost',
              componentType: 'we-number-range',
              format: (text) => {
                if (text) {
                  let res = text.toFixed(3);
                  return res.substring(0, res.lastIndexOf('.') + 3);
                } else {
                  return '-';
                }
              },
            },
            {
              title: '收入',
              dataIndex: 'revenue',
              componentType: 'we-number-range',
              format: (text) => {
                if (text) {
                  let res = text.toFixed(3);
                  return res.substring(0, res.lastIndexOf('.') + 3);
                } else {
                  return '-';
                }
              },
            },
            {
              title: 'Credit',
              dataIndex: 'credit_cost',
              componentType: 'we-number-range',
              hiddenFilter: true,
              format: (text) => {
                if (text) {
                  let res = text.toFixed(3);
                  return res.substring(0, res.lastIndexOf('.') + 3);
                } else {
                  return '-';
                }
              },
            },
            {
              title: '币种',
              dataIndex: 'currency',
              componentType: 'a-select',
            },
            {
              title: '日期',
              dataIndex: 'date',
              componentType: 'a-range-picker',
              componentProps: {
                valueFormat: 'YYYY-MM-DD',
              },
            },
          ];
        }
      });
      const get_drop_down_menus = computed(() => {
        return [
          { title: 'AWS自动对账', click: () => doAwsBilling() },
          { title: '对账结果写入账单', click: () => writeAwsBilling() },
        ];
      });
      const table_config = computed<Recordable>(() => {
        const config: Recordable = {
          columns,
          title: 'AWS对账',
          api: (params) => {
            params.fields.push('id');
            if (is_aws_config.value) {
              if (props.isInserted) {
                params.page_params.filters.push({
                  field: 'contract_id',
                  content: `${props.contract_id}`,
                  type: 'text',
                });
              }
              return get_aws_config_page(params);
            } else if (is_payer_config.value) {
              return get_aws_payer_account_config_page(params);
            } else {
              params.month = month.value;
              params.type = type.value;
              return get_aws_billing_page(params);
            }
          },
          moreFunc: get_drop_down_menus.value,
        };
        if (!props.isInserted) {
          config.tabConfig = {
            tabItems: [
              { key: 'AWS对账配置', tab: 'AWS对账配置' },
              { key: 'AWS对账明细', tab: 'AWS对账明细' },
              { key: 'payer账号配置', tab: 'payer账号配置' },
            ],
          };
        }
        return config;
      });
      const [register, { reload }] = useTable(table_config.value);
      const { export_html_file, get_header_cost, get_all_header_cost } = useAwsBill();
      const change_type = () => {
        type.value = type.value === 'month' ? 'date' : 'month';
        reload();
      };
      const getAwsDetailByAccountId = (record) => {
        company__name.value = record.company__name;
        account_id.value = record.account_id;
        get_aws_billing_aggregation({ account_id: record.account_id, month: month.value }).then((res) => {
          aggregation.value = res;
          account_dialog_visible.value = true;
        });
      };
      const export_html_page = () => {
        account_dialog_visible.value = false;
        export_html_file(aggregation.value, company__name.value, account_id.value, month.value);
      };
      const writeAwsBilling = () => {
        const inputs = [
          { result: 'month', type: 'month', label: '选择月份', rules: [required()] },
          {
            result: 'to_next_state',
            type: 'radio',
            label: 'To Next State',
            rules: [required()],
            items: getTfItems,
            init: false,
          },
          {
            result: 'buline_name',
            type: 'select',
            label: '业务',
            rules: [required()],
            items: [{ id: 'AWS', name: 'AWS' }],
            init: 'AWS',
            width: 24,
          },
        ];
        $showDialog({
          title: '将对账结果写入账单',
          width: '500px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            write_cloud_billing_result(result).then((res) => {
              $showOperLog({
                operation_group: res.operation_group,
                afterClose: () => {
                  appStore.SET_WE_DIALOG(null);
                },
              });
            });
          },
        });
      };
      const doAwsBilling = () => {
        const inputs = [
          {
            result: 'month',
            type: 'month',
            label: '选择月份',
            rules: [required('string', '请选择月份')],
            width: 12,
          },
        ];

        $showDialog({
          title: 'Do AWS Billing',
          width: '580px',
          labelCol: '100px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            result.buline_name = 'AWS';
            edit_cloud_usd_to_cny(result).then(() => {
              $message.success('Done');
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      };
      return {
        tab,
        user,
        register,
        reload,
        columns,
        getTfItems,
        is_aws_config,
        account_dialog_title,
        spp_dialog_title,
        account_dialog_visible,
        spp_dialog_visible,
        spp_table_data,
        export_html_file,
        get_all_header_cost,
        change_type,
        month,
        getAwsDetailByAccountId,
        aggregation,
        get_header_cost,
        export_html_page,
        type,
        account_id,
        is_payer_config,
        region_type_items,
        is_aws_detail,
      };
    },
    computed: {
      spp_table_config(): BasicTableProps {
        return {
          columns: [
            {
              title: '云服务名称',
              dataIndex: 'service',
              componentType: 'a-input',
            },
            {
              title: '金额',
              dataIndex: 'spp_discount',
              componentType: 'we-number-range',
              format: (text) => {
                return `$${formatNumber(text, '0,0.00')}`;
              },
            },
          ],
          useSearchForm: false,
          immediate: false,
        };
      },
    },
    methods: {
      edit_aws_payer_account_item(record = {}) {
        const is_add = isEmptyData(record);
        const inputs: DialogFormItem[] = [
          {
            label: '地区',
            result: 'region_type',
            type: 'select',
            width: 24,
            items: this.region_type_items,
          },
          {
            label: '秘钥ID',
            result: 'access_key_id',
            type: 'text',
            width: 24,
          },
          {
            label: 'secret',
            result: 'access_key',
            type: 'text',
            width: 24,
          },
          {
            label: 's3存储桶',
            result: 'bucket',
            type: 'text',
            width: 24,
          },
          {
            label: 'billing前缀',
            result: 'report_path_prefix',
            type: 'text',
            width: 24,
          },
          {
            label: 'payer账号',
            result: 'payer_account_id',
            type: 'text',
            width: 24,
          },
        ];
        const dialog = {
          title: is_add ? '添加payer' : '编辑payer',
          blocks: [{ type: 'input', value: inputs }],
          init: record,
          width: '600px',
          labelCol: '100px',
          callback: (result) => {
            edit_aws_payer_account_config({
              ...record,
              ...result,
            }).then(() => {
              this.$message.success('操作成功');
              appStore.SET_WE_DIALOG(null);
              this.reload();
            });
          },
        };
        this.$showDialog(dialog);
      },
      formatNumber,
      editData(record) {
        let isEdit = !isEmptyData(record);
        const inputs: DialogFormItem[] = this.columns
          .map((column) => {
            let type = 'text';
            if (['month'].includes(column.dataIndex!)) {
              type = 'month';
            }
            let placeholder = '';
            let disabled = true;
            if (['discount'].includes(column.dataIndex!)) {
              disabled = false;
            }
            return {
              label: column.title,
              result: column.dataIndex,
              type,
              width: 24,
              placeholder,
              disabled: isEdit ? disabled : false,
              rules: column.dataIndex == 'comment' ? [] : [required()],
            };
          })
          .filter((item) => item.result !== '' && item.result !== 'contract_id') as DialogFormItem[];
        const title = isEdit ? '编辑' : '添加';

        const dialogConfig: DialogConfig = {
          title,
          width: '600px',
          labelCol: '100px',
          blocks: [{ type: 'input', value: inputs }],
          ...(isEdit ? { init: record } : {}),
          callback: (result) => {
            const configs = [{ ...result, contract_id: this.contract_id }];
            edit_aws_configs({ configs })
              .then(() => {
                appStore.SET_WE_DIALOG(null);
                this.$message.success(`${title}成功`);
                this.reload();
              })
              .catch(() => {
                appStore.SET_WE_DIALOG(null);
                this.$message.success(`${title}失败`);
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      deleteData(record) {
        this.$showAlert('Warning', '确定删除吗', () => {
          appStore.SET_ALERT_CONFIG(null);
          delete_we_cloud_config({ id: record.id })
            .then((res) => {
              if (res.error_message) {
                this.$message.error(res.error_message);
              } else {
                this.$message.success('删除成功');
              }
            })
            .catch(() => {
              this.$message.success('删除失败');
            })
            .finally(() => {
              this.reload();
            });
        });
      },
      get_spp_table_data(record) {
        get_aws_billing_spp_aggregation({ month: this.month, account_id: record.account_id }).then((res) => {
          this.spp_table_data = res;
          this.spp_dialog_visible = true;
        });
      },
    },
    watch: {
      tab() {
        this.reload();
      },
    },
  });
