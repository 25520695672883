import request from '@/utils/request';
/**
 * 获取OSS配置信息
 */
export function getOSSInfo() {
  return request({
    url: '/api/v1/get_pub_oss_sts',
    method: 'post',
  });
}
/**
 * 文件上传成功后，设置文件的读写权限
 * @param {}} data
 */
export function setOSSFileToRead(data: any) {
  return request({
    url: '/api/v1/set_pub_oss_file_public',
    method: 'post',
    data,
  });
}
