
  import { computed, defineComponent } from 'vue';
  import { BasicColumn } from '@/components/WeTable';
  import { printInvoiceData } from '@/utils/const';
  import dayjs from 'dayjs';
  import round from 'lodash-es/round';
  import { isEmptyData } from '@/utils/common';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { cacheStore } from '@/store/modules/cache';
  import { getColSpanItem } from '@/utils/common';
  export default defineComponent({
    name: 'print-invoice',
    props: {
      invoice: {
        type: [Object] as PropType<Recordable>,
        required: true,
        default: () => ({} as Recordable),
      },
      desc: {
        type: [String],
        required: true,
        default: '',
      },
      is_gts: {
        type: [Number],
        required: true,
        default: 2,
      },
      showBuline: {
        type: [Boolean],
        required: false,
        default: false,
      },
      company_name: {
        type: [String],
        required: true,
        default: '',
      },
      neutralize_reason: {
        type: [String],
        required: true,
        default: '',
      },
    },
    setup(props) {
      const isGts = props.is_gts === 1;
      const isNeutralize = props.neutralize_reason !== '';
      let spaceSymbol = '  ';
      if (isNeutralize) {
        spaceSymbol = spaceSymbol + '-';
      }
      const headP = computed(() => {
        const p = [props.invoice.we_company_name, props.invoice.we_address];
        if (isGts) {
          p.push(printInvoiceData.gtsEmail);
          p.push(printInvoiceData.regNo);
        }
        return p;
      });
      const invoiceHead = computed(() => {
        return isNeutralize
          ? 'Credit Note'
          : isGts
          ? 'Tax Invoice'
          : props.invoice.is_prepay
          ? 'Bill Confirmation'
          : 'Invoice';
      });

      const invoiceLeftInfo = [props.company_name || props.invoice.company_name, props.invoice.company.invoice_address];
      const invoiceRightInfo = computed(() => {
        const info: any[] = [];
        let invoice_number = props.invoice.invoice_number;
        let invoice_date = props.invoice.date;
        if (isNeutralize) {
          invoice_date = new Date();
          if (invoice_number) {
            invoice_number =
              invoice_number.slice(0, -2) + (Number(invoice_number.slice(-2)) + 1).toString().padStart(2, '0');
          }
        }
        if (!props.invoice.is_prepay) {
          info.push('Invoice No.:' + invoice_number);
        }
        info.push('Invoice Date:' + dayjs(invoice_date).format('YYYY-MM-DD'));
        if (isGts) {
          info.push('GST Reg No:' + printInvoiceData.regNo);
        }
        return info;
      });
      const columns = computed<BasicColumn[]>(() => {
        const desc: any[] = [];
        const amount: any[] = [];
        if (isGts || props.showBuline) {
          desc.push({
            title: 'Description',
            colSpan: 2,
            align: 'center',
          });
          desc.push({
            title: 'Description1',
            colSpan: 0,
            align: 'center',
          });
        } else {
          desc.push({
            title: 'Description',
            colSpan: 1,
            align: 'center',
          });
        }
        if (isGts) {
          amount.push({
            title: 'Amount',
            colSpan: 2,
            align: 'center',
          });
          amount.push({
            title: 'realAmount',
            colSpan: 0,
            align: 'center',
          });
        } else {
          amount.push({
            title: 'Amount',
            colSpan: 1,
            align: 'center',
          });
        }
        return [
          {
            title: 'No.',
            colSpan: 1,
            align: 'center',
          },
          ...desc,
          ...amount,
        ];
      });

      const getCurrencyTr = (amount, currency_id, month = null, getTr = true) => {
        const currency = cacheStore.appInfo?.currency_map[currency_id].name;
        let currencyList = cacheStore.appInfo?.currency_map[currency_id].currency_rate_list;
        if (month) {
          currencyList = currencyList.filter((rate) => rate.month === month);
        }
        if (currencyList.length > 0) {
          amount = round(currencyList[0].dollar_rate * amount, 2);
        }
        if (getTr) {
          const currencyTr = currency + spaceSymbol + amount.toLocaleString();
          return currencyTr;
        } else {
          return [currency, amount];
        }
      };

      const makeDataList = (dataList, num, bill) => {
        const { model } = useAppInfo();
        let buline = '';
        let period = '';
        const date_list = new Date(bill.month).toDateString().split(' ');
        const month = bill.month.split('-')[1];
        const currency = cacheStore.appInfo?.currency_map[bill.currency_id].name;
        const amount = currency + spaceSymbol + bill.this_linked_amount.toLocaleString();

        if (['GCP', 'GcpSupport', 'AWS', 'GSuite'].includes(bill.rd_buline_name)) {
          buline = bill.rd_buline_name;
        } else if (bill.rd_buline_name === 'FbSA') {
          buline = 'Facebook';
        } else if (['GoogleCEP', 'GoogleSA'].includes(bill.rd_buline_name)) {
          buline = 'Google';
        } else if (bill.rd_buline_name === 'TiktokCEP') {
          buline = 'Tiktok';
        } else if (bill.rd_buline_name === 'TwitterSA') {
          buline = 'Twitter';
        } else if (!isEmptyData(bill.rd_buline_name)) {
          buline = bill.rd_buline_name.toLowerCase();
          buline = buline.charAt(0).toUpperCase() + buline.slice(1);
        } else {
          buline = '';
        }

        if (bill.is_rebate) {
          if (
            [
              model.value.Contract.LOGIC_TYPE.CLOUD,
              model.value.Contract.LOGIC_TYPE.EE,
              model.value.Contract.LOGIC_TYPE.FEISHU,
            ].includes(bill.logic_type)
          ) {
            period = 'PSF Credit';
          } else if (bill.time_span_id) {
            period = bill.rd_time_span_name;
          } else {
            const quarter = Math.floor(month / 3 <= 1 ? 4 : month % 3 === 0 ? month / 3 - 1 : month / 3);
            const date = 'Q' + quarter + ' ' + (quarter < 4 ? date_list[3] : parseInt(date_list[3]) - 1);
            period = 'Rebate of ' + date;
          }
        } else {
          const date = date_list[1] + ' ' + date_list[3];
          period = 'Service fee of ' + date;
        }
        if (!props.showBuline) {
          dataList.push([{ value: num }, { value: period }, { value: amount }]);
        } else {
          dataList.push([{ value: num }, { value: period }, { value: buline }, { value: amount }]);
        }
      };
      const columnsData = computed(() => {
        const dataList: any[] = [];
        let num = 0;
        if (isGts) {
          let totalAmount = 0;
          props.invoice.linked_bill_list.forEach((bill) => {
            const link_amount = (bill.this_linked_amount / props.invoice.it_amount) * props.invoice.et_amount;
            if (link_amount) {
              totalAmount += getCurrencyTr(link_amount, 22, bill.month, false)[1];
            }
          });

          const dueAmount = round((totalAmount / props.invoice.et_amount) * props.invoice.it_amount, 2);
          const taxAmount = round(
            (dueAmount / props.invoice.it_amount) * (props.invoice.it_amount - props.invoice.et_amount),
            2
          );
          props.invoice.linked_bill_list.forEach((bill) => {
            const link_amount = (bill.this_linked_amount / props.invoice.it_amount) * props.invoice.et_amount;
            if (link_amount) {
              num += 1;
              dataList.push([
                { value: num },
                { value: props.desc },
                { value: bill.month },
                {
                  value: getCurrencyTr(link_amount, 22, bill.month),
                },
                {
                  value: getCurrencyTr(link_amount, bill.currency_id, bill.month),
                },
              ]);
            }
          });
          dataList.push([
            { colSpan: 3, value: 'Net amount before GST' },
            { colSpan: 0, value: '' },
            { colSpan: 0, value: '' },
            { colSpan: 1, value: `SGD  ${round(totalAmount, 2).toLocaleString()}` },
            {
              colSpan: 1,
              value: getCurrencyTr(props.invoice.et_amount, props.invoice.currency_id),
            },
          ]);
          dataList.push([
            { colSpan: 3, value: `GST@${props.invoice.tax_rate}%` },
            { colSpan: 0, value: '' },
            { colSpan: 0, value: '' },
            { colSpan: 1, value: `SGD  ${taxAmount.toLocaleString()}` },
            {
              colSpan: 1,
              value: getCurrencyTr(props.invoice.it_amount - props.invoice.et_amount, props.invoice.currency_id),
            },
          ]);
          dataList.push([
            { colSpan: 3, value: 'Total amount including GST' },
            { colSpan: 0, value: '' },
            { colSpan: 0, value: '' },
            { colSpan: 1, value: `SGD  ${dueAmount.toLocaleString()}` },
            {
              colSpan: 1,
              value: getCurrencyTr(props.invoice.it_amount, props.invoice.currency_id),
            },
          ]);
        } else if (props.invoice.is_prepay) {
          num += 1;
          const period = 'Prepayment for service fee';
          const currency = cacheStore.appInfo?.currency_map[props.invoice.currency_id].name;
          const amount = currency + spaceSymbol + props.invoice.it_amount.toLocaleString();
          dataList.push([{ value: num }, { value: period }, { value: amount }]);
          dataList.push([{ value: '' }, { value: 'tax' }, { value: '-' }]);
          dataList.push([{ colSpan: 2, value: 'Subtotal' }, { colSpan: 0, value: '' }, { value: amount }]);
        } else {
          const currency = cacheStore.appInfo?.currency_map[props.invoice.currency_id].name;
          const amount = currency + spaceSymbol + props.invoice.it_amount.toLocaleString();
          props.invoice.linked_bill_list.forEach((bill) => {
            if (bill.is_parent && bill.sub_bill_list.length > 0 && !isEmptyData(bill.team_id)) {
              bill.sub_bill_list.forEach((subBill) => {
                num += 1;
                subBill.this_linked_amount = round(
                  (subBill.finance_invoice_money / bill.finance_invoice_money) * bill.this_linked_amount,
                  2
                );
                makeDataList(dataList, num, subBill);
              });
            } else {
              num += 1;
              makeDataList(dataList, num, bill);
            }
          });
          if (dataList[0].length < 4) {
            dataList.push([{ value: '' }, { value: 'tax' }, { value: '-' }]);
            dataList.push([{ colSpan: 2, value: 'Subtotal' }, { colSpan: 0, value: '' }, { value: amount }]);
          } else {
            dataList.push([{ value: '' }, { colSpan: 2, value: 'tax' }, { colSpan: 0, value: '' }, { value: '-' }]);
            dataList.push([
              { colSpan: 3, value: 'Subtotal' },
              { colSpan: 0, value: '' },
              { colSpan: 0, value: '' },
              { value: amount },
            ]);
          }
        }
        return dataList;
      });
      const data = getColSpanItem({ columns: columns.value, columnsData: columnsData.value });
      const beneficiaryData = computed(() => {
        const bdColumns = [
          {
            title: 'Beneficiary',
            colSpan: 2,
          },
          {
            title: 'Beneficiary Detail',
            colSpan: 0,
          },
        ];
        const bdColumnsData = [
          [{ value: 'Beneficiary Name:' }, { value: props.invoice.we_company_name }],
          [{ value: 'Beneficiary Address:' }, { value: props.invoice.we_address }],
        ];
        return getColSpanItem({ columns: bdColumns, columnsData: bdColumnsData });
      });
      const wireData = computed(() => {
        const wireColumns = [
          {
            title: 'Wire Transfer',
            colSpan: 2,
          },
          {
            title: 'Wire Detail',
            colSpan: 0,
          },
        ];
        const wireColumnsData = [
          [{ value: 'ACCOUNT NUMBER:', colSpan: 1 }, { value: props.invoice.we_bank.bf_account_number }],
          [{ value: 'Beneficiary Bank:' }, { value: props.invoice.we_bank.bf_bank }],
          [{ value: 'Beneficiary Bank Address:' }, { value: props.invoice.we_bank.bf_bank_address }],
          [{ value: 'BANK CODE:' }, { value: props.invoice.we_bank.bf_bank_code }],
          [{ value: 'BRANCH CODE:' }, { value: props.invoice.we_bank.bf_branch_code }],
          [{ value: 'SWIFT CODE:' }, { value: props.invoice.we_bank.bf_swift_code }],
        ];
        if (!isNeutralize) {
          wireColumnsData.push([{ value: '※Paypal Account:' }, { value: '' }]);
          wireColumnsData.push([
            { value: 'Please kindly remark the 【Invoice No.】 in remittance advice.', colSpan: 2 },
            { value: '', colSpan: 0 },
          ]);
        }
        return getColSpanItem({ columns: wireColumns, columnsData: wireColumnsData });
      });
      const creditNote = computed(() => {
        const columns = [
          {
            title: 'Note',
            colSpan: 2,
          },
          {
            title: 'Detail',
            colSpan: 0,
          },
        ];
        const columnsData = [
          [
            { value: 'Original Tax Invoice Date:', colSpan: 1 },
            { value: dayjs(props.invoice.date).format('YYYY-MM-DD'), colSpan: 1 },
          ],
          [{ value: 'Original Tax Invoice No:' }, { value: props.invoice.invoice_number }],
          [{ value: 'Reason For The Credit:' }, { value: props.neutralize_reason }],
        ];
        if (isNeutralize) {
          columnsData.push([
            { value: 'Please kindly remark the 【Invoice No.】 in remittance advice.', colSpan: 2 },
            { value: '', colSpan: 0 },
          ]);
        }
        return getColSpanItem({ columns: columns, columnsData: columnsData });
      });
      const sealSrc = 'data:image/png;base64,' + props.invoice.sealBase64Data;
      return {
        headP,
        invoiceHead,
        invoiceLeftInfo,
        invoiceRightInfo,
        beneficiaryData,
        data,
        wireData,
        creditNote,
        sealSrc,
        isGts,
        isNeutralize,
      };
    },
    methods: {},
  });
