
  import { UploadOSS } from '@/utils/oss';
  import { setOSSFileToRead } from '@/api/oss';
  import { computed, defineComponent, watch } from 'vue';
  import { DOWNLOAD_URL } from '@/utils/const';
  import isArray from 'lodash-es/isArray';
  import { copyObjectToReactive, mediaType } from '@/utils/common';
  import filePreview from './FilePreview.vue';
  import { Form } from 'ant-design-vue';

  interface ICustomRequest {
    action: string;
    data: Recordable;
    file: File;
    filename: string;
    headers: Recordable;
    onError: Fn;
    onProgress: Fn;
    onSuccess: Fn;
  }
  export default defineComponent({
    name: 'WeOssUpload',
    components: {
      filePreview,
    },
    props: {
      value: {
        type: [Object, Array] as PropType<any>,
        default: () => {
          return [{ filename: '', url: '' }];
        },
      },
      editMode: {
        type: Boolean,
        default: true,
      },
      useOss: {
        type: Boolean,
        default: true,
      },
      accept: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      showUploadList: {
        type: Boolean,
        default: false,
      },
      popType: {
        type: String,
        default: 'img',
      },
    },
    emits: ['update:value', 'change', 'input'],
    setup(props, { emit }) {
      const formItemContext = Form.useInjectFormItemContext();
      const fileList = computed<{ filename: string; url: string }[] | { filename: string; url: string }>({
        get() {
          return props.value;
        },
        set(val) {
          emit('update:value', val);
          emit('change', val);
          emit('input', val);
          formItemContext.onFieldChange();
        },
      });
      watch(fileList, (newVal) => {
        emit('update:value', newVal);
      });
      const isEmptyData = computed(() => {
        if (fileList.value instanceof Array) {
          for (let i = 0, length = (fileList.value as any[]).length; i < length; i++) {
            if (fileList.value[i].filename && fileList.value[i].url) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      });
      return {
        fileList,
        isEmptyData,
        formItemContext,
      };
    },
    data() {
      return {
        drawerVisible: false,
        url: '',
        drawerType: '',
      };
    },
    methods: {
      // 视频预览 图片预览 pdf跳转预览
      showDrawer(_type: string, index: number) {
        if (this.fileList instanceof Array) {
          this.url = this.fileList[index].url;
        } else {
          this.url = this.fileList.url;
        }
        const fileType = mediaType(this.url.split('.').pop()!);
        if (['video', 'img'].includes(fileType)) {
          this.drawerType = fileType;
        } else {
          this.drawerType = 'blank';
        }
        if (this.drawerType !== 'blank') {
          this.drawerVisible = true;
          return;
        }
        window.open(this.url, '__blank__');
      },
      deleteFile(index: number) {
        if (index === -1) {
          copyObjectToReactive(this.fileList, { filename: '', url: '' });
        } else {
          (this.fileList as any).splice(index, 1);
        }
      },
      customRequest(options: ICustomRequest) {
        if (this.useOss) {
          UploadOSS(options);
        } else {
          this.hwSelectFile(options);
        }
      },
      isArray,
      async handleChange(options: any) {
        const file = options.file;
        if (file.status === 'done') {
          if (this.useOss) {
            await setOSSFileToRead({ uri: file.response.name });
            if (isArray(this.fileList)) {
              (this.fileList as any).push({
                filename: file.name,
                url: DOWNLOAD_URL + file.response.name,
              });
            } else {
              copyObjectToReactive(this.fileList, {
                filename: file.name,
                url: DOWNLOAD_URL + file.response.name,
              });
            }
          }
        }
      },
      // base64上传
      hwSelectFile(options: ICustomRequest) {
        const vm = this;
        const file = options.file;
        const fileResultList: { name: string; base64_data: string }[] = [];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          fileResultList.push({ name: file.name, base64_data: e.target?.result as string });
          options.onSuccess(true);
          if (vm.multiple) {
            vm.$emit('update:value', fileResultList);
          } else {
            vm.$emit('update:value', fileResultList[0]);
          }
        };
      },
      getRealName(value: string) {
        // 开发者平台上传图片用的是images/前缀，管理后台用的是img/前缀,在这边改改动量最少，bug率低
        if (value) {
          if (value.indexOf(this.popType + '/') === 0) {
            return value.split(this.popType + '/')[1];
          } else if (this.popType === 'img' && value.indexOf('images/') === 0) {
            return value.split('images/')[1];
          }
        }
        return value;
      },
    },
  });
