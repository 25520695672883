
  import { defineComponent, ref } from 'vue';
  import XLSX from 'xlsx';
  import { sheet2blob, openDownloadDialog } from '@/utils/excel';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'WeImportExcel',
    props: ['value', 'config'],
    data: () => ({
      showPreviewDialog: false,
      loading: false,
      title: 'Import Excel Data Preview',
    }),
    computed: {
      tableConfig() {
        const config: Recordable = {
          useSearchForm: false,
          useFilterFromColumns: false,
          showTableHeader: false,
          tabConfig: null,
          columns: [],
        };
        if (this.excelData.length < 500) {
          config.pagination = false;
        }
        if (this.config && this.config.headers) {
          if (this.config.headers[0].key) {
            config.columns = this.config.headers.map((x) => ({ title: x.label, dataIndex: x.key }));
          } else {
            config.columns = this.config.headers.map((x) => ({ title: x, dataIndex: x }));
          }
        }
        return config;
      },
      excelHeader() {
        let h = {};
        if (this.config && this.config.headers) {
          this.config.headers.forEach((header) => {
            if (header.key && header.label) {
              h[header.label || header.key] = header.key;
            } else {
              h[header] = header;
            }
          });
        }
        return h;
      },
    },
    setup() {
      const excelName = ref<Nullable<string>>(null);
      const excelData = ref<Recordable[]>([]);
      return {
        excelName,
        excelData,
      };
    },
    methods: {
      isEmptyData,
      selectFile({ file }) {
        const vm = this;
        vm.loading = true;
        const fileExtension = file.name.replace(/^.*\./, '');
        const reader = new FileReader();
        function handleJson(j) {
          const json: any[] = [];
          j.forEach((x) => {
            const data = {};
            Object.keys(x).forEach((key) => {
              if (key.trim() !== key) {
                x[key.trim()] = x[key];
              }
            });

            Object.keys(vm.excelHeader).forEach((label) => {
              const key = vm.excelHeader[label];
              const k = Object.keys(x).find((h) => h === label || h.startsWith(label));
              if (k) {
                data[key] = x[k];
              } else {
                data[key] = null;
              }
            });
            json.push(data);
          });
          let convertedResult = vm.config.converter ? vm.config.converter(json) : json;
          if (vm.config.timeHeaders) {
            json.forEach((x) => {
              vm.config.timeHeaders.forEach((f) => {
                if (typeof x[f] === 'number') {
                  x[f] = XLSX.SSF.format('yyyy-mm-dd HH:mm:ss', x[f]);
                }
              });
            });
          }
          if (isEmptyData(vm.excelData)) {
            vm.excelData = convertedResult;
          } else {
            vm.excelData = vm.excelData.concat(convertedResult);
          }
          vm.loading = false;
          vm.$emit('update:value', vm.excelData);
        }
        if (fileExtension === 'csv') {
          let config = {
            delimiter: '', // auto-detect
            newline: '', // auto-detect
            quoteChar: '"',
            escapeChar: '"',
            header: false,
            trimHeader: false,
            dynamicTyping: false,
            preview: 0,
            encoding: '',
            worker: false,
            comments: false,
            step: undefined,
            complete: undefined,
            error: undefined,
            download: false,
            skipEmptyLines: false,
            chunk: undefined,
            fastMode: undefined,
            beforeFirstChunk: undefined,
            withCredentials: undefined,
          };
          reader.readAsText(file, 'GBK');
          reader.onload = function (e) {
            const data = e.target?.result;
            const json: any = window.Papa.parse(data, config);
            // 模拟从第n行开始，例如2表示从表格第三行开始
            if (vm.config.json_config && vm.config.json_config.range) {
              json.data.splice(0, vm.config.json_config.range);
            }
            const header = json.data.splice(0, 1)[0];
            const list: Recordable[] = [];
            json.data.forEach((item) => {
              const obj = {};
              header.forEach((key, index) => {
                obj[key] = item[index];
              });
              list.push(obj);
            });
            handleJson(list);
          };
        } else {
          const sheet_to_json_config = {
            raw: false,
            defval: '',
            range: 1,
            dateNF: 'yyyy-mm-dd',

            ...(isEmptyData(vm.config.json_config) ? {} : vm.config.json_config),
          };

          reader.readAsArrayBuffer(file);
          const getHeaderRow = (wb) => {
            console.log(wb);
            const sheet = wb.Sheets[wb.SheetNames[0]];
            let sheetHeadList = Object.keys(sheet);
            if (sheetHeadList.includes('!merges')) {
              const range = XLSX.utils.decode_range(sheet['!ref']);
              const sheetNameMergeNum = Math.max(
                ...sheet['!merges'].filter((m) => m.e.r !== 0 || m.s.r !== 0).map((m) => Math.max(m.e.r, m.s.r))
              );
              const headers: any[] = [];
              let C,
                R = range.s.r + sheetNameMergeNum; /* 从表头最后一行开始 倒推 */

              for (C = range.s.c; C <= range.e.c; ++C) {
                /* 查找表头最后一行中的单元格 */
                for (let rr = R; rr >= 0; rr--) {
                  const cell = sheet[XLSX.utils.encode_cell({ c: C, r: rr })];
                  if (cell && cell.t) {
                    headers.push(XLSX.utils.format_cell(cell));
                    break;
                  }
                }
              }
              return headers;
            } else {
              return wb.Strings.filter((x) => !isEmptyData(x.t)).map((x) => x.t);
            }
          };
          reader.onload = function (e) {
            const data = e.target?.result;
            const v8 = new Uint8Array(data as ArrayBuffer);
            const wb: any = XLSX.read(v8, { type: 'array', cellDates: true, cellText: false });
            sheet_to_json_config.header = getHeaderRow(wb);
            const json: any = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], sheet_to_json_config);
            handleJson(json);
          };
        }
        if (isEmptyData(vm.excelName)) {
          vm.excelName = file.name;
        } else {
          vm.excelName =
            vm.excelName + `<div style="border-bottom: solid thin #ccc; margin: 4px 0;"></div>` + file.name;
        }
      },
      tipHeaders() {
        if (this.config && this.config.headers) {
          let headersTip = '';
          if (this.config.headers[0].key) {
            headersTip = this.config.headers.map((x) => `<li>${x.label}</li>`).join('');
          } else {
            headersTip = this.config.headers.map((x) => `<li>${x}</li>`).join('');
          }
          let exampleData: Recordable[] = [];
          if (this.config.example) {
            exampleData = this.config.example;
          } else {
            const defaultExample = {};
            if (this.config.headers[0].key) {
              this.config.headers.forEach((x) => (defaultExample[x.label] = ''));
            } else {
              this.config.headers.forEach((x) => (defaultExample[x] = ''));
            }
            exampleData.push(defaultExample);
          }
          this.$showAlert(
            'Required Excel Headers',
            `<div style="margin-left: 40px;">${headersTip}</div>`,
            () => {
              const sheet = XLSX.utils.json_to_sheet(exampleData, { raw: false } as any);
              openDownloadDialog(sheet2blob(sheet), `模板.xlsx`);
              appStore.SET_ALERT_CONFIG(null);
            },
            undefined,
            { okText: '下载模版' }
          );
        }
      },
      down_template() {
        window.open(this.config.url);
      },
    },
  });
