import { RouteRecordRaw } from 'vue-router';

import AppLayout from '@/layout/index.vue';
const more: RouteRecordRaw = {
  path: '/more',
  name: 'More',
  meta: { title: '更多', ignoreKeepAlive: true },
  redirect: { name: 'MoreOther' },
  component: AppLayout,
  children: [],
};
export default more;
