import request from '@/utils/request';
import { SelectOption } from 'types/store';
// 账号申请列表
export function get_ml_account_apply_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_ml_account_apply_page', params);
}
// 账号申请列表-创建申请
export function create_ml_account_apply(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/create_ml_account_apply', params);
}
// 获取 买量账号审核 详情
export function get_ml_account_apply_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_ml_account_apply_item', params);
}
// 账号列表
export function get_account_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_account_list', params);
}
// 账号列表-认领
export function apply_to_me(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/apply_to_me', params);
}
// 买量账号Config接口
export function get_ml_account_apply_const() {
  return request.post<null, Recordable>('/api/v1/get_ml_account_apply_const');
}
// 代理商列表接口
export function get_agency_list() {
  return request.post<null, SelectOption[]>('/api/v1/get_agency_list');
}
// supply合同接口
export function get_ml_account_apply_contract_list(params) {
  return request.post<null, SelectOption[]>('/api/v1/get_ml_account_apply_contract_list', params);
}
// 获取AM接口
export function get_apply_demand_am_list() {
  return request.post<null, SelectOption[]>('/api/v1/get_apply_demand_am_list');
}
// 添加/导入 买量账号
export function upload_ml_accounts(params: Recordable) {
  return request.post<null, any>('/api/v1/upload_ml_accounts', params);
}
// 获取账号列表
export function get_account_page(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_account_page', params);
}
// 批量编辑买量账号
export function edit_ml_accounts(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/edit_ml_accounts', params);
}
// 选择代理
export function ml_account_apply_select_agency(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/ml_account_apply_select_agency', params);
}
// 取消 买量账号申请
export function cancel_ml_account_apply(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/cancel_ml_account_apply', params);
}
// 账号新增或God编辑
export function edit_ml_account(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/edit_ml_account', params);
}
// 批量编辑账号
export function batch_edit_ml_accounts(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/batch_edit_ml_accounts', params);
}
// 刷新授权状态
export function refresh_ml_account_auth_status() {
  return request.post<null, Recordable[]>('/api/v1/refresh_ml_account_auth_status');
}
// 获取全局配置中的下一步操作人id
export function get_china_main_account_managers() {
  return request.post<null, Recordable[]>('/api/v1/get_china_main_account_managers');
}
// 获取全局配置中的下一步操作人id
export function ml_account_apply_select_operator_done(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/ml_account_apply_select_operator_done', params);
}
// 测试买量账号
export function test_mlaccount(data: { id: number }) {
  return request.post<null, Recordable>('/api/v1/test_mlaccount', data);
}
// 获取所有开户主体列表
export function get_ml_account_company_names() {
  return request.post<null, Recordable[]>('/api/v1/get_ml_account_company_names');
}

export function get_okr(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_okr', params);
}

export function set_okr(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/set_okr', params);
}

export function get_scaler_opp_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_scaler_opp_page', params);
}
