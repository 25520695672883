
  import { Form } from 'ant-design-vue';

  interface IBase64FileItems {
    name: string;
    base64_data: string;
  }
  import { defineComponent, ref } from 'vue';
  export default defineComponent({
    name: 'LocalUpload',
    props: {
      value: {
        type: [Array, Object] as PropType<IBase64FileItems[] | IBase64FileItems>,
        default: () => {},
      },
      maxCount: {
        type: Number,
        default: null,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      accept: {
        type: String,
        default: '',
      },
      showUploadList: {
        type: Boolean,
        default: true,
      },
    },
    setup() {
      const formItemContext = Form.useInjectFormItemContext();

      const fileList = ref<IBase64FileItems[]>([]);
      return {
        formItemContext,
        fileList,
      };
    },
    emits: ['update:value', 'change', 'input'],
    methods: {
      selectFile({ file, onSuccess }) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const vm = this;
        reader.onload = function (e) {
          vm.fileList.push({ name: file.name, base64_data: e.target?.result as string });
          onSuccess(true);
          if (vm.multiple) {
            vm.$emit('update:value', vm.fileList);
            vm.$emit('change', vm.fileList);
            vm.$emit('input', vm.fileList);
            vm.formItemContext.onFieldChange();
          } else {
            vm.$emit('update:value', vm.fileList[0]);
            vm.$emit('change', vm.fileList);
            vm.$emit('input', vm.fileList[0]);
            vm.formItemContext.onFieldChange();
          }
        };
      },
      removeFile(file) {
        if (this.multiple) {
          const index = this.fileList.findIndex((item) => item.name === file.name);
          this.fileList.splice(index, 1);
          this.$emit('update:value', this.fileList);
        } else {
          this.$emit('update:value', {});
        }
      },
    },
  });
