import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
const persistedMutation = ['SET_SELECTED_MENUS', 'TOGGLE_SIDEBAR_COLLAPSED', 'SET_APPINFO', 'SET_GLOBAL_SEARCH'];
const persistedConfig = {
  key: 'hw_app',
  paths: ['cache.appInfo', 'cache.sidebarCollapsed', 'cache.selectedMenus', 'cache.app_menus', 'cache.global_search'],
  filter: ({ type }) => {
    return persistedMutation.includes(type);
  },
};
export default createStore({
  plugins: [createPersistedState(persistedConfig)],
  devtools: true,
});
