
  import { computed, defineComponent } from 'vue';
  import AppLayoutHeader from './header/index.vue';
  import AppLayoutSider from './sider/index.vue';
  import AppLayoutContent from './content/index.vue';
  import WaterMark from '@/components/WaterMark/index.vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import { isDev } from '@/utils/const';
  import { cacheStore } from '@/store/modules/cache';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'AppLayout',
    data() {
      return {
        zhCN: zhCN,
        isDev,
      };
    },
    components: {
      AppLayoutHeader,
      AppLayoutSider,
      AppLayoutContent,
      WaterMark,
    },
    setup() {
      const { appInfo } = useAppInfo();
      const showApp = computed(() => {
        return !isEmptyData(cacheStore.appInfo);
      });
      return { showApp, appInfo };
    },
    async created() {
      if (isEmptyData(this.appInfo)) {
        cacheStore.GetAppInfoAction().then(() => {
          this.$setGlobalState({
            jwt_token: this.appInfo.jwt_token,
            user: this.appInfo.user,
          });
        });
      } else {
        const vm = this;
        setTimeout(function () {
          cacheStore.GetAppInfoAction().then(() => {
            vm.$setGlobalState({
              jwt_token: vm.appInfo.jwt_token,
              user: vm.appInfo.user,
            });
          });
        }, 5000);
      }
    },
    watch: {
      'appInfo.user': {
        handler(value) {
          if (value) {
            this.$dataFinder.pushEvent('登录');
          }
        },
      },
    },
  });
