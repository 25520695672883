import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/logo.svg'


const _hoisted_1 = { class: "logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_menu = _resolveComponent("header-menu")!
  const _component_global_search = _resolveComponent("global-search")!
  const _component_header_drop_down = _resolveComponent("header-drop-down")!
  const _component_a_layout_header = _resolveComponent("a-layout-header")!

  return (_openBlock(), _createBlock(_component_a_layout_header, {
    class: _normalizeClass(_ctx.isProd ? 'app_header' : 'app_dev_header')
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isProd)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToHome && _ctx.backToHome(...args)))
            }))
          : (_ctx.isLocal)
            ? (_openBlock(), _createElementBlock("span", {
                key: 1,
                style: {"font-size":"24px","font-weight":"700"},
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backToHome && _ctx.backToHome(...args)))
              }, "HW - Local"))
            : (_openBlock(), _createElementBlock("span", {
                key: 2,
                style: {"font-size":"24px","font-weight":"700","color":"#4885ed"},
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.backToHome && _ctx.backToHome(...args)))
              }, "HW - Test"))
      ]),
      _createVNode(_component_header_menu),
      _createVNode(_component_global_search),
      _createVNode(_component_header_drop_down, {
        "avatar-url": _ctx.lark_avatar,
        "user-name": _ctx.name
      }, null, 8, ["avatar-url", "user-name"])
    ]),
    _: 1
  }, 8, ["class"]))
}