import { MenuItem } from 'types/config';

const business: MenuItem = {
  path: 'business',
  title: '业务',
  is_root: true,
  hasTag: true,
  children: [
    {
      path: 'common',
      title: '通用',
      icon: 'AppstoreOutlined',
      children: [
        {
          path: 'cms',
          title: '账号管理',
          icon: 'WE_BuildingOutlined',
          children: [
            { path: 'ml-apply-list', title: '买量账号申请' },
            { path: 'ml-account-list', title: '买量账号列表' },
            { path: 'realize-list', title: '变现账号列表' },
            { path: 'realize-config', title: 'Admob账号列表' },
            { path: 'big-account-config', title: '大账号列表' },
          ],
        },
        {
          path: 'cash',
          title: '兑付管理',
          icon: 'WE_RMBSyncDotsOutlined',
          children: [],
        },
        {
          path: 'opa',
          title: '开户充值',
          icon: 'WE_MetaOutlined',
          children: [
            { path: 'audit-list', title: '开户审核列表' },
            { path: 'recharge-list', title: '充值审核列表' },
            { path: 'platform-account-list', title: '账号管理' },
          ],
        },
        {
          path: 'other',
          title: '其它',
          icon: 'WE_ThreeCirclesOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'pista',
      title: 'Pista',
      icon: 'WE_HeatmapOutlined',
      children: [
        {
          path: 'drama',
          title: '短剧工厂',
          icon: 'WE_CameraAddOutlined',
          children: [],
        },
        {
          path: 'report',
          title: '短剧报表',
          icon: 'WE_ProductManageOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'pub',
      title: 'Harbor',
      icon: 'WE_PublishLogoOutlined',
      children: [
        {
          path: 'product',
          title: '产品管理',
          icon: 'WE_ProductManageOutlined',
          children: [
            { path: 'list', title: '产品列表' },
            { path: 'permission-audit-list', title: '产品权限审核列表' },
            { path: 'publish-rate', title: '发行分成比例列表' },
            { path: 'developer-account', title: '开发者平台账号列表' },
          ],
        },
        {
          path: 'report',
          title: '产品报表',
          icon: 'WE_ProductManageOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'taurus',
      title: 'Taurus',
      icon: 'WE_TaurusXOutlined',
      children: [
        {
          path: 'scaler',
          title: 'Scaler',
          icon: 'WE_AllCustomersOutlined',
          children: [{ title: 'Scaler周报', path: 'week-report' }],
        },
        {
          path: 'tax',
          title: 'TAX',
          icon: 'WE_RMBSyncDotsOutlined',
          children: [],
        },
      ],
    },
    {
      path: 'ugs',
      title: 'MS',
      icon: 'WE_SupplyHandOutlined',
      children: [
        {
          path: 'reseller',
          title: '营销服务',
          icon: 'AlertOutlined',
          children: [
            { path: 'credits', title: '授信列表' },
            { path: 'recharge', title: '充值列表' },
            { path: 'pay-alert-mail', title: 'MS预警邮件' },
          ],
        },
      ],
    },
    {
      path: 'cloud',
      title: 'Cloud',
      icon: 'WE_CloudUserOutlined',
      children: [
        {
          path: 'business-bill',
          title: '业务对账',
          icon: 'CarryOutOutlined',
          children: [
            { path: 'gcp', title: 'GCP对账' },
            { path: 'gsuite', title: 'GSuite对账' },
            { path: 'aws', title: 'AWS对账' },
            { path: 'msg', title: 'MSG对账' },
          ],
        },
        {
          path: 'self-bill',
          title: '自用对账',
          icon: 'CarryOutOutlined',
          children: [
            { path: 'gcp', title: 'GCP自用' },
            { path: 'aws', title: 'AWS自用' },
            { path: 'aliyun', title: '阿里云自用' },
            { path: 'ucloud', title: 'UCloud自用' },
            { path: 'linode', title: 'Linode自用' },
            { path: 'vultr', title: 'Vultr自用' },
            { path: 'huawei', title: '华为云自用' },
          ],
        },
        {
          path: 'other',
          title: '其它功能',
          icon: 'CarryOutOutlined',
          children: [
            { path: 'test-account-list', title: '测试账号' },
            { path: 'currency-rate', title: 'Cloud币种与汇率' },
            { path: 'commit-contract', title: 'Commit合同统计' },
          ],
        },
      ],
    },
  ],
};
export default business;
