import request from '@/utils/request';
export function edit_purchase_pay(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_purchase_pay', params);
}
export function get_pay_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_pay_page', params);
}
export function batch_add_pay(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_add_pay', params);
}
export function get_paying_money_bill_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_paying_money_bill_list', params);
}
export function get_pay_item(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_pay_item', params);
}
export function delete_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_pay', params);
}
export function assign_pay_to_me(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_pay_to_me', params);
}
export function close_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/close_pay', params);
}
export function reopen_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/reopen_pay', params);
}
export function get_same_company_bill_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_same_company_bill_list', params);
}
export function link_bill_for_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/link_bill_for_pay', params);
}
export function finish_bill(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/finish_bill', params);
}
export function reverse_bill(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/reverse_bill', params);
}
export function god_edit_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/god_edit_pay', params);
}
export function edit_supply_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_supply_pay', params);
}
export function add_prepay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_prepay', params);
}
export function edit_demand_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_demand_pay', params);
}
export function upload_demand_pay_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_demand_pay_list', params);
}
export function get_company_with_bank(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_company_with_bank', params);
}
export function edit_direct_pay(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_direct_pay', params);
}
export function get_pay_cat(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_pay_cat', params);
}
export function upload_direct_pays(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_direct_pays', params);
}
