import request from '@/utils/request';
/**
 * 获取充值列表页
 * @param params
 */
export function get_opa_apply_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_opa_apply_page', params);
}
/**
 * 获取详情
 * @param params
 */
export function get_opa_apply_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_opa_apply_item', params);
}
/**
 * 编辑基本信息
 * @param params
 */
export function edit_opa_apply_info(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_opa_apply_info', params);
}
/**
 * 获取充值列表页
 * @param params
 */
export function get_ugs_recharge_apply_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_ugs_recharge_apply_page', params);
}
/**
 * 获取充值详情
 * @param params
 */
export function get_ugs_recharge_apply_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_ugs_recharge_apply_item', params);
}
/**
 * 编辑充值详情
 * @param params
 */
export function edit_ugs_recharge_account(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_ugs_recharge_account', params);
}

export function admin_done_recharge(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/admin_done_recharge', params);
}

/**
 * 导入账号
 * @param params
 */
export function upload_opa_accounts(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_opa_accounts', params);
}
/**
 * 获取平台列表页面
 * @param params
 */
export function get_opa_account_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_opa_account_page', params);
}

/**
 * 获取合同平台
 * @param params
 */
export function get_opa_contract_company() {
  return request.post<null, Recordable>('/api/v1/get_opa_contract_company');
}
