import { createApp } from 'vue';
import App from './App.vue';
import { setupRouter } from './router';
import store from './store';
import '@/router/guard';
import { registerUI } from './expand/ui';
import { registerGlobalProperty } from './expand/global';
import animate from 'animate.css';
import './style/index.less';
import { setupGlobDirectives } from './directives';
import { DialogConfig } from './components/WeDialog/types';
import { DrawerConfig } from './components/WeDrawer/types';
import { BasicTableProps } from './components/WeTable';
import { cacheStore } from './store/modules/cache';
import { initGlobalState, registerMicroApps } from 'qiankun';
import { isEmptyData } from './utils/common';
import { isProd, isDev } from './utils/const';
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $showDialog: (weDialog: DialogConfig) => void;
    $showOperLog: (weOperLog: Recordable) => void;
    $clipboard: any;
    $message: { error: Function; success: Function; info: Function; warning: Function };
    $confirm: (
      message: string,
      title: string,
      button: {
        confirmButtonText: string;
        cancelButtonText?: string;
        type: string;
        width?: number;
      }
    ) => Promise<void>;
    $showDrawer: (drawerConfig: DrawerConfig) => void;
    $showAlert: (
      title: string,
      alertContent: string | { config: BasicTableProps; [key: string]: any },
      callback?: Fn,
      cancel?: Fn,
      moreConfig?: Recordable
    ) => void;
    $showChange: (entity: Recordable, entityType: string) => void;
    $setGlobalState: (param: Recordable) => void;
    $dataFinder: {
      pushEvent: (event: string, param?: object) => void;
      config: (param: object) => void;
    };
  }
}
const app = createApp(App);
const win: any = window; //
if (process.env.NODE_ENV === 'development') {
  if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in win) {
    win.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
  }
}
registerUI(app);
registerGlobalProperty(app);
setupRouter(app);
setupGlobDirectives(app);
app.use(animate);
app.use(store);
app.mount('#app');

registerMicroApps([
  {
    name: 'erp-front-new',
    entry: isProd
      ? 'https://hellowe.chinawebeye.com'
      : isDev
      ? 'http://localhost:8080'
      : 'https://hellowe-bk2.webeye.cn', // test-env hw3
    container: '#sub_content',
    activeRule: '/business/hw3',
  },
  {
    name: 'open-account',
    entry: isProd
      ? 'https://asap.webeyeug.com.cn'
      : isDev
      ? 'http://localhost:9520'
      : 'http://hw-test-opa.cd2d07431f1f249f49465b7cb40827b3f.cn-shenzhen.alicontainer.com',
    container: '#sub_content',
    activeRule: '/business/opa',
  },
]);

// 主子应用全局共享
const { setGlobalState, onGlobalStateChange } = initGlobalState({
  jwt_token: isEmptyData(cacheStore.appInfo) ? '' : cacheStore.appInfo?.jwt_token,
  is_collapsed: cacheStore.sidebarCollapsed,
  app_env:
    location.hostname === 'hellowe-test.webeye.cn' ? 'test' : location.hostname.includes('localhost') ? 'dev' : 'prod',
  user: isEmptyData(cacheStore.appInfo) ? {} : cacheStore.appInfo?.user,
  appInfo: cacheStore.appInfo,
});
app.config.globalProperties.$setGlobalState = setGlobalState;
onGlobalStateChange((value) => {
  cacheStore.TOGGLE_SIDEBAR_COLLAPSED(value.is_collapsed);
});
