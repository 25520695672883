import { getFlatTitlePathMenus } from '@/router/menu';
import { isOut, isTest } from '@/utils/const';
import { isEmptyData } from '@/utils/common';
import { RouteToMenu } from 'types/config';
import { IAppInfo, IUser } from 'types/store';
import { computed, unref } from 'vue';
import { findDialogInput, mapItems } from '../utils/common';
import { useAppInfo } from './useAppInfo';
import { cacheStore } from '@/store/modules/cache';
import { useRoute, useRouter } from 'vue-router';
import { pathToRegexp } from 'path-to-regexp';
import { cloneDeep } from 'lodash-es';

export function useUser() {
  const router = useRouter();
  const route = useRoute();
  const appInfo = computed(() => {
    const temp = cacheStore.appInfo;
    return temp as IAppInfo;
  });
  const custom_menu_list = computed(() => {
    return unref(appInfo).custom_menu_list || [];
  });
  const user = computed(() => {
    return unref(appInfo)?.user as IUser;
  });
  const userList = computed(() => {
    return unref(appInfo).user_list;
  });
  const userInfo = computed(() => {
    return unref(appInfo)?.user.user_info;
  });
  const getMyTeamByType = function (teamType) {
    let ret: Recordable = {};
    unref(user)?.user_team_list.forEach((ut) => {
      if (ut.team.team_type === teamType) {
        ret = ut.team;
      }
    });
    return ret;
  };
  const isTeamHeadByType = function (teamType) {
    let ret = false;
    unref(user)?.user_team_list.forEach((ut) => {
      if (ut.team.team_type === teamType && ut.is_team_manager) {
        ret = true;
      }
    });
    return ret;
  };
  const model = computed(() => {
    return unref(appInfo)?.model;
  });
  const isOne = computed(() => {
    return unref(user)?.id === 1 || unref(user)?.id === 10221;
  });
  const isCeo = computed(() => {
    return unref(user)?.id === 10018;
  });
  const isGod = computed(() => {
    return unref(user)?.user_info.is_god as boolean;
  });
  // const isCRMManager = computed(() => {
  //   return [10018, 10049, 10294, 10071, 10148].includes(unref(user).id);
  // });
  const isAdmin = computed<boolean>(() => {
    return unref(user)?.user_info.is_admin || (isTest && [10350, 10439].includes(unref(user)!.id));
  });
  const isAnalysis = computed<boolean>(() => {
    return !isEmptyData(getMyTeamByType(unref(model)?.Team.TYPE.ANALYSIS));
  });
  const isAccountant = computed(() => {
    return !isEmptyData(getMyTeamByType(unref(model)?.Team.TYPE.ACCOUNTANT));
  });
  const isCapital = computed(() => {
    return !isEmptyData(getMyTeamByType(unref(model)?.Team.TYPE.CAPITAL));
  });
  const global_search_list = computed(() => {
    return cacheStore.global_search || [];
  });
  const global_search_items_self = computed(() => {
    if (global_search_list.value.length === 0) {
      return [];
    } else {
      const findItem = global_search_list.value.find((item) => item.emp_id === user.value.lark_employee_id);
      if (isEmptyData(findItem)) {
        return [];
      } else {
        return findItem?.items;
      }
    }
  });
  const isTax = computed(() => {
    return !isEmptyData(getMyTeamByType(unref(model)?.Team.TYPE.TAX));
  });
  // 财务
  const isFinance = computed(() => {
    return unref(isAnalysis) || unref(isAccountant) || unref(isCapital) || unref(isTax);
  });
  // 法务
  const isLegal = computed(() => {
    return !isEmptyData(getMyTeamByType(unref(model)?.Team.TYPE.LEGAL));
  });
  const isBoss = computed(() => {
    return [10018, 10062, 10063].includes(unref(user)!.id);
  });
  const isLegalHead = computed(() => {
    return isTeamHeadByType(unref(model)?.Team.TYPE.LEGAL);
  });
  const isBusinessHead = computed(() => {
    return isTeamHeadByType(unref(model)?.Team.TYPE.BUSINESS);
  });
  const isBusiness = computed(() => {
    return !isEmptyData(getMyTeamByType(unref(model)?.Team.TYPE.BUSINESS));
  });
  const isTeamHead = (teamId) => {
    let ret = false;
    unref(user).user_team_list.forEach((ut) => {
      if (ut.team_id === teamId && ut.is_team_manager) {
        ret = true;
      }
    });
    return ret;
  };
  const hasPermission = (permissionNameList) => {
    let pnList = permissionNameList;
    if (typeof permissionNameList === 'string') {
      pnList = [pnList];
    }
    return unref(isGod) || !!unref(user)?.user_permission_list.find((p) => pnList.includes(p.name));
  };
  const userTeamList = computed(() => {
    return unref(user)?.user_team_list;
  });
  const validUserList = computed(() => {
    let validUsers = unref(userList).filter(
      (x) => (x.user_info.lark_id || x.user_info.is_temp_user) && !x.user_info.is_deleted
    );
    if (isOut) {
      validUsers = unref(userList);
    }
    validUsers.sort((a, b) => a.name.localeCompare(b.name, 'zh-Hans-CN', { sensitivity: 'accent' }));
    return validUsers;
  });
  function getTeamUsers(teamId) {
    const { teamMap, userMap } = useAppInfo();
    if (unref(teamMap)[teamId]) {
      return unref(teamMap)
        [teamId].user_team_list.map((ut) => ut.user_id)
        .map((uid) => unref(userMap)[uid]);
    } else {
      return [];
    }
  }
  function getTeamUserDialogChange(team_key, user_key) {
    return (result, _input, config) => {
      const entityInput = findDialogInput(config, user_key);
      entityInput.items = mapItems(getTeamUsers(result[team_key]));
      result[user_key] = undefined;
    };
  }
  const flatMenuList = computed<Recordable[]>(() => {
    return getFlatTitlePathMenus(unref(getFilteredHWRoutesAndMenus).menus)
      .filter((item) => !item.children || item.children.length === 0)
      .map((mtem) => ({
        key: mtem.path,
        parent_path: mtem.parent_path!,
        ...mtem,
      }));
  });
  const findMenuItem = (path: string) => {
    return unref(flatMenuList).find((item) => item.path === path) || {};
  };
  const getAllRoutePath = computed(() => {
    return router.getRoutes();
  });
  const getFilteredHWRoutesAndMenus = computed(() => {
    return unref(appInfo).routes_menus_by_roles;
  });
  const getMatchedRouteByMenu = (menu_key: string) => {
    const findItem: RouteToMenu = unref(getFilteredHWRoutesAndMenus).routes.find(
      (item) => item.union_menu_key === menu_key
    ) || {
      route_path: '',
      auth: [],
      mount_menu_key: [],
      union_menu_key: '',
    };
    return findItem;
  };
  function getMatchedMenuKeyByRoute(): string[] {
    let mount_menu_key: string[] = [];
    const routes = unref(getFilteredHWRoutesAndMenus).routes;
    for (let i = 0; i < routes.length; i++) {
      const pathToReg = pathToRegexp(routes[i].route_path);
      if (pathToReg.exec(route.path) && !routes[i].route_path.includes('*')) {
        mount_menu_key = routes[i].mount_menu_key;
        break;
      }
    }
    return mount_menu_key;
  }
  function getAllParentMenuKeyByRoute(): string[] {
    let menus = cloneDeep(unref(getFilteredHWRoutesAndMenus).menus);
    const matched_menu_key = getMatchedMenuKeyByRoute();
    const cloneMenu = cloneDeep(matched_menu_key)!;
    const temp = cloneMenu.pop();
    if (temp?.includes(':')) {
      //用于动态匹配
      cloneMenu.pop();
    }
    if (isEmptyData(cloneMenu)) {
      return [];
    }
    let menu_keys_arr: string[] = [];
    let ok = true;
    while (ok) {
      if (isEmptyData(menus) || menus.map((child) => child.path).includes(`/${cloneMenu.join('/')}`)) {
        ok = false;
        menu_keys_arr = menus!.map((item) => item.path);
      } else {
        const find_item = menus.find(
          (item) => `/${cloneMenu.join('/')}`.includes(item.path) && item.path.includes(cloneMenu[0])
        );
        if (!isEmptyData(find_item)) {
          menus = find_item!.children!;
        } else {
          menus = [];
        }
      }
    }
    return menu_keys_arr;
  }
  return {
    user,
    userInfo,
    isBoss,
    isOne,
    isCeo,
    isGod,
    isAdmin,
    isTax,
    isCapital,
    isAccountant,
    isFinance,
    isAnalysis,
    isLegal,
    isLegalHead,
    isBusinessHead,
    isBusiness,
    userTeamList,
    validUserList,
    userList,
    global_search_list,
    global_search_items_self,
    isTeamHead,
    hasPermission,
    getMyTeamByType,
    getTeamUsers,
    getTeamUserDialogChange,
    flatMenuList,
    findMenuItem,
    getMatchedRouteByMenu,
    getMatchedMenuKeyByRoute,
    getAllParentMenuKeyByRoute,
    getAllRoutePath,
    getFilteredHWRoutesAndMenus,
    custom_menu_list,
  };
}
