<template>
  <span v-if="tabs && style_type === 'style1'" style="color: #ccc" class="style1">
    <span
      v-for="(t, i) in tabs"
      :key="i"
      :class="'tab_item ' + (t.selected ? 'tab_item_selected' : '')"
      @click="selectTab(t)"
    >
      <span :class="'tab_item_text ' + (t.selected ? 'tab_item_text_selected' : '')">
        {{ t.text }}
      </span>
      <span v-if="i < tabs.length - 1" class="mx-3">|</span>
    </span>
  </span>
  <div v-else-if="tabs" class="style2">
    <span
      v-for="(t, i) in tabs"
      :key="i"
      :class="'tab_item ' + (t.selected ? 'tab_item_selected primary--text' : '')"
      @click="selectTab(t)"
    >
      <span :class="'tab_item_text ' + (t.selected ? 'tab_item_text_selected primary--text' : '')">
        {{ t.text }}
      </span>
    </span>
  </div>
</template>

<style lang="less" scoped>
  .style1 .tab_item {
    cursor: pointer;
  }

  .style1 .tab_item_text_selected {
    color: #333;
  }

  .style2 .tab_item {
    cursor: pointer;
    color: transparent;
    padding: 10px 18px;
    margin-right: 10px;
    border-bottom: solid 2px;
    display: inline-block;
    font-weight: 500;
  }

  .style2 .tab_item_text {
    color: #aaa;
    display: inline-block;
  }

  .style2 .tab_item_selected {
    background: white;
  }
</style>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'WeTab',
    props: ['config', 'value', 'style_type'],
    data: () => ({
      tabs: [],
    }),
    computed: {},
    watch: {},
    created() {
      const tabs = this.config.map((x) => ({ text: x, value: x }));
      tabs.forEach((t) => {
        t.selected = t.value === this.value;
      });
      this.tabs = tabs;
    },
    methods: {
      selectTab(tab) {
        if (this.tabs && !tab.selected) {
          this.tabs.forEach((t) => {
            t.selected = false;
          });

          tab.selected = true;
          this.$emit('update:value', tab.value);
        }
      },
    },
  });
</script>
