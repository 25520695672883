import { formatNumber } from '@/utils/number';
import { saveAs } from 'file-saver';
export function useAwsBill() {
  const get_header_cost = (data: Recordable) => {
    if (!data.val) {
      return data.cost;
    }
    let count = 0;
    data.val.forEach((item) => {
      if (item.val) {
        item.cost = get_header_cost(item);
      }
      count += item.cost;
    });
    return count;
  };
  const get_all_header_cost = (data: Recordable[]) => {
    const wrapper_data = {
      val: data,
    };
    return get_header_cost(wrapper_data);
  };
  const export_html_file = (data: Recordable[], company__name, account_id, month) => {
    const html_header = `
    <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>${company__name}_${account_id}${month}</title>
    <style>
        * {
          padding: 0;
          margin: 0;
        }
        .app {
          width: 1200px;
          margin: 0 auto;
        }
        .all_amount_header{
          display:flex;
          justify-content: space-between;
          margin: 32px 0;
        }
        .all_amount_header div{
          padding: 0 16px;
        }
        .all_amount_header div:nth-child(2) {
          font-weight: 700;
          font-size: 16px;
        }
        .collapse_item {
          border: 1px solid #d9d9d9;
          border-radius: 4px;
        }
        .collapse_header {
          height: 48px;
          line-height: 48px;
          cursor: pointer;
          background-color: #fafafa;
          padding:0 16px;
          display: flex;
          justify-content: space-between;
        }
        .collapse_header span:nth-child(1){
          display: flex;
          align-items: center;
        }
        .collapse_content {
          display: none;
          padding: 16px;
        }
        .collapse_content_box{
          display:none;
          justify-content: space-between;
          border-bottom: #d9d9d9 1px solid;
          margin-bottom:16px;
        }
        .collapse_content_box span{
          width:30%;
          text-align: right;
        }
        .collapse_content_box span:nth-child(1){
          width:30%;
          text-align: left;
        }
        .collapse_item_active>.collapse_content {
          display: block;
        }
    </style>
</head>

<body>
    <div class="app">
      <div class="all_amount_header">
        <div>${'账户ID：' + account_id}</div>
        <div>${'$' + formatNumber(get_all_header_cost(data), '0,0.0000')}</div>
      </div>
      <div class="all_amount_header">
        <div>AWS服务费用</div>
        <div>${'$' + formatNumber(get_all_header_cost(data), '0,0.0000')}</div>
      </div>
    `;
    const html_script = `    </div>
    <script>
        const register_handle_header_click = () => {
            const collapse_header_items = document.querySelectorAll('.collapse_header')
            collapse_header_items.forEach(item => {
                item.addEventListener('click', () => {
                    const item_parent_node = item.parentNode
                    const svg = item_parent_node.querySelector('svg');
                    const item_class = item_parent_node.getAttribute('class')
                    if (item_class.includes('collapse_item_active')) {
                        item_parent_node.classList.remove('collapse_item_active')
                        svg.style.transform = null
                    } else {
                        item_parent_node.classList.add('collapse_item_active')
                        svg.style.transform = 'rotate(90deg)'
                    }
                })
            })
        }
        window.onload = register_handle_header_click
    </script>
</body>

</html>
        `;
    let html_body = '';
    const create_svg = () => {
      const svg = document.createElement('svg');
      const svg_attributes = {
        focusable: false,
        'data-icon': 'right',
        width: '1em',
        height: '1em',
        fill: 'currentColor',
        'aria-hidden': true,
        viewBox: '64 64 896 896',
      };
      Reflect.ownKeys(svg_attributes).forEach((item) => svg.setAttribute(item as string, svg_attributes[item]));
      const path = document.createElement('path');
      path.setAttribute(
        'd',
        'M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z'
      );
      svg.appendChild(path);
      return svg;
    };
    const get_html_body = (data: Recordable[]) => {
      return data.map((item) => {
        if (!Reflect.has(item, 'val')) {
          const collapse_content_box = document.createElement('div');
          collapse_content_box.classList.add('collapse_content_box');
          const sku_desc = document.createElement('span');
          sku_desc.innerText = item.sku_desc;
          const amount = document.createElement('span');
          amount.innerText = formatNumber(item.amount, '0,0.0000') + item.unit;
          const cost = document.createElement('span');
          cost.innerText = `$${formatNumber(item.cost, '0,0.0000')}`;
          collapse_content_box.appendChild(sku_desc);
          collapse_content_box.appendChild(amount);
          collapse_content_box.appendChild(cost);
          return collapse_content_box;
        }
        const collapse_item = document.createElement('div');
        collapse_item.classList.add('collapse_item');
        const collapse_header = document.createElement('div');
        collapse_header.classList.add('collapse_header');
        collapse_header.setAttribute('role', 'button');
        const header_svg = create_svg();
        const header_span = document.createElement('span');
        header_span.style.marginLeft = '16px';
        if (Reflect.has(item, 'sku_region')) {
          header_span.innerText = item.sku_region ? item.sku_region : 'No Region';
        } else {
          header_span.innerText = item.service ? item.service : 'No Service';
        }
        const header_cost_span = document.createElement('span');
        const header_cost = get_header_cost(item);
        header_cost_span.innerText = `$${formatNumber(header_cost, '0,0.0000')}`;
        const header_left_span = document.createElement('span');
        header_left_span.appendChild(header_svg);
        header_left_span.appendChild(header_span);
        collapse_header.appendChild(header_left_span);
        collapse_header.appendChild(header_cost_span);
        collapse_item.appendChild(collapse_header);
        if (item.val.length > 0) {
          const collapse_content = document.createElement('div');
          collapse_content.classList.add('collapse_content');
          const child_list = get_html_body(item.val);
          child_list.forEach((item) => collapse_content.appendChild(item));
          collapse_item.appendChild(collapse_content);
        }
        return collapse_item;
      });
    };
    get_html_body(data).forEach((item) => (html_body += item.outerHTML));
    const html_content = html_header + html_body + html_script;
    const blob = new Blob([html_content], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${company__name}_${account_id}${month}.html`);
  };
  return { export_html_file, get_header_cost, get_all_header_cost };
}
