
  import { computed, defineComponent, watch, ref, unref, nextTick } from 'vue';
  import { useWindowSize } from '@vueuse/core';
  import ERPContractDetail from '@/views/erp/contracts/ContractDetail.vue';
  import ERPBillDetail from '@/views/erp/bills/BillDetail.vue';
  import ERPPayDetail from '@/views/erp/pays/PayDetail.vue';
  import ERPInvoiceDetail from '@/views/erp/invoices/InvoiceDetail.vue';
  import UGSResellerDetail from '@/views/ugs/reseller/ResellerDetail.vue';
  import UGSResellerCreditDetail from '@/views/ugs/reseller/ResellerCreditDetail.vue';
  import CommonCMSDetail from '@/views/common/cms/CMSDetail.vue';
  import UgsOpenAccountAuditDetail from '@/views/ugs/openAccount/AuditDetail.vue';
  import UgsOpenAccountRechargeDetail from '@/views/ugs/openAccount/RechargeDetail.vue';
  import { cacheStore } from '@/store/modules/cache';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'WeDrawer',
    inheritAttrs: false,
    components: {
      ERPContractDetail,
      ERPBillDetail,
      ERPPayDetail,
      ERPInvoiceDetail,
      UGSResellerDetail,
      UGSResellerCreditDetail,
      CommonCMSDetail,
      UgsOpenAccountAuditDetail,
      UgsOpenAccountRechargeDetail,
    },
    props: {
      componentName: {
        type: String,
        default: '',
      },
      keyName: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const { width: wWidth } = useWindowSize();
      const width = computed(() => {
        return wWidth.value - 180;
      });
      const drawerVisible = ref(false);
      watch(
        () => props.componentName,
        (val) => {
          if (val !== '') {
            drawerVisible.value = true;
          } else {
            drawerVisible.value = false;
          }
        }
      );
      watch(
        () => unref(drawerVisible),
        (val) => {
          if (!val) {
            const reloadList = appStore.drawerConfig?.reloadList;
            !isEmptyData(reloadList) && reloadList();
            appStore.SET_DRAWER_CONFIG(null);
          }
          nextTick(() => {
            cacheStore.TOGGLE_SIDEBAR_COLLAPSED(val);
          });
        }
      );
      return {
        drawerVisible,
        width,
      };
    },
  });
