/* eslint-disable camelcase */
import request from '@/utils/request';

export function send_legal_letter(data: any) {
  return request.post<null>('/api/v1/send_legal_letter', data);
}
export function get_contract_legal_letter_template(data: any) {
  return request.post<null, Recordable[]>('/api/v1/get_contract_legal_letter_template', data);
}
export function preview_legal_letter_template(data: any) {
  return request.post<null, Recordable>('/api/v1/preview_legal_letter_template', data);
}
