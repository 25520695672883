
  import { useUser } from '@/hooks/useUser';
  import { computed, defineComponent, ref } from 'vue';
  import HeaderMenuItem from './HeaderMenuItem.vue';
  import { isEmptyData } from '@/utils/common';
  import { getFlatTitlePathMenus } from '@/router/menu';
  import { MenuItem } from 'types/config';
  import { useAppInfo } from '@/hooks/useAppInfo';
  export default defineComponent({
    name: 'HeaderMenu',
    components: {
      HeaderMenuItem,
    },
    setup() {
      const {
        getFilteredHWRoutesAndMenus,
        flatMenuList,
        getAllRoutePath,
        getMatchedRouteByMenu,
        getMatchedMenuKeyByRoute,
      } = useUser();
      const { sub_app_suffix_list } = useAppInfo();
      const selectedMenuItem = ref<Recordable>({});
      const choosedMenuItem = ref<Recordable>({});
      const showDropDown = ref(false);
      const offsetLeft = ref(0);
      const offsetRight = ref(0);
      const helloHeaderDropdownStyle = computed<Recordable>(() => {
        return {
          right: offsetRight.value + 'px',
          left: offsetLeft.value + 'px',
          opacity: showDropDown.value ? 1 : 0,
          visibility: showDropDown.value ? 'visible' : 'hidden',
          height: 'max-content',
        };
      });
      return {
        sub_app_suffix_list,
        getFilteredHWRoutesAndMenus,
        showDropDown,
        selectedMenuItem,
        flatMenuList,
        choosedMenuItem,
        offsetLeft,
        offsetRight,
        helloHeaderDropdownStyle,
        getAllRoutePath,
        getMatchedRouteByMenu,
        getMatchedMenuKeyByRoute,
      };
    },
    data: () => {
      return {
        leave_time: 0,
        timeout: 200,
      };
    },
    methods: {
      isEmptyData,
      getThirdSubMenuItemClass(path: string) {
        let mount_menu_key = this.getMatchedMenuKeyByRoute();
        if (path === `/${mount_menu_key.join('/')}`) {
          return {
            'third-sub-menu-item': true,
            'third-sub-menu-item-selected': true,
          };
        }
        return {
          'third-sub-menu-item': true,
        };
      },
      handleMenuLeave() {
        this.showDropDown = false;
      },
      handleItemMouseEnter(val, offsetLeft) {
        this.offsetLeft = offsetLeft;
        this.selectedMenuItem = val;
        if (this.selectedMenuItem.hasTag) {
          const matched_menu_key = this.getMatchedMenuKeyByRoute();
          const findItem = this.selectedMenuItem.children.find((item) => {
            return `/${matched_menu_key.join('/')}`.includes(item.path);
          });
          if (isEmptyData(findItem)) {
            this.choosedMenuItem = this.selectedMenuItem.children[0];
          } else {
            this.choosedMenuItem = findItem;
          }
        } else {
          this.choosedMenuItem = this.selectedMenuItem;
        }
        this.showDropDown = true;
      },
      async handleMenuClick(menu_key: string, title?: string) {
        this.showDropDown = false;
        const route_path = this.getMatchedRouteByMenu(menu_key).route_path;
        const findTemp = getFlatTitlePathMenus(this.getFilteredHWRoutesAndMenus.menus).find(
          (item) => item.path === menu_key
        ) as MenuItem;
        if (title) {
          this.$dataFinder.pushEvent(title, { url: route_path });
        } else {
          this.$dataFinder.pushEvent(findTemp.title, { url: route_path });
        }
        const findItem = this.sub_app_suffix_list.find((item) => route_path.includes(item));
        if (!isEmptyData(findItem)) {
          await history.pushState(
            {
              ...history.state,
              ...{
                back: this.$route.fullPath,
                current: route_path.replaceAll(findItem as string, ''),
              },
            },
            '',
            route_path
          );
          if (!(this.$route.fullPath.includes(findItem as string) && route_path.includes(findItem as string))) {
            location.reload();
          }
        } else {
          this.$router.push({ path: route_path });
        }
      },
      switchRightMenu(val) {
        this.leave_time = 0;
        setTimeout(() => {
          if (this.leave_time === 0) {
            this.choosedMenuItem = val;
          }
        }, this.timeout);
      },
      handleMouseLeave() {
        this.leave_time = new Date().getTime();
      },
    },
  });
