import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const report: RouteRecordRaw = {
  path: '/report',
  name: 'Report',
  meta: { title: '报表', ignoreKeepAlive: true },
  redirect: { name: 'ReportCommon' },
  component: AppLayout,
  children: [
    {
      path: 'erp',
      name: 'ReportCommon',
      meta: { title: 'ERP', ignoreKeepAlive: true },
      redirect: { name: 'ReportCommonContract' },
    },
    {
      path: 'erp/bill',
      name: 'ReportCommonBill',
      meta: { title: '账单报表', ignoreKeepAlive: true },
      redirect: { name: 'ReportCommonBillMonthlySummary' },
    },
    {
      path: 'erp/bill/monthly-summary',
      name: 'ReportCommonBillMonthlySummary',
      meta: { title: '月度暂估 & 对账', ignoreKeepAlive: true, auth: ['HasMenu_ReportMonthlySummary'] },
      component: () => import('@/views/report/common/bill/BillReport.vue'),
    },
    {
      path: 'erp/bill/plan-to-pay',
      name: 'ReportCommonBillPlanToPay',
      meta: { title: '付款计划', ignoreKeepAlive: true, auth: ['HasMenu_ReportPlanToPay'] },
      component: () => import('@/views/report/common/bill/BillReport.vue'),
    },
    {
      path: 'erp/bill/plan-to-collect',
      name: 'ReportCommonBillPlanToCollect',
      meta: { title: '收款计划', ignoreKeepAlive: true, auth: ['HasMenu_ReportPlanToCollect'] },
      component: () => import('@/views/report/common/bill/BillReport.vue'),
    },
    {
      path: 'erp/bill/overdue-billing',
      name: 'ReportCommonBillOverdueBilling',
      meta: { title: '逾期对账', ignoreKeepAlive: true, auth: ['HasMenu_ReportOverdueBilling'] },
      component: () => import('@/views/report/common/bill/BillReport.vue'),
    },
    {
      path: 'erp/bill/overdue-collection',
      name: 'ReportCommonBillOverdueCollection',
      meta: { title: '逾期收款', ignoreKeepAlive: true, auth: ['HasMenu_ReportOverdueCollection'] },
      component: () => import('@/views/report/common/bill/BillReport.vue'),
    },

    {
      path: 'admin',
      name: 'ReportAdmin',
      meta: { title: '其它报表', ignoreKeepAlive: true },
      redirect: { name: 'ReportAdminAdmin' },
    },
    {
      path: 'admin/admin',
      name: 'ReportAdminAdmin',
      meta: { title: 'Admin报表', ignoreKeepAlive: true },
      component: () => import('@/views/common/Blank.vue'),
    },
  ],
};
export default report;
