
  import { defineComponent, reactive, ref, unref, computed } from 'vue';
  import isString from 'lodash-es/isString';
  import cloneDeep from 'lodash/cloneDeep';
  import isEqual from 'lodash-es/isEqual';
  import pick from 'lodash-es/pick';
  import { copyObjectToReactive, isEmptyData } from '@/utils/common';
  import { isDev } from '@/utils/const';
  import { appStore } from '@/store/modules/app';
  import type { DialogFormItem } from './types';
  import { basicProps } from './props';
  import WeImportExcel from '@/components/WeImportExcel/index.vue';
  import LocalUpload from '@/components/WeUpload/LocalUpload.vue';
  import BackEndOssUpload from '@/components/WeUpload/BackEndOssUpload.vue';
  import WeUpload from '@/components/WeUpload/index.vue';
  import WeEditorInput from '@/components/WeEditorInput/index.vue';

  export default defineComponent({
    name: 'WeDialog',
    props: basicProps,
    components: {
      WeImportExcel,
      LocalUpload,
      WeUpload,
      BackEndOssUpload,
      WeEditorInput,
    },
    setup(props) {
      const WeDialogForm = ref<Nullable<FormActionType>>(null);
      const cached = ref(false);

      const fileList = ref([]);
      const iconLoading = ref<boolean>(false);
      const form_item_blocks = ref<Recordable>([]);
      const dialogVisible = ref(false);
      const time = ref(30);
      const time_id = ref(-1);
      const formModel = reactive<Recordable>({});
      const cacheFormModel = reactive<Recordable>({});

      const computedFormItemBlocks = computed(() => {
        return form_item_blocks.value;
      });

      function getInit(formItem: DialogFormItem) {
        const valueField = formItem.resultValueFiled ? formItem.resultValueFiled : formItem.result;
        function getInitFromCache() {
          if (valueField && !isEmptyData(cacheFormModel[valueField])) {
            return cacheFormModel[valueField];
          }
          return null;
        }
        if (cached.value) {
          if (!isEmptyData(cacheFormModel)) {
            const key = props.key;
            //编辑
            if (props.init[key]) {
              if (props.init[key] === cacheFormModel[key]) {
                return getInitFromCache();
              }
            } else {
              //新增
              if (!cacheFormModel[key]) {
                return getInitFromCache();
              }
            }
          }
        }
        if (formItem.init !== undefined) {
          return formItem.init;
        }
        // 编辑
        if (props.init && !isEmptyData(props.init[valueField as string])) {
          return props.init[valueField as string];
        }
        if (formItem.mode === 'multiple' || formItem.mode === 'tag' || formItem.type === 'check') {
          return [];
        }
        if (formItem.type === 'a-range-picker') {
          return [];
        }

        return null;
      }
      function changeInputDisplay() {
        const temp = cloneDeep(form_item_blocks.value);
        temp.forEach((block) => {
          if (block.type === 'input') {
            block.value.forEach((item) => initInputDisplay(item));
          } else {
            initInputDisplay(block);
          }
        });
        form_item_blocks.value = temp;
      }
      function initInputDisplay(formItem: DialogFormItem) {
        if (formItem.show) {
          formItem.showElement = formItem.show(unref(formModel));
        } else if (isEmptyData(formItem.showElement)) {
          formItem.showElement = true;
        }
        if (typeof formItem.disabled === 'function') {
          formItem.elementDisabled = formItem.disabled(unref(formModel));
        } else {
          formItem.elementDisabled = formItem.disabled;
        }
        if (typeof formItem.mode === 'function') {
          formItem.mode = formItem.mode(unref(formModel));
        }
      }
      function initBlocks() {
        const config = cloneDeep(props.blocks);
        config?.forEach((block) => {
          if (block.type === 'json') {
            block.type = 'tip';
            block.value = JSON.stringify(block.value, null, 2);
            block.style =
              'white-space: pre-wrap; font-family: monospace; height: 260px; overflow: auto; border: solid 1px #ddd; padding: 5px;';
          }
        });
        config?.forEach((block: DialogFormItem) => {
          if (block.type === 'input') {
            block.value.forEach((input) => {
              formModel[input.result] = getInit(input);
              initRules(input);
              if (isEmptyData(input.width)) {
                input.width = 24;
              }
            });
          } else if (block.type === 'radio') {
            let itemInit = null;
            block.value.forEach((radio) => {
              if (radio.itemInit) {
                itemInit = radio.value;
              }
            });
            const blockInit = typeof block.init !== 'undefined' ? block.init : null;
            const configInit = props.init ? props.init[block.result as string] : null;
            formModel[block.result as string] = itemInit || blockInit || configInit;
            initRules(block);
          } else if (block.type === 'check') {
            let init: any = [];
            block.value.forEach((check) => {
              if (check.init) {
                init.push(check.value);
              }
              check.width = check.width ? check.width : '200px';
              check.style = `margin: 5px 0 -15px 0 !important; width: ${check.width};`;
            });
            init = typeof block.init != 'undefined' ? block.init : init;
            if (block.cleanInit) {
              const cleanedInit: any = [];
              init.forEach((i) => {
                let keep = false;
                block.value.forEach((c) => {
                  if (isEqual(i, c.value)) {
                    keep = true;
                  }
                });
                if (keep) {
                  cleanedInit.push(i);
                }
              });
              init = cleanedInit;
            }
            formModel[block.result as string] = props.init ? props.init[block.result as string] : init;
            initRules(block);
          } else if (block.type === 'note') {
            formModel[block.result as string] = getInit(block);
            initRules(block);
          } else if (block.type === 'excel') {
            const init = block.init || block.init === '' ? block.init : null;
            formModel[block.result as string] = props.init ? props.init[block.result as string] : init;
          } else if (
            block.type === 'localfile' ||
            block.type === 'file' ||
            block.type === 'localFile' ||
            block.type === 'backend_oss_upload'
          ) {
            const init = block.init || block.init === '' ? block.init : null;
            formModel[block.result as string] = props.init ? props.init[block.result as string] : init;
            initRules(block);
          }
          if (isEmptyData(block.width) && !['input', 'note'].includes(block.type!)) {
            block.width = 24;
          }
        });
        form_item_blocks.value = config!;
        changeInputDisplay();
      }
      function initRules(item) {
        const rules = item.rules;
        if (isEmptyData(rules)) {
          return;
        }
        rules.forEach((rule, index) => {
          if (typeof rule === 'function') {
            rules[index] = rule(formModel);
          }
        });
        item.rules = rules;
      }
      return {
        isString,
        isEmptyData,
        initBlocks,
        computedFormItemBlocks,
        dialogVisible,
        WeDialogForm,
        form_item_blocks,
        formModel,
        cacheFormModel,
        changeInputDisplay,
        iconLoading,
        fileList,
        cached,
        time,
        time_id,
      };
    },
    methods: {
      refresh_config(config) {
        this.form_item_blocks = config;
      },
      inputChange(formItem: DialogFormItem) {
        if (formItem.change) {
          formItem.change(this.formModel, formItem, this.form_item_blocks, this);
        }
      },
      handleSelect(formItem: DialogFormItem) {
        if (formItem.select) {
          formItem.select(this.formModel, formItem, this.form_item_blocks, this);
        }
      },
      handleUploadChange(info, formItem) {
        if (info.file.status === 'done') {
          this.formModel[formItem.result] = info.file.response.data || info.file.response;
          this.$message.success(`${info.file.name}上传成功`);
        } else if (info.file.status === 'error') {
          this.$message.error(`${info.file.name}上传失败`);
        }
      },
      btnClick(btn) {
        if (btn.callback) {
          btn.callback(this);
        }
      },
      cancelClick() {
        if (typeof this.cancelStop === 'function') {
          this.cancelStop(this.formModel, this);
        } else if (typeof this.cancel === 'function') {
          this.cancel(this.formModel, this);
          appStore.SET_WE_DIALOG(null);
        } else {
          appStore.SET_WE_DIALOG(null);
        }
      },
      afterClose() {
        Reflect.ownKeys(this.formModel).forEach((key: string) => {
          delete this.formModel[key];
        });
        this.form_item_blocks = [];
        this.WeDialogForm?.clearValidate();
        if (this.cached) {
          if (this.time_id !== -1) {
            clearTimeout(this.time_id);
            this.time_id = setTimeout(() => {
              this.resetDialog();
            }, this.time * 1000);
          } else {
            this.time_id = setTimeout(() => {
              this.resetDialog();
            }, this.time * 1000);
          }
        }
        if (typeof this.cancelStop === 'function') {
          this.cancelStop(this.formModel, this);
        } else if (typeof this.cancel === 'function') {
          appStore.SET_WE_DIALOG(null);
        } else {
          appStore.SET_WE_DIALOG(null);
        }
      },
      resetDialog() {
        Reflect.ownKeys(this.cacheFormModel).forEach((key: string) => {
          delete this.cacheFormModel[key];
        });
      },
      storageFormModel() {
        this.resetDialog();
        copyObjectToReactive(this.cacheFormModel, { ...this.init, ...this.formModel });
      },
      async save() {
        this.resetDialog();
        this.cached = false;
        await this.WeDialogForm?.validate();
        if (!isDev) {
          this.iconLoading = true;
        }
        const save_items: any[] = [];
        this.form_item_blocks.forEach((i: Recordable) => {
          if (isEmptyData(i.value)) {
            if (i.showElement) {
              save_items.push(i.result);
            }
          } else if (i.type === 'input') {
            i.value.forEach((v) => {
              if (v.showElement) {
                save_items.push(v.result);
              }
            });
          }
        });
        this.callback(pick(this.formModel, save_items), this);
      },
    },
    watch: {
      formModel: {
        handler(val) {
          if (!isEmptyData(val)) {
            this.storageFormModel();
          }
          this.changeInputDisplay();
          if (this.change) {
            this.change(val);
          }
        },
        deep: true,
        immediate: true,
      },
      title: {
        handler(val) {
          if (val) {
            this.dialogVisible = true;
            this.iconLoading = false;
            if (this.needCache) {
              this.cached = true;
            } else {
              this.cached = false;
            }
            this.initBlocks();
          } else {
            this.dialogVisible = false;
          }
        },
        immediate: true,
      },
    },
  });
