import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isDev, isLocal, isTest } from './const';
import { removeCookie } from './cookies';

const HW4_VERSION = 385;

const service = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_API,
  timeout: 60000,
  withCredentials: true,
});
/**
 * @description: 请求之前的拦截器
 */
const requestInterceptor = (config: AxiosRequestConfig) => {
  if (config.url?.includes('meta_api')) {
    config.headers['token'] = '2e2e954f-7ed5-4a9b-b1ae-8230dab94c80';
  }
  if (isDev || isLocal) {
    config.headers['DEBUG-API-TOKEN'] = '4650e366-dbd3-11eb-9747-e0accb64ac84';
    const employee_id = localStorage.getItem('employee_id') || 'g4b81b61';
    config.headers['USER-EMPLOYEE-ID'] = employee_id;
  }
  if (config.method === 'post') {
    config.data = {
      hello_we_version: isTest || isDev ? 1000000 : HW4_VERSION,
      ...config.data,
    };
  }

  return config;
};
/**
 * @description: 请求之前的拦截器错误处理
 */
const requestInterceptorCatch = (error: Error) => {
  Promise.reject(error);
};
/**
 * @description: 请求之后的拦截器
 */
const responseInterceptor = (response: AxiosResponse<any>) => {
  // removePendingRequest(response.config); // 从pendingRequest对象中移除请求
  if (response.data.status === 700) {
    removeCookie('sessionid');
    const login_url = `${window.location.origin.replace('localhost', '127.0.0.1')}/login?redirect=${
      window.location.pathname + window.location.search
    }`;
    window.location.href = login_url;
    return Promise.resolve<any>(response.data.data || response.data);
  }
  if (response.data.status === 600) {
    location.reload();
    return Promise.resolve<any>(response.data.data || response.data);
  }
  if (
    response.data.success ||
    response.data.code === 0 ||
    response.data.error_code === 0 ||
    response.data.status === 200 ||
    response.status === 200
  ) {
    if (response.data.code === 500 || JSON.stringify(response.data.success) === 'false') {
      return Promise.resolve<any>(response.data);
    } else {
      return Promise.resolve<any>(response.data.data || response.data);
    }
  }

  const errMsg = response.data.msg || response.data.message;
  return Promise.reject<any>(new Error(errMsg));
};
/**
 * @description: 请求之后的拦截器错误处理
 */
const responseInterceptorCatch = (error: any) => {
  try {
    // removePendingRequest(error.config || {}); // 从pendingRequest对象中移除请求
  } catch (error) {
    throw new Error(error);
  }
  return Promise.reject(error);
};

service.interceptors.request.use(requestInterceptor, requestInterceptorCatch);
service.interceptors.response.use(responseInterceptor, responseInterceptorCatch);
export default service;
