import { commonStore } from '@/store/modules/commonLists';
import { isEmptyData } from '@/utils/common';
import { computed, unref } from 'vue';
import { useAppInfo } from './useAppInfo';

export function useOptionList() {
  const { getModelItems, model } = useAppInfo();
  const getCountryList = computed(() => {
    return commonStore.countries;
  });
  const getOpUserList = computed(() => {
    return commonStore.opUserList;
  });
  const getIndustrySubList = computed(() => {
    return commonStore.industrySubList;
  });
  const get_ml_platform = computed(() => {
    return commonStore.ml_platform;
  });
  const get_ml_delivery_method = computed(() => {
    return getModelItems(unref(model)?.MlApply.DELIVERY_METHOD);
  });
  const get_ml_business = computed(() => {
    return commonStore.ml_business;
  });
  const get_bx_platform = computed(() => {
    return commonStore.bx_platform;
  });
  const get_mcc_account_list = computed(() => {
    return commonStore.mcc_account_list.map((item) => {
      if (isEmptyData(item.client_customer_id)) {
        return item;
      } else {
        return {
          id: item.id,
          name: `${item.name}(${item.client_customer_id})`,
        };
      }
    });
  });
  const get_chiefs = computed(() => {
    return commonStore.chiefs;
  });
  // 获取PMAMBD
  const get_pmambd = computed(() => {
    return commonStore.pm_am_bd;
  });
  /**
   * 付款方式
   */
  const pay_method_items = getModelItems(unref(model).Pay.METHOD);
  const get_double_sign_items = computed(() => {
    return [
      { id: 1, name: '是' },
      { id: 2, name: '否' },
      { id: 3, name: '不需要双签' },
    ];
  });
  /**
   * 地区
   */
  const region_type_list = computed(() => {
    return getModelItems(unref(model)?.Buline.REGION_TYPE);
  });
  return {
    getCountryList,
    getOpUserList,
    getIndustrySubList,
    get_ml_platform,
    get_ml_delivery_method,
    get_ml_business,
    get_bx_platform,
    get_mcc_account_list,
    get_chiefs,
    get_pmambd,
    pay_method_items,
    get_double_sign_items,
    region_type_list,
  };
}
