import { MenuItem } from 'types/config';

const erp: MenuItem = {
  path: 'erp',
  title: 'ERP',
  icon: '',
  expandAll: true,
  is_root: true,
  hasTag: false,
  children: [
    {
      path: 'contracts',
      title: '合同管理',
      icon: 'WE_DocStampOutlined',
      children: [
        { path: 'supply', title: '业务采购合同' },
        { path: 'demand', title: '销售合同' },
        { path: 'purchase', title: '非业务采购合同' },
        { path: 'backup', title: '备份类合同' },
        { path: 'fs-template', title: '飞书合同模板分类' },
        { path: 'archive', title: '合同存档' },
      ],
    },
    {
      path: 'bills',
      title: '账单管理',
      icon: 'WE_BillsOutlined',
      children: [
        { path: 'supply', title: '成本账单' },
        { path: 'demand', title: '收入账单' },
        { path: 'bill-booking', title: '记账' },
      ],
    },
    {
      path: 'pays',
      title: '收付款管理',
      icon: 'WE_RMBSyncDotsOutlined',
      children: [
        { path: 'supply', title: '业务付款' },
        { path: 'demand', title: '收款' },
        { path: 'purchase', title: '非业务付款-有合同' },
        { path: 'direct', title: '非业务付款-无合同' },
      ],
    },
    {
      path: 'invoices',
      title: '发票管理',
      icon: 'WE_InvoiceOutlined',
      children: [
        { path: 'supply', title: '业务成本发票' },
        { path: 'demand', title: '销售发票' },
        { path: 'purchase', title: '非业务支出发票' },
        { path: 'template', title: '发票模板配置' },
        { path: 'zip', title: '发票下载结果页' },
      ],
    },
  ],
};
export default erp;
