
  import { attach_product, edit_products_group, edit_product_category_item, remove_product } from '@/api/meta';
  import { get_product_list } from '@/api/more';
  import { BasicColumn, BasicTableProps, useTable } from '@/components/WeTable';
  import { useJump } from '@/hooks/useJump';
  import { appStore } from '@/store/modules/app';
  import { isEmptyData } from '@/utils/common';
  import groupBy from 'lodash-es/groupBy';
  import cloneDeep from 'lodash/cloneDeep';
  import { defineComponent, ref, reactive, computed } from 'vue';
  export default defineComponent({
    name: 'MetaProductList',
    props: {
      products: {
        type: Array as PropType<Recordable[]>,
        default: () => [],
      },
      entity: {
        type: Object,
        default: () => {},
      },
      entityType: {
        type: String,
        default: '',
      },
      canEdit: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['updateList'],
    setup(props) {
      const edit_products_category_visible = ref(false);
      const edit_product_category_visible = ref(false);
      const products_category_form = reactive<Recordable>({
        // category_id: undefined,
        // category_name: '',
        name_group: '',
        product_ids: [],
      });
      const product_category_name_list = ref<Recordable[]>([{ value: '类名1' }, { value: '类名2' }]);
      const product_category_list = ref<Recordable[]>([
        { name: '类名1', id: 1, edit: false },
        { name: '类名2', id: 2, edit: false },
      ]);
      const product_list = computed(() => {
        return props.products.map((item) => ({ id: item.id, name: item.name }));
      });
      const data_source = computed(() => {
        const product_list = cloneDeep(props.products);
        let has_classified = product_list.some((item) => !isEmptyData(item.category_name));
        if (has_classified) {
          let grouped_product_list = groupBy(product_list, 'category_name');
          Reflect.ownKeys(grouped_product_list).forEach((item: string) => {
            if (isEmptyData(item)) {
              grouped_product_list[item].map((m) => ({ ...m, category_name: '其它' }));
              const first_grouped_product_item = grouped_product_list[item][0];
              grouped_product_list[item].unshift({
                name: '',
                category_name: first_grouped_product_item.category_name,
                code: '',
                rd_platform_name: '',
                platform_id: '',
                rd_status_name: '',
                rd_pm_name: '',
                rd_sales_name: '',
                rd_am_name: '',
              });
            }
            grouped_product_list[item].unshift({});
          });
        }
        return [];
      });
      const filterOption = (input: string, option: Recordable) => {
        return option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0;
      };
      const { jumpToProduct } = useJump();
      const [register, { getSelectRows, clearSelectedRowKeys }] = useTable();
      return {
        edit_products_category_visible,
        edit_product_category_visible,
        product_category_list,
        product_category_name_list,
        products_category_form,
        product_list,
        data_source,
        register,
        getSelectRows,
        jumpToProduct,
        filterOption,
        clearSelectedRowKeys,
      };
    },
    computed: {
      isCompany(): boolean {
        return this.entityType === 'Company';
      },
      companyId(): number {
        return this.isCompany ? this.entity.id : this.entity.company_id;
      },
      companyName(): string {
        return this.isCompany ? this.entity.name : this.entity.rd_company_name;
      },
      tableConfig(): BasicTableProps {
        return {
          columns: [
            {
              title: 'Name',
              dataIndex: 'name',
              slots: { customRender: 'name' },
            },
            {
              title: '类名',
              dataIndex: 'name_group',
            },
            {
              title: 'Code',
              dataIndex: 'code',
            },
            {
              title: 'Platform',
              dataIndex: 'rd_platform_name',
            },
            {
              title: 'Package/AppID',
              dataIndex: 'platform_id',
            },
            {
              title: 'Status',
              dataIndex: 'rd_status_name',
            },
            {
              title: 'PM',
              dataIndex: 'rd_pm_name',
            },
            {
              title: 'BD',
              dataIndex: 'rd_sales_name',
            },
            {
              title: 'AM',
              dataIndex: 'rd_am_name',
            },
          ] as BasicColumn[],
          actionColumn: this.canEdit
            ? {
                width: 120,
                title: '操作',
                dataIndex: 'action',
                slots: { customRender: 'action' },
              }
            : undefined,
          immediate: false,
          title: '合作产品',
          rowSelection: { type: 'checkbox' },
        };
      },
    },
    methods: {
      async handle_category_id_change(val) {
        const find_item = this.product_category_list.find((item) => item.name === val);
        if (isEmptyData(find_item)) {
          const list = await edit_product_category_item({ name: val, contract_id: this.entity.id });
          this.product_category_list = list.map((item) => ({ ...item, edit: false }));
          this.product_category_name_list = list.map((item) => {
            item.name;
          });
          this.products_category_form.category_id = list.find((f) => f.name === val).id;
        } else {
          this.products_category_form.category_id = find_item!.id;
        }
      },
      handleTableActions(record: any) {
        return [
          {
            label: 'Remove',
            show: true,
            onClick: this.removeProduct.bind(null, record),
          },
        ];
      },
      handle_edit_products_group() {
        edit_products_group(this.products_category_form).then(() => {
          this.clearSelectedRowKeys();
          this.edit_products_category_visible = false;
          this.afterEditProduct();
        });
      },
      async attachProduct() {
        const vm = this;
        const entityType = this.entityType;
        const entityId = this.entity.id;

        const bulineProductItems: any = await new Promise((re) => {
          get_product_list({ get_all: true, company_id: this.entity.company_id }).then((res) => {
            re(res.product_list.map((u) => ({ text: u.code + ': ' + u.name, value: u.id })));
          });
        });

        const existProductIds = vm.products.map((x: any) => x.id);
        const otherProductItems = bulineProductItems.filter((x) => !existProductIds.includes(x.value));

        const dialogConfig = {
          title: 'Attach Product',
          width: '400px',
          labelCol: '40px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'auto-select',
                  mode: 'multiple',
                  label: '产品',
                  result: 'product_ids',
                  items: otherProductItems,
                  width: 24,
                },
              ],
            },
          ],
          callback: (result) => {
            attach_product({ entity_type: entityType, entity_id: entityId, data: result }).then(() => {
              vm.$emit('updateList');
              appStore.SET_WE_DIALOG(null);
            });
          },
        };

        this.$showDialog(dialogConfig);
      },
      removeProduct(product) {
        const vm = this;
        vm.$showAlert('Warning', 'Are you sure to REMOVE this Product?', () => {
          remove_product({
            id: product.id,
            entity_type: vm.entityType,
            entity_id: vm.entity.id,
          }).then(() => {
            vm.$emit('updateList');
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      edit_product_category(record, type) {
        const is_delete = type === 'delete';
        const params = {
          id: record.id,
          name: record.label,
          contract_id: record.contract_id,
          is_deleted: is_delete,
        };
        edit_product_category_item(params);
      },
      edit_products_group() {
        const selected_rows = this.getSelectRows();
        if (!isEmptyData(selected_rows)) {
          this.products_category_form.product_ids = selected_rows.map((item) => item.id);
        }
        this.edit_products_category_visible = true;
      },
      afterEditProduct() {
        this.$emit('updateList');
      },
    },
  });
