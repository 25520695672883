
  import { useUser } from '@/hooks/useUser';
  import { formatMenu } from '@/router/menu';
  import { urlToList } from '@/utils/page';
  import { computed, defineComponent, provide, ref } from 'vue';
  import { useRoute } from 'vue-router';
  export default defineComponent({
    name: 'PageWrapper',
    props: {
      pageWrapperClass: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
      showTitle: {
        type: Boolean,
        default: false,
      },
      titleType: {
        type: String as PropType<'text' | 'breadcrumb' | 'slot'>,
        default: 'text',
      },
      drop_down_menus: {
        type: Array as PropType<{ label: string; onClick: Fn }[]>,
        default: () => {
          return [];
        },
      },
    },
    setup(props) {
      const pageWrapperEL = ref<HTMLDivElement>();
      const route = useRoute();
      const breadItems = ref<Recordable[]>([]);
      const { getFilteredHWRoutesAndMenus } = useUser();
      const computedTitle = computed<String>(() => {
        if (props.title !== '') {
          return props.title;
        } else if (props.titleType === 'text') {
          if (route.meta.title) {
            return route.meta.title as string;
          }
        }
        return '';
      });
      provide('is_in_page_detail', false);
      return {
        pageWrapperEL,
        computedTitle,
        getFilteredHWRoutesAndMenus,
        breadItems,
      };
    },
    created() {
      this.getRouteBreadcrumb();
    },
    methods: {
      getRouteBreadcrumb() {
        let items: Recordable[] = [];
        const path = this.$route.path;
        const urls = urlToList(path);
        const getFormatMenu = formatMenu(this.getFilteredHWRoutesAndMenus.menus);
        items = getFormatMenu.filter((item) => urls.includes(item.path));
        this.breadItems = items;
      },
    },
  });
