
  import { defineComponent } from 'vue';
  import { removeCookie } from '@/utils/cookies';
  import { loginOut, editProfile } from '@/api/app';
  import { appStore } from '@/store/modules/app';
  import { required } from '@/utils/validate';
  import { isEmptyData } from '@/utils/common';
  export default defineComponent({
    name: 'HeaderDropDown',
    props: {
      avatarUrl: {
        type: String,
        required: true,
        default: '',
      },
      userName: {
        type: String,
        required: true,
        default: '',
      },
    },
    methods: {
      menuClick({ key }: { key: string }) {
        switch (key) {
          case 'logout':
            this.handleLogout();
            break;
          case 'password':
            this.changePassword();
            break;
          default:
            break;
        }
      },
      handleLogout() {
        removeCookie('sessionid');
        loginOut()
          .then(() => {
            this.$router.push({ name: 'Login' });
          })
          .catch(() => {
            this.$router.push({ name: 'Login' });
          });
      },
      changePassword() {
        const passwordValid = { password: '', password_again: '' };
        this.$showDialog({
          title: '修改密码',
          width: '600px',
          labelCol: '80px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  type: 'password',
                  label: '密码',
                  result: 'password',
                  width: 24,
                  rules: [required()],
                  change: async (result, _input, _config, context) => {
                    passwordValid.password = result.password;
                    context.WeDialogForm?.validate();
                  },
                },
                {
                  type: 'password',
                  label: '确认密码',
                  result: 'password_again',
                  width: 24,
                  rules: [
                    required(),
                    {
                      validator: async (_rule, val: any) => {
                        passwordValid.password_again = val;
                        if (!isEmptyData(passwordValid.password_again) && !isEmptyData(passwordValid.password)) {
                          if (passwordValid.password_again == passwordValid.password) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject('密码不一致，请重新输入！');
                          }
                        }
                      },
                    },
                  ],
                  change: async (result, _input, _config, context) => {
                    passwordValid.password_again = result.password_again;
                    context.WeDialogForm?.validate();
                  },
                },
              ],
            },
          ],
          callback: (result) => {
            editProfile(result).then(() => {
              appStore.SET_WE_DIALOG(null);
              this.$message.success('密码修改成功');
            });
          },
        });
      },
    },
  });
