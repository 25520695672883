
  import { computed, defineComponent, unref } from 'vue';
  import HeaderMenu from './components/HeaderMenu.vue';
  import HeaderDropDown from './components/HeaderDropDown.vue';
  import GlobalSearch from '@/components/GlobalSearch/index.vue';
  import { useUser } from '@/hooks/useUser';
  import { isProd, isLocal } from '@/utils/const';
  import { useJump } from '@/hooks/useJump';
  export default defineComponent({
    name: 'AppLayoutHeader',
    setup() {
      const { userInfo } = useUser();
      const { backToHome } = useJump();
      const lark_avatar = computed(() => {
        return unref(userInfo)?.lark_avatar;
      });

      const name = computed(() => {
        return unref(userInfo)?.name;
      });
      return {
        lark_avatar,
        name,
        backToHome,
      };
    },
    components: {
      HeaderMenu,
      HeaderDropDown,
      GlobalSearch,
    },
    data: () => ({ globalSearchVisible: false, isProd, isLocal }),
  });
