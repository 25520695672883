
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { BasicColumn, BasicTableProps } from '@/components/WeTable';
  import { computed, defineComponent, ref } from 'vue';
  import { isEmptyData, getTextFromValue, formatNumberByNumeral } from '@/utils/common';
  import {
    cancel_credit_apply,
    can_open_credit_flow,
    credit_apply_to_fbp_review_done,
    get_credit_item,
    reopen_credit_flow,
    notify_bd_to_follow_contract,
    edit_final_approval_limit,
  } from '@/api/ugs-reseller';
  import { useOptionList } from '@/hooks/useOptionList';
  import { useJump } from '@/hooks/useJump';
  import dayjs from 'dayjs';
  import { useUser } from '@/hooks/useUser';
  import { useReseller } from './hooks/useReseller';
  import { appStore } from '@/store/modules/app';
  import { required } from '@/utils/validate';
  import { cloneDeep } from 'lodash-es';

  export default defineComponent({
    name: 'UGSResellerCreditDetail',
    props: {
      id: {
        type: [Number, String],
        default: -1,
      },
    },
    setup() {
      const detail_data = ref<Recordable>({});
      const bill_amount_history = computed(() => {
        if (!isEmptyData(detail_data)) {
          return detail_data.value.bill_history;
        }
        return [];
      });
      const bill_apply_history = computed(() => {
        if (!isEmptyData(detail_data)) {
          return detail_data.value.credit_history;
        }
        return [];
      });
      const company_info = computed(() => {
        if (!isEmptyData(detail_data)) {
          const detail_data_copy = cloneDeep(detail_data.value);
          if (
            !isEmptyData(detail_data_copy.company_info) &&
            isEmptyData(detail_data_copy.company_info.gm_review_exceed_amount)
          ) {
            detail_data_copy.company_info.gm_review_exceed_amount = 200000;
          }
          return detail_data_copy.company_info;
        }
        return {};
      });
      const credit_info = computed(() => {
        if (!isEmptyData(detail_data)) {
          return detail_data.value.credit_info;
        }
        return {};
      });
      const flow = computed(() => {
        if (!isEmptyData(detail_data) && !isEmptyData(detail_data.value.flow)) {
          return detail_data.value.flow;
        }
        return {};
      });
      const { get_double_sign_items } = useOptionList();
      const { jumpToContract, jumpToCompany } = useJump();
      const { user } = useUser();
      const { createOrEditApply } = useReseller();
      return {
        detail_data,
        bill_amount_history,
        bill_apply_history,
        company_info,
        credit_info,
        get_double_sign_items,
        jumpToContract,
        user,
        flow,
        createOrEditApply,
        jumpToCompany,
      };
    },
    data: () => ({
      editMode: false,
    }),
    computed: {
      title() {
        if (isEmptyData(this.credit_info)) {
          return '授信详情';
        } else {
          return this.credit_info.apply_name || '';
        }
      },
      get_drop_down_menus(): Recordable[] {
        const ret: Recordable[] = [
          {
            show: this.canShowReopen,
            label: 'Reopen',
            onClick: () => this.reOpen(),
          },
        ];
        return ret.filter((x) => x.show);
      },
      history_aomunt_table_config(): BasicTableProps {
        const columns: BasicColumn[] = [
          { title: 'Team', dataIndex: 'rd_team_name' },
          { title: 'Month', dataIndex: 'month' },
          { title: 'Invoice Amount', dataIndex: 'invoice_money', componentType: 'we-number-range', round: 2 },
          { title: '回款DeadLine', dataIndex: 'rd_pay_deadline' },
          { title: '最后一笔款项到账日期', dataIndex: 'rd_pay_time' },
          {
            title: '逾期天数（按收款数加权）',
            dataIndex: '',
            format: (_text, record) => {
              if (!isEmptyData(record.rd_pay_time) && !isEmptyData(record.rd_pay_deadline)) {
                return dayjs(record.rd_pay_time).diff(dayjs(record.rd_pay_deadline), 'days');
              } else {
                return '-';
              }
            },
          },
        ];
        return {
          useSearchForm: false,
          showTableHeader: false,
          columns,
        };
      },
      history_apply_table_config(): BasicTableProps {
        const columns: BasicColumn[] = [
          { title: '申请日期', dataIndex: 'apply_time', width: '40%' },
          // { title: '申请时Normal额度', dataIndex: 'credit_limit', componentType: 'we-number-range', round: 2 },
          // { title: '申请时授信余额', dataIndex: 'left_credit_limit', componentType: 'we-number-range', round: 2 },
          {
            title: '申请Normal额度',
            dataIndex: 'apply_limit_amount',
            componentType: 'we-number-range',
            round: 2,
            width: '15%',
          },
          {
            title: '最终审批额度',
            dataIndex: 'normal_limit_amount',
            componentType: 'we-number-range',
            round: 2,
            width: '45%',
          },
        ];
        return {
          columns,
          useSearchForm: false,
          showTableHeader: false,
        };
      },
      canCancel() {
        return this.user.id === this.credit_info.owner_id;
      },
      canShowReopen(): boolean {
        const state = this.flow.current_state;
        return state && state.key === 'Done';
      },
    },
    methods: {
      isEmptyData,
      getTextFromValue,
      formatNumberByNumeral,
      /**
       * 加载数据
       */
      initData() {
        if (Number(this.id) === -1) {
          return;
        }
        get_credit_item({ id: this.id }).then((res) => {
          this.detail_data = res;
        });
      },
      /**
       * 审核通过或拒绝
       */
      auditPassOrNot(type = 'pass') {
        let isPass = type === 'pass';
        const title = isPass ? '审核通过' : '审核拒绝';
        const inputs: DialogFormItem[] = [
          {
            type: 'note',
            label: '审核意见',
            result: 'comment',
            width: 24,
            placeholder: '请在这里留言',
          },
        ];
        const dialogConfig: DialogConfig = {
          title,
          blocks: [{ type: 'input', value: inputs }],
        };
        this.$showDialog(dialogConfig);
      },
      /**
       * 前端自定义工作流功能
       */
      catchFlowFlag(flag) {
        if (flag === 'EDIT_FINAL_APPROVAL_LIMIT') {
          this.edit_final_limit();
        }
        if (flag === 'APPLY_TO_FBP_REVIEW') {
          this.submitApply({
            apply_limit_amount: this.credit_info.apply_limit_amount,
          });
        }
        if ('NOTIFY_BD_TRACKING_CONTRACT' === flag) {
          this.notifyBD();
        }
      },
      selectPointAll(riskList) {
        this.$showAlert('风险列表', {
          config: {
            title: '风险列表',
            columns: [
              {
                title: '风险点通道',
                dataIndex: 'channel_name',
                componentType: 'a-select',
                colorField: 'risk_color',
                showTop: true,
                slots: {
                  customRender: 'channel_name',
                },
              },
              {
                title: '风险详情',
                dataIndex: 'title',
                componentType: 'a-select',
                showTop: true,
              },
              {
                title: '风险描述',
                dataIndex: 'description',
                componentType: 'a-input',
              },
            ],
          },
          items: riskList,
        });
      },
      /**
       * 终止
       */
      async cancelWorkflow() {
        await this.$confirm('请确认终止该授信吗', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'confirm',
        }).catch(() => {
          throw new Error('cancel');
        });
        cancel_credit_apply({ id: this.id })
          .then(() => {
            this.$message.success('操作成功');
            this.initData();
          })
          .catch(() => {
            this.$message.error('操作失败');
          });
      },
      /**
       * 重启
       */
      reOpen() {
        can_open_credit_flow({ id: this.id })
          .then((res) => {
            if (res.can_flow) {
              reopen_credit_flow({ id: this.id }).then(() => {
                this.$message.success('操作成功');
                this.initData();
              });
            } else {
              this.$message.error(res.error_message);
            }
          })
          .catch(() => {
            this.$message.error('操作失败');
          });
      },
      /**
       * 提交申请
       */
      submitApply(record) {
        const params: Recordable = { id: this.id };
        const blocks: DialogFormItem[] = [
          {
            type: 'input',
            value: [
              {
                type: 'number',
                label: '申请Normal额度',
                result: 'apply_limit_amount',
                rules: [required()],
                componentProps: {
                  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
                },
                width: 24,
              },
            ],
          },
        ];
        const dialog_config: DialogConfig = {
          title: '注意',
          width: '600px',
          layout: 'vertical',
          init: record,
          blocks,
          okText: '继续提交',
          callback: (result) => {
            params.note = result.note;
            params.apply_limit_amount = result.apply_limit_amount;
            credit_apply_to_fbp_review_done(params).then(() => {
              this.$message.success('操作成功');
              appStore.SET_WE_DIALOG(null);
              this.initData();
            });
          },
        };
        this.$showDialog(dialog_config);
      },
      /**
       * 通知BD跟进合同
       */
      notifyBD() {
        const params = {
          id: this.id,
          owner_id: this.credit_info.owner_id,
          rd_company_name: this.credit_info.rd_company_name,
          rd_owner_name: this.credit_info.rd_owner_name,
          apply_name: this.credit_info.apply_name,
          company_id: this.credit_info.company_id,
        };
        notify_bd_to_follow_contract(params)
          .then(() => this.$message.success('操作成功'))
          .catch(() => this.$message.error('操作失败'));
      },
      /**
       * 填写最终审批额度
       */
      edit_final_limit() {
        const record = {
          id: this.id,
          normal_limit_amount: this.credit_info.apply_limit_amount,
        };
        const inputs: DialogFormItem[] = [
          {
            type: 'number',
            label: '最终审批额度',
            result: 'normal_limit_amount',
            rules: [required()],
            componentProps: {
              formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              parser: (value) => value.replace(/\$\s?|(,*)/g, ''),
            },
            width: 24,
          },
        ];
        const dialog_config: DialogConfig = {
          title: '填写最终审批额度',
          width: '600px',
          init: record,
          blocks: [
            { type: 'input', value: inputs },
            { type: 'note', width: 24, label: '其它审批意见', result: 'review_note' },
          ],
          callback: (result) => {
            edit_final_approval_limit({ ...result, id: record.id }).then(() => {
              this.$message.success('保存成功');
              appStore.SET_WE_DIALOG(null);
              appStore.SET_ALERT_CONFIG(null);
              this.initData();
            });
          },
          okText: '确定',
        };
        this.$showDialog(dialog_config);
      },
    },
    created() {
      this.initData();
    },
  });
