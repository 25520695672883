import request from '@/utils/request';
export function delete_pay_cat_1(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_pay_cat_1', params);
}
export function edit_pay_cat_1(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_pay_cat_1', params);
}
export function edit_pay_cat_2(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_pay_cat_2', params);
}
export function delete_pay_cat_2(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_pay_cat_2', params);
}
export function get_book_batch_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_book_batch_list', params);
}
export function get_book_changing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_book_changing', params);
}
export function get_company_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_company_code', params);
}
export function check_dup_company_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/check_dup_company_code', params);
}
export function get_supply_subject_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_supply_subject_code', params);
}
export function get_demand_subject_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_demand_subject_code', params);
}
export function get_subject_china_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_subject_china_code', params);
}
export function delete_book_batch(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_book_batch', params);
}
export function get_book_item_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_book_item_list', params);
}
export function add_book_batch(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_book_batch', params);
}
export function import_demand_subject_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_demand_subject_code', params);
}
export function import_supply_subject_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_supply_subject_code', params);
}
export function import_buline_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_buline_code', params);
}
export function import_tax_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_tax_code', params);
}
export function import_company_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_company_code', params);
}
export function import_subject_china(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_subject_china', params);
}
export function set_book_changing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/set_book_changing', params);
}
export function clear_acct_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/clear_acct_code', params);
}
export function get_media_billing_list(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_media_billing_list', params);
}
export function get_product_billing_default_map(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_product_billing_default_map', params);
}
export function run_media_billing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/run_media_billing', params);
}
export function delete_media_billing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_media_billing', params);
}
export function upload_product_billing_default_map(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_product_billing_default_map', params);
}
export function get_tax_code(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_tax_code', params);
}
export function get_bill_email_template_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_email_template_list', params);
}
export function get_model_file_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_model_file_list', params);
}
export function upload_model_file(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_model_file', params);
}
export function delete_model_file(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_model_file', params);
}

// 获取接口进度
export function get_user_operation_log(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_user_operation_log', params);
}
