import request from '@/utils/request';
export function edit_invoice(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_invoice', params);
}
export function delete_invoice(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_invoice', params);
}
export function get_invoice_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_invoice_item', params);
}
export function get_invoice_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_invoice_page', params);
}
export function get_invoice_link_bill_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_invoice_link_bill_list', params);
}
export function link_bill_for_invoice(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/link_bill_for_invoice', params);
}
export function get_link_file_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_link_file_list', params);
}
export function link_bill_files_for_invoice(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/link_bill_files_for_invoice', params);
}
export function add_pdf(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_pdf', params);
}
export function re_gen_invoice_pdf(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/re_gen_invoice_pdf', params);
}
export function change_invoice_state(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/change_invoice_state', params);
}
export function assign_invoice_to_me(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_invoice_to_me', params);
}
export function upload_invoices(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_invoices', params);
}
export function get_invoice_template_list(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_invoice_template_list', params);
}
export function edit_invoice_template(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_invoice_template', params);
}
export function get_invoice_template_detail(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_invoice_template_detail', params);
}
export function edit_invoice_template_detail(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_invoice_template_detail', params);
}
export function download_invoice_file(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/download_invoice_file', params);
}
export function get_download_invoice_file_result_page(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_download_invoice_file_result_page', params);
}
