import { App } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import workbenchRouteConfig from './route/workbench';
import businessRouteConfig from './route/business';
import commonRouteConfig from './route/common';
import reportRouteConfig from './route/report';
import erpRouteConfig from './route/erp';
import moreRouteConfig from './route/more';
import adminRouteConfig from './route/admin';
import threeCrmRouteConfig from './route/mmm';

import { isEmptyData } from '@/utils/common';
const routes: RouteRecordRaw[] = [
  ...commonRouteConfig,
  workbenchRouteConfig,
  ...businessRouteConfig,
  threeCrmRouteConfig,
  erpRouteConfig,
  reportRouteConfig,
  moreRouteConfig,
  adminRouteConfig,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ left: 0, top: 0 }),
});

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    location.reload();
  }
});

export function setupRouter(app: App<Element>) {
  app.use(router);
}
export function addRoutesByCustomReportList(list) {
  const routes = router.getRoutes();
  const cloneList = [...list].filter((item) => !item.auto_generate_url);
  cloneList.forEach((item) => {
    let report_menu: Recordable = {};
    try {
      report_menu = JSON.parse(item.report_menu);
    } catch (e) {
      return;
    }
    const first_menu = report_menu.parent_menu_path[0];
    const find_root_route = routes.find((f) => f.path === `/${first_menu}`);
    const route: any = {
      path: item.report_route,
      meta: {
        title: item.name,
        ignoreKeepAlive: true,
        auth: item.report_permission.trim() ? item.report_permission.split('\t') : [],
        is_added: true,
      },
      props: ({ params, query }) => ({ ...params, ...query, supplier: false }),
      component: () => import('@/views/report/common/CommonReport.vue'),
    };
    if (!isEmptyData(find_root_route)) {
      if (!isEmptyData(find_root_route!.name)) {
        router.addRoute(find_root_route!.name!, route);
      }
    }
  });
}
export default router;
