
  import { defineComponent, computed, unref, provide } from 'vue';
  import WeAlert from '@/components/WeAlert/index.vue';
  import WeChange from '@/components/WeChange/index.vue';
  import WeOperLog from '@/components/WeOperLog/index.vue';
  import { appStore } from '@/store/modules/app';
  import { useApp } from '@/hooks/useApp';
  import router from '@/router';
  import { isEmptyData } from '@/utils/common';
  import { start } from 'qiankun';
  import { useRoute } from 'vue-router';
  import { useAppInfo } from '@/hooks/useAppInfo';
  export default defineComponent({
    name: 'AppLayoutContent',
    components: {
      WeAlert,
      WeChange,
      WeOperLog,
    },
    data: () => ({
      router,
    }),
    setup() {
      provide('is_in_page_detail', false);
      const { drawerConfig } = useApp();
      const route = useRoute();
      const alertConfig = computed(() => {
        return appStore.alertConfig;
      });
      const dialogConfig = computed(() => {
        return appStore.weDialog;
      });
      const weOperLogConfig = computed(() => {
        return appStore.weOperLog;
      });
      const changeConfig = computed(() => {
        return appStore.changeConfig;
      });
      const getCacheComponents = computed(() => {
        const routeList = router.getRoutes();
        return routeList
          .filter((item) => {
            return (
              !isEmptyData(item.meta) && !isEmptyData(item.meta.ignoreKeepAlive) && item.meta.ignoreKeepAlive === false
            );
          })
          .map((mtem) => mtem.meta.componentName);
      });
      const { sub_app_suffix_list } = useAppInfo();

      const isSub = computed(() => {
        return unref(sub_app_suffix_list).some((item) => route.path.includes(item));
      });
      return {
        isSub,
        drawerConfig,
        alertConfig,
        dialogConfig,
        weOperLogConfig,
        changeConfig,
        getCacheComponents,
      };
    },
    mounted() {},
    watch: {
      isSub: {
        handler(val) {
          this.$nextTick(() => {
            if (val) {
              if (!window.qiankunStarted) {
                window.qiankunStarted = true;
                start();
              }
            }
          });
        },
        immediate: true,
      },
    },
  });
