import request from '@/utils/request';
export function get_bill_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_page', params);
}
export function get_bill_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_item', params);
}
export function assign_bill_to_me(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_bill_to_me', params);
}
export function assign_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/assign_bill', params);
}
export function get_we_company_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_we_company_item', params);
}
export function reset_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/reset_bill', params);
}
export function counteract_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/counteract_bill', params);
}
export function delete_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_bill', params);
}
export function edit_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_bill', params);
}
export function edit_bill_for_adjust(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_bill_for_adjust', params);
}
export function reject_sub_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/reject_sub_bill', params);
}
export function add_sub_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_sub_bill', params);
}
export function delete_sub_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_sub_bill', params);
}
export function send_billing_email(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/send_billing_email', params);
}
export function make_bill_normal_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_bill_normal_bill', params);
}
export function make_bill_main_bill(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_bill_main_bill', params);
}
export function make_bill_classcial_mode(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/make_bill_classcial_mode', params);
}
export function close_bill_no_data(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/close_bill_no_data', params);
}
export function change_bill_buline(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/change_bill_buline', params);
}
export function get_email_preview(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_email_preview', params);
}
export function clear_bill_billing_log(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/clear_bill_billing_log', params);
}
export function refresh_billing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/refresh_billing', params);
}
export function edit_bill_adjust(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_bill_adjust', params);
}
export function delete_bill_adjust(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_bill_adjust', params);
}
export function check_bill_done(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/check_bill_done', params);
}
export function batch_edit_media_billing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_edit_media_billing', params);
}
export function batch_edit_media_billing_by_excel(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_edit_media_billing_by_excel', params);
}
export function edit_bill_plan_pay_time(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_bill_plan_pay_time', params);
}
export function get_bill_email_bundle(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_email_bundle', params);
}
export function pub_edit_billing_item(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/pub_edit_billing_item', params);
}
export function get_pub_billing_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_pub_billing_page', params);
}
export function pub_batch_edit_billing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/pub_batch_edit_billing', params);
}
export function gen_pub_confirmation_pdf(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/gen_pub_confirmation_pdf', params);
}
export function send_bill_email_bundle(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/send_bill_email_bundle', params);
}
export function get_bill_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_list', params);
}
export function get_export_bill_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_export_bill_list', params);
}
export function get_export_data(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_export_data', params);
}
export function get_bill_email_history(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_email_history', params);
}
export function get_media_email_send_record(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_media_email_send_record', params);
}
export function edit_media_email_send_record(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_media_email_send_record', params);
}
export function get_counteract_invoice_list(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_counteract_invoice_list', params);
}
export function counteract_invoice(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/counteract_invoice', params);
}
export function get_bill_link_against_bills(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_link_against_bills', params);
}
export function link_bill_against_bills(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/link_bill_against_bills', params);
}
export function get_media_email_attachment_list(params?: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_media_email_attachment_list', params);
}
export function set_media_email_attachment(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/set_media_email_attachment', params);
}
export function get_bill_aws_billing_detail(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_bill_aws_billing_detail', params);
}
export function upload_scaler_billing(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/upload_scaler_billing', params);
}
export function edit_scaler_billing(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_scaler_billing', params);
}
export function add_scaler_billing_pdf(params?: Recordable) {
  return request.post<null, Recordable>('/api/v1/add_scaler_billing_pdf', params);
}
export function split_adjust_cost(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/split_adjust_cost', params);
}
export function sync_scaler_tax_rate(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/sync_scaler_tax_rate', params);
}
