
  import { defineComponent, ref } from 'vue';
  import { useUser } from '@/hooks/useUser';
  import { isDev, isPc, isLark, FILE_ICON_MAP } from '@/utils/const';
  import { appStore } from '@/store/modules/app';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { getCookie } from '@/utils/cookies';
  import pick from 'lodash-es/pick';
  import request from '@/utils/request';
  import axios from 'axios';
  import {
    delete_entity_file,
    edit_entity_note,
    edit_entity_state_added_note,
    edit_entity_state_note,
    upload_entity_file_link,
  } from '@/api/erp-contracts';
  import { god_edit_flow_state, flow_re_send_message } from '@/api/crm-customerTrack';
  import { isUrl } from '@/utils/validate';
  import { ITimeLineItem } from '@/api/types/product';
  import { required } from '@/utils/validate';
  import { get_website_title } from '@/api/app';
  interface ICustomRequest {
    action: string;
    data: Recordable;
    file: File;
    filename: string;
    headers: Recordable;
    onError: Fn;
    onProgress: Fn;
    onSuccess: Fn;
  }
  const fileLinkRules = {
    link_name: [required('string', '请输入链接名称')],
    link_address: [
      {
        required: true,
        trigger: 'change',
        message: '请输入链接地址',
        type: 'any',
      },
      isUrl,
    ],
  };
  export default defineComponent({
    name: 'WeHWTimeLine',
    props: {
      entity: {
        type: Object as PropType<Recordable>,
      },
      entityType: {
        type: String,
        default: '',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      activities: {
        type: Array as PropType<ITimeLineItem[]>,
        default: () => [],
      },
      reverse: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      isDev,
      fileLinkRules,
      editStateNoteDialog: false,
      editStateNoteData: {
        title: '',
        sendLarkTip: '',
      },
      editStateNoteForm: {
        id: 0,
        note: '',
        method: '',
        send_lark: false,
      },
      noteChanged: false,

      uploadEntityFileDisabled: false,
      uploadEntityFileDialog: false,
      uploadEntityFileData: {
        url: '',
        id: 0,
        method: 'add',
        title: '',
        note: '',
        send_lark: false,
      },
      uploadEntityFileCount: 0,
      FILE_ICON_MAP,
      showImage: '',
      showPdf: null,
    }),
    computed: {
      showEntity(): boolean {
        return !!this.entity;
      },
      addedParams(): Recordable {
        if (this.entity) {
          return {
            entity_type: this.entityType,
            entity_id: this.entity.id,
          };
        } else {
          return {};
        }
      },
      addedParams2(): string {
        if (this.entity) {
          return `&entity_id=${this.entity.id}&entity_type=${this.entityType}`;
        } else {
          return '';
        }
      },
    },
    setup() {
      const { hasPermission } = useUser();
      const filesList = ref<any>([]);
      const { apiRoot, userMap } = useAppInfo();
      const { isGod, isAdmin } = useUser();
      const uploadEntityFileLinks = ref<{ link_name: string; link_address: string; resolve_success: boolean }[]>([]);
      return { isGod, isAdmin, userMap, apiRoot, hasPermission, filesList, uploadEntityFileLinks, required };
    },
    methods: {
      afterEditEntity(res) {
        this.$emit('afterEditEntity', res);
      },
      afterCloseFileDialog() {
        this.uploadEntityFileLinks = [];
        this.uploadEntityFileData = {
          id: 0,
          url: '',
          method: 'add',
          title: '',
          note: '',
          send_lark: false,
        };
      },
      hwSelectFile(options: ICustomRequest) {
        const vm = this;
        const file = options.file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
          vm.filesList.push({ name: file.name, base64_data: e.target?.result as string });
          options.onSuccess(true);
        };
      },
      getFileType(file) {
        const lowerName = file.file_name.toLowerCase();
        if (
          lowerName.endsWith('.jpg') ||
          lowerName.endsWith('.png') ||
          lowerName.endsWith('.bmp') ||
          lowerName.endsWith('.jpeg')
        ) {
          return 'img';
        } else if (lowerName.endsWith('pdf')) {
          return 'pdf';
        } else if (lowerName.endsWith('doc') || lowerName.endsWith('docx')) {
          return 'word';
        } else if (lowerName.endsWith('xlsx') || lowerName.endsWith('xls')) {
          return 'xlsx';
        } else {
          return 'link';
        }
      },
      deleteUploadEntityFileLinks(key) {
        this.uploadEntityFileLinks.splice(key, 1);
      },
      openEditStateNoteDialog(state, method) {
        this.editStateNoteForm.id = state.id;
        this.editStateNoteForm.method = method;
        this.editStateNoteForm.send_lark = false;
        if (method === 'edit') {
          this.editStateNoteData.title = 'Edit State Note';
          this.editStateNoteForm.note = state.note;
        } else if (method === 'add') {
          this.editStateNoteData.title = 'Add State Note';
          this.editStateNoteForm.note = '';
        } else if (method === 'comment') {
          this.editStateNoteData.title = '添加评论';
          this.editStateNoteData.sendLarkTip = '发送飞书消息给' + state.owner.name;
          this.editStateNoteForm.note = '';
        }
        this.editStateNoteDialog = true;
        this.noteChanged = false;
      },
      closeEditStateNote() {
        if (this.noteChanged) {
          this.$showAlert('Warning', '确定不保存并退出？', () => {
            this.editStateNoteDialog = false;
            appStore.SET_ALERT_CONFIG(null);
          });
        } else {
          this.editStateNoteDialog = false;
        }
      },
      saveStateNote() {
        const params = { ...this.addedParams, ...this.editStateNoteForm };
        edit_entity_note(params).then((res) => {
          this.afterEditEntity(res);
          this.editStateNoteDialog = false;
        });
      },
      pasteClipboardImage(event) {
        const items = event.clipboardData && event.clipboardData.items;
        let file: any = null;
        if (items && items.length) {
          for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
              file = items[i].getAsFile();
              break;
            }
          }
        }
        if (file && file.type === 'image/png') {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let url = this.uploadEntityFileData.url;
            request.post(url, { file_data: reader.result }).then((res) => {
              this.closeUploadEntityFileDialog(res);
              this.afterEditEntity(null);
            });
          };
        }
      },
      closeUploadEntityFileDialog(_state) {
        document.removeEventListener('paste', this.pasteClipboardImage);
        this.filesList = [];
        this.uploadEntityFileDialog = false;
      },
      openUploadEntityFileDialog(state, method) {
        document.addEventListener('paste', this.pasteClipboardImage);
        // this.error = false;
        this.uploadEntityFileData.id = state.id;
        this.uploadEntityFileData.method = method;

        this.uploadEntityFileData.title = '追加备注与文件';
        if (method === 'edit') {
          this.uploadEntityFileData.title = '编辑备注与文件';
        }
        if (method === 'comment') {
          this.uploadEntityFileData.title = '文件评论上传';
        }
        this.uploadEntityFileDisabled = false;
        this.uploadEntityFileLinks = [];
        const url = `${this.apiRoot}/upload_entity_file/`;
        const params = `entity_state_id=${state.id}&method=${method}${this.addedParams2}`;
        this.uploadEntityFileData.url = `${url}?${params}`;
        this.uploadEntityFileDialog = true;
        this.uploadEntityFileData.note = state.note;
      },
      async uploadEntityFile() {
        // 判断是否解析完成:遍历 uploadEntityFileLinks 的 link_name 和 link_address
        if (this.uploadEntityFileLinks.length > 0) {
          for (let i = 0; i < this.uploadEntityFileLinks.length; i++) {
            if (!this.uploadEntityFileLinks[i].link_name || !this.uploadEntityFileLinks[i].link_address) {
              this.$message.warning('请完善添加的链接');
              return false;
            } else if (this.uploadEntityFileLinks[i].link_name === '解析中...') {
              this.$message.warning('请等待title解析');
              return false;
            }
          }
        }

        this.uploadEntityFileCount = this.filesList.length;
        this.uploadEntityFileDisabled = true;
        if (this.uploadEntityFileData.note) {
          const params = {
            ...this.addedParams,
            ...pick(this.uploadEntityFileData, ['methond', 'id', 'note', 'send_lark']),
          };
          params.method = 'edit';
          edit_entity_note(params).then((res) => {
            this.afterEditEntity(res);
            this.editStateNoteDialog = false;
          });
        }
        if (this.uploadEntityFileLinks.length > 0) {
          const params = {
            method: this.uploadEntityFileData.method,
            entity_state_id: this.uploadEntityFileData.id,
            link_list: this.uploadEntityFileLinks.map((item) => ({
              link_name: item.link_name,
              link_address: item.link_address,
            })),
            ...this.addedParams,
          };
          upload_entity_file_link(params).then(() => {
            if (this.uploadEntityFileCount > 0) {
              this.uploadEntityFileByFormData();
            } else {
              this.uploadEntityFileDialog = false;
              this.afterEditEntity(null);
            }
          });
        } else {
          this.uploadEntityFileByFormData();
        }
      },
      async uploadEntityFileByFormData() {
        let config = {
          headers: { 'Content-Type': 'application/json' },
        };
        const url = `${this.apiRoot}/upload_entity_file/`;
        const params = `entity_state_id=${this.uploadEntityFileData.id}&method=${this.uploadEntityFileData.method}${this.addedParams2}`;

        await axios.post(`${url}?${params}`, { file_list: this.filesList }, config);

        this.filesList = [];
        this.uploadEntityFileDialog = false;
        this.afterEditEntity(null);
      },
      uploadEntityFileError(_err) {
        // this.error = err;
      },
      uploadEntityFileSuccess() {
        this.uploadEntityFileCount = this.uploadEntityFileCount - 1;
        if (this.uploadEntityFileCount <= 0) {
          this.uploadEntityFileDialog = false;
          this.afterEditEntity(null);
        }
      },
      deleteEntityFile(file) {
        this.$showAlert('Alert', `Delete file: "${file.file_name}"?`, () => {
          delete_entity_file({ file_id: file.id, ...this.addedParams }).then((res) => {
            this.afterEditEntity(res);
            appStore.SET_ALERT_CONFIG(null);
          });
        });
      },
      godEditState(state) {
        this.$showDialog({
          title: 'God edit state: ' + state.state_desc,
          layout: 'vertical',
          blocks: [
            { type: 'json', value: state, width: 24 },
            {
              type: 'input',
              value: [{ type: 'text', result: 'operation', label: 'Operation', width: 24 }],
            },
            { type: 'note', result: 'value', label: 'Value', width: 24 },
          ],
          callback: (result) => {
            god_edit_flow_state({ id: state.id, ...result, ...this.addedParams }).then((res) => {
              this.afterEditEntity(res);
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      },
      addExternalLink() {
        this.uploadEntityFileLinks.push({ link_name: '', link_address: '', resolve_success: true });
      },
      openUrl(url) {
        if (isPc && isLark) {
          window.open(url);
        } else {
          window.open(url, '_blank');
        }
      },
      downloadPic(file) {
        const url = `${this.apiRoot}/get_entity_file/?entity_file_id=${file.id}${this.addedParams2}`;
        if (isPc && isLark) {
          window.open(url);
        } else {
          window.open(url, '_blank');
        }
      },
      openFile(file, openInNew) {
        if (file.is_link) {
          window.open(file.file_path, '_blank');
        } else {
          const url = `${this.apiRoot}/get_entity_file/?entity_file_id=${file.id}${this.addedParams2}`;
          const session = '&visit_token=' + getCookie('sessionid');
          if (openInNew) {
            if (isPc && isLark) {
              window.open(url + '&open_in_new_tab=1' + session, '_blank');
            } else {
              window.open(url + '&open_in_new_tab=1', '_blank');
            }
            return;
          }
          const lowerName = file.file_name.toLowerCase();
          if (
            lowerName.endsWith('.jpg') ||
            lowerName.endsWith('.png') ||
            lowerName.endsWith('.bmp') ||
            lowerName.endsWith('.jpeg')
          ) {
            this.showImage = url!;
          } else {
            if (isPc && isLark) {
              // window.location.href = url;
              window.open(url + session);
            } else {
              window.open(url, '_blank');
            }
          }
        }
      },
      modifyNote(state) {
        if (this.isGod || this.hasPermission('CanModifyTimelineNote')) {
          this.$showDialog({
            title: 'Modify Note【请小心操作】',
            width: '600px',
            labelCol: '80px',
            blocks: [{ type: 'note', label: 'Note', result: 'note', init: state.note, width: 24 }],
            callback: (result) => {
              edit_entity_state_note({ ...this.addedParams, ...result, state_id: state.id }).then((res) => {
                this.afterEditEntity(res);
              });
            },
          });
        }
      },
      modifyAddedNote(addedNote) {
        if (this.isGod || this.hasPermission('CanModifyTimelineNote')) {
          this.$showDialog({
            width: '600px',
            labelCol: '80px',
            title: 'Modify Comment【请小心操作】',
            blocks: [{ type: 'note', label: 'Note', result: 'note', init: addedNote.note, width: 24 }],
            callback: (result) => {
              edit_entity_state_added_note({
                ...this.addedParams,
                ...result,
                added_note_id: addedNote.id,
              }).then((res) => {
                this.afterEditEntity(res);
              });
            },
          });
        }
      },
      analyzeUrl(url, index) {
        if (url && /http(s?):\/\/.*/.test(url)) {
          this.uploadEntityFileLinks[index].link_name = '解析中...';
          get_website_title({ url }).then((res) => {
            // 获取成功res为title字符串
            // 获取失败res为{error_code: 0, data: ''}
            if (typeof res === 'string') {
              this.uploadEntityFileLinks[index].link_name = res;
            } else {
              this.uploadEntityFileLinks[index].link_name = '';
              this.uploadEntityFileLinks[index].resolve_success = false;
            }
          });
        }
      },
      handle_alert(state) {
        this.$showAlert('注意', '确定发送“催办通知”？', () => {
          appStore.SET_ALERT_CONFIG(null);
          flow_re_send_message({ state_id: state.id }).then(() => {
            this.$message.success('通知成功');
          });
        });
      },
    },
  });
