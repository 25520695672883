import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17e24ae9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "we-edit-warp" }
const _hoisted_2 = {
  key: 0,
  style: {"white-space":"pre-wrap"}
}
const _hoisted_3 = {
  key: 1,
  style: {"color":"#ccc"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (!_ctx.edit)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass([{ item: true }, { mr16: true }, _ctx.spanWrapperClass])
        }, [
          _renderSlot(_ctx.$slots, "text", {}, () => [
            (!_ctx.isEmptyData(_ctx.realValue))
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.realValue), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, "—"))
          ], true),
          (_ctx.show_copy)
            ? (_openBlock(), _createBlock(_component_svg_icon, {
                key: 0,
                onClick: _ctx.copyValue,
                class: "icon-border",
                "icon-name": "CopyOutlined",
                style: {"font-size":"16px","color":"#4885ed","cursor":"pointer"}
              }, null, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ], 2))
      : (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass({ inline: _ctx.inline })
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 2))
  ]))
}