import { isEmptyData } from './common';
// import isEqual from 'lodash-es/isEqual';
const puncs =
  /[\$\uFFE5\^\+=`~<>{}\[\]|\u3000-\u303F!-#%-\x2A,-/:;\x3F@\x5B-\x5D_\x7B}\u00A1\u00A7\u00AB\u00B6\u00B7\u00BB\u00BF\u037E\u0387\u055A-\u055F\u0589\u058A\u05BE\u05C0\u05C3\u05C6\u05F3\u05F4\u0609\u060A\u060C\u060D\u061B\u061E\u061F\u066A-\u066D\u06D4\u0700-\u070D\u07F7-\u07F9\u0830-\u083E\u085E\u0964\u0965\u0970\u0AF0\u0DF4\u0E4F\u0E5A\u0E5B\u0F04-\u0F12\u0F14\u0F3A-\u0F3D\u0F85\u0FD0-\u0FD4\u0FD9\u0FDA\u104A-\u104F\u10FB\u1360-\u1368\u1400\u166D\u166E\u169B\u169C\u16EB-\u16ED\u1735\u1736\u17D4-\u17D6\u17D8-\u17DA\u1800-\u180A\u1944\u1945\u1A1E\u1A1F\u1AA0-\u1AA6\u1AA8-\u1AAD\u1B5A-\u1B60\u1BFC-\u1BFF\u1C3B-\u1C3F\u1C7E\u1C7F\u1CC0-\u1CC7\u1CD3\u2010-\u2027\u2030-\u2043\u2045-\u2051\u2053-\u205E\u207D\u207E\u208D\u208E\u2329\u232A\u2768-\u2775\u27C5\u27C6\u27E6-\u27EF\u2983-\u2998\u29D8-\u29DB\u29FC\u29FD\u2CF9-\u2CFC\u2CFE\u2CFF\u2D70\u2E00-\u2E2E\u2E30-\u2E3B\u3001-\u3003\u3008-\u3011\u3014-\u301F\u3030\u303D\u30A0\u30FB\uA4FE\uA4FF\uA60D-\uA60F\uA673\uA67E\uA6F2-\uA6F7\uA874-\uA877\uA8CE\uA8CF\uA8F8-\uA8FA\uA92E\uA92F\uA95F\uA9C1-\uA9CD\uA9DE\uA9DF\uAA5C-\uAA5F\uAADE\uAADF\uAAF0\uAAF1\uABEB\uFD3E\uFD3F\uFE10-\uFE19\uFE30-\uFE52\uFE54-\uFE61\uFE63\uFE68\uFE6A\uFE6B\uFF01-\uFF03\uFF05-\uFF0A\uFF0C-\uFF0F\uFF1A\uFF1B\uFF1F\uFF20\uFF3B-\uFF3D\uFF3F\uFF5B\uFF5D\uFF5F-\uFF65]+/g;
// export const required = (val: any) => {
//   return (val === null && val === '') || (val !== undefined && !isEqual(val, []));
// };
// export const isUrl = (val: string) => {
//   return val === null || val === '' || /http(s?):\/\/.*/.test(val);
// };
export const isUrl = {
  validator: async (_rule, val: any) => {
    if (/http(s?):\/\/.*/.test(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('请输入正确的url地址,以http(s)://开头');
    }
  },
  trigger: 'change',
};

export const uploadRequired = {
  validator: async (_rule, val: any) => {
    if (!isEmptyData(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('请上传文件');
    }
  },
  required: true,
  message: '请上传文件',
  trigger: 'change',
};

// export const isInt = (val: any) => {
//   return val === null || val === '' || /^\+?(0|[1-9]\d*)$/.test(val);
// };
// export const isFloat = (val: any) => {
//   return val === null || val === '' || /(^\-|^\+)?(0|0.\d*|[1-9]\d*|[1-9]\d*.\d*)$/.test(val);
// };
// export const isNumber = (val: any) => {
//   return !isNaN(val);
// };
// export const isNonNegativeNumber = (val: any) => {
//   return val === null || val === '' || !isNaN(val) || parseFloat(val) >= 0;
// };
export const isEmail = (val: any) => {
  return isEmptyData(val) || /.+@.+/.test(val);
};
export const isEmails = (val: any) => {
  return (
    isEmptyData(val) ||
    !Array.isArray(val) ||
    val.every((item) => {
      return isEmail(item);
    })
  );
};
export const isShort = (val: any) => {
  return !!val && val.replace(/[^\x00-\xff]/g, '**').length <= 6;
};
export const isChinese = (val: any) => {
  return !!val && val.replace(/[^\x00-\xff]/g, '').length === 0;
};
export const noPunc = (val: any) => {
  return !!val && val.search(puncs) === -1;
};
export const short10 = (val: any) => {
  return !!val && val.length <= 10;
};
export const short12 = (val: any) => {
  return !!val && val.length <= 12;
};
export const short18 = (val: any) => {
  return !!val && val.length <= 18;
};
export const simple = (val: any) => {
  return !!val && !val.match(/\W/g);
};
export const check_text_length = (val, len) => {
  let size = 0;
  for (const item of val) {
    if (isChinese(item)) {
      size += 2;
    } else {
      size += 1;
    }
  }
  return size < len;
};
// export const isValidAccounts = (val: any) => {
//   return (
//     val !== null &&
//     val.length !== 0 &&
//     val.every((item) => {
//       if (typeof item === 'string') {
//         return item.search(/^[0-9a-zA-z]+:.+$/i) !== -1;
//       } else {
//         return false;
//       }
//     })
//   );
// };
// export const isEnglish = (val: any) => {
//   return val !== null && val !== '' && val.match(/^\\w+$/);
// };
export const isJson = (str: any) => {
  try {
    if (typeof JSON.parse(str) == 'object') {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const hasChina = (str: any) => {
  try {
    return escape(str).indexOf('%u') >= 0;
  } catch (e) {
    console.log(e);
  }
  return false;
};
export const required = (type = 'string', message = '请输入', trigger = 'change') => ({
  required: true,
  type: type,
  message: message,
  trigger: trigger,
  transform: (value) => {
    if (!isEmptyData(value)) {
      if (type === 'string') {
        return `${value}`;
      } else {
        return value;
      }
    } else {
      return undefined;
    }
  },
});
export const gtZero = (message = '需大于0') => ({
  required: true,
  type: 'number',
  message: message,
  trigger: 'change',
  transform: (value) => {
    if (!isEmptyData(value)) {
      if (!/^\+?(0|[1-9]\d*)$/.test(value) || parseFloat(value) <= 0) {
        return undefined;
      }
    }
    return value;
  },
});
export const isNonNegativeNumber = {
  validator: async (_rule, val: any) => {
    if (isNaN(val) || parseFloat(val) < 0) {
      return Promise.reject('数字格式有误');
    } else {
      return Promise.resolve();
    }
  },
  trigger: 'change',
};
export const isValidAccounts = {
  validator: async (_rule, val: any) => {
    if (
      !isEmptyData(val) &&
      val.every((item) => {
        if (typeof item === 'string') {
          return item.search(/^[0-9a-zA-z]+:.+$/i) !== -1;
        } else {
          return false;
        }
      })
    ) {
      return Promise.reject('数据错误');
    } else {
      return Promise.resolve();
    }
  },
  trigger: 'change',
};

export const isInt = {
  validator: async (_rule, val: any) => {
    if (isEmptyData(val) || /^\+?(0|[1-9]\d*)$/.test(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('只能为数字');
    }
  },
  trigger: 'change',
};
export const isNumber = {
  validator: async (_rule, val: any) => {
    if (!isNaN(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('只能为数字');
    }
  },
  trigger: 'change',
};
export const isEnglish = {
  validator: async (_rule, val: any) => {
    if (!isEmptyData(val) && !/\u4e00-\u9fa5/.test(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('只能为英文');
    }
  },
  trigger: 'change',
};
export const isFloat = {
  validator: async (_rule, val: any) => {
    if (isEmptyData(val) || /(^\-|^\+)?(0|0.\d*|[1-9]\d*|[1-9]\d*.\d*)$/.test(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('格式不正确');
    }
  },
  trigger: 'change',
};
export const short6 = {
  validator: async (_rule, val: any) => {
    // if (isEmptyData(val) || val.length <= 6) {
    if (!isEmptyData(val) && isChinese(val) && val.length > 6) {
      return Promise.reject('请控制在6个字符内');
    } else {
      return Promise.resolve();
    }
  },
  trigger: 'change',
};
export const isAccount = {
  validator: async (_rule, val: any) => {
    if (isEmptyData(val) || phone.pattern.test(val) || email.pattern.test(val)) {
      return Promise.resolve();
    } else {
      return Promise.reject('您填写的登录账号有误');
    }
  },
  trigger: 'change',
};
export const duplicateCompanyName = (companyItems) => ({
  validator: async (_rule, val: any) => {
    const nameItems = companyItems.map((x) => x.name.toLowerCase());
    if (nameItems.includes(val.toLowerCase())) {
      return Promise.reject('客户名称重复');
    } else {
      return Promise.resolve();
    }
  },
  trigger: 'change',
});
export const duplicateCompanyShortName = (companyItems) => ({
  validator: async (_rule, val: any) => {
    const nameItems = companyItems.filter((x) => !isEmptyData(x.short_name)).map((x) => x.short_name.toLowerCase());
    if (nameItems.includes(val.toLowerCase())) {
      return Promise.reject('客户简称重复');
    } else {
      return Promise.resolve();
    }
  },
  trigger: 'change',
});
export const phone = {
  pattern: /^0?(1[3-9])[0-9]{9}$/,
  message: '请输入正确的手机号码',
  trigger: 'blur',
};
export const phoneRegex = /^(\+\d{1,3}\s?)?(\(\d{1,4}\))?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
export const email = {
  pattern: /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/,
  trigger: 'blur',
};
export const is_html = {
  pattern: /<[a-z][\s\S]*>/i,
  trigger: 'blur',
};
