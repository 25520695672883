import { isPc, isLark } from '@/utils/const';
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';

export function useJump() {
  const router = useRouter();
  const internalInstance = getCurrentInstance();
  const $showDrawer = internalInstance?.appContext.config.globalProperties.$showDrawer;
  let url = '';
  function jumpToCMSDetail(cms, showDrawer = false, reload = null) {
    url = router.resolve({
      name: 'BusinessCommonCMSDetail',
      params: { id: cms.id },
    }).href;
    if (showDrawer) {
      $showDrawer({
        componentName: 'CommonCMSDetail',
        id: cms.id,
        keyName: 'id',
        href: url,
        reloadList: reload,
      });
      return;
    }
    openWindow();
  }
  function jumpToContract(contract, showDrawer = false, reload = null) {
    url = router.resolve({
      name: 'ERPContractDetail',
      query: {
        contractId: contract.id,
      },
    }).href;
    if (showDrawer) {
      showDrawer = false;
    }
    if (showDrawer) {
      $showDrawer({
        componentName: 'ERPContractDetail',
        contractId: contract.id,
        keyName: 'contractId',
        href: url,
        reloadList: reload,
      });
      return;
    }
    openWindow();
  }
  function jumpToBill(bill, showDrawer = false) {
    url = router.resolve({
      name: 'ERPBillDetail',
      query: {
        billId: bill.id,
      },
    }).href;
    if (showDrawer) {
      showDrawer = false;
    }
    if (showDrawer) {
      $showDrawer({
        componentName: 'ERPBillDetail',
        billId: bill.id,
        keyName: 'billId',
        href: url,
      });
      return;
    }
    openWindow();
  }
  function jumpToInvoice(invoiceId, showDrawer = false) {
    url = router.resolve({ name: 'ERPInvoiceDetail', query: { invoiceId: invoiceId } }).href;

    if (showDrawer) {
      showDrawer = false;
    }
    if (showDrawer) {
      $showDrawer({
        componentName: 'ERPInvoiceDetail',
        invoiceId: invoiceId,
        keyName: 'invoiceId',
        href: url,
      });
      return;
    }
    openWindow();
  }
  function jumpToCompany(id, mode = '_blank') {
    url = router.resolve({ name: 'CustomerDetail', query: { companyId: id } }).href;
    openWindow(mode);
  }
  function jumpToOpp(pId, mode = '_blank') {
    url = router.resolve({ name: 'PipelineDetail', query: { pipelineId: pId } }).href;
    openWindow(mode);
  }
  const jumpToPay = (pay, showDrawer = false) => {
    url = router.resolve({ name: 'ERPPayDetail', query: { payId: pay.id } }).href;

    if (showDrawer) {
      showDrawer = false;
    }
    if (showDrawer) {
      $showDrawer({
        componentName: 'ERPPayDetail',
        payId: pay.id,
        keyName: 'payId',
        href: url,
      });
      return;
    }
    openWindow();
  };
  function jumpToWebeye(id) {
    url = `${window.location.origin}/business/hw3/wecompany/detail/${id}`;
    openWindow();
  }
  function jumpToUGSResellerDetail(id, showDrawer = false) {
    url = router.resolve({ name: 'UGSResellerDetail', query: { resellerId: id } }).href;

    if (showDrawer) {
      $showDrawer({
        componentName: 'UGSResellerDetail',
        resellerId: id,
        keyName: 'resellerId',
        href: url,
      });
      return;
    }
    openWindow();
  }
  /**
   * 跳转到Reseller授信详情
   * @param id
   * @param showDrawer
   */
  function jumpToUGSResellerCreditDetail(credit, showDrawer = false) {
    url = router.resolve({ name: 'UGSResellerCreditDetail', query: { id: credit.id } }).href;

    if (showDrawer) {
      $showDrawer({
        componentName: 'UGSResellerCreditDetail',
        id: credit.id,
        keyName: 'id',
        href: url,
      });
      return;
    }
    openWindow();
  }
  /**
   * 跳到开户申请审核详情
   * @param pipeline
   * @param showDrawer
   */
  function jumpToOpenAccountAuditDetail(record, showDrawer = false) {
    url = router.resolve({ name: 'BusinessCommonOpaAuditDetail', query: { id: record.id } }).href;

    if (showDrawer) {
      $showDrawer({
        componentName: 'UgsOpenAccountAuditDetail',
        id: record.id,
        keyName: 'id',
        href: url,
      });
      return;
    }
    openWindow();
  }
  /**
   * 跳到充值申请审核详情
   * @param pipeline
   * @param showDrawer
   */
  function jumpToOpenAccountRechargeDetail(record, showDrawer = false) {
    url = router.resolve({ name: 'BusinessCommonOpaRechargeDetail', query: { id: record.id } }).href;

    if (showDrawer) {
      $showDrawer({
        componentName: 'UgsOpenAccountRechargeDetail',
        id: record.id,
        keyName: 'id',
        href: url,
      });
      return;
    }
    openWindow();
  }
  function jumpToPerson(person) {
    url = router.resolve({ name: 'AdminMetaCustomersPersonDetail', query: { id: `${person.id}` } }).href;
    openWindow();
  }
  function jumpToProduct(product) {
    url = router.resolve({ name: 'PUBProductDetail', query: { id: `${product.id}` } }).href;
    openWindow();
  }
  function jumpToCustomerDetail(company, mode = '_blank') {
    url = router.resolve({ name: 'CustomerDetail', query: { companyId: Number(company.id) } }).href;
    openWindow(mode);
  }
  function jumpToCfiDetail(cfi, mode = '_blank') {
    url = router.resolve({ name: 'CustomerCfiDetail', query: { id: Number(cfi.id) } }).href;
    openWindow(mode);
  }
  function jumpToOrderDetail(order, mode = '_blank') {
    url = router.resolve({ name: 'OrderDetail', query: { orderId: Number(order.id) } }).href;
    openWindow(mode);
  }
  function jumpToCreateReport(record) {
    url = router.resolve({ name: 'MetaCreateReport', query: { id: `${record.id}` } }).href;
    openWindow();
  }
  function backToHome() {
    router.push({ name: 'WorkbenchMyHome' });
  }
  function openWindow(type = '_blank') {
    if (isLark && isPc) {
      window.location.href = url;
    } else {
      window.open(url, type);
    }
  }
  return {
    backToHome,
    jumpToOpp,
    jumpToCompany,
    jumpToContract,
    jumpToBill,
    jumpToInvoice,
    jumpToPay,
    jumpToWebeye,
    jumpToPerson,
    jumpToProduct,
    jumpToUGSResellerDetail,
    jumpToUGSResellerCreditDetail,
    jumpToCMSDetail,
    jumpToOpenAccountAuditDetail,
    jumpToOpenAccountRechargeDetail,
    jumpToCustomerDetail,
    jumpToCreateReport,
    jumpToCfiDetail,
    jumpToOrderDetail,
  };
}
