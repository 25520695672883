import { DrawerConfig } from '@/components/WeDrawer/types';
import { appStore } from '@/store/modules/app';
import { cacheStore } from '@/store/modules/cache';
import { copyObjectToReactive, isEmptyData } from '@/utils/common';
import { computed } from 'vue';
import { message, Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { DialogConfig } from '@/components/WeDialog/types';

export function useApp() {
  const route = useRoute();
  const isSupplyPage = computed(() => {
    return route.path.includes('supply');
  });
  const isDemandPage = computed(() => {
    return route.path.includes('demand');
  });
  const isPurchasePage = computed(() => {
    return route.path.includes('purchase');
  });
  const isDirectPage = computed(() => {
    return route.path.includes('direct');
  });
  const isBackupPage = computed(() => {
    return route.path.includes('backup');
  });
  const isDetailPage = computed(() => {
    return route.path.includes('detail') || !isEmptyData(appStore.drawerConfig);
  });
  const drawerConfig = computed(() => {
    return appStore.drawerConfig;
  });
  const isCollapsed = computed(() => {
    return cacheStore.sidebarCollapsed;
  });
  const setDrawerConfig = (drawer: DrawerConfig) => {
    copyObjectToReactive(drawerConfig, drawer);
  };
  const $message = {
    error: message.error.bind(null),
    success: message.success.bind(null),
    warning: message.warning.bind(null),
    info: message.info.bind(null),
  };
  const $confirm = (
    message = '',
    title = '',
    config: {
      confirmButtonText: string;
      cancelButtonText?: string;
      type: 'confirm';
      width?: number;
    } = {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'confirm',
    }
  ) => {
    return new Promise((resolve, reject) => {
      Modal[config.type]({
        title: title,
        width: config.width || 520,
        content: message,
        okText: config.confirmButtonText,
        cancelText: config.cancelButtonText,
        centered: true,
        onOk() {
          return resolve('');
        },
        onCancel() {
          return reject('error');
        },
      });
    });
  };
  const $showDrawer = (drawerConfig: DrawerConfig) => {
    appStore.SET_DRAWER_CONFIG(drawerConfig);
  };
  const $showAlert = (
    title: string,
    alertContent: string | Recordable,
    callback?: Fn,
    cancel?: Fn,
    moreConfig?: Recordable
  ) => {
    appStore.SET_ALERT_CONFIG({
      title,
      alertContent,
      callback,
      cancel,
      moreConfig,
    });
  };
  const $showChange = (entity: Recordable, entityType: string) => {
    appStore.SET_WE_CHANGE({
      entity,
      entityType,
    });
  };
  const $showDialog = (weDialog: DialogConfig) => {
    appStore.SET_WE_DIALOG(weDialog);
  };
  const $showOperLog = (weOperLog: Recordable) => {
    appStore.SET_WE_OPERLOG(weOperLog);
  };
  return {
    isSupplyPage,
    isDemandPage,
    isPurchasePage,
    isDirectPage,
    isBackupPage,
    isDetailPage,
    drawerConfig,
    isCollapsed,
    setDrawerConfig,
    $message,
    $confirm,
    $showDrawer,
    $showAlert,
    $showChange,
    $showDialog,
    $showOperLog,
  };
}
