
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { BasicColumn, useTable } from '@/components/WeTable';
  import { computed, defineComponent, ref } from 'vue';
  import {
    delete_gsuit_config,
    edit_gsuit_configs,
    edit_cloud_usd_to_cny,
    get_gsuit_config_page,
    get_gsuit_billing_page,
    edit_gsuit_billings,
  } from '@/api/cloud-gsuite';
  import { edit_bill } from '@/api/erp-bills';
  import { isEmptyData } from '@/utils/common';
  import { appStore } from '@/store/modules/app';
  import dayjs from 'dayjs';
  import { useUser } from '@/hooks/useUser';
  import { required } from '@/utils/validate';
  import { write_cloud_billing_result } from '@/api/cloud-gcp';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { omit } from 'lodash-es';
  import { useApp } from '@/hooks/useApp';
  export default defineComponent({
    name: 'CloudGsuiteBill',
    props: {
      isInserted: {
        type: Boolean,
        default: false,
      },
      contract_id: {
        type: Number,
        default: -1,
      },
    },
    setup(props) {
      const { user, hasPermission } = useUser();
      const { getTfItems, appInfo } = useAppInfo();
      const { $showDialog, $message, $showOperLog } = useApp();
      const tab = ref('GSuite对账配置');
      props.isInserted;
      const is_guite_config = computed(() => {
        return tab.value === 'GSuite对账配置';
      });
      const writeGsuiteBilling = () => {
        const inputs = [
          { result: 'month', type: 'month', label: '选择月份', rules: [required()] },
          {
            result: 'to_next_state',
            type: 'radio',
            label: 'To Next State',
            rules: [required()],
            items: getTfItems,
            init: false,
          },
          {
            result: 'buline_name',
            type: 'select',
            label: '业务',
            rules: [required()],
            items: [{ id: 'GSuite', name: 'GSuite' }],
            init: 'GSuite',
            width: 24,
          },
        ];
        $showDialog({
          title: '将对账结果写入账单',
          width: '500px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            write_cloud_billing_result(result).then((res) => {
              $showOperLog({
                operation_group: res.operation_group,
                afterClose: () => {
                  appStore.SET_WE_DIALOG(null);
                },
              });
            });
          },
        });
      };
      const doGsuiteBilling = () => {
        const inputs = [
          {
            result: 'month',
            type: 'month',
            label: '选择月份',
            rules: [required('string', '请选择月份')],
            width: 12,
          },
        ];

        $showDialog({
          title: 'Do Gsuite Billing',
          width: '580px',
          labelCol: '100px',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            result.buline_name = 'GSuite';
            edit_cloud_usd_to_cny(result).then(() => {
              $message.success('Done');
              appStore.SET_WE_DIALOG(null);
            });
          },
        });
      };
      const table_config = computed<Recordable>(() => {
        const config: Recordable = {
          columns,
          title: 'GSuite对账',
          api: (params) => {
            params.fields.push('id');
            if (tab.value === 'GSuite对账配置') {
              if (props.isInserted) {
                params.fields.push('contract_id');
                params.page_params.filters.push({
                  field: 'contract_id',
                  content: `${props.contract_id}`,
                  type: 'text',
                });
              }
              return get_gsuit_config_page(params);
            } else {
              return get_gsuit_billing_page(params);
            }
          },
          moreFunc: [
            { title: 'GSuite自动对账', click: () => doGsuiteBilling() },
            { title: '对账结果写入账单', click: () => writeGsuiteBilling() },
          ],
        };
        if (!props.isInserted) {
          config.tabConfig = {
            tabItems: [
              { key: 'GSuite对账配置', tab: 'GSuite对账配置' },
              { key: 'GSuite对账明细', tab: 'GSuite对账明细' },
            ],
          };
        }
        return config;
      });
      const columns = computed<BasicColumn[]>(() => {
        if (is_guite_config.value) {
          const list: BasicColumn[] = [
            {
              title: '合同ID',
              dataIndex: 'contract_id',
              showTop: true,
            },

            {
              title: '客户名称',
              dataIndex: 'company__name',
              showTop: true,
            },
            {
              title: '域名',
              dataIndex: 'domain_name',
              showTop: true,
            },
            {
              title: '类型',
              dataIndex: 'subscription',
            },
            {
              title: '单价',
              dataIndex: 'revenue_price',
            },
            {
              title: '备注',
              dataIndex: 'comment',
            },
            {
              title: '月份',
              dataIndex: 'month',
              componentType: 'a-month-picker',
              componentProps: {
                valueFormat: 'YYYY-MM',
              },
              init: props.isInserted ? dayjs().subtract(1, 'months').format('YYYY-MM') : undefined,
              showTop: true,
              format: (text) => {
                return dayjs(text).format('YYYY-MM');
              },
            },
          ];
          if (props.isInserted) {
            list.push({ title: '操作', dataIndex: '', flag: 'ACTION', slots: { customRender: 'g-action' } });
            return list.filter((item) =>
              ['域名', '类型', '单价', '备注', '月份', '操作'].includes(item.title as string)
            );
          }
          return list;
        } else {
          return [
            {
              title: '合同ID',
              dataIndex: 'contract_id',
              showTop: true,
              ignoreField: true,
            },
            { title: '开票方式', dataIndex: 'rd_manual_invoice', slots: { customRender: 'rd_manual_invoice' } },
            {
              title: '客户名称',
              dataIndex: 'company__name',
              showTop: true,
            },
            {
              title: '域名',
              dataIndex: 'domain_name',
              showTop: true,
            },
            {
              title: '类型',
              dataIndex: 'subscription',
            },
            {
              title: '描述',
              dataIndex: 'description',
            },
            {
              title: '起始日期',
              dataIndex: 'start_date',
            },
            {
              title: '结束日期',
              dataIndex: 'end_date',
            },
            {
              title: '使用天数',
              dataIndex: 'days',
              ignoreField: true,
            },
            {
              title: '月份',
              dataIndex: 'month',
              componentType: 'a-month-picker',
              componentProps: {
                valueFormat: 'YYYY-MM',
              },
              ignoreField: true,
              showTop: true,
              format: (text) => {
                return dayjs(text).format('YYYY-MM');
              },
            },
            {
              title: '席位数',
              dataIndex: 'quantity',
            },
            {
              title: '单价',
              dataIndex: 'revenue_price',
              ignoreField: true,
            },
            {
              title: '成本',
              dataIndex: 'checked_cost',
              componentType: 'we-number-range',
              ignoreField: true,
            },
            {
              title: '暂估成本',
              dataIndex: 'unchecked_cost',
              componentType: 'we-number-range',
              ignoreField: true,
            },
            {
              title: '收入',
              dataIndex: 'revenue',
              componentType: 'we-number-range',
            },
            {
              title: '净利润',
              dataIndex: 'profit',
              componentType: 'we-number-range',
            },
          ];
        }
      });
      const [register, { reload }] = useTable(table_config.value);
      return {
        tab,
        user,
        register,
        reload,
        columns,
        getTfItems,
        hasPermission,
        is_guite_config,
        appInfo,
        isEmptyData,
      };
    },
    methods: {
      editData(record) {
        let isEdit = !isEmptyData(record);
        const inputs: DialogFormItem[] = this.columns
          .map((column) => {
            let type = 'text';
            if (['month'].includes(column.dataIndex!)) {
              type = 'month';
            }
            if (['subscription'].includes(column.dataIndex!)) {
              type = 'select';
            }
            const input: Recordable = {
              label: column.title,
              result: column.dataIndex,
              type,
              width: 24,
              rules: [required('string')],
            };
            if (input.label === '备注') {
              input.placeholder = '若没有备注信息，请输入无。';
            }
            if ('subscription' === column.dataIndex!) {
              input.items = this.appInfo.key_vals.gsuite_config_type.split('\t').map((x) => ({ id: x, name: x }));
            }
            return input;
          })
          .filter((item) => item.result !== '') as DialogFormItem[];
        const title = isEdit ? '编辑' : '添加';

        const dialogConfig: DialogConfig = {
          title,
          width: '600px',
          labelCol: '60px',
          blocks: [{ type: 'input', value: inputs }],
          ...(isEdit ? { init: record } : {}),
          callback: (result) => {
            const configs = [{ ...result, contract_id: this.contract_id }];
            edit_gsuit_configs({ configs })
              .then((res) => {
                if (res.error_message) {
                  appStore.SET_WE_DIALOG(null);
                  this.$message.error(`${res.error_message}`);
                } else {
                  this.reload();
                  appStore.SET_WE_DIALOG(null);
                  this.$message.success(`${title}成功`);
                }
              })
              .catch(() => {
                appStore.SET_WE_DIALOG(null);
                this.$message.error(`${title}失败`);
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      async deleteData(record) {
        await this.$confirm('是否确认删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'confirm',
        });
        delete_gsuit_config({ id: record.id })
          .then(() => {
            this.reload();
            this.$message.success('删除成功');
          })
          .catch(() => {
            this.$message.success('删除失败');
          });
      },
      importGsuiteConfigData() {
        const headers: { label: string; key: string }[] = this.columns
          .map((item) => ({ key: item.dataIndex as string, label: item.title as string }))
          .filter((item) => item.label !== '客户名称');
        const dialogConfig: DialogConfig = {
          title: '导入',
          width: '600px',
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers } }],
          callback: (result) => {
            const configs = result.excel_data;
            edit_gsuit_configs({ configs })
              .then(() => {
                this.reload();
                appStore.SET_WE_DIALOG(null);
                this.$message.success(`导入成功`);
              })
              .catch(() => {
                appStore.SET_WE_DIALOG(null);
                this.$message.success(`导入失败`);
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      importGsuiteDetailData() {
        const month = dayjs().subtract(1, 'months').format('YYYY-MM');
        const inputs: DialogFormItem[] = [
          { type: 'month', result: 'month', rules: [required()], label: '月份', width: 24, init: month },
        ];
        const headers: { label: string; key: string }[] = [
          { label: 'Domain name', key: 'domain_name' },
          { label: 'Subscription', key: 'subscription' },
          { label: 'Description', key: 'description' },
          { label: 'Order name', key: 'order_name' },
          { label: 'Start date', key: 'start_date' },
          { label: 'End date', key: 'end_date' },
          { label: 'Quantity', key: 'quantity' },
          { label: 'PO number', key: 'p0_number' },
          { label: 'Amount', key: 'cost' },
        ];
        const converter = (arr: Recordable) => {
          arr.forEach((ele) => {
            if (!isEmptyData(ele.subscription)) {
              ele.subscription = ele['subscription'].replaceAll(/Â/g, '');
            }
            if (!isEmptyData(ele.domain_name)) {
              ele.domain_name = ele['domain_name'].replaceAll(/Â/g, '');
            }
          });
          return arr;
        };
        const dialogConfig: DialogConfig = {
          title: '导入(以选择的月份为准)',
          width: '600px',
          labelCol: '100px',
          blocks: [
            { type: 'input', value: inputs },
            { type: 'excel', result: 'excel_data', config: { headers, json_config: { range: 9 }, converter } },
          ],
          callback: (result) => {
            const year = dayjs(result.month).year();
            result.excel_data.forEach((item) => {
              item.start_date = dayjs(item.start_date).format(`${year}-MM-DD`);
              item.end_date = dayjs(item.end_date).format(`${year}-MM-DD`);
            });

            edit_gsuit_billings({ billings: result.excel_data, ...omit(result, ['excel_data']), is_create: true })
              .then((res) => {
                if (res.error_code === 20003) {
                  this.$message.error(res.error_message);
                  appStore.SET_WE_DIALOG(null);
                } else {
                  this.reload();
                  this.$message.success('导入成功');
                  appStore.SET_WE_DIALOG(null);
                }
              })
              .catch(() => {
                this.$message.success('导入失败');
                appStore.SET_WE_DIALOG(null);
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      editManuallInvoice(record) {
        console.log(record);
        this.$showDialog({
          title: '修改帐单开票方式',
          width: '500px',
          blocks: [
            {
              type: 'input',
              value: [
                {
                  result: 'is_manual_invoice',
                  type: 'select',
                  label: '开票方式',
                  rules: [required()],
                  init: record.rd_manual_invoice,
                  items: [
                    { text: '自动开票', value: false },
                    { text: '人工开票', value: true },
                  ],
                  width: 24,
                },
              ],
            },
          ],
          callback: (result) => {
            edit_bill({ id: record.bill_id, ...result }).then(() => {
              appStore.SET_WE_DIALOG(null);
              this.reload();
            });
          },
        });
      },
    },
    watch: {
      tab() {
        this.reload();
      },
    },
  });
