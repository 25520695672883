
  import { defineComponent, computed, unref, ref, nextTick } from 'vue';
  import SiderMenu from './components/SiderMenu.vue';
  import { useUser } from '@/hooks/useUser';
  import { appStore } from '@/store/modules/app';
  import { cacheStore } from '@/store/modules/cache';
  import { getCasCaderMenus } from '@/router/menu';
  import { MenuItem } from 'types/config';
  import { isEmptyData } from '@/utils/common';
  import { useAppInfo } from '@/hooks/useAppInfo';

  export default defineComponent({
    name: 'AppLayoutSider',
    components: {
      SiderMenu,
    },
    setup() {
      const collapsed = computed({
        get() {
          return cacheStore.sidebarCollapsed;
        },
        set(val: boolean) {
          if (val === false) {
            appStore.SET_DRAWER_CONFIG(null);
          }
          nextTick(() => {
            cacheStore.TOGGLE_SIDEBAR_COLLAPSED(val);
          });
        },
      });
      const { getFilteredHWRoutesAndMenus, getMatchedMenuKeyByRoute } = useUser();
      const filter = (inputValue: string, path: any[]) => {
        return path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
      };
      const menuOptions = ref<(MenuItem & { id: string; name: string })[]>([]);
      const selected_menu_item = ref<string | undefined>(undefined);
      const { sub_app_suffix_list } = useAppInfo();
      const menus = computed(() => {
        const mount_menu_key = getMatchedMenuKeyByRoute();
        if (mount_menu_key.length > 1) {
          const matchedMenuItem = unref(getFilteredHWRoutesAndMenus).menus.filter((item) => {
            return item.path === `/${mount_menu_key[0]}`;
          })[0];
          if (matchedMenuItem && matchedMenuItem.hasTag && matchedMenuItem.children) {
            const index = matchedMenuItem.children.findIndex(
              (item) => item.path === `/${mount_menu_key[0]}/${mount_menu_key[1]}`
            );
            if (index === -1) {
              return [];
            }
            return matchedMenuItem.children[index].children;
          }
          if (matchedMenuItem && matchedMenuItem.children) {
            return matchedMenuItem.children;
          } else {
            return [];
          }
        } else {
          return [];
        }
      });
      return {
        sub_app_suffix_list,
        collapsed,
        menus,
        menuOptions,
        filter,
        selected_menu_item,
        getFilteredHWRoutesAndMenus,
        getMatchedMenuKeyByRoute,
      };
    },

    watch: {
      collapsed: {
        handler(val) {
          this.$nextTick(() => {
            appStore.SET_SLIDE_WIDTH(document.getElementById('app_sider')?.clientWidth || 0);
          });
          this.$setGlobalState({
            is_collapsed: val,
          });
        },
        immediate: true,
      },
      '$route.path': {
        handler() {
          this.getMenu();
        },
      },
      menus: {
        handler(val) {
          if (isEmptyData(val)) {
            cacheStore.TOGGLE_SIDEBAR_COLLAPSED(true);
          }
        },
      },
    },
    created() {
      this.getMenu();
    },
    methods: {
      getMenu() {
        const mount_menu_key = this.getMatchedMenuKeyByRoute();
        if (mount_menu_key.length > 1) {
          const matchedMenuItem = this.getFilteredHWRoutesAndMenus.menus.find((item) => {
            return item.path === `/${mount_menu_key[0]}`;
          })!;
          if (!isEmptyData(matchedMenuItem)) {
            if (!isEmptyData(matchedMenuItem.hasTag) && matchedMenuItem.hasTag) {
              this.menuOptions = getCasCaderMenus(matchedMenuItem.children!);
              this.selected_menu_item = `/${mount_menu_key[0]}/${mount_menu_key[1]}`;
            } else {
              this.menuOptions = [];
              this.selected_menu_item = undefined;
            }
          } else {
            this.menuOptions = [];
            this.selected_menu_item = undefined;
          }
        } else {
          this.menuOptions = [];
          this.selected_menu_item = undefined;
        }
      },
      changeMenus(val: string) {
        let path = val;
        const findItem = this.sub_app_suffix_list.find((item) => path.includes(item));
        const findValue = this.menuOptions.find((item) => path.includes(item.path));
        if (findValue?.children) {
          path = findValue.children[0].path;
          if (findValue.children[0].children) {
            path = findValue.children[0].children[0].path;
          }
        }
        if (!isEmptyData(findItem)) {
          history.pushState(
            {
              ...history.state,
              ...{
                back: this.$route.fullPath,
                current: path.replaceAll(findItem as string, ''),
              },
            },
            '',
            path
          );
          location.reload();
        } else {
          this.$router.push({ path });
        }
      },
    },
  });
