
  import type { BasicTableProps, TableActionType } from './types/table';
  import { FormItem, ComponentType } from './types/form';
  import { defineComponent, ref, computed, unref, toRaw, reactive, inject } from 'vue';
  import TableHeader from './components/TableHeader.vue';
  import TableSearchForm from './components/TableSearchForm.vue';
  import TableInfo from './components/TableInfo.vue';
  import TableChart from './components/TableChart.vue';
  import { usePagination } from './hooks/usePagination';
  import { useColumns } from './hooks/useColumns';
  import { useDataSource } from './hooks/useDataSource';
  import { useLoading } from './hooks/useLoading';
  import { useTableForm } from './hooks/useTableForm';
  import { useRowSelection } from './hooks/useRowSelection';
  import omit from 'lodash/omit';
  import { basicProps } from './props';
  import { createTableContext } from './hooks/useTableContext';
  import orderBy from 'lodash-es/orderBy';
  import { cloneDeep } from 'lodash-es';
  import { copyObjectToReactive, isEmptyData } from '@/utils/common';

  export default defineComponent({
    name: 'WeTable',
    components: {
      TableHeader,
      TableSearchForm,
      TableInfo,
      TableChart,
    },
    props: basicProps,
    emits: [
      'fetch-success',
      'fetch-error',
      'register',
      'update:modelValue',
      'selection-change',
      'update:activeKey',
      'refreshData',
      'table_search_form_change',
    ],
    setup(props, { attrs, emit, slots }) {
      const table_search_form_ref = ref<any>(null);
      const tableElRef = ref<ComponentRef>(null);
      const table_chart_ref = ref<ComponentRef>(null);
      const wrapRef = ref<Nullable<HTMLDivElement>>(null);
      const innerPropsRef = ref<Partial<BasicTableProps>>();
      const table_chart_config = reactive({
        x_axis: {},
        y_axises: [],
        pie_field: undefined,
        echart_type: 'line',
      });

      const computedActiveKey = computed({
        get() {
          return props.activeKey;
        },
        set(val) {
          table_search_form_ref.value?.clear_form(false);
          setTimeout(() => (table_search_form_ref.value!.need_reload = true), 600);
          emit('update:activeKey', val);
        },
      });
      const getProps = computed<BasicTableProps>(() => {
        return { ...props, ...unref(innerPropsRef) } as BasicTableProps;
      });
      let table_display_type = ref(unref(getProps).display_type);

      const is_table_display = computed(() => table_display_type.value === 'table');
      const { setPagination, getPaginationRef } = usePagination(getProps);
      const { getViewColumns, getColumns, setColumns } = useColumns(getProps, getPaginationRef);
      const tabConfig = computed(() => {
        const { tabConfig } = unref(getProps);
        return tabConfig;
      });
      const scroll = computed<{ x?: number | true; y?: number }>(() => {
        if (!isEmptyData(unref(getProps).scroll)) {
          return unref(getProps).scroll!;
        } else {
          return { x: true };
        }
      });
      const getFormProps = computed((): Partial<{ formItems: FormItem[] }> => {
        const { useFilterFromColumns, formItems } = unref(getProps);
        if (useFilterFromColumns) {
          const filterFormItems: FormItem[] = getColumns({ ignoreIndex: true, ignoreAction: true })
            .filter((jtem) => jtem.componentType && !jtem.hiddenFilter)
            .map((item) => {
              return {
                init: item.init,
                field: item.filterField ? item.filterField : (item.dataIndex as string),
                label: item.title as string,
                showTop: item.showTop ? item.showTop : false,
                componentType: item.componentType as ComponentType,
                showElement: true,
                componentProps: {
                  placeholder: item.componentProps?.placeholder
                    ? item.componentProps?.placeholder
                    : item.componentType === 'we-number-range' || item.componentType === 'a-range-picker'
                    ? [item.title as string, '']
                    : (item.title as string),
                  ...item.componentProps,
                },
                changeFn: item.changeFn,
              };
            });
          return {
            formItems: orderBy(filterFormItems, ['showTop'], ['desc', 'desc']),
          };
        } else {
          return {
            formItems: orderBy(formItems, ['showTop'], ['desc', 'desc']),
          };
        }
      });
      const is_in_page_detail = inject<any>('is_in_page_detail', false);

      const is_hidden_table = computed(() => {
        return is_in_page_detail && !getProps.value.api && cacheDataSourceRef.value.length === 0;
      });
      const { getLoading, setLoading } = useLoading(getProps);
      const [registerForm, formActions] = useTableForm();
      const {
        setTableData,
        getOptions,
        getDataSource,
        handleTableChange,
        getDataSourceRef,
        cacheDataSourceRef,
        updateTableData,
        getRowKey,
        getTotalCount,
        fetchAllNoChange,
        getRowSpan,
        reload,
      } = useDataSource(
        getProps,
        {
          getPaginationRef,
          setPagination,
          setLoading,
          getFieldsValue: formActions.getFieldsValue,
          getColumns,
        },
        emit
      );
      const { getRowSelectionRef, getSelectRows, clearSelectedRowKeys, getSelectRowKeys } = useRowSelection(
        getProps,
        emit,
        getDataSource
      );
      const getBindValues = computed(() => {
        const dataSource = toRaw(unref(getDataSourceRef));
        const { initItem } = unref(getProps);
        if (initItem) {
          dataSource.forEach((item) => {
            initItem(item);
          });
        }
        let propsData: Recordable = {
          size: 'middle',
          table_title: unref(getProps).title,
          activeKey: unref(getProps).activeKey,
          showTableHeader: unref(getProps).showTableHeader,
          showTableInfo: unref(getProps).showTableInfo,
          useSearchForm: unref(getProps).useSearchForm,
          useWatchInitDefault: unref(getProps).useWatchInitDefault,
          loading: unref(getLoading),
          tableLayout: unref(getProps).tableLayout,
          sortDirections: unref(getProps).sortDirections,
          rowKey: unref(getRowKey),
          columns: toRaw(unref(getViewColumns())),
          rowSelection: unref(getRowSelectionRef),
          selections: unref(getProps).selections,
          pagination: toRaw(unref(getPaginationRef)),
          scroll: unref(scroll),
          dataSource,
          moreFunc: unref(getProps).moreFunc,
          need_view_filter: unref(getProps).need_view_filter,
          table_key: unref(getProps).table_key,
          ...attrs,
          tableCol: unref(getProps).tableCol,
        };
        if (slots.expandedRowRender) {
          propsData = omit(propsData, 'scroll');
        }
        propsData = omit(propsData, 'class');
        propsData.getCollect = true;
        if (unref(getProps).getCollect === false) {
          propsData.getCollect = false;
        }
        return propsData;
      });
      const getEmptyDataIsShowTable = computed(() => {
        const { emptyDataIsShowTable, useSearchForm } = unref(getProps);
        if (emptyDataIsShowTable || !useSearchForm) {
          return true;
        }
        return !!unref(getDataSourceRef).length;
      });
      function setProps(props: Partial<BasicTableProps>) {
        innerPropsRef.value = { ...unref(innerPropsRef), ...props };
      }
      const tableAction: TableActionType = {
        reload,
        fetchAllNoChange,
        getRowSpan: getRowSpan,
        setTableData,
        getColumns,
        setColumns,
        getViewColumns,
        getDataSource,
        getTotalCount,
        setProps,
        updateTableData,
        getSelectRows,
        clearSelectedRowKeys,
        getSelectRowKeys,
        getOptions,
        getFieldsValue: formActions.getFieldsValue,
        set_search_item_value: formActions.set_search_item_value,
      };
      createTableContext({ ...tableAction, wrapRef, getBindValues, getProps });

      emit('register', tableAction);

      const getColorFieldItem = computed(() => {
        const Dcolumns = cloneDeep(unref(getBindValues).columns);
        return Dcolumns.filter((item) => {
          return item.colorField;
        });
      });

      return {
        table_search_form_ref,
        computedActiveKey,
        tabConfig,
        getBindValues,
        getEmptyDataIsShowTable,
        handleTableChange,
        tableElRef,
        getLoading,
        wrapRef,
        tableAction,
        getFormProps,
        registerForm,
        getColorFieldItem,
        getViewColumns,
        table_display_type,
        is_table_display,
        table_chart_ref,
        table_chart_config,
        getTotalCount,
        is_hidden_table,
      };
    },
    methods: {
      handle_table_search_form_change(val) {
        this.$emit('table_search_form_change', val);
        if (!this.is_table_display) {
          (this.$refs.table_chart_ref as any).get_table_data();
        }
      },
      change_display_type(val, data) {
        this.table_display_type = val;
        if (val === 'chart') {
          copyObjectToReactive(this.table_chart_config, data);
        } else {
          copyObjectToReactive(this.table_chart_config, {
            x_axis: {},
            y_axises: [],
            pie_field: undefined,
            echart_type: 'line',
          });
        }
      },
    },
  });
