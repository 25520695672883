import request from '@/utils/request';

export function get_gcp_billing_config(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_gcp_billing_config', params);
}
export function get_gcp_bill_list(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_gcp_bill_list', params);
}
export function get_gcp_billing_one_month(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_gcp_billing_one_month', params);
}
export function do_gcp_billing(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/do_gcp_billing', params, {
    timeout: 600000,
  });
}
export function write_cloud_billing_result(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/write_cloud_billing_result', params);
}
export function get_gcp_billing_history(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_gcp_billing_history', params);
}
export function gcp_copy_config_by_month(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/gcp_copy_config_by_month', params);
}
export function delete_gcp_billing_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_gcp_billing_config', params);
}
export function batch_accept_bills(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/batch_accept_bills', params);
}
export function import_gcp_cost_list(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/import_gcp_cost_list', params);
}
