
  interface IAgencyContract {
    contract_items: Recordable[];
    agency_name: string;
    agency_id: number;
    contract_id: number | undefined;
  }
  import {
    cancel_ml_account_apply,
    create_ml_account_apply,
    edit_ml_accounts,
    get_account_page,
    get_china_main_account_managers,
    get_ml_account_apply_contract_list,
    get_ml_account_apply_item,
    ml_account_apply_select_agency,
    ml_account_apply_select_operator_done,
    upload_ml_accounts,
  } from '@/api/common-cms';
  import { DialogConfig, DialogFormItem } from '@/components/WeDialog/types';
  import { BasicColumn, BasicTableProps, useTable } from '@/components/WeTable';
  import { computed, defineComponent, ref, unref } from 'vue';
  import { useCMS } from './hooks/useCMS';
  import { isEmptyData, getTextFromValue, getIsEmptyItemList, copyObjectToReactive } from '@/utils/common';
  import { appStore } from '@/store/modules/app';
  import { get_account_config_page } from '@/api/meta';
  import { SelectOption } from 'types/store';
  import { translateCommonList } from '@/store/modules/commonLists';
  import { useJump } from '@/hooks/useJump';
  import { cloneDeep, pick, sortBy } from 'lodash-es';
  import { useOptionList } from '@/hooks/useOptionList';
  import { useUser } from '@/hooks/useUser';
  import { required } from '@/utils/validate';
  import { useAppInfo } from '@/hooks/useAppInfo';
  export default defineComponent({
    name: 'CommonCMSDetail',
    props: {
      id: {
        type: [Number, String],
        default: -1,
      },
    },
    setup(props) {
      const {
        getInputs,
        agency_list,
        product_items,
        demand_am_list,
        isRS,
        isMB,
        isPD,
        isUG,
        isPK,
        is_foreign,
        is_china,
        status_list,
        ad_type_list,
        is_google,
      } = useCMS();
      const { get_ml_delivery_method, get_mcc_account_list, region_type_list } = useOptionList();
      const { jumpToContract } = useJump();
      const { appInfo } = useAppInfo();
      const { user, isGod } = useUser();
      const business_type = ref(0);
      const title = ref('');
      const platform = ref('');
      const delivery_method = ref(0);
      const op_name_list = ref<Recordable[]>([]);
      const detail_data = ref<Recordable>({});
      const detail_data_edit = ref<Recordable>({});
      const demand_contract_items = ref<SelectOption[]>([]);
      const supply_contract_items = ref<Recordable[]>([]);
      const tip_message_list = ref<Recordable>({
        suffix: '',
        items: [],
      });
      const agency_datasource = ref<Recordable[]>([]);
      const selected_agency_list = ref<number[]>([]);
      const dialogVisible = ref(false);
      const editMode = ref(false);
      const user_id = computed(() => {
        return user.value.id;
      });
      const is_show_delete = computed(() => {
        if (isEmptyData(detail_data.value.flow)) {
          return false;
        } else {
          const current_flow_id = detail_data.value.flow.state_list.find(
            (item) => item.id === detail_data.value.flow.current_state_id
          ).owner_id;
          return (
            ['Append Account', 'BD Add Account'].includes(detail_data.value.flow.current_state_key) &&
            user_id.value === current_flow_id
          );
        }
      });
      const china_main_account_manager_id = computed(() => {
        return Number(unref(appInfo).key_vals.china_main_account_manager);
      });
      const [register, { getSelectRows, reload, clearSelectedRowKeys }] = useTable({
        rowSelection: { type: 'checkbox' },
        useSearchForm: false,
        isWeApi: true,
        title: '账号列表',
        api: (params) => {
          params.ml_apply_id = props.id;
          params.fields.push('account_config');
          params.fields.push('ml_apply_id');
          params.fields.push('id');
          params.fields.push('demand_contract_id');
          params.fields.push('supply_contract_id');
          params.fields.push('company_id');
          params.fields.push('buline_id');
          params.show_in = 'apply';
          return get_account_page(params);
        },
      });
      return {
        appInfo,
        title,
        agency_list,
        detail_data,
        detail_data_edit,
        editMode,
        platform,
        business_type,
        register,
        getSelectRows,
        reload,
        dialogVisible,
        agency_datasource,
        delivery_method,
        getInputs,
        product_items,
        demand_contract_items,
        supply_contract_items,
        selected_agency_list,
        tip_message_list,
        jumpToContract,
        get_mcc_account_list,
        clearSelectedRowKeys,
        user_id,
        is_show_delete,
        get_ml_delivery_method,
        region_type_list,
        demand_am_list,
        isRS,
        isMB,
        isPD,
        isUG,
        isPK,
        is_foreign,
        is_china,
        china_main_account_manager_id,
        op_name_list,
        status_list,
        ad_type_list,
        is_google,
        isGod,
      };
    },
    computed: {
      agencyTableConfig() {
        const tableConfig: BasicTableProps = {
          showTableHeader: false,
          useSearchForm: false,
          immediate: false,
          columns: [
            {
              title: '开户代理',
              dataIndex: 'agency_name',
              width: '160px',
            },
            {
              title: 'Supply合同',
              dataIndex: 'contract_id',
              slots: {
                customRender: 'contract_id',
              },
            },
          ],
        };
        return tableConfig;
      },
      columns() {
        const columns: BasicColumn[] = [
          { title: '平台', dataIndex: 'platform' },
          { title: '客户名称', dataIndex: 'rd_company_name' },
          { title: 'Token', dataIndex: 'token', ignoreField: true },
          { title: 'MCC账号', dataIndex: 'mcc_account_name', ignoreField: true },
          { title: '优化师邮箱', dataIndex: 'ua__user_info__email' },
          { title: '账号ID', dataIndex: 'account_id' },
          { title: '账号名称', dataIndex: 'account_name' },
          { title: '服务商账号', dataIndex: 'service_account_id', ignoreField: true },
          { title: '开户代理', dataIndex: 'rd_account_agency_name' },
          { title: '投放版本（V1/V2）', dataIndex: 'version_name' },
          { title: '行业', dataIndex: 'industry' },
          { title: '账户性质', dataIndex: 'account_region' },
          { title: '产品Code', dataIndex: 'product__code' },
          { title: '投放代理', dataIndex: 'rd_service_agency_name' },
          { title: 'Supply合同', dataIndex: 'rd_supply_contract_name' },
          { title: 'Demand合同', dataIndex: 'rd_demand_contract_name' },
          { title: '开户主体', dataIndex: 'account_company_name' },
          { title: '登录账号', dataIndex: 'username' },
          { title: '邮箱密码', dataIndex: 'email_password' },
          { title: '密码', dataIndex: 'password' },
          {
            title: '广告类型',
            dataIndex: 'ad_type',
            format: (text) => getTextFromValue(this.ad_type_list, text, 'id', 'name'),
          },
          {
            title: '状态',
            dataIndex: 'status',
            format: (text) => getTextFromValue(this.status_list, text, 'id', 'name'),
          },
          { title: '创建人', dataIndex: 'rd_creator_name' },
          { title: '创建时间', dataIndex: 'create_time' },
        ];
        const input_items = this.getInputs('table', this);
        let columns_filter = columns.filter((item) => {
          return input_items.map((item) => item.label).includes(item.title as string);
        });
        columns_filter = sortBy(columns_filter, (value) => {
          return input_items.findIndex((item) => item.label === value.title);
        });
        return columns_filter;
      },
      canShowImport() {
        const a =
          !isEmptyData(this.detail_data) &&
          !isEmptyData(this.detail_data.flow) &&
          (this.op_name_list.map((item) => item.id).includes(this.user_id) ||
            this.detail_data.business_type === this.isUG ||
            (this.china_main_account_manager_id === this.user_id && this.detail_data.region_type === this.is_china) ||
            (JSON.parse(this.detail_data.agency_contract_owner_ids).includes(this.user_id) &&
              this.detail_data.region_type === this.is_china &&
              [this.isMB, this.isPD].includes(this.detail_data.business_type)) ||
            (this.detail_data.region_type === this.is_foreign &&
              [this.isMB, this.isPD, this.isRS].includes(this.detail_data.business_type)));

        const b =
          [this.isPK, this.isRS].includes(this.detail_data.business_type) &&
          this.user_id === this.detail_data.flow.current_state.owner_id;

        return a || b || this.isGod;
      },
      canShowAdd() {
        return (
          !isEmptyData(this.detail_data) &&
          ((this.detail_data.region_type === this.is_china &&
            [this.isMB].includes(this.detail_data.business_type) &&
            (this.op_name_list.map((item) => item.id).includes(this.user_id) ||
              JSON.parse(this.detail_data.agency_contract_owner_ids).includes(this.user_id))) ||
            (this.detail_data.region_type === this.is_foreign &&
              [this.isMB, this.isRS].includes(this.detail_data.business_type)))
        );
      },
    },
    methods: {
      required,
      getTextFromValue,
      translateCommonList,
      isEmptyData,
      initData() {
        get_ml_account_apply_item({ id: Number(this.id) }).then((res) => {
          if (!res.code && res.product_id) {
            res.code = this.product_items.find((item) => item.product_id === res.product_id)!.id;
          }
          this.detail_data = res;
          copyObjectToReactive(this.detail_data_edit, pick(res, ['id', 'port_type']));
          this.business_type = res.business_type;
          this.platform = res.platform;
          this.delivery_method = res.delivery_method;
          this.title = res.name;
        });
        get_ml_account_apply_contract_list({ contract_type: 'demand' }).then((res) => {
          this.demand_contract_items = res;
        });
        get_china_main_account_managers().then((res) => {
          this.op_name_list = res;
        });
        get_ml_account_apply_contract_list({ contract_type: 'supply' }).then((res) => {
          this.supply_contract_items = res;
        });
      },
      getMessageTip(val) {
        if ([this.isPD, this.isUG].includes(this.business_type)) {
          return;
        }
        const is_media_by_am =
          !isEmptyData(this.detail_data.flow) && this.detail_data.flow.current_state_name === '媒体AM开户';
        const is_demand_config =
          !isEmptyData(this.detail_data.flow) && this.detail_data.flow.current_state_key === 'Contract Config';
        if (!(is_media_by_am || is_demand_config)) {
          return;
        }
        const map_config = {
          rebate_reate: is_media_by_am ? 'supply_rebate_rate' : 'demand_rebate_rate',
          suffix: is_media_by_am ? '以下Supply合同没有配置返点信息，请配置' : '以下Deman合同没有配置返点信息，请配置',
        };
        const items: number[] = [];
        if (is_media_by_am) {
          val.items.forEach((item) => {
            if (item.supply_contract_id) {
              items.push(item.supply_contract_id);
            }
          });
        }
        if (is_demand_config) {
          val.items.forEach((item) => {
            if (item.demand_contract_id) {
              items.push(item.demand_contract_id);
            }
          });
        }
        if (items.length <= 0) {
          return;
        }
        const params = {
          fields: ['supply_rebate_rate', 'demand_rebate_rate', 'contract_id', 'platform', 'contract__name'],
          page_params: {
            filters: [
              {
                type: 'select',
                field: 'contract_id',
                content: items,
              },
              {
                type: 'text',
                field: 'platform',
                content: this.platform,
              },
            ],
            get_all: true,
          },
        };
        get_account_config_page(params).then((res) => {
          this.tip_message_list.suffix = '';
          this.tip_message_list.items = [];
          if (isEmptyData(res.page_result) || isEmptyData(res.page_result.data_list)) {
            this.tip_message_list.suffix = '';
            this.tip_message_list.items = [];
          } else {
            res.page_result.data_list.forEach((item) => {
              if (isEmptyData(item[map_config.rebate_reate])) {
                const temp = {
                  contract__name: item.contract__name,
                  contract_id: item.contract_id,
                };
                this.tip_message_list.items.push(temp);
                this.tip_message_list.suffix = map_config.suffix;
              }
            });
          }
        });
      },
      handleManualButtonClick() {
        const inputs = this.getInputs('add', this);
        const fields: string[] = inputs.map((item) => item.result!);
        const dialogConfig: DialogConfig = {
          title: '添加账号 - FB/Adwards/TikTok',
          blocks: [{ type: 'input', value: inputs }],
          width: '600px',
          labelCol: '110px',
          callback: (result) => {
            const params = {
              is_add: true,
              platform: this.platform,
              ml_apply_id: this.id,
              accounts: [pick(result, fields)],
              ...(!isEmptyData(this.detail_data.supply_contract_id)
                ? { supply_contract_id: this.detail_data.supply_contract_id }
                : {}),
            };
            upload_ml_accounts(params)
              .then((res) => {
                appStore.SET_WE_DIALOG(null);
                if (res.length > 0) {
                  let showMsg = '';
                  res.forEach((item) => {
                    showMsg += `<span style="display: block; width: max-content; text-align: right; margin-right: 5px;">${JSON.stringify(
                      item
                    )}</span>`;
                  });
                  this.$showAlert('添加失败', showMsg);
                } else {
                  this.$message.success('添加成功');
                  this.initData();
                  this.reload();
                }
              })
              .catch(() => {
                appStore.SET_WE_DIALOG(null);
                this.$message.error('导入失败');
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      handleAutoButtonClick() {
        const headers: { label: string; key: string }[] = this.getInputs('import', this).map((item) => ({
          label: item.label!,
          key: item.result!,
        }));
        this.$showDialog({
          title: '批量导入',
          width: '600px',
          blocks: [{ type: 'excel', result: 'excel_data', config: { headers } }],
          callback: (result) => {
            const required_header = headers.filter((item) => item.label.includes('*'));
            const isEmptyItemList = getIsEmptyItemList(
              result.excel_data,
              required_header.map((item) => item.key)
            );
            if (isEmptyItemList.length !== 0) {
              const lable_name = required_header
                .filter((item) => isEmptyItemList.includes(item.key))
                .map((item) => item.label)
                .join('、');
              const error_message = `${lable_name}字段为必填项，请检查文件`;
              this.$showAlert('导入失败', error_message);
              return;
            }
            if ([this.isMB, this.isPD].includes(this.detail_data.business_type)) {
              let isWrong = false;
              if (headers.map((item) => item.label).includes('开户代理')) {
                if (this.is_china === this.detail_data.region_type) {
                  const rd_agency_name_list = this.detail_data.rd_agency_name_list;
                  isWrong = result.excel_data.some((item) => !rd_agency_name_list.includes(item.account_agency_name));
                } else {
                  const agency_item_name = this.getTextFromValue(
                    this.agency_list,
                    this.detail_data.apply_agency_id,
                    'id',
                    'name'
                  );
                  isWrong = result.excel_data.some((item) => agency_item_name !== item.account_agency_name);
                }
              }
              if (isWrong) {
                this.$message.error('开户代理存在错误，请检查');
                return;
              }
            }
            if (this.detail_data.platform === 'Adwords') {
              let isWrong = false;
              const reg = /\d{3}-\d{3}-\d{4}/;
              isWrong = result.excel_data.some((item) => !reg.test(item.account_id));
              if (isWrong) {
                this.$message.error('账号ID填写有误，请检查');
                return;
              }
            }
            if (this.detail_data.region_type === this.is_foreign && this.detail_data.platform === 'FB') {
              if (this.detail_data.business_type === this.isRS) {
                result.excel_data.forEach((item) => (item.status = 'active'));
              } else {
                result.excel_data.forEach((item) => (item.status = 'active'));
              }
            }
            if (
              this.detail_data.region_type === this.is_foreign &&
              this.detail_data.apply_agency_id === this.is_google
            ) {
              result.excel_data.forEach((item) => (item.ad_type = item.ad_type.toLowerCase()));
            }
            const params = {
              platform: this.platform,
              ml_apply_id: this.id,
              accounts: result.excel_data,
              ...(!isEmptyData(this.detail_data.supply_contract_id)
                ? { supply_contract_id: this.detail_data.supply_contract_id }
                : {}),
            };
            upload_ml_accounts(params)
              .then((res) => {
                appStore.SET_WE_DIALOG(null);
                if (res.length > 0) {
                  let showMsg = '';
                  res.forEach((item) => {
                    const message = `账号${item.account_id}导入失败，失败原因：${item.error_msg}`;
                    showMsg += `<span style="display: block; width: max-content; text-align: right; margin-right: 5px;">${message}</span>`;
                  });
                  showMsg += `<span style="display: block; width: max-content; text-align: right; margin-right: 5px;">请重新导入，开户代理、MCC账号有误可参考<a href="https://webeye.feishu.cn/docs/doccnwCGbWBNIVrtp92FpXM2bUd">HW-买量账号模板帮助文档2.0</a></span>`;
                  this.$showAlert('导入失败', showMsg);
                } else {
                  this.$message.success('导入成功');
                  this.initData();
                  this.reload();
                }
              })
              .catch(() => {
                appStore.SET_WE_DIALOG(null);
                const showMsg = `<span style="display: block; width: max-content; text-align: right; margin-right: 5px;">请重新导入，开户代理、MCC账号有误可参考<a href="https://webeye.feishu.cn/docs/doccnwCGbWBNIVrtp92FpXM2bUd">HW-买量账号模板帮助文档2.0</a></span>`;
                this.$showAlert('导入失败', showMsg);
              });
          },
        });
      },
      async catchFlowFlag(flag) {
        if (flag === 'FLAG_PD_SELECT_AGENCY') {
          this.selectAgency();
        }
        if (flag === 'FLAG_PD_SELECT_OPERATOR') {
          this.selectOpUser();
        }
      },
      selectOpUser() {
        const inputs: DialogFormItem[] = [
          {
            type: 'select',
            label: '选择操作人',
            width: 24,
            result: 'operator_id',
            items: [
              {
                id: 0,
                name: '合同AM',
              },
              ...this.op_name_list,
            ],
            rules: [required()],
          },
        ];
        const dialog: DialogConfig = {
          title: '选择操作人',
          blocks: [{ type: 'input', value: inputs }],
          callback: (result) => {
            const params: Recordable = {
              apply_id: this.id,
              operator_id: result.operator_id,
              select_operator_type: result.operator_id === 0 ? 1 : 2,
            };
            ml_account_apply_select_operator_done(params)
              .then(() => {
                this.$message.success('操作成功');
                appStore.SET_WE_DIALOG(null);
                this.reload();
                this.initData();
              })
              .catch(() => {
                this.$message.error('操作失败');
                appStore.SET_WE_DIALOG(null);
              });
          },
        };
        this.$showDialog(dialog);
      },
      selectAgency() {
        this.dialogVisible = true;
      },
      editAccount() {
        const dialogConfig: DialogConfig = {
          title: '编辑账号',
        };
        this.$showDialog(dialogConfig);
      },
      handleEditAccount(type = 'Demand配置') {
        if (this.getSelectRows().length === 0) {
          this.$message.error('请至少勾选一条数据');
          return;
        }
        const isTieDemand = type === 'Demand配置';
        const result_type = isTieDemand ? 'demand_contract_id' : 'supply_contract_id';
        const input: DialogFormItem[] = [
          {
            type: 'select',
            result: result_type,
            label: isTieDemand ? 'Demand合同' : 'Supply合同',
            width: 24,
            items: isTieDemand ? this.demand_contract_items : this.supply_contract_items,
          },
        ];
        const dialogConfig: DialogConfig = {
          title: isTieDemand ? 'Demand配置' : 'Supply配置',
          blocks: [{ type: 'input', value: input }],
          width: isTieDemand ? '400px' : '600px',
          labelCol: '100px',
          callback: (result) => {
            const selectRows = this.getSelectRows();
            const arr = selectRows.map((item) => ({ id: item.id, [result_type]: result[result_type] }));
            edit_ml_accounts({ accounts: arr })
              .then(() => {
                this.$message.success('配置成功');
              })
              .catch(() => {
                this.$message.error('配置失败');
              })
              .finally(() => {
                appStore.SET_WE_DIALOG(null);
                this.clearSelectedRowKeys();
                this.initData();
                this.reload();
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      handleEdit() {
        const selectRows = this.getSelectRows();
        const isSignal = selectRows.length === 1;
        const isMul = selectRows.length > 1;
        if (!(isSignal || isMul)) {
          this.$message.error('请至少勾选一条数据');
          return;
        }
        let inputs: DialogFormItem[] = [];
        if (isSignal) {
          inputs = this.getInputs('edit', this);
        } else {
          inputs = this.getInputs('edit', this)
            .filter((item) => item.type === 'select')
            .map((item) => ({
              ...item,
              rules: [],
            }));
        }
        console.log(inputs);
        const dialogConfig: DialogConfig = {
          title: `编辑账号(${selectRows.length}条)`,
          blocks: [{ type: 'input', value: inputs }],
          width: '600px',
          labelCol: '100px',
          ...(isSignal ? { init: selectRows[0] } : {}),
          callback: (result) => {
            const selectRows = this.getSelectRows();
            const arr = selectRows.map((item) => ({ id: item.id, ...result }));
            edit_ml_accounts({ accounts: arr })
              .then(() => {
                this.$message.success('编辑成功');
              })
              .catch(() => {
                this.$message.error('编辑失败');
              })
              .finally(() => {
                appStore.SET_WE_DIALOG(null);
                this.clearSelectedRowKeys();
                this.initData();
                this.reload();
              });
          },
        };
        this.$showDialog(dialogConfig);
      },
      async initAgencyDataSource() {
        if (this.supply_contract_items.length === 0) {
          await get_ml_account_apply_contract_list({ contract_type: 'supply' }).then((res) => {
            this.supply_contract_items = res;
          });
        }
        const that = this;
        const clone_agency_datasource = cloneDeep(this.agency_datasource);
        const clone_supply_contract_items = cloneDeep(this.supply_contract_items);
        this.agency_datasource = this.selected_agency_list.map((item) => {
          const { name, company_id } = that.agency_list.find((fitem) => fitem.id === item)!;
          const contract_items = clone_supply_contract_items.filter((item) => item.company_id === company_id);
          const findItem = clone_agency_datasource.find((cItem) => cItem.agency_id === item) as IAgencyContract;
          if (isEmptyData(findItem)) {
            const data: IAgencyContract = {
              contract_items,
              agency_id: item,
              agency_name: name,
              contract_id: undefined,
            };
            return data;
          } else {
            return findItem;
          }
        });
      },
      handleSelectedAgency() {
        if (this.agency_datasource.some((item) => isEmptyData(item.contract_id))) {
          this.$message.error('Supply合同为必选项');
          return;
        }
        const selected = this.agency_datasource.map((item) => ({
          agency_id: item.agency_id,
          contract_id: item.contract_id,
        }));
        const params = {
          apply_id: this.id,
          selected,
        };
        ml_account_apply_select_agency(params)
          .then((res) => {
            this.dialogVisible = false;
            if (res.error_code === 20003) {
              this.$showAlert('保存失败', res.error_message);
            } else {
              this.initData();
              this.reload();
              this.$message.success('保存成功');
            }
          })
          .catch(() => {
            this.dialogVisible = false;
            this.$message.success('保存失败');
          });
      },
      saveDataSource(_value, record) {
        Object.assign(this.agency_datasource.filter((item) => record.agency_id === item.agency_id)[0], record);
      },
      cancelMS() {
        this.$showAlert('Waring', '确定要终止该买量账号申请吗？', () => {
          appStore.SET_ALERT_CONFIG(null);
          cancel_ml_account_apply({ id: this.id }).then(() => {
            this.$message.success('终止成功');
            this.initData();
          });
        });
      },
      async deleteAccount() {
        const selectRows = this.getSelectRows();
        if (selectRows.length === 0) {
          this.$message.error('请至少勾选一条数据');
          return;
        }
        const accounts = selectRows.map((item) => ({
          id: item.id,
          is_deleted: true,
        }));
        await this.$confirm('是否确认删除', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'confirm',
        });
        edit_ml_accounts({ accounts })
          .then(() => {
            this.$message.success('删除成功');
            this.clearSelectedRowKeys();
            this.initData();
            this.reload();
          })
          .catch(() => {
            this.$message.error('删除失败');
          });
      },
      assginToAM() {
        const input: DialogFormItem[] = [
          {
            type: 'select',
            result: 'am_id',
            label: 'AM',
            width: 24,
            items: this.demand_am_list,
          },
        ];
        const dialogConfig: DialogConfig = {
          title: '指定AM',
          labelCol: '80px',
          blocks: [{ type: 'input', value: input }],
        };
        this.$showDialog(dialogConfig);
      },
      submitEdit() {
        create_ml_account_apply(this.detail_data_edit)
          .then(() => {
            this.$message.success('编辑成功');
          })
          .catch(() => {
            this.$message.error('编辑失败');
          })
          .finally(() => {
            this.editMode = false;
            this.initData();
          });
      },
    },
    created() {
      this.initData();
    },
  });
