
  import { useUser } from '@/hooks/useUser';
  import { useWindowSize } from '@vueuse/core';
  import { computed, defineComponent, ref } from 'vue';
  export default defineComponent({
    name: 'WaterMark',
    setup() {
      const count = ref(1);
      const { height, width } = useWindowSize();
      const { user, userInfo } = useUser();
      const rows = ref(0);
      const columns = ref(0);
      const wrapper_style = computed(() => {
        return {};
      });
      return {
        count,
        user,
        userInfo,
        height,
        width,
        wrapper_style,
        rows,
        columns,
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.rows = Number((this.height / 64).toFixed(0));
        this.columns = Number((this.width / 260).toFixed(0));
        this.count = this.rows * this.columns;
      });
    },
  });
