import { isLark, isPc } from '@/utils/const';
import Cookies from 'js-cookie';
import { getCurrentInstance } from 'vue';

export function useUtils() {
  const internalInstance = getCurrentInstance();
  const $showAlert = internalInstance?.appContext.config.globalProperties.$showAlert;
  function downloadUrl(url) {
    if (isPc && isLark) {
      const session = (url.includes('?') ? '&' : '?') + 'visit_token=' + Cookies.get('sessionid');
      window.open(url + session, '_blank');
    } else {
      window.open(url, '_blank');
    }
  }
  function showJson(jsonObject) {
    $showAlert(null, JSON.stringify(jsonObject, null, 2), null, null, {
      alertTextStyle: 'white-space: pre-wrap; font-family: monospace;',
      maxWidth: '800px',
    });
  }

  return {
    downloadUrl,
    showJson,
  };
}
