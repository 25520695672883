
  import { useUser } from '@/hooks/useUser';
  import { isEmptyData } from '@/utils/common';
  import { computed, defineComponent } from 'vue';
  export default defineComponent({
    name: 'HeaderMenuItem',
    emits: ['item-mouse-enter'],
    props: {
      menu: {
        type: Object as PropType<Recordable>,
        default: () => ({}),
      },
    },
    setup(props) {
      const { getMatchedMenuKeyByRoute, getMatchedRouteByMenu } = useUser();
      const menuItemClass = computed<Recordable>(() => {
        const mount_menu_key = getMatchedMenuKeyByRoute();
        const is_selected = `/${mount_menu_key[0]}` === props.menu.path;
        if (is_selected) {
          return {
            'hello-header-menu-item': true,
            'hello-header-menu-item-selected': true,
          };
        }
        return {
          'hello-header-menu-item': true,
        };
      });
      return {
        menuItemClass,
        getMatchedRouteByMenu,
      };
    },
    data: () => {
      return {
        leave_time: 0,
        timeout: 80,
      };
    },
    methods: {
      handleMouseEnter(e) {
        this.leave_time = 0;
        setTimeout(() => {
          if (this.leave_time === 0) {
            const offsetLeft = e.clientX - e.offsetX + 10;
            this.$emit('item-mouse-enter', this.menu, offsetLeft);
          }
        }, this.timeout);
      },
      handleMouseLeave() {
        this.leave_time = new Date().getTime();
      },
      handleClick() {
        let menu: Recordable = this.menu;
        while (!isEmptyData(menu.children)) {
          if (!isEmptyData(menu.children) && !isEmptyData(menu.children[0])) {
            menu = menu.children[0];
          }
        }
        if (isEmptyData(menu)) {
          return;
        }
        this.$router.push({ path: this.getMatchedRouteByMenu(menu.path).route_path });
      },
    },
  });
