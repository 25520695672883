import JSPDF from 'jspdf';
import html2canvas from 'html2canvas';
export async function generatorPDFByCanvas(id: string, filename: string) {
  const element = document.getElementById(id)!;
  const scrollWidth = element.scrollWidth;
  const scrollHeight = element.scrollHeight;
  // const opt = {
  //   allowTaint: false,
  //   width: scrollWidth,
  //   height: scrollHeight,
  //   useCORS: true,
  //   windowHeight: scrollHeight,
  //   windowWidth: scrollWidth,
  //   scale: 2,
  //   x: 0,
  //   y: 0,
  //   scrollY: 0,
  //   scrollX: 0,
  //   foreignObjectRendering: true,
  // };
  let file;
  // console.log(opt);
  await html2canvas(element).then(function (canvas: any) {
    const pageData = canvas.toDataURL('image/jpeg', 1.0);
    const pageHeight = (scrollWidth / 592.28) * 841.89;
    let leftHeight = scrollHeight;
    let position = 0;
    const imgWidth = 595.28;
    const imgHeight = (imgWidth / scrollWidth) * scrollHeight;
    const pdf = new JSPDF('p', 'pt', 'a4');
    if (leftHeight < pageHeight) {
      pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
    } else {
      while (leftHeight > 0) {
        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
        leftHeight -= pageHeight;
        position -= 841.89;
        if (leftHeight > 0) {
          pdf.addPage();
        }
      }
    }
    const datauir = pdf.output('datauristring');
    file = dataURLtoFile(datauir, filename);
  });
  return file;
}
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  let mime = '';
  mime = arr![0].match(/:(.*?);/)![1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
export function dataURLtoHTML(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  let mime = '';
  mime = arr![0].match(/:(.*?);/)![1] + ',charset=UTF-8';
  const bstr = decodeURIComponent(atob(arr[1]));
  // let n = bstr.length
  // let u8arr = new Uint8Array(n);
  // while (n--) {
  //   u8arr[n] = bstr.codePointAt(n)!;
  // }
  return new File(['\ufeff', bstr], filename, { type: mime });
}
