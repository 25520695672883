import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "we_upload_container" }
const _hoisted_2 = { class: "files_container" }
const _hoisted_3 = { class: "file_content_container" }
const _hoisted_4 = { class: "file_name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_a_progress = _resolveComponent("a-progress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_upload, {
      multiple: _ctx.multiple,
      accept: _ctx.accept,
      customRequest: _ctx.customUpload,
      showUploadList: false,
      onChange: _ctx.handleChange,
      beforeUpload: _ctx.beforeUpload
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "primary",
          ghost: "",
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isCompleted ? _ctx.uploadText : _ctx.uploadingText), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    }, 8, ["multiple", "accept", "customRequest", "onChange", "beforeUpload"]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileList, (file, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: file.filename
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_svg_icon, {
              "icon-name": file.icon_name
            }, null, 8, ["icon-name"]),
            _createElementVNode("div", _hoisted_4, _toDisplayString(file.filename), 1),
            _withDirectives(_createVNode(_component_svg_icon, {
              "icon-name": "DeleteOutlined",
              class: "file_delete_icon",
              onClick: ($event: any) => (_ctx.deleteFile(index))
            }, null, 8, ["onClick"]), [
              [_vShow, _ctx.editMode]
            ])
          ]),
          _withDirectives(_createVNode(_component_a_progress, {
            percent: file.percent
          }, null, 8, ["percent"]), [
            [_vShow, file.showProgress]
          ])
        ]))
      }), 128))
    ])
  ]))
}