import request from '@/utils/request';

export function handle_flow_action(params: {}) {
  return request.post<null, Recordable>('/api/v1/handle_flow_action', params);
}

export function doPostAsync(url: string, params: {}) {
  return new Promise<Recordable>((resolve) => {
    request.post<null, Recordable>(url, params).then((res) => {
      resolve(res);
    });
  });
}

export function god_edit_flow_state(params: {}) {
  return request.post<null, Recordable>('/api/v1/god_edit_flow_state', params);
}
export function flow_re_send_message(params: {}) {
  return request.post<null, Recordable>('/api/v1/flow_re_send_message', params);
}
// 新增订单 Media
export function edit_ms_order(params: {}) {
  return request.post<null, Recordable>('/api/v1/edit_ms_order', params);
}
// 订单详情 Cloud
export function get_cs_order_detail(params: {}) {
  return request.post<null, Recordable>('/api/v1/get_cs_order_detail', params);
}
// 新增订单 Cloud
export function edit_cs_order(params: {}) {
  return request.post<null, Recordable>('/api/v1/edit_cs_order', params);
}
// 新增订单 Scaler
export function edit_scaler_order(params: {}) {
  return request.post<null, Recordable>('/api/v1/edit_scaler_order', params);
}
// 重置商机 Cloud
export function reset_cs_order(params: {}) {
  return request.post<null, Recordable>('/api/v1/reset_cs_order', params);
}

export function set_cs_order_to_win(params: {}) {
  return request.post<null, Recordable>('/api/v1/set_cs_order_to_win', params);
}
