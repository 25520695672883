import numeral from 'numeral';
function formatMoney(number, decPlaces, decSep?, thouSep?) {
  (decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces),
    (decSep = typeof decSep === 'undefined' ? '.' : decSep);
  thouSep = typeof thouSep === 'undefined' ? ',' : thouSep;
  const sign = number < 0 ? '-' : '';
  const i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))));
  let j = i.length;
  j = j > 3 ? j % 3 : 0;

  return (
    sign +
    (j ? i.substr(0, j) + thouSep : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - (i as any))
          .toFixed(decPlaces)
          .slice(2)
      : '')
  );
}

export function formatMoneyByRound(x, round = 2, is_need_round = true) {
  if (!is_need_round) {
    const res = x.toFixed(round + 1);
    return res.substring(0, res.lastIndexOf('.') + round + 1);
  }
  x = formatMoney(x, round);
  return x;
}
// 格式化数字，round为保留小数点多少位
export function switchNumber(val, round) {
  if (!val || Math.abs(Number(val)) === 0 || Math.abs(Number(val)) < 0.0001) {
    return '-';
  } else if (typeof val === 'number') {
    return formatMoneyByRound(val, round);
  } else if (typeof val === 'string') {
    return val.split('\t').join(', ');
  } else {
    return val;
  }
}
export function formatNumber(val, format) {
  if (!val || /\d(?:.(\d*))?e([-]\d+)/.test(val)) {
    val = 0;
  }
  return numeral(val).format(format);
}
export function eq(a, b) {
  if (a === null && b === null) {
    return true;
  }

  return Math.abs(a - b) < 0.005;
}

export function roundNumber(x) {
  if (typeof x === 'number') {
    x = Math.round(x * 100) / 100;
  }
  return x;
}

export function digitUppercase(amount) {
  const fraction = ['角', '分'];
  const digit = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟'],
  ];
  const head = amount < 0 ? '欠' : '';
  amount = Math.abs(amount);
  let s = '';
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(amount * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '');
  }
  s = s || '整';
  amount = Math.floor(amount);
  for (let i = 0; i < unit[0].length && amount > 0; i++) {
    let p = '';
    for (let j = 0; j < unit[1].length && amount > 0; j++) {
      p = digit[amount % 10] + unit[1][j] + p;
      amount = Math.floor(amount / 10);
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s;
  }
  return (
    head +
    s
      .replace(/(零.)*零元/, '元')
      .replace(/(零.)+/g, '零')
      .replace(/^整$/, '零元整')
  );
}
