import { MenuItem } from 'types/config';

const mmm: MenuItem = {
  path: 'mmm',
  title: '3M',
  icon: '',
  is_root: true,
  hasTag: false,
  children: [
    {
      path: 'market',
      title: '销售日常',
      icon: 'WE_AccountDailyOutlined',
      children: [
        { path: 'pipeline_list', title: '我的客户', order: 100 },
        { path: 'daily_report', title: '销售日报', order: 150 },
      ],
    },
    {
      path: 'customer',
      title: '客户列表',
      icon: 'WE_DocStampOutlined',
      children: [
        { path: 'assign', title: '线索分配' },
        { path: 'open_sea', title: '公海池' },
        { path: 'no-track', title: '长期未跟进客户' },
        { path: 'list', title: '主体列表' },
        { path: 'supplier', title: '供应商列表' },
        { path: 'black', title: '黑名单主体' },
      ],
    },
    {
      path: 'marketing',
      title: '市场',
      icon: 'WE_MetaOutlined',
      children: [{ path: 'clue', title: '市场线索' }],
    },
    {
      path: 'other',
      title: '其它',
      icon: 'WE_ThreeCirclesOutlined',
      children: [{ path: 'cfi', title: '客商信息申请' }],
    },
  ],
};
export default mmm;
