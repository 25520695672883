import { RouteRecordRaw } from 'vue-router';
import AppLayout from '@/layout/index.vue';

const erp: RouteRecordRaw = {
  path: '/erp',
  name: 'ERP',
  meta: {
    title: 'ERP',
    ignoreKeepAlive: true,
  },
  redirect: { name: 'ERPContracts' },
  component: AppLayout,
  children: [
    {
      path: 'contracts',
      name: 'ERPContracts',
      meta: { title: '合同', ignoreKeepAlive: true },
      redirect: { name: 'ERPContractsSupply' },
    },
    {
      path: 'contracts/supply',
      name: 'ERPContractsSupply',
      meta: { title: '业务采购合同', auth: ['HasMenu_ErpContractSupply'], ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/List.vue'),
    },
    {
      path: 'contracts/demand',
      name: 'ERPContractsDemand',
      meta: { title: '销售合同', ignoreKeepAlive: true, auth: ['HasMenu_ErpContractDemand'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/List.vue'),
    },
    {
      path: 'contracts/purchase',
      name: 'ERPContractsPurchase',
      meta: { title: '非业务采购合同', ignoreKeepAlive: true, auth: ['HasMenu_ErpContractPurchase'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/List.vue'),
    },
    {
      path: 'contracts/backup',
      name: 'ERPContractsBackup',
      meta: { title: '备份类合同', ignoreKeepAlive: true, auth: ['HasMenu_ErpContractBackup'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/List.vue'),
    },
    {
      path: 'contracts/fs-template',
      name: 'ERPContractFSTemplate',
      meta: { title: '飞书合同模板分类', ignoreKeepAlive: true, auth: ['HasMenu_ErpContractTemplate'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/FeiShuContractTemplate.vue'),
    },
    {
      path: 'contracts/archive',
      name: 'ERPContractArchive',
      meta: { title: '合同存档', ignoreKeepAlive: true, auth: ['HasMenu_ErpContractArchive'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/ContractArchive.vue'),
    },
    {
      path: 'contracts/detail',
      name: 'ERPContractDetail',
      meta: { title: '合同详情', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/contracts/ContractDetail.vue'),
    },

    {
      path: 'bills',
      name: 'ERPBills',
      meta: { title: '账单', ignoreKeepAlive: true },
      redirect: { name: 'ERPBillsSupply' },
    },
    {
      path: 'bills/supply',
      name: 'ERPBillsSupply',
      meta: { title: '成本账单', ignoreKeepAlive: true, auth: ['HasMenu_ErpBillSupply'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/bills/List.vue'),
    },
    {
      path: 'bills/demand',
      name: 'ERPBillsDemand',
      meta: { title: '收入账单', ignoreKeepAlive: true, auth: ['HasMenu_ErpBillDemand'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/bills/List.vue'),
    },
    {
      path: 'bills/bill-booking',
      name: 'ERPBillsBillBooking',
      meta: { title: '记账', ignoreKeepAlive: true, auth: ['HasMenu_ErpBillBooking'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/more/BillBooking.vue'),
    },
    {
      path: 'bills/detail',
      name: 'ERPBillDetail',
      meta: { title: '账单详情', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/bills/BillDetail.vue'),
    },

    {
      path: 'pays',
      name: 'ERPPays',
      meta: { title: '收付款', ignoreKeepAlive: true },
      redirect: { name: 'ERPPaysSupply' },
    },
    {
      path: 'pays/supply',
      name: 'ERPPaysSupply',
      meta: { title: '业务付款', ignoreKeepAlive: true, auth: ['HasMenu_ErpTransSupply'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/pays/List.vue'),
    },
    {
      path: 'pays/demand',
      name: 'ERPPaysDemand',
      meta: { title: '收款', ignoreKeepAlive: true, auth: ['HasMenu_ErpTransDemand'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/pays/List.vue'),
    },
    {
      path: 'pays/purchase',
      name: 'ERPPaysPurchase',
      meta: { title: '非业务付款-有合同', ignoreKeepAlive: true, auth: ['HasMenu_ErpTransPurchase'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/pays/List.vue'),
    },
    {
      path: 'pays/direct',
      name: 'ERPPaysDirect',
      meta: { title: '非业务付款-无合同', ignoreKeepAlive: true, auth: ['Role3MUser', 'Role3MUserSimple', 'RoleGod'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/pays/List.vue'),
    },
    {
      path: 'pays/detail',
      name: 'ERPPayDetail',
      meta: { title: '收付款详情', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/pays/PayDetail.vue'),
    },

    {
      path: 'invoices',
      name: 'ERPInvoices',
      meta: { title: '发票', ignoreKeepAlive: true },
      redirect: { name: 'ERPInvoicesSupply' },
    },
    {
      path: 'invoices/supply',
      name: 'ERPInvoicesSupply',
      meta: { title: '业务成本发票', ignoreKeepAlive: true, auth: ['HasMenu_ErpInvoiceSupply'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/invoices/List.vue'),
    },
    {
      path: 'invoices/demand',
      name: 'ERPInvoicesDemand',
      meta: { title: '销售发票', ignoreKeepAlive: true, auth: ['HasMenu_ErpInvoiceDemand'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/invoices/List.vue'),
    },
    {
      path: 'invoices/purchase',
      name: 'ERPInvoicesPurchase',
      meta: { title: '非业务支出发票', ignoreKeepAlive: true, auth: ['HasMenu_ErpInvoicePurchase'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/invoices/List.vue'),
    },
    {
      path: 'invoices/template',
      name: 'ERPInvoicesTemplate',
      meta: { title: '发票模板', ignoreKeepAlive: true, auth: ['HasMenu_WebeyeInvoiceConfig'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/invoices/Template.vue'),
    },
    {
      path: 'invoices/zip',
      name: 'ERPInvoiceFileZip',
      meta: { title: '发票下载结果页', ignoreKeepAlive: true, auth: ['CanSeeBatchDownloadInvoice'] },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/invoices/FileZipPage.vue'),
    },
    {
      path: 'invoices/detail',
      name: 'ERPInvoiceDetail',
      meta: { title: '发票详情', ignoreKeepAlive: true },
      props: ({ params, query }) => ({ ...params, ...query }),
      component: () => import('@/views/erp/invoices/InvoiceDetail.vue'),
    },
  ],
};
export default erp;
