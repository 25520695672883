
  import { copyValue, isEmptyData } from '@/utils/common';
  import { computed, defineComponent } from 'vue';
  export default defineComponent({
    name: 'WeEditableWarp',
    props: {
      value: {
        default: '',
      },
      edit: {
        type: Boolean,
        default: false,
      },
      inline: {
        type: Boolean,
        default: true,
      },
      copy: {
        type: Boolean,
        default: false,
      },
      spanWrapperClass: {
        type: String,
        default: '',
      },
    },
    setup(props) {
      const realValue = computed(() => {
        let { value } = props; // 这一行必须放在里面才能监听变化
        return value;
      });
      const show_copy = computed(() => {
        let { copy } = props;
        if (copy && !isEmptyData(realValue.value)) {
          return true;
        } else {
          return false;
        }
      });

      return {
        realValue,
        show_copy,
        isEmptyData,
      };
    },
    methods: {
      copyValue() {
        copyValue(this.realValue);
        this.$message.success('复制成功');
      },
    },
  });
