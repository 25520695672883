import request from '@/utils/request';
// 获取AWS config列表接口
export function get_aws_config_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_aws_config_page', params);
}
// 编辑添加 AWS config列表接口
export function edit_aws_configs(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_aws_configs', params);
}
// 获取账单明细接口
export function get_aws_billing_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_aws_billing_page', params);
}
// 获取账单明细接口
export function get_aws_billing_aggregation(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_aws_billing_aggregation', params);
}
// 获取SPP折扣表格数据
export function get_aws_billing_spp_aggregation(params: Recordable) {
  return request.post<null, Recordable[]>('/api/v1/get_aws_billing_spp_aggregation', params);
}
// 删除AWS配置
export function delete_we_cloud_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/delete_we_cloud_config', params);
}
// 编辑payer配置
export function edit_aws_payer_account_config(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/edit_aws_payer_account_config', params);
}
// 获取payer配置
export function get_aws_payer_account_config_page(params: Recordable) {
  return request.post<null, Recordable>('/api/v1/get_aws_payer_account_config_page', params);
}
