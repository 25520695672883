
  import { global_search } from '@/api/app';
  import { useAppInfo } from '@/hooks/useAppInfo';
  import { useJump } from '@/hooks/useJump';
  import { useUser } from '@/hooks/useUser';
  import { cacheStore } from '@/store/modules/cache';
  import { isEmptyData } from '@/utils/common';
  import { useDebounceFn } from '@vueuse/core';
  import { defineComponent, ref, unref } from 'vue';
  export default defineComponent({
    name: 'GlobalSearch',
    setup() {
      const result = ref<Recordable[]>([]);
      const searchContent = ref<string>('');
      const activeKey = ref<string>('');
      const tipContent = ref<string>('输入关键词或点击搜索历史查找记录');
      const seach_input = ref(null);
      const { sub_app_suffix_list } = useAppInfo();
      const searching = ref<boolean>(false);
      const entity_map = ref<{ title: String; entity: String }[]>([]);
      const { flatMenuList, global_search_list, user, global_search_items_self } = useUser();
      const { jumpToProduct, jumpToOpp } = useJump();
      const doSearch = useDebounceFn(() => {
        if (!searchContent.value || !searchContent.value.trim()) {
          tipContent.value = '输入关键词或点击搜索历史查找记录';
          result.value = [];
          entity_map.value = [];
          return;
        }
        searching.value = true;
        result.value = [];
        entity_map.value = [];
        global_search({ content: searchContent.value, version: 4 }).then((res) => {
          result.value = res;
          result.value.push({
            entity: 'Menu',
            title: '菜单',
            data_list: unref(flatMenuList)
              .filter((item) => {
                return item.title.includes(searchContent.value);
              })
              .map((mtem) => ({ id: mtem.key, name: mtem.title })),
          });
          const temp_entity_map: { title: string; entity: string }[] = [];
          result.value.forEach((item) => {
            if (!isEmptyData(item.data_list)) {
              temp_entity_map.push({
                title: item.title,
                entity: item.entity,
              });
            }
          });
          entity_map.value = temp_entity_map;
          if (!isEmptyData(temp_entity_map) && temp_entity_map.length !== 1) {
            entity_map.value.splice(0, 0, {
              title: '全部',
              entity: 'All',
            });
            activeKey.value = 'All';
          } else if (!isEmptyData(temp_entity_map) && temp_entity_map.length === 1) {
            activeKey.value = temp_entity_map[0].entity;
          }
          if (entity_map.value.length === 0) {
            tipContent.value = '没有查找到相关记录，请重新输入';
          }
          if (global_search_list.value.length === 0) {
            const temp: { emp_id: String; items: String[] } = { emp_id: '', items: [] };
            temp.emp_id = user.value.lark_employee_id;
            temp.items.unshift(searchContent.value);
            cacheStore.SET_GLOBAL_SEARCH([temp]);
          } else {
            const cloneList = global_search_list.value;
            const findIndex = cloneList.findIndex((item) => item.emp_id === user.value.lark_employee_id);
            if (findIndex === -1) {
              const temp: { emp_id: String; items: String[] } = { emp_id: '', items: [] };
              temp.emp_id = user.value.lark_employee_id;
              if (temp.items.findIndex((item) => item === searchContent.value) === -1) {
                temp.items.unshift(searchContent.value);
              }
              if (global_search_list.value.length === 5) {
                cloneList.splice(4, 1, temp);
              } else {
                cloneList.push(temp);
              }
            } else {
              if (cloneList[findIndex].items.length === 10) {
                if (cloneList[findIndex].items.findIndex((item) => item === searchContent.value) === -1) {
                  cloneList[findIndex].items.splice(4, 1);
                  cloneList[findIndex].items.unshift(searchContent.value);
                }
              } else {
                if (cloneList[findIndex].items.findIndex((item) => item === searchContent.value) === -1) {
                  cloneList[findIndex].items.unshift(searchContent.value);
                }
              }
            }
            cacheStore.SET_GLOBAL_SEARCH(cloneList);
          }
          searching.value = false;
        });
      }, 500);
      return {
        seach_input,
        result,
        user,
        searchContent,
        doSearch,
        searching,
        flatMenuList,
        sub_app_suffix_list,
        entity_map,
        activeKey,
        tipContent,
        global_search_list,
        global_search_items_self,
        jumpToProduct,
        jumpToOpp,
      };
    },
    data: () => ({
      globalSearchVisible: false,
    }),
    computed: {
      filter_resulet() {
        if (this.activeKey === 'All') {
          return this.result;
        } else if (!isEmptyData(this.searchContent)) {
          return this.result.filter((item) => item.entity === this.activeKey);
        } else {
          return [];
        }
      },
    },
    methods: {
      async jumpTo(entity: string, id: number | string) {
        if (entity === 'Menu') {
          const findItem = this.sub_app_suffix_list.find((item) => (id as string).includes(item));
          if (!isEmptyData(findItem)) {
            await history.pushState(
              {
                ...history.state,
                ...{
                  back: this.$route.fullPath,
                  current: (id as string).replaceAll(findItem as string, ''),
                },
              },
              '',
              id as string
            );
            location.reload();
          } else {
            this.$router.push({ path: id as string });
          }
        }

        const jumpMap: { routerName: string; routerKey: string; entity: string; type: string }[] = [
          { entity: 'Opp', routerName: 'PipelineDetail', routerKey: 'pipelineId', type: 'query' },
          { entity: 'OrderCs', routerName: 'OrderDetail', routerKey: 'orderId', type: 'query' },
          { entity: 'Company', routerName: 'CustomerDetail', routerKey: 'companyId', type: 'query' },
          { entity: 'Contract', routerName: 'ERPContractDetail', routerKey: 'contractId', type: 'query' },
          { entity: 'Bill', routerName: 'ERPBillDetail', routerKey: 'billId', type: 'query' },
          { entity: 'Pay', routerName: 'ERPPayDetail', routerKey: 'payId', type: 'query' },
          { entity: 'Product', routerName: 'PUBProductDetail', routerKey: 'id', type: 'query' },
          { entity: 'Invoice', routerName: 'ERPInvoiceDetail', routerKey: 'invoiceId', type: 'query' },
        ];

        const routerData = jumpMap.find((item) => item.entity === entity);
        if (routerData) {
          window.open(
            this.$router.resolve({
              name: routerData?.routerName,
              query: { [routerData?.routerKey as string]: id },
            }).href
          );
        }
        // this.globalSearchVisible = false;
      },
      afterClose() {
        this.result = [];
        this.searchContent = '';
        this.searching = false;
        this.entity_map = [];
      },
      deleteHistory() {
        const cloneList = this.global_search_list;
        const index = cloneList.findIndex((item) => item.emp_id === this.user.lark_employee_id);
        cloneList.splice(index, 1);
        cacheStore.SET_GLOBAL_SEARCH(cloneList);
      },
      searchByHistory(val) {
        if (this.searchContent === val) {
          return;
        }
        this.searchContent = val;
        this.doSearch();
      },
    },
    watch: {
      globalSearchVisible(val) {
        if (val) {
          this.$nextTick(() => {
            (this.seach_input as any).focus();
          });
        }
      },
    },
  });
