import { IToDoConfig } from '@/api/types/workbench';
import { SelectOption } from 'types/store';

export enum OptionLogAction {
  create = '创建',
  distribute = '分配',
  claim = '认领',
  sendback = '退回',
}

export const phoneReg = /^(\d){9,}$/;
export const emailReg = /^[A-Za-z0-9]+([_.][A-Za-z0-9]+)*@([A-Za-z0-9-]+\.)+[A-Za-z]{2,6}$/;

export const CHINA_CODE = '156';
export const TEMPLATE_URL = 'https://cache.modooplay.com/xlsx/template.xlsx';
export const DOWNLOAD_URL = 'https://cache.modooplay.com/';
export const isLocal = process.env.VUE_APP_ENV === 'local';
export const isDev = process.env.VUE_APP_ENV === 'development';
export const isTest =
  location.hostname === 'hellowe-test.webeye.cn' ||
  location.hostname === 'hellowe-bk2.webeye.cn' ||
  location.hostname === '39.108.128.72';
export const isProd =
  location.hostname === 'hellowe.webeye.cn' ||
  location.hostname === 'hellowe.chinawebeye.com' ||
  location.hostname === '120.78.92.152';

export const isOut = location.hostname === '139.196.138.145';
export const isPc = !window.navigator.userAgent.includes('Mobile');
export const isLark = window.navigator.userAgent.includes('Lark');
export const cubeOssTest = 'http://106.15.186.75:8080/';
export const cubeOss = 'http://sophon-resource.webeyeug.com.cn/';
/*
5.字符串 10 整形 25.日期，30.日期时间，35.单选框，40.多选框，
45.下拉列表，50.多选下拉列表， 55.文本域
80.附件(只保存路径，多个使用逗号隔开) 85 富文本 90 国家地区 95 excel表
*/

export const fieldTypeMap = {
  5: {
    tag: 'WeInput',
    span: 12,
  },
  10: {
    tag: 'WeInputNumber',
    span: 12,
  },
  25: {
    tag: 'WeInputNumberDate',
    span: 12,
  },
  30: {
    tag: 'WeInputNumberDateTime',
    span: 12,
  },
  35: {
    tag: 'WeRadioGroup',
    span: 12,
  },
  40: {
    tag: 'WeCheckboxGroup',
    span: 12,
  },
  45: {
    tag: 'WeSingleSelect',
    span: 12,
  },
  50: {
    tag: 'WeMultiSelect',
    span: 12,
  },
  55: {
    tag: 'WeTextArea',
    span: 24,
  },
  80: {
    tag: 'WeUpload',
    span: 12,
  },
  85: {
    tag: 'WeWangEditor',
    span: 24,
  },
  90: {
    tag: 'WeLocationSelect',
    span: 12,
  },
  95: {
    tag: 'WeAssess',
    span: 24,
  },
};

export const accessOptions: SelectOption[] = [
  { id: 0, name: 'S' },
  { id: 1, name: 'A+' },
  { id: 2, name: 'A' },
  { id: 3, name: 'A-' },
  { id: 4, name: 'B+' },
  { id: 5, name: 'B' },
  { id: 6, name: 'B-' },
  { id: 7, name: 'C+' },
  { id: 8, name: 'C' },
  { id: 9, name: 'C-' },
];

export const operation_list: { label: string; value: string; msg: string }[] = [
  {
    label: '小说app',
    value: 'novel',
    msg: '仅适用于小说APP的内容浏览功能条款',
  },
  {
    label: '搜索功能',
    value: 'search',
    msg: '在APP内可以输入关键字搜索的，需要填写“搜索”',
  },
  {
    label: '普通聊天',
    value: 'chat',
    msg: '仅适用于一般的社交聊天工具',
  },
  {
    label: '红包群聊天',
    value: 'red_envelope',
    msg: '适用于成语红包群聊天APP',
  },
  {
    label: '相机',
    value: 'camera',
    msg: '适用于需要打开相机、摄像头的APP',
  },
  {
    label: '提现',
    value: 'withdrawal',
    msg: '网赚、提现APP必须填写',
  },
  {
    label: '宗门',
    value: 'fission',
    msg: '宗门系统邀请好友',
  },
  {
    label: '悬浮窗',
    value: 'floating',
    msg: '适用于带悬浮窗的APP',
  },
  {
    label: '步数',
    value: 'pedometer',
    msg: '适用于步数功能APP',
  },
  {
    label: '内购',
    value: 'purchase',
    msg: '适用于内购场景的APP，充值与消费条款',
  },
  {
    label: '关闭消息推送',
    value: 'turnoff_msg',
    msg: '适用于有推送功能的APP，会增加"自主管理推送"的内容',
  },
  {
    label: '防沉迷',
    value: 'anti_game',
    msg: '适用于接入防沉迷系统的游戏APP',
  },
  {
    label: '多渠道包',
    value: 'multi',
    msg: '适用于：同一个产品，多渠道隐私政策，名称不同；选择后，会从apk分析得到产品名称，覆盖上面的product选项',
  },
  {
    label: '自研产品',
    value: 'inter_dev',
    msg: '自研产品必选',
  },
  {
    label: '上架应用市场的发行产品',
    value: 'app_market',
    msg: '上架应用市场的发行产品必选',
  },
  {
    label: '显示开发者名称',
    value: 'show_dev_name',
    msg: '显示开发者名称',
  },
  {
    label: '游戏平台搜索',
    value: 'search_game',
    msg: '适用于游戏平台搜索功能',
  },
  {
    label: '发布与互动',
    value: 'publish_interact',
    msg: '适用于APP信息发送互动功能',
  },
  {
    label: '活动与分享',
    value: 'action_share',
    msg: '适用于活动分享功能',
  },
  {
    label: '个性化推荐',
    value: 'recommendation',
    msg: '适用于个性化推荐功能',
  },
  {
    label: '显示授权（字节专用）',
    value: 'show_licence',
    msg: '勾选此选项将在文本中展示发行商和开发商的授权关系',
  },
];

// 广告插入类型
export const advertisers: { id: number; name: string }[] = [
  {
    id: 0,
    name: '插屏图片',
  },
  {
    id: 1,
    name: '全屏视频',
  },
  {
    id: 2,
    name: '激励视频',
  },
  {
    id: 3,
    name: 'Banner',
  },
  {
    id: 4,
    name: '信息流',
  },
  {
    id: 5,
    name: '开屏',
  },
];

export const auditStatusCn: string[] = ['未提交', '审核中', '被驳回', '已通过'];

export const toDoMap: IToDoConfig[] = [
  {
    key: 'bill_todo',
    count_key: 'bill_todo_count',
    title: '账单',
    icon: 'WE_BillsOutlined',
    color: '#78C87D',
  },
  {
    key: 'contract_todo',
    count_key: 'contract_todo_count',
    title: '合同',
    icon: 'WE_DocStampOutlined',
    color: '#71C8CA',
  },
  {
    key: 'pay_todo',
    count_key: 'pay_todo_count',
    title: '收付款',
    icon: 'WE_RMBSyncDotsOutlined',
    color: '#ffff4e',
  },
  {
    key: 'invoice_todo',
    count_key: 'invoice_todo_count',
    title: '发票',
    icon: 'WE_InvoiceOutlined',
    color: '#8676FF',
  },
  {
    key: 'recharge_todo',
    count_key: 'recharge_todo_count',
    title: '授信充值',
    icon: 'AlertOutlined',
    color: '#4885ED',
  },
  {
    key: 'pipeline_todo',
    count_key: 'opp_todo_count',
    title: '客户',
    icon: 'WE_AllCustomersOutlined',
    color: '#fa58d0',
  },
  {
    key: 'credit_todo',
    count_key: 'credit_todo_count',
    title: '授信申请',
    icon: 'SolutionOutlined',
    color: '#a5df00',
  },
  {
    key: 'opa_apply_todo',
    count_key: 'opa_apply_todo_count',
    title: '平台开户审核',
    icon: 'WE_UserCheckOutlined',
    color: '#00BFFF',
    preventClick: true,
    routerName: 'UGSOpenAccountAuditList',
  },
  {
    key: 'ml_account_todo',
    count_key: 'mlapply_todo_count',
    title: '买量账号申请',
    icon: 'WE_BuildingOutlined',
    color: '#00d836',
  },
  {
    key: 'ugs_recharge_todo',
    count_key: 'ugs_recharge_todo_count',
    title: '平台充值审核',
    icon: 'WE_UserCheckOutlined',
    color: '#00CED1',
    preventClick: true,
    routerName: 'UGSOpenAccountRechargeList',
  },
  {
    key: 'product_review_todo',
    count_key: 'product_review_todo_count',
    title: '产品审核',
    icon: 'InboxOutlined',
    color: '#f7819f',
  },
  {
    key: 'confirm_alert_mail_todo',
    count_key: 'confirm_alert_mail_todo_count',
    title: '付款预警邮件',
    icon: 'WE_ProductCheckOutlined',
    color: '#ff0000',
    preventClick: true,
    routerName: 'OtherAlertPayMail',
  },
  {
    key: 'media_rs_rebate_todo',
    count_key: 'media_rs_rebate_todo_count',
    title: '合同返点设置',
    icon: 'WE_UserCheckOutlined',
    color: '#00CED1',
  },
  {
    key: 'media_contract_allot_todo',
    count_key: 'media_contract_allot_todo_count',
    title: '待分配合同',
    icon: 'WE_UserCheckOutlined',
    color: '#00CED1',
  },
  {
    key: 'cashpayapply_todo',
    count_key: 'cashpayapply_todo_count',
    title: '大额兑付',
    icon: 'WE_RMBCheckOutlined',
    color: '#4b4bd4',
  },
  {
    key: 'cashconfigapply_todo',
    count_key: 'cashconfigapply_todo_count',
    title: '兑付配置',
    icon: 'WE_RMBSyncOutlined',
    color: '#78C5cD',
  },
  {
    key: 'privacy_todo',
    count_key: 'privacy_todo_count',
    title: '隐私政策',
    icon: 'WE_ComplianceLogoOutlined',
    color: '#8258fa',
  },
  {
    key: 'order_cs_todo',
    count_key: 'order_cs_todo_count',
    title: '商机',
    icon: 'AlertOutlined',
    color: '#4885ED',
  },
];

export const filed_types: SelectOption[] = [
  {
    id: 10,
    name: 'Int',
  },
  {
    id: 20,
    name: 'Float',
  },
  {
    id: 30,
    name: 'Bool',
  },
  {
    id: 40,
    name: 'Datetime',
  },
  {
    id: 50,
    name: 'Select',
  },
  {
    id: 60,
    name: 'Multi Select',
  },
  {
    id: 70,
    name: 'Text',
  },
];

export enum ACCOUNT_REVIEW_ACTION {
  UNkNOWN,
  APPLY_TRY, // 申请试用账号
  EXCHANGE_OFFICIAL, // 转为正式账号
  EDIT_CHARGES, // 修改次数
  ADD_ACCOUNT, // 添加账号
  EDIT_ACCOUNT, // 编辑账号
}

export const customerTypes: SelectOption[] = [
  {
    id: 0,
    name: 'Gameing',
  },
  {
    id: 1,
    name: 'Publish(发行)',
  },
  {
    id: 2,
    name: 'Marketing & Advertising',
  },
  {
    id: 3,
    name: 'Internet',
  },
  {
    id: 4,
    name: 'Online Media',
  },
];

export const serviceTypes: SelectOption[] = [
  {
    id: 'cube模板平台',
    name: 'cube模板平台',
  },
  {
    id: 'tube定制业务',
    name: 'tube定制业务',
  },
];

export const gameTypes: SelectOption[] = [
  {
    id: 0,
    name: '节奏',
  },
  {
    id: 1,
    name: '模拟经营',
  },
  {
    id: 2,
    name: '射击',
  },
  {
    id: 3,
    name: '卡牌',
  },
  {
    id: 4,
    name: '塔防',
  },
  {
    id: 5,
    name: '休闲',
  },
  {
    id: 6,
    name: '棋牌',
  },
  {
    id: 7,
    name: '三消',
  },
  {
    id: 8,
    name: '益智',
  },
  {
    id: 9,
    name: 'SLG',
  },
  {
    id: 10,
    name: '竞速',
  },
  {
    id: 11,
    name: '博彩',
  },
  {
    id: 12,
    name: '社交',
  },
  {
    id: 13,
    name: '新闻',
  },
  {
    id: 14,
    name: '实用工具',
  },
  {
    id: 15,
    name: '天气',
  },
  {
    id: 16,
    name: '生活方式',
  },
];

export const coopStates: SelectOption[] = [
  {
    id: 0,
    name: '谈判中',
  },
  {
    id: 1,
    name: '入库',
  },
  {
    id: 2,
    name: '已入库',
  },
  {
    id: 3,
    name: '素材采集中',
  },
  {
    id: 4,
    name: '合作中',
  },
];

export const buyTimes: any[] = [
  {
    value: 30,
  },
  {
    value: 60,
  },
  {
    value: 120,
  },
  {
    value: 180,
  },
  {
    value: 300,
  },
];

export const accountTypes: SelectOption[] = [
  {
    id: 0,
    name: '主账号',
  },
  {
    id: 1,
    name: '子账号',
  },
];

export const printInvoiceData: Recordable = {
  regNo: '201831780G',
  gtsEmail: 'fin-billing@webeye.com',
};
export const FILE_ICON_MAP: Recordable = {
  txt: 'WE_TxtColor',
  zip: 'WE_ZipColor',
  apk: 'WE_ApkColor',
  video: 'WE_VideoColor',
  link: 'WE_LinkColor',
  excel: 'WE_ExcelColor',
  xlsx: 'WE_ExcelColor',
  word: 'WE_WordColor',
  doc: 'WE_WordColor',
  img: 'WE_PictureColor',
  pdf: 'WE_PDFColor',
};
export const IS_ANDROID = 2;
export const IS_IOS = 1;
export const COMMON_COMPANY_TYPE = {
  IS_INTERNAL: 1, //内部
  IS_EXTERNAL: 2, //外部
};
export const withdraw_desc_tip = `（1）概括表述（具体还是要结合APP实际来）
        参考一：金额门槛+人数要求
        用户提现除需要满足提现门槛（具体见提现界面），还需要邀请5名好友且提现金额均达80元。
        参考二：金额门槛
        用户提现需要满足提现门槛（具体见提现界面）
        （2）具体表述（具体还是要结合APP实际来）
        a、用户使用游戏中的虚拟道具：红包、元宝、积分等进行提现活动，需要满足游戏中提现界面规定的登录天数、视频播放量、关卡数量、等级数量、虚拟道具到达量、虚拟道具存量等提现条件。
        b、未达成游戏提现界面中规定的任意一项内容的，不予提现。
        c、我们会在核实您身份及提现行为合规正常后30天内完成提现
        （3）具体示例
        一、红包提现：
        1.需按照游戏内要求，升级餐厅达到对应等级要求，并确保账户金额余额充足。（具体见提现界面描述）
        2.未达成游戏提现界面中规定的任意一项内容的，不予提现。
        3.我们会在核实您身份及提现行为合规正常后30天内完成提现。
        二、每日打卡提现
        1.需要满足每日的观看视频数量要求（具体见提现界面）
        2.需要达到游戏界面中提示的等级要求（具体见提现时提示要求）
        3.需要在天数总量上达到游戏界面进度显示及描述的要求。
        `;
export const PRODUCT_STATUS_MAP = {
  全部: 0,
  新建: 1000,
  评估: 1100,
  审核: 1150,
  吸量: 1200,
  接入: 1300,
  QA: 1500,
  投放: 1600,
  下线: 4000,
  评估未通过: 4100,
};

export const MENU_ORDER = {
  DEFAULT: 100,
  DASHBOARD: 200,
  EMBED: 300,
};
